import {
  computed,
  ref,
  watch,
} from 'vue';

import useStore from '@/store/useStore';
import { RESET_AUTH_STATE } from '@/store/modules/app/action-types';
import { IS_LOADING } from '@/store/modules/tenants/mutation-types';
import { TDomainError } from '@/types';

export const useAppGlobalEntities = () => {
  const store = useStore();

  const isGlobalEntitiesLoading = ref(false);

  const isUserLoading = computed(() => store?.getters['user/userLoading']);
  const isTenantLoading = computed(() => store?.getters['tenants/tenantLoading']);
  const userTenants = computed(() => store?.getters['tenants/availableUserTenants']);
  const currentUserData = computed(() => store?.getters['user/currentUser']);
  // Если oneTimePassword === true, то fetchTenant - отдаст 403, т.к. не будет доступа к tenants
  // чтобы не делать ненужного запроса и не ловить ошибку - добавим проверку на otp
  const isUserLoggedInByOTP = computed(() => store.getters['user/currentUser']?.lockedSince && store.getters['user/currentUser']?.oneTimePassword);

  const setGlobalEntitiesLoading = (isLoading: boolean) => {
    isGlobalEntitiesLoading.value = isLoading;
  };

  const fetchGlobalEntities = () => {
    store.commit(`user/${IS_LOADING}`, true);
    store.commit(`tenants/${IS_LOADING}`, true);
    if (currentUserData.value && !isUserLoggedInByOTP.value) {
      return store.dispatch('tenants/fetchTenants')
        .catch((error: TDomainError) => {
          store.dispatch(`app/${RESET_AUTH_STATE}`);
          throw error;
        })
        .finally(() => {
          store.commit(`user/${IS_LOADING}`, false);
          store.commit(`tenants/${IS_LOADING}`, false);
        });
    }

    if (!currentUserData.value) {
      return store.dispatch('user/fetchUser')
        .then(() => {
          if (!isUserLoggedInByOTP.value) {
            return store.dispatch('tenants/fetchTenants');
          }
        })
        .catch((error: TDomainError) => {
          store.dispatch(`app/${RESET_AUTH_STATE}`);
          throw error;
        })
        .finally(() => {
          store.commit(`user/${IS_LOADING}`, false);
          store.commit(`tenants/${IS_LOADING}`, false);
        });
    }
    return Promise.resolve();
  };

  // Смотрим на изменение стейта загрузки пользователя и тенантов
  // Сетим isGlobalEntitiesLoading для того чтобы избежать рывков в анимации лоадера приложения
  watch(() => [isUserLoading.value, isTenantLoading.value], ([isUserLoading, isTenantLoading]: boolean[]) => {
    setGlobalEntitiesLoading(isUserLoading || isTenantLoading);
  });

  return {
    userTenants,
    isGlobalEntitiesLoading,

    fetchGlobalEntities,
  };
};

<script lang="ts">

import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Redirect',
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    onMounted(() => {
      router.push(props.to);
    });
  },
  render: () => null,
});
</script>

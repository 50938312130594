import { TNotification } from '@/contexts/notificationsContext/domain/types';
import getNotificationMapping from '@/contexts/notificationsContext/domain/getNotificationMapping';
import { isString } from '@/utils';

const getNotificationMessage = (notification: TNotification) => {
  const notificationMapping = getNotificationMapping(notification.type, notification.payload);
  if (!notificationMapping) return '';
  const { template } = notificationMapping;
  if (isString(template)) return template;
  return template(notification.payload);
};

export default getNotificationMessage;

<template>
  <PaywallMessage>
    <template #text>
      {{ tt('tenant.licensePaywallMessage.text') }}
    </template>
    <template #action>
      <Button
        :type="EButtonType.primary"
        :size="ESize.small"
        :title="tt('tenant.licensePaywallMessage.action')"
        @click="openModal"
      />
    </template>
  </PaywallMessage>

  <LicenseRoleSelectionModal
    v-if="isOpenModal"
    @close="isOpenModal = false"
  />
</template>

<script lang="ts">

import {
  defineComponent,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import PaywallMessage from '@/components/PaywallMessage/index.vue';
import { Button } from '@/ui';
import { EButtonType, ESize } from '@/ui/types';

import LicenseRoleSelectionModal from '../LicenseRoleSelectionModal/index.vue';

export default defineComponent({
  name: 'LicensePaywallMessage',
  components: {
    Button,
    PaywallMessage,
    LicenseRoleSelectionModal,
  },
  setup() {
    const isOpenModal = ref<boolean>(false);

    const openModal = () => {
      isOpenModal.value = true;
    };

    return {
      isOpenModal,
      EButtonType,
      ESize,

      tt,
      openModal,
    };
  },
});
</script>

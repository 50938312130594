import {
  Ref,
  ref,
} from 'vue';

import { DropdownList } from '@/ui';
import { EKeyboardKey } from '@/constants';

import DatePickerInput from '../components/DatePickerInput/index.vue';

type TUseChangeDropdownVisibility = {
  dropdownRef: Ref<InstanceType<typeof DropdownList> | null>,
  datePickerInputRef: Ref<InstanceType<typeof DatePickerInput> | null>,
};

export const useChangeDropdownVisibility = ({ dropdownRef, datePickerInputRef }: TUseChangeDropdownVisibility) => {
  const isDropdownVisible = ref(false);
  const isInputFocus = ref(false);
  const isSelectFocus = ref(false);

  /**
   * Признак того, что событие blur у Select не должно вызывать логику закрытия панели dropdown.
   * Используется только при переходе с одного Select на другой (месяц <-> год).
   * Потребность в данном параметре обусловлена тем, что при переключении между элементами, событие blur элемента, который покидаем,
   * возникает позже, чем событие focus элемента, на который переключаемся. И если не обработать такую ситуацию, будут непреднамеренные закрытия dropdown.
   */
  const isPreviousSelectBlurPrevent = ref(false);

  const isDropdownFocused = () => dropdownRef.value?.dropdown?.isEqualNode(document.activeElement);

  const handleDatePickerInputFocus = () => {
    isInputFocus.value = true;
    isDropdownVisible.value = true;
  };

  const handleDatePickerInputBlur = () => {
    isInputFocus.value = false;

    // Использование setTimout вызвано тем, что могут быть переключения между элементами (смена активного элемента) в рамках компонента
    // и поэтому обработчик на закрытие dropdown нужно перенести в конец очереди задач,
    // чтобы успели отработать обработчики событий (focus), в которых выставляется признак активного элемента.
    setTimeout(closeDropdown);
  };

  const handleDropdownBlur = () => {
    closeDropdown();
  };

  const handleSelectFocus = () => {
    // Если флаг isSelectFocus true значит было переключение с Select (месяц) на Select (год) или наоборот (год <-> месяц).
    // Устанавливаем признак isPreviousSelectBlurPrevent, для корректной работы обработчика события blur предыдущего активного Select.
    if (isSelectFocus.value) {
      isPreviousSelectBlurPrevent.value = true;
    } else {
      isSelectFocus.value = true;
    }
  };

  const handleSelectBlur = () => {
    if (isPreviousSelectBlurPrevent.value) {
      isPreviousSelectBlurPrevent.value = false;

      return;
    }

    isSelectFocus.value = false;

    setTimeout(closeDropdown);
  };

  const resetDropdownVisibilityOptions = () => {
    isSelectFocus.value = false;
    isInputFocus.value = false;
    isDropdownVisible.value = false;
    isPreviousSelectBlurPrevent.value = false;

    datePickerInputRef.value?.resetSelectedSection();
  };

  const closeDropdown = () => {
    // Если фокус на элементе, размещенном в контейнере dropdown. К примеру, фокус на кнопке "Сегодня".
    if (dropdownRef.value?.dropdown?.contains(document.activeElement)
    // Или если фокус на одном из элементов (панель dropdown, поле ввода даты, Select месяц/год)
    || isDropdownFocused() || isInputFocus.value || isSelectFocus.value) {
      return;
    }

    resetDropdownVisibilityOptions();
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (!isDropdownVisible.value) return;

    if (event.key === EKeyboardKey.tab) {
      if (isInputFocus.value) {
        setDropdownFocus();
      }
    } else if (event.key === EKeyboardKey.escape) {
      if (isInputFocus.value) {
        datePickerInputRef.value?.blur();
      }

      resetDropdownVisibilityOptions();
    }
  };

  const setDropdownFocus = () => {
    dropdownRef.value?.focus({ preventScroll: true });
  };

  return {
    isDropdownVisible,

    handleDatePickerInputFocus,
    handleDatePickerInputBlur,
    handleDropdownBlur,
    handleSelectFocus,
    handleSelectBlur,
    handleKeydown,
    setDropdownFocus,
  };
};

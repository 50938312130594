import * as types from './mutation-types';
import { TStrategiesActions } from './types';

const actions: TStrategiesActions = {
  setChainId: ({ commit }, { strategyId, automationId, chainId }) => {
    commit(types.SET_CHAIN_ID, {
      strategyId,
      automationId,
      chainId,
    });
  },
};

export default actions;

import { THEMES } from '@/themeConstants';

const useAppTheme = () => {
  const currentTheme = import.meta.env.VITE_APP_THEME_CONFIG_NAME || 'default';

  const getThemeConfig = () => THEMES[currentTheme];

  return {
    currentTheme,
    getThemeConfig,
  };
};

export default useAppTheme;

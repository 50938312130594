import { useRoute } from 'vue-router';
import {
  computed,
  ComputedRef,
  ref,
} from 'vue';

import { EExperimentalFeatures } from '@/domains/constants';
import store from '@/store';
import { ROUTE_TYPE } from '@/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';

type TUseChangeBillingDebtPaywallMessageVisibility = {
  isBillingDebtPaywallMessageShown: ComputedRef<boolean>,
  handleBillingDebtPaywallMessageVisibility: () => void,
};

export const useChangeBillingDebtPaywallMessageVisibility = (): TUseChangeBillingDebtPaywallMessageVisibility => {
  const route = useRoute();
  const isPrivateRoute = computed(() => route?.meta?.type === ROUTE_TYPE.private);

  const hasTenants = computed(() => store.state.tenants.userTenants?.length > 0);

  const isBillingDebtPaywallMessageAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingDebtPaywallMessage),
  );

  const hasInstanceLicense = computed(() => !!store.state.app.appSettings?.licenseSettings);

  const isBillingDebtPaywallMessageVisible = ref<boolean>(true);

  const handleBillingDebtPaywallMessageVisibility = () => {
    isBillingDebtPaywallMessageVisible.value = false;
  };

  // инстанс может работать либо по лицензии, либо с биллингом
  const isBillingDebtPaywallMessageShown = computed(() => !hasInstanceLicense.value
    && isBillingDebtPaywallMessageAvailable.value
    && isBillingDebtPaywallMessageVisible.value
    && hasTenants.value
    && isPrivateRoute.value,
  );

  return {
    isBillingDebtPaywallMessageShown,

    handleBillingDebtPaywallMessageVisibility,
  };
};

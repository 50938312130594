import { EAppState, EWasAuthBy } from '@/constants';
import { signOut } from '@/contexts/accountingContext/services';
import { disconnectConsumer } from '@/websocket/controller';
import { TSberDataParams } from '@/components/SberBusinessIntegrationButton/domain/types';
import { TSessionWithTokensSerialized, TUserResponse } from '@/contexts/accountingContext/domain/types';
import { TApplicationSettings } from '@/types';
import { useViewSettingsStore } from '@/stores/viewSettings';
import { useCustomizationStore } from '@/stores/customization';
import { useSearchStore } from '@/stores/search';
import { useExternalDigitalQueuesStore } from '@/stores/externalDigitalQueues';
import { useFoldersStore } from '@/stores/folders';
import { useDigitalQueueSettingsStore } from '@/stores/digitalQueueSettings';
import { useResourcesControlStore } from '@/stores/resourcesControl';
import { useCombinedOrdersStore } from '@/stores/CombinedOrders';

import {
  SET_APP_STATE,
  SET_TOKENS,
  SET_LAST_DATA_BEFORE_AUTH,
  DELETE_TOKENS,
  SET_WAS_AUTH_BY,
  SET_COLLAPSED,
  SET_LEGISLATIVE_REMARK_VISIBLE,
  SET_IS_TAB_ACTIVE,
  SET_FOLDER_OPEN_KEYS,
  SET_SBER_PARAMS,
  SET_APP_SETTINGS,
} from './mutation-types';
import { TAppActions, TLastDataBeforeAuthParams } from './types';
import { RESET_AUTH_STATE } from './action-types';

export const actions: TAppActions = {
  proceedAuthentication: ({ commit }, user: TUserResponse | null) => {
    if (user?.lockedSince || user?.oneTimePassword) {
      commit(SET_APP_STATE, EAppState.interactionLocked);
    } else {
      commit(SET_APP_STATE, EAppState.authenticated);
    }
  },
  setAuthTokens: ({ commit }, tokens: TSessionWithTokensSerialized) => {
    commit(SET_TOKENS, tokens);
  },
  logout: ({ dispatch, rootState }) => {
    const { refreshToken } = rootState.app;
    if (!refreshToken) return;

    disconnectConsumer();

    signOut({ refreshToken })
      .finally(() => {
        dispatch(RESET_AUTH_STATE);
      });
  },
  [RESET_AUTH_STATE]: ({ commit, dispatch }) => {
    const viewSettingsStore = useViewSettingsStore();
    const customizationStore = useCustomizationStore();
    const searchStore = useSearchStore();
    const externalDigitalQueuesStore = useExternalDigitalQueuesStore();
    const foldersStore = useFoldersStore();
    const digitalQueueSettingsStore = useDigitalQueueSettingsStore();
    const resourcesControlStore = useResourcesControlStore();
    const combinedOrdersStore = useCombinedOrdersStore();

    commit(SET_LAST_DATA_BEFORE_AUTH, { key: 'entryUid' });
    commit(DELETE_TOKENS);
    commit(SET_APP_STATE, EAppState.unauthenticated);
    dispatch('user/resetState', undefined, { root: true });
    dispatch('tenants/resetState', undefined, { root: true });
    dispatch('notifications/resetState', undefined, { root: true });
    dispatch('filter/resetState', undefined, { root: true });

    foldersStore.$reset();
    viewSettingsStore.$reset();
    customizationStore.$reset();
    searchStore.$reset();
    externalDigitalQueuesStore.$reset();
    digitalQueueSettingsStore.$reset();
    resourcesControlStore.$reset();
    combinedOrdersStore.$reset();
  },
  setWasAuthBy: ({ commit }, wasAuthBy: EWasAuthBy) => {
    commit(SET_WAS_AUTH_BY, wasAuthBy);
  },
  setMaintenanceMode: ({ commit }) => {
    commit(SET_APP_STATE, EAppState.onMaintenance);
  },
  setAuthenticatedMode: ({ commit }) => {
    commit(SET_APP_STATE, EAppState.authenticated);
  },
  setCollapsed: ({ commit }, collapsed: boolean) => {
    commit(SET_COLLAPSED, collapsed);
  },
  setLegislativeRemarkVisible: ({ commit }, isVisible: boolean) => {
    commit(SET_LEGISLATIVE_REMARK_VISIBLE, isVisible);
  },
  setIsTabActive: ({ commit }, isTabActive: boolean) => {
    commit(SET_IS_TAB_ACTIVE, isTabActive);
  },
  setFolderOpenKeys: ({ commit }, folderOpenKeys: string[]) => {
    commit(SET_FOLDER_OPEN_KEYS, folderOpenKeys);
  },
  setSberParams: ({ commit }, value: TSberDataParams | null) => {
    commit(SET_SBER_PARAMS, value);
  },
  setLastDataBeforeAuth: ({ commit }, value: TLastDataBeforeAuthParams) => {
    commit(SET_LAST_DATA_BEFORE_AUTH, value);
  },
  setAppSettings: ({ commit }, value: TApplicationSettings) => {
    commit(SET_APP_SETTINGS, value);
  },
};

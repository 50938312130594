import { TFormatDate } from '@/utils/dateUtils/domains/types';
import { DATE_FORMAT } from '@/constants';
// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';
import { TDateAppFormat } from '@/types';

/**
 * (2021-10-29T15:21:00.000Z  { null , DATE_FORMAT.fullDate }) -> 29.10.2021
 * (2021-10-29T15:21:00.000Z { null , DATE_FORMAT.hoursMinutes }) -> 18:21
 */
const formatDate: TFormatDate = (
  date: TDateAppFormat | number | string,
  {
    inputFormat = null,
    outputFormat = DATE_FORMAT.fullDateWithHoursMinutes,
    timeZone,
    dateInUTC = false,
  }: {
    inputFormat?: string | null,
    outputFormat?: string,
    timeZone?: string,
    dateInUTC?: boolean,
  } = {}) => {
  const dayjsObject = inputFormat ? dayjs(date, inputFormat) : dayjs(date);

  if (!dayjsObject.isValid()) return '';

  if (dateInUTC) {
    return (timeZone ? dayjsObject.utc().tz(timeZone) : dayjsObject.utc()).format(outputFormat);
  }

  return (timeZone ? dayjsObject.tz(timeZone) : dayjsObject).format(outputFormat);
};

export default formatDate;

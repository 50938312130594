import * as types from './mutation-types';
import { TTimerActions } from './types';

const actions: TTimerActions = {
  setEndDate: ({ commit }, payload) => {
    commit(types.SET_END_DATE, payload);
  },
};

export default actions;

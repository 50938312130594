let stack: PromiseLike<unknown>[] = [];

const deleteTaskPromiseFromStack = (taskPromise: PromiseLike<unknown>) => {
  stack = stack.filter((item) => item !== taskPromise);
};

export const addTaskToStack = () => {
  let resolver: ((value?: unknown) => void) | null = null;
  const promise = new Promise((resolve) => {
    resolver = resolve;
  });
  stack.push(promise);

  const resolveAndDeleteFromStack = () => {
    if (resolver) {
      resolver();
      deleteTaskPromiseFromStack(promise);
    }
  };

  return resolveAndDeleteFromStack;
};

export const getTasksStackPromise = () => Promise.all(stack);

import { Ref, ComputedRef } from 'vue';

import {
  TRawSelectValue,
  TSelectOption,
  TSelectValue,
} from '@/ui/types';
import { hasProperty } from '@/utils';

export const getSelectedValue = (
  value: Ref<TSelectValue | null>,
  options: ComputedRef<TSelectOption[]>,
): TRawSelectValue => {
  if (!value.value && value.value !== 0) return '';
  // если переданное value - объект, то выставляем label у этого объекта как текущее значение
  if (typeof value.value === 'object' && hasProperty(value.value, 'value')) {
    return (value.value as TSelectOption).label;
  }
  if (options.value.length > 0) {
    // пытаемся найти текущее value внутри options (если пришел id например и нам надо вывести значение по id)
    const indexValue = options.value.findIndex((item: TSelectOption) => item.value === value.value);
    if (indexValue !== -1) {
      return options.value[indexValue].label;
    }
  }
  // если не смогли сопоставить value с options, или массива options нет
  return value.value as TRawSelectValue;
};

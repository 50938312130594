/**
   * Функция возвращает объект с ключами value, hasDot и hasMinus.
   *
   * @param {string} value строковое значение цифры/пробела/точки/минуса
   * @param {boolean} hasDot сообщает нам содержится ли в числе точка
   * @param {boolean} hasMinus сообщает нам содержится ли в числе минус(равен true только когда символ минуса первый в строке)
   * * @returns {object} Объект с ключами value, hasDot и hasMinus.
   */
export const getNumberFromValue = (value: string | undefined | null, isOnlyInteger?: boolean) => {
  if (!value) return null;

  /** Кодовый диапазон символов ASCII для цифр */
  const isSymbolDigit = (value: string) => value.charCodeAt(0) >= 48 && value.charCodeAt(0) <= 57;
  /** Кодовый диапазон символов ASCII для точки */
  const isSymbolDot = (value: string) => value.charCodeAt(0) === 46;
  /** Кодовый диапазон символов ASCII для пробела */
  const isSymbolSpace = (value: string) => value.charCodeAt(0) === 32;
  /** Кодовый диапазон символов ASCII для дефиса(минус) */
  const isSymbolMinus = (value: string) => value.charCodeAt(0) === 45;

  const maximumSymbolsAfterDot = 2;
  let inputValue = '';
  let hasDot = false;
  let dotPosition: number | null = null;
  let hasMinus = false;

  for (let i = 0; i < value.length; i++) {
    const isDigit = isSymbolDigit(value[i]);
    const isDot = isSymbolDot(value[i]);
    const isSpace = isSymbolSpace(value[i]);
    const isMinus = isSymbolMinus(value[i]);

    /** Прерываем цикл, когда пользователь вводит количество символов > maximumSymbolsAfterDot */
    if (hasDot && dotPosition && i > dotPosition + maximumSymbolsAfterDot) {
      break;
    }

    /** Не даем ввести минус больше одного раза или не первым символом */
    if ((isMinus && i > 0) || (isMinus && hasMinus)) {
      break;
    }

    if (isDigit || isSpace) {
      inputValue += value[i];
    }

    if (isDot && hasDot) {
      break;
    }

    if (isMinus && !hasMinus) {
      hasMinus = true;
      inputValue += value[i];
    }

    if (isDot && !isOnlyInteger) {
      hasDot = true;
      inputValue += value[i];
      /** Если итерируемый символ - точка, то записываем ее индекс в переменную */
      dotPosition = i;
    }
  }

  /** Не даем ввести точку или пробел первым символом */
  if (inputValue.length === 1) {
    if (isSymbolDot(inputValue) || isSymbolSpace(inputValue)) {
      return null;
    }
  }

  return {
    value: isOnlyInteger ? inputValue.replace(/\s/g, '') : inputValue,
    hasDot,
    hasMinus,
  };
};

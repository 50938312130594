<template>
  <div class="contractor-sider-content">
    <div class="contractor-sider-content__menu-content">
      <SiderTopContent
        :isCollapsed="isCollapsed"
        @collapse="handleCollapse"
      />

      <div class="contractor-sider-content__menu-title">
        {{ !isCollapsed ? tt('sider.menuGroups.runsWatching') : '' }}
      </div>
      <Menu
        class="contractor-sider-content__menu contractor-sider-content__menu_static"
        theme="light"
        mode="inline"
        :selectedKeys="selectedKeys"
      >
        <MenuItem
          v-for="item in runsSchema"
          :key="item.key"
        >
          <SmartLink
            class="contractor-sider-content__menu-link"
            :href="item.path"
          >
            <MenuIconWithLabel
              :icon="item.icon"
              :label="tt(`sider.menu.contractorPersonalAccountRuns.${item.label}`)"
              :isCollapsed="isCollapsed"
              :count="getCount(item.key)"
            />
          </SmartLink>
        </MenuItem>
      </Menu>

      <div v-if="warningSchemaItems.length" class="contractor-sider-content__menu-title">
        {{ !isCollapsed ? tt('sider.menuGroups.requiredAttention') : '' }}
      </div>
      <Menu
        class="contractor-sider-content__menu"
        theme="light"
        mode="inline"
        :selectedKeys="selectedKeys"
      >
        <MenuItem
          v-for="item in warningSchemaItems"
          :key="item.key"
        >
          <SmartLink
            class="contractor-sider-content__menu-link"
            :href="item.path"
          >
            <MenuIconWithLabel
              :icon="item.icon"
              :label="tt(`sider.menu.contractorPersonalAccountRuns.${item.label}`)"
              :isCollapsed="isCollapsed"
              :count="getCount(item.key)"
              :counterType="ECounterType.error"
            />
          </SmartLink>
        </MenuItem>
      </Menu>
    </div>
    <div
      v-if="!isCollapsed"
      class="contractor-sider-content__user"
    >
      <div class="contractor-sider-content__user-name">
        {{ contractorName }}
      </div>
      <div class="contractor-sider-content__user-support">
        {{ tt('contractors.signIn.techSupport') }}
        <SmartLink
          class="contractor-sider-content__email-link"
          :href="`mailto:${supportEmail}`"
          aria-label="support email"
        >
          {{ supportEmail }}
        </SmartLink>
      </div>
      <SmartLink
        class="contractor-sider-content__privacy-link"
        href="https://tms.ru/privacy"
        aria-label="privacy policy"
        alwaysOpenNewTab
      >
        {{ tt('shared.privatePolicy') }}
      </SmartLink>

      <!--    кнопка выхода-->
      <Button
        class="contractor-sider-content__logout"
        type="link"
        @click="handleLogoutClick"
      >
        {{ tt('sider.info.logOff') }}
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Menu,
  MenuItem,
  Button,
} from 'ant-design-vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';

import SmartLink from '@/components/SmartLink/index.vue';
import tt from '@/i18n/utils/translateText';
import { SUPPORT_EMAIL } from '@/domains/constants';
import { fetchContractor, getContractorInfo } from '@/contexts/contractorsContext/services';
import useStore from '@/store/useStore';
import { ECounterType } from '@/components/layouts/domain/constants';
import { TReachedResponse } from '@/api/types';
import { TContractorInfo } from '@/components/layouts/domain/types';
import { TStaticSchemaItem } from '@/contexts/contractorsContext/views/ContractorPersonalAccountRuns/components/MobileMenu/constants';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';

import SiderTopContent from '../SiderTopContent/index.vue';
import MenuIconWithLabel from '../MenuIconWithLabel/index.vue';
import { warningSchema, runsSchema } from './constants';

export default defineComponent({
  name: 'ContractorSiderContent',
  components: {
    Button,
    SiderTopContent,
    Menu,
    MenuItem,
    SmartLink,
    MenuIconWithLabel,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['collapse'],
  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();
    const selectedKeys = ref<string[]>([]);
    const contractorName = ref<string | null>(null);

    const entryUid = computed(() => store.getters['app/decodedJWT']?.entry_uid);
    const currentRunsCount = computed(() => store.getters['contractorInfo/getCurrentRunsCount']);
    const plannedRunsCount = computed(() => store.getters['contractorInfo/getPlannedRunsCount']);
    const finishedRunsCount = computed(() => store.getters['contractorInfo/getFinishedRunsCount']);
    const incidentsCount = computed(() => store.getters['contractorInfo/getIncidentsCount']);
    // TODO: Сделать проверку через permissions, временное решение, весь сайдбар нужно переписать по аналогии с TmsSiderContent
    const canSeeRunIncidents = computed(() => store.state.contractorInfo.canSeeRunIncidents);
    const warningSchemaItems = computed(() => {
      const schemaItems = canSeeRunIncidents.value
        ? warningSchema
        : warningSchema.filter((warningSchemaItem: TStaticSchemaItem) => warningSchemaItem.key !== 'incidents');

      return schemaItems;
    });

    const handleCollapse = () => {
      emit('collapse');
    };

    const setActiveMenuItem = () => {
      const activeMenuItem = [...runsSchema, ...warningSchema].find((item) => item.path === route.path);

      if (!activeMenuItem?.key) return;
      selectedKeys.value = [activeMenuItem.key];
    };

    const fetchContractorData = () => fetchContractor({ entryUid: entryUid.value } as { entryUid: string })
      .then((result) => {
        if (!result) return null;
        contractorName.value = result?.data?.name;
        return result;
      });

    const getCount = (runKey) => {
      if (runKey === 'current') {
        return currentRunsCount.value;
      } if (runKey === 'planned') {
        return plannedRunsCount.value;
      } if (runKey === 'finished') {
        return finishedRunsCount.value;
      } if (runKey === 'incidents') {
        // Если счетчик равен 0, то возвращаем null чтобы скрыть counter
        return incidentsCount.value || null;
      }
      return null;
    };

    const fetchContractorInfo = () => {
      getContractorInfo()
        .then((response: TReachedResponse<TContractorInfo>) => {
          if (!response.data) return;

          store.dispatch('contractorInfo/setCurrentRunsCount', { count: response.data.onExecutionRunsCount });
          store.dispatch('contractorInfo/setPlannedRunsCount', { count: response.data.plannedRunsCount });
          store.dispatch('contractorInfo/setFinishedRunsCount', { count: response.data.completedRunsCount });
          store.dispatch('contractorInfo/setIncidentsCount', { count: response.data.incidentsCount });
          store.dispatch('contractorInfo/setCanSeeRunIncidents', { canSeeRunIncidents: response.data.canSeeRunIncidents });
        })
        .catch(() => {});
    };

    const handleLogoutClick = async () => {
      store.dispatch('app/setWasAuthBy', undefined, { root: true });
      await store.dispatch('app/logout');
      redirectToInitialRoute('/');
    };

    onMounted(() => {
      fetchContractorData();
      setActiveMenuItem();
      fetchContractorInfo();
    });

    watch(
      () => route.path,
      () => {
        setActiveMenuItem();
      },
    );

    return {
      baseUrl: import.meta.env.BASE_URL,
      contractorName,
      supportEmail: SUPPORT_EMAIL,
      handleCollapse,
      getCount,
      tt,
      warningSchemaItems,
      runsSchema,
      selectedKeys,
      ECounterType,
      handleLogoutClick,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { ROUTE_TYPE } from '@/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { getIsTenantWithCustomization } from '@/domains/getIsTenantWithCustomization';
import { canBrowseMatricesByRole } from '@/domains/permissions/role/matrices';
import { hasCargoOwnerWorkplaceSubscription } from '@/domains/permissions/subscription';

import { ROUTE_NAME } from '../domain/constants';

export default [
  buildProtectedRoute({
    path: '/matrices',
    name: ROUTE_NAME.matrices,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        () => getIsTenantWithCustomization(),
        hasCargoOwnerWorkplaceSubscription,
        canBrowseMatricesByRole,
      ],
      component: () => import('@/contexts/rateManagementContext/views/MatricesListView/index.vue'),
    },
  }),
];

import * as types from './mutation-types';
import { TContractorInfoActions } from './types';

const actions: TContractorInfoActions = {
  setCurrentRunsCount: ({ commit }, payload) => {
    commit(types.SET_CURRENT_RUNS_COUNT, payload);
  },
  setPlannedRunsCount: ({ commit }, payload) => {
    commit(types.SET_PLANNED_RUNS_COUNT, payload);
  },
  setFinishedRunsCount: ({ commit }, payload) => {
    commit(types.SET_FINISHED_RUNS_COUNT, payload);
  },
  setIncidentsCount: ({ commit }, payload) => {
    commit(types.SET_INCIDENTS_COUNT, payload);
  },
  setCanSeeRunIncidents: ({ commit }, payload) => {
    commit(types.SET_CAN_SEE_RUN_INCIDENTS, payload);
  },
};

export default actions;

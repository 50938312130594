<template>
  <CustomAutoComplete
    ref="autoCompleteRef"
    class="bank-selector__select"
    fullWidth
    :options="items"
    :value="value"
    valueBy="bik"
    :loading="isLoading"
    :dropdownClassName="dropdownClassName"
    @select="handleSelect"
    @search="handleSearch"
    @blur="handleBlur"
  >
    <template #optionTemplate="{ option }">
      <div
        :key="option.id"
        class="bank-selector__option"
      >
        <p class="bank-selector__option-text">
          {{ option.data.bik }}
        </p>
        <div class="bank-selector__option-meta">
          {{ option.data.name }}
        </div>
      </div>
    </template>
  </CustomAutoComplete>
</template>
<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';

import CustomAutoComplete from '@/components/CustomAutoComplete/index.vue';

import { TBankAccounts } from './types';

export default defineComponent({
  name: 'BankSelector',
  components: { CustomAutoComplete },
  props: {
    value: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    items: {
      type: Array as PropType <TBankAccounts>,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dropdownClassName: {
      type: String,
      default: '',
    },
  },
  emits: ['change', 'search'],
  setup(props, { emit }) {
    const autoCompleteRef = ref<InstanceType<typeof CustomAutoComplete> | null>(null);

    const handleSearch = (q) => {
      emit('search', q);
    };

    const handleSelect = (item) => {
      emit('change', item);
      handleSearch(item.bik);
    };

    const handleBlur = () => {
      // Не отсылать запрос на options каждый раз, когда происходит клик вне инпута
      if (props.value !== autoCompleteRef.value?.valueRef) {
        handleSearch(props.value || '');
      }
      autoCompleteRef.value?.onReset(props.value || '');
    };

    return {
      autoCompleteRef,

      handleSelect,
      handleSearch,
      handleBlur,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import {
  Ref,
  computed,
  ref,
} from 'vue';

import { TReachedResponse } from '@/api/types';
import useViewState from '@/composables/useViewState';
import { fetchTenantBankAccount } from '@/contexts/accountingContext/services';
import { TBankAccountResponse } from '@/contexts/accountingContext/domain/types';
import useStore from '@/store/useStore';
import { getSubscriptionsLimit } from '@/contexts/billingContext/services';
import { TBillingSubscriptionsLimitsResponse } from '@/contexts/billingContext/domain/types';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';

export const useFetchData = (
  planCode: Ref<EBillingPlanCode | null>,
  emit: (event: 'close' | 'goPreviousStep', ...args: unknown[]) => void,
) => {
  const {
    isLoading,
    isDone,
    setViewStateAs,
  } = useViewState();

  const store = useStore();

  const currentTenant = computed(() => store.state.tenants.currentTenant);

  const isPlanCodeWithMaxUsersCountLimit = computed(
    () => planCode.value === EBillingPlanCode.carrierBasic
    || planCode.value === EBillingPlanCode.freightForwarderBasic
    || planCode.value === EBillingPlanCode.carrierStart,
  );

  const isManyEmployeesAlertShown = ref(false);
  const bankAccountInfo = ref<TBankAccountResponse | null>(null);

  const promises = [] as Promise<unknown>[];

  if (isPlanCodeWithMaxUsersCountLimit.value && planCode.value) {
    promises.push(
      getSubscriptionsLimit(planCode.value)
        .then(({ data }: TReachedResponse<TBillingSubscriptionsLimitsResponse>) => {
          if (data.maxUsersCount.current > data.maxUsersCount.max) {
            isManyEmployeesAlertShown.value = true;
          }
        })
        .catch(() => {
          emit('goPreviousStep');
        }),
    );
  }

  if (currentTenant.value?.id) {
    promises.push(
      fetchTenantBankAccount(currentTenant.value.id)
        .then((result: TReachedResponse<TBankAccountResponse>) => {
          bankAccountInfo.value = result.data;
        })
        .catch(() => {}),
    );
  }

  const fetchDataForSetForm = () => {
    setViewStateAs.loading();

    Promise.all(promises)
      .finally(() => {
        setViewStateAs.done();
      });
  };

  return {
    isLoading,
    isDone,
    isManyEmployeesAlertShown,
    bankAccountInfo,

    fetchDataForSetForm,
  };
};

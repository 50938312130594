import { ELicenseRole } from '@/contexts/accountingContext/domain/constants';
import tt from '@/i18n/utils/translateText';

export const allLicenseRoles = [
  {
    value: ELicenseRole.carrier,
    label: tt('shared.carrier'),
  },
  {
    value: ELicenseRole.freightForwarder,
    label: tt('shared.forwarder'),
  },
  {
    value: ELicenseRole.cargoOwner,
    label: tt('shared.cargoOwner'),
  },
  {
    value: ELicenseRole.shipper,
    label: tt('shared.shipper'),
  },
];

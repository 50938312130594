import { useRouter } from 'vue-router';
import { Ref } from 'vue';

import { TNotification, TNotificationAction } from '@/contexts/notificationsContext/domain/types';
import useStore from '@/store/useStore';
import useViewState from '@/composables/useViewState';

import getActionPath from '../domain/getActionPath';

export const useInteractWithNotification = (notification: Ref<TNotification>) => {
  const router = useRouter();
  const store = useStore();

  const {
    isLoading: isIndicatorDisabled,
    setViewStateAs: setIndicatorDisabled,
  } = useViewState();

  const readNotificationById = () => {
    setIndicatorDisabled.loading();
    store.dispatch('notifications/readNotificationById', notification.value.id)
      .finally(setIndicatorDisabled.done);
  };

  const unreadNotificationById = () => {
    setIndicatorDisabled.loading();
    store.dispatch('notifications/unreadNotificationById', notification.value.id)
      .finally(setIndicatorDisabled.done);
  };

  const handleIndicatorClick = () => {
    if (notification.value.isRead) {
      unreadNotificationById();
    } else {
      readNotificationById();
    }
  };

  const handleNotificationActionClick = (action: TNotificationAction) => {
    const path = getActionPath(action.path, notification.value);
    router.push(path);
    if (!notification.value.isRead) {
      readNotificationById();
    }
    store.dispatch('notifications/toggleNotificationsListVisible', false);
  };

  return {
    isIndicatorDisabled,

    handleIndicatorClick,
    handleNotificationActionClick,
  };
};

<template>
  <div class="action-progress">
    <div
      v-for="(progress, id) in progresses"
      :key="id"
      class="action-progress__item"
      :class="{ 'action-progress__item_active': progress.total > 0 }"
    >
      <component
        :is="progress.component"
        :iconComponent="iconComponent(progress)"
        :progress="progress"
        v-bind="progress.componentProps"
      />

      <Button
        :type="EButtonType.ghostSecondary"
        :size="ESize.small"
        class="action-progress__close"
        @click="closeProgress(id)"
      >
        <template #icon>
          <CloseSvg class="action-progress__close-icon" />
        </template>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import { EButtonType, ESize } from '@/ui/types';
import { EActionProgressStatus } from '@/components/ActionProgress/domains/constants';
import { TActionProgressStateProgress } from '@/stores/actionProgress/types';
import { useActionProgressStore } from '@/stores/actionProgress';
import { Button } from '@/ui';
import CheckColoredSvg from '@/assets/svg/16x16/check-colored.svg';
import CloseSvg from '@/assets/svg/16x16/close.svg';
import ErrorColoredSvg from '@/assets/svg/16x16/error-colored.svg';

import ActionProgressSpinner from './components/ActionProgressSpinner/index.vue';

export default defineComponent({
  name: 'ActionProgress',
  components: {
    ActionProgressSpinner,
    Button,
    CheckColoredSvg,
    CloseSvg,
  },
  setup() {
    const store = useActionProgressStore();

    const progresses = computed(() => store.progresses);

    const iconComponent = (progress: TActionProgressStateProgress) => {
      switch (progress.status) {
        case EActionProgressStatus.completed:
          return CheckColoredSvg;
        case EActionProgressStatus.failed:
          return ErrorColoredSvg;
        default:
          return ActionProgressSpinner;
      }
    };

    const closeProgress = (id: string) => {
      store.resetProgress({ id });
    };

    return {
      EButtonType,
      ESize,
      progresses,

      closeProgress,
      iconComponent,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { toLowercase, isString } from '@/utils';

type TMetaItem = null | string | undefined;
type TFilterByInnKppName = (
  query: string,
  { inn, kpp, name }: { inn: TMetaItem, kpp: TMetaItem, name: TMetaItem },
) => boolean;

const removeSpecialSymbols = (string: string) => string.replace(/"|'|`/g, '');

const filterByInnKppName: TFilterByInnKppName = (query, { inn, kpp, name } = {
  inn: null,
  kpp: null,
  name: null,
}) => {
  if (!query || (!inn && !kpp && !name)) return false;

  const lowerQuery = removeSpecialSymbols(toLowercase(query));

  return [inn, kpp, name]
    .filter(isString)
    .some((item) => removeSpecialSymbols(toLowercase(item)).includes(lowerQuery));
};

export default filterByInnKppName;

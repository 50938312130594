import { EWasAuthBy, EAppState } from '@/constants';
import { decodeJWT } from '@/utils';

export const isIntermediate = (state: any) => state.state === EAppState.initial || state.state === EAppState.checking;
/** TODO: getter isAuthenticated нигде не используется
 * нужно попробовать его заюзать где-то в useAppState.ts либо удалить вовсе
*/
export const isAuthenticated = (state: any) => state.state === EAppState.authenticated && !!state.accessToken;
export const decodedJWT = (state: any) => decodeJWT(state.accessToken);
export const isAuthByLoginPassword = (state: any, getters) => state.wasAuthBy === EWasAuthBy.loginPassword
|| !!getters.decodedJWT?.user_id;
export const isAuthByEntryCode = (state: any, getters) => state.wasAuthBy === EWasAuthBy.entryCode
|| !!getters.decodedJWT?.entry_uid;
export const isAuthBySocial = (state: any) => state.wasAuthBy === EWasAuthBy.social;
export const currentState = (state: any) => state.state;
export const folderOpenKeys = (state: any) => state.folderOpenKeys;
export const sberParams = (state: any) => state.sberParams;
export const appSettings = (state: any) => state.appSettings;

export const OPERATIONAL_DOCUMENTS_VIEW_FILTER_SECTION = 'operationalDocumentsView';
export const OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_STATUS = 'opearationalDocumentsFilterDocumentStatus';
export const OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_TYPE = 'opearationalDocumentsFilterDocumentType';

export const employeeListLink = '/employees';

export const CRYPTOPRO_SITE_LINK = 'https://www.cryptopro.ru/products/csp';
export const CRYPTOPRO_PLUGIN_LINK = 'https://www.cryptopro.ru/products/cades/plugin';
export const CRYPTOPRO_GOOGLE_PLUGIN_GUIDE_LINK = 'https://chrome.google.com/webstore/detail/cryptopro-extension-for-c/iifchhfnnmpdbibifmljnfjhpififfog?hl=ru';
export const CRYPTOPRO_FIREFOX_PLUGIN_GUIDE_LINK = 'https://docs.cryptopro.ru/cades/plugin/plugin-installation-windows';
export const CRYPTOPRO_OPERA_PLUGIN_GUIDE_LINK = 'https://addons.opera.com/en/extensions/details/cryptopro-extension-for-cades-browser-plug-in';
export const CRYPTOPRO_YANDEX_PLUGIN_GUIDE_LINK = 'https://addons.opera.com/en/extensions/details/cryptopro-extension-for-cades-browser-plug-in';
export const CRYPTOPRO_CERTIFICATE_INSTRUCTIONS_GUIDE_LINK_FOR_CUSTOMER = 'https://confluence.trucker.group/manual/11-2-nastrojka-sertifikatov-etsp-kep-2519716634.html';
export const CRYPTOPRO_CERTIFICATE_INSTRUCTIONS_GUIDE_LINK_FOR_EXECUTOR = 'https://confluence.trucker.group/manual/8-2-nastrojka-sertifikatov-etsp-kep-2519716574.html';

export enum EOperationalDocumentsFlowFilters {
  /** Входящие */
  inbox = 'inbox',
  /** Исходящие */
  outbox = 'outbox',
  /** Черновики */
  drafts = 'drafts',
  /** Все */
  all = 'all',
}

export enum EDocumentAvailableAction {
  changeDraft = 'CHANGE_DRAFT',
  deleteDraft = 'DELETE_DRAFT',
  toArchive = 'TO_ARCHIVE',
  titleSign = 'TITLE_SIGN',
  titleResign = 'TITLE_RESIGN',
  titleReject = 'TITLE_REJECT',
  getPrintable = 'GET_PRINTABLE',
  manualAccept = 'MANUAL_ACCEPT',
  messageSign = 'MESSAGE_SIGN',
  autoAccept = 'AUTO_ACCEPT',
  cargoAcceptance = 'CARGO_ACCEPTANCE',
  cargoReception = 'CARGO_RECEPTION',
  cargoDelivery = 'CARGO_DELIVERY',
  cargoRelay = 'CARGO_RELAY',
  cargoForwarding = 'CARGO_FORWARDING',
  universalInvoiceCustomer = 'UNIVERSAL_INVOICE_CUSTOMER',
  universalStatementCustomer = 'UNIVERSAL_STATEMENT_CUSTOMER',
  statementCustomer = 'STATEMENT_CUSTOMER',
  offerCancellation = 'OFFER_CANCELLATION',
  acceptCancellation = 'ACCEPT_CANCELLATION',
  rejectCancellation = 'REJECT_CANCELLATION',
  approveDriverAcceptance = 'APPROVE_DRIVER_ACCEPTANCE',
  approveDriverDelivery = 'APPROVE_DRIVER_DELIVERY',
}

export enum EOperationalDocumentsStatus {
  /** Это временные данные, потом модель расширится и создадим enum */
  important = 'important',
  /** Это временные данные, потом модель расширится и создадим enum */
  notImportant = 'not important',
}

export const MESSAGE_DISPLAY_DURATION = 1000;

<template>
  <div class="billing-successful-plan-connection-card">
    <div class="billing-successful-plan-connection-card__icon">
      <UpgradePlanSuccessSvg />
    </div>
    <div class="billing-successful-plan-connection-card__description">
      {{ getTextAboutConnectedPlan({ planCode: planCode }) }}
    </div>
    <Divider class="billing-successful-plan-connection-card__divider" />
    <Button
      :type="EButtonType.primary"
      :size="ESize.large"
      :loading="isLoading || isContinueButtonLoading"
      :title="tt('billing.modal.startUsing')"
      @click="onContinue"
    />
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
} from 'vue';
import { Divider } from 'ant-design-vue';

import { Button } from '@/ui';
import tt from '@/i18n/utils/translateText';
import UpgradePlanSuccessSvg from '@/assets/svg/others/upgrade-plan-success.svg';
import { getTextAboutConnectedPlan } from '@/contexts/billingContext/domain/getTextAboutConnectedPlan';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import { EButtonType, ESize } from '@/ui/types';

import { useContinue } from './application/useContinue';

export default defineComponent({
  name: 'BillingSuccessfulPlanConnectionCard',
  components: {
    Divider,
    Button,
    UpgradePlanSuccessSvg,
  },
  props: {
    planCode: {
      type: String as PropType<EBillingPlanCode>,
      required: true,
    },
    isContinueButtonLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(_, { emit }) {
    const { isLoading, onContinue } = useContinue(emit);

    return {
      isLoading,
      EButtonType,
      ESize,

      tt,
      getTextAboutConnectedPlan,
      onContinue,
    };
  },
});
</script>
<style lang="scss" src="./styles.scss" />

<template>
  <div
    class="empty-label"
    :class="extraClasses"
  >
    <span v-if="value">
      {{ value }}
    </span>
    <span v-else>
      {{ tt(`shared.notSpecified.${declension}`) }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import tt from '@/i18n/utils/translateText';

import { ELabelDeclension, ELabelSize } from './constants';

export default defineComponent({
  name: 'EmptyLabel',
  props: {
    declension: {
      type: String as PropType<ELabelDeclension>,
      default: ELabelDeclension.neuter,
    },
    value: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    inTableCell: {
      type: Boolean,
      default: false,
    },
    inTooltip: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ELabelSize>,
      default: ELabelSize.small,
    },
  },
  setup(props) {
    const extraClasses = [
      `empty-label_${props.size}`,
      {
        'empty-label_table-cell': props.inTableCell,
        'empty-label_in-tooltip': props.inTooltip,
      },
    ];

    return {
      extraClasses,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

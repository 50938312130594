import { ROUTE_TYPE } from '@/constants';
import store from '@/store';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';

const checkRouteType = (route) => {
  const isRouteUnauthenticated = route.meta.type === ROUTE_TYPE.unauthenticated;
  const isAuthenticated = store.getters['app/isAuthenticated'];
  if (isRouteUnauthenticated && isAuthenticated) {
    redirectToInitialRoute();
  }

  return Promise.resolve();
};

export default checkRouteType;

import { watch, computed } from 'vue';

import useStore from '@/store/useStore';
import useWebsocketChannel from '@/composables/useWebsocketChannel';
import SmartLink from '@/components/SmartLink/index.vue';
import { notification } from '@/utils';
import tt from '@/i18n/utils/translateText';

import useGlobalEventBus from '../useGlobalEventBus';

// ReportsChannel - канал для отчетов, сюда прилетает отчеты которые сформировались
const useReportsWsUtils = () => {
  const store = useStore();
  const employeeId = computed(() => store.getters['tenants/currentTenant']?.employee?.id || null);

  const { emit: emitToBus } = useGlobalEventBus();

  const onMessage = ({ payload }) => {
    notification.success({
      message: (
        <span>
          {tt('report.shared.yourReport')}
          <SmartLink href="/report_templates?filter=reports">
            {payload.title}
          </SmartLink>
          {tt('report.shared.successfullyFormed')}
        </span>
      ),
    });
    emitToBus('reportsUpdate');
  };

  const { connect, closeConnection } = useWebsocketChannel({
    channelName: 'ReportsChannel',
    payload: { employeeId: employeeId.value },
    onMessage,
  });

  const connectToReportsChannel = () => {
    connect();
  };

  const disconnectFromReportsChannel = () => {
    closeConnection();
  };

  const reconnectReportsChannel = () => {
    disconnectFromReportsChannel();
    connectToReportsChannel();
  };

  watch(() => employeeId.value, () => {
    if (!employeeId.value) return;
    reconnectReportsChannel();
  });
};

export default useReportsWsUtils;

<template>
  <div class="support-block" :class="`support-block_${theme}`">
    <SmartLink
      class="support-block__link"
      :class="{ 'support-block__link_dark': theme === EThemeSupportBlock.dark }"
      :href="`tel:${phone}`"
    >
      {{ phone }}
    </SmartLink>
    <div class="support-block__dot" :class="`support-block__dot_${theme}`" />
    <SmartLink
      class="support-block__link"
      :class="{ 'support-block__link_dark': theme === EThemeSupportBlock.dark }"
      :href="`mailto:${email}`"
    >
      {{ email }}
    </SmartLink>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import useAppTheme from '@/composables/application/useAppTheme';
import tt from '@/i18n/utils/translateText';
import { EThemeSupportBlock } from '@/components/InformationPanel/components/SupportBlock/constants';
import SmartLink from '@/components/SmartLink/index.vue';

export default defineComponent({
  name: 'SupportBlock',
  components: { SmartLink },
  props: {
    theme: {
      type: String as PropType<EThemeSupportBlock>,
      default: EThemeSupportBlock.dark,
    },
  },
  setup() {
    const { getThemeConfig } = useAppTheme();
    const { support } = getThemeConfig();

    return {
      phone: support.phone,
      email: support.email,

      EThemeSupportBlock,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

<template>
  <Tooltip
    :placement="EPopoverPlacement.left"
    :title="label"
    :visible="isTooltipVisible"
    @visibleChange="handleTooltipVisibleChange"
  >
    <Popover
      class="notification-menu-item"
      trigger="click"
      overlayClassName="notification-menu-item__popover"
      placement="rightTop"
      destroyTooltipOnHide
      :visible="isNotificationListOpen"
      @visibleChange="handleVisibleChange"
    >
      <div class="notification-menu-item__content">
        <div class="notification-menu-item__menu">
          <div class="notification-menu-item__row">
            <MenuIconWithLabel
              :icon="icon"
              :label="label"
              :isCollapsed="isCollapsed"
            />
          </div>
          <div
            v-if="showCounter"
            class="notification-menu-item__counter"
          >
            {{ counter }}
          </div>
        </div>
        <div
          v-if="showRedDot"
          class="notification-menu-item__red-dot"
        />
      </div>
      <template #content>
        <NotificationsList />
      </template>
    </Popover>
  </Tooltip>
</template>

<script lang="ts">
import { Popover } from 'ant-design-vue';
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from 'vue';

import useStore from '@/store/useStore';
import BellSvg from '@/assets/svg/16x16/bell.svg';
import { Tooltip } from '@/ui';
import { EPopoverPlacement } from '@/ui/types';
import MenuIconWithLabel from
  '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/components/MenuIconWithLabel/index.vue';

import NotificationsList from '../NotificationsList/index.vue';

export default defineComponent({
  name: 'NotificationMenuItem',
  components: {
    Popover,
    NotificationsList,
    MenuIconWithLabel,
    Tooltip,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    parentClass: {
      type: String,
      default: '',
    },
    icon: {
      type: Object as PropType<SVGElement>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const isTooltipVisible = ref<boolean>(false);

    const notificationsCounter = computed(() => store.getters['notifications/unreadCount']);
    const isNotificationListOpen = computed(() => store.getters['notifications/isNotificationsListVisible']);
    const showRedDot = computed(() => notificationsCounter.value && props.isCollapsed);
    const showCounter = computed(() => notificationsCounter.value && !props.isCollapsed);
    const counter = computed(() => (notificationsCounter.value && notificationsCounter.value > 99
      ? '99+'
      : notificationsCounter.value));

    const handleVisibleChange = (visible: boolean) => {
      store.dispatch('notifications/toggleNotificationsListVisible', visible);
    };

    const handleTooltipVisibleChange = (visible: boolean) => {
      const shouldShowTooltip = visible && !isTooltipVisible.value && props.isCollapsed;

      isTooltipVisible.value = shouldShowTooltip;
    };

    return {
      showRedDot,
      showCounter,
      counter,
      isNotificationListOpen,
      BellSvg,
      isTooltipVisible,
      EPopoverPlacement,

      handleVisibleChange,
      handleTooltipVisibleChange,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

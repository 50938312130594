export const ROUTE_NAME = { reportTemplates: 'report_templates' };

export enum EDateFilterKind {
  loadingDate = 'loading_date',
  unloadingDate = 'unloading_date',
  timeslotBookingDate = 'timeslot_booking_date',
  orderCreationDate = 'order_creation_date',
  orderConfirmationDate = 'order_confirmation_date',
}

export enum EPeriodKind {
  today = 'today',
  yesterday = 'yesterday',
  exactDate = 'exact_date',
  currentMonth = 'current_month',
  previousMonth = 'previous_month',
  custom = 'custom',
}

export enum ESendSettingsPeriodKind {
  selectedDays = 'selected_days',
  weekly = 'weekly',
  everyTwoWeeks = 'every_two_weeks',
  monthly = 'monthly',
  quarterly = 'quarterly',
}

export enum EAccessLevel {
  private = 'private',
  allEmployees = 'all_employees',
  selectedEmployees = 'selected_employees',
}

export enum EAccessMode {
  browse = 'browse',
  manage = 'manage',
}

export enum EReportStatuses {
  failed = 'failed',
  pending = 'pending',
  inProgress = 'in_progress',
  processed = 'processed',
}

export enum ETransferType {
  email = 'email',
  web = 'web',
}

import { joinString } from '@/utils';

import { TStrategiesState } from './types';

export const getChainId = (state: TStrategiesState) => (strategyId: string, automationId: string) => {
  const keyFromParams = joinString([strategyId, automationId], '_');
  const isKeyFromParamsContainedInObject = Object.keys(state.persistedStrategies).includes(keyFromParams);

  if (!isKeyFromParamsContainedInObject) return null;

  return state.persistedStrategies[keyFromParams];
};

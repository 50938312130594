type TFormatNumberAsCurrencyOptions = Partial<{
  separator: string,
  decimal: string,
  symbol: string,
  precision: number,
  pattern: string,
  negativePattern: string,
}>;

/**
 * Форматирует число в строку с валютой,
 * с заданными опциями разделителей, группировкой разрядов числа,
 * и по заданному шаблону:
 *
 * "-"" - знак при отрицательном числе
 * "#"" - число с примененными опциями разделителей числа
 * "!"" - валюта
 *
 * @example Примеры шаблонов: "# !", "- # !'", "(#!)", "-(!#)"
 */
export function formatNumberAsCurrency(
  number: number | null | undefined,
  currencyPrecision: number = 2,
  options: TFormatNumberAsCurrencyOptions = {},
): string {
  if (number === null || number === undefined) {
    return '';
  }

  const {
    separator = '.',
    decimal = ',',
    symbol = '₽',
    precision = currencyPrecision,
    pattern = '#!',
    negativePattern,
  } = options;

  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    currencyDisplay: 'narrowSymbol',
    signDisplay: 'auto',
    useGrouping: true,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  // Возвращает число вида: '-1 000 000,83 ₽'
  let formattedNumber = formatter.format(number);

  // Замены разделителя разрядов и дробной части
  formattedNumber = formattedNumber
  // Замена разделителя дробной части
    .replace(/,/g, decimal) // Output: `1 000 000${decimal}83 ₽`
  // Замена разделя групп разрядов
    .replace(/\s/g, separator); // Output:`1.000.000,83.₽`

  // Разделение числа и символа валюты
  const numberPart = formattedNumber.slice(0, -2); // Output: `1.000.000,83`

  // Определение знака числа
  const isNegative = numberPart.startsWith('-');
  const absoluteNumberPart = isNegative ? numberPart.slice(1) : numberPart;

  // Замена "#" символа числа в шаблоне
  let formatted = (negativePattern || pattern).replace('#', absoluteNumberPart);
  // Замена "!" валютного символа в шаблоне
  formatted = formatted.replace('!', symbol);
  // Замена "-" знака в шаблоне
  formatted = formatted.replace(/^-/g, isNegative ? '-' : '');

  return formatted;
}

import { ROUTE_TYPE } from '@/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { ROUTE_NAME } from '@/contexts/reportsContext/domain/constants';
import { canBrowseReportsTemplatesByRole } from '@/domains/permissions/role/reportTemplates';

export default [
  buildProtectedRoute({
    path: '/report_templates',
    name: ROUTE_NAME.reportTemplates,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [canBrowseReportsTemplatesByRole],
      component: () => import('@/contexts/reportsContext/views/ReportTemplatesView/index.vue'),
    },
  }),
];

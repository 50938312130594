// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';

/** Возвращет количество дней в месяце для переданной даты. Если переданное значение не дата, возвращается null */
const getDaysCountInCurrentMonth = (value: string | number | Date): number | null => {
  const date = dayjs(value);

  if (!date.isValid()) return null;

  return date.daysInMonth();
};

export default getDaysCountInCurrentMonth;

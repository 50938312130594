import { v4 as uuidv4 } from 'uuid';

import { globalEventBus } from '@/utils/eventBus';

const useGlobalEventBus = ({ key }: { key: string | null } = { key: null }) => {
  const uniqId = uuidv4();
  const clientId = key
    ? `${uniqId}-${key}`
    : `${uniqId}`;

  const subscribe = (event, callback) => {
    globalEventBus.subscribe({
      event,
      key: clientId,
    }, callback);
  };

  const emit = (event, ...args) => {
    globalEventBus.emit({
      event,
      emitter: clientId,
    }, ...args, clientId);
  };

  const { unsubscribe } = globalEventBus;
  const { stopEvent } = globalEventBus;

  return {
    clientId,

    subscribe,
    unsubscribe,
    stopEvent,
    emit,
  };
};

export default useGlobalEventBus;

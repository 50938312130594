import { TFilterSectionAndSubSection } from './types';

export const getMainFilterCurrentAppliedSet = (state) => (
  { section, subSection }: TFilterSectionAndSubSection,
) => state[section]?.[subSection]?.filter?.currentAppliedSet;

export const getMainFilterCurrentAppliedPreset = (state) => (
  { section, subSection }: TFilterSectionAndSubSection,
) => state[section]?.[subSection]?.filter?.currentAppliedPreset;

export const getMainFilterCurrentEditablePreset = (state) => (
  { section, subSection }: TFilterSectionAndSubSection,
) => state[section]?.[subSection]?.filter?.currentEditablePreset;

export const getFilterSelectCurrent = (state) => (
  {
    name,
    section,
    subSection,
  }: TFilterSectionAndSubSection & { name: string },
) => (subSection ? state[section][subSection][name].current : null);

export const getFilterState = (state) => (
  {
    name,
    section,
    subSection,
  }: TFilterSectionAndSubSection & { name: string },
) => state[section][subSection][name];

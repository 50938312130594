import { useRoute } from 'vue-router';

import store from '@/store';
import { ROUTE_TYPE } from '@/constants';
import { EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { TSubscription } from '@/types';
import isTenantVerified from '@/domains/isTenantVerified';

export const getIsBillingRestrictionLayoutShown = (): boolean => {
  const route = useRoute();
  const isPrivateRoute = route?.meta?.type === ROUTE_TYPE.private;

  const hasTenants = store.state.tenants.userTenants?.length > 0;

  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;

  const hasActiveSubscription = store.state.tenants.currentTenantInfo?.subscriptions?.some(
    (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active,
  );

  // инстанс может работать либо по лицензии, либо с биллингом
  return !hasInstanceLicense
    && hasTenants
    && isPrivateRoute
    && !route.path.startsWith('/employees')
    && !route.path.startsWith('/tenant/settings')
    && !route.path.startsWith('/account')
    && !route.path.startsWith('/invitations')
    && (!isTenantVerified() || !hasActiveSubscription);
};

import { getInputNumbersValue } from './getInputNumbersValue';

export const formatInputValue = (inputValue: string | null, hasMask: boolean = true) => {
  let formattedInputValue = '';

  if (!inputValue) return formattedInputValue;

  let inputNumbersValue = getInputNumbersValue(inputValue);

  if (hasMask && ['7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
    formattedInputValue = '+7 ';

    if (inputNumbersValue[0] === '9') {
      inputNumbersValue = `7${inputNumbersValue}`;
    }
    // ввод кода оператора
    if (inputNumbersValue.length > 1) {
      formattedInputValue += `${inputNumbersValue.substring(1, 4)}`;
    }
    // ввод первый трех цифр номера
    if (inputNumbersValue.length >= 5) {
      formattedInputValue += ` ${inputNumbersValue.substring(4, 7)}`;
    }
    // ввод 4 и 5 цифры
    if (inputNumbersValue.length >= 8) {
      formattedInputValue += `-${inputNumbersValue.substring(7, 9)}`;
    }
    // ввод 6 и 7 цифры
    if (inputNumbersValue.length >= 10) {
      formattedInputValue += `-${inputNumbersValue.substring(9, 11)}`;
    }
  } else if (inputNumbersValue) {
    // для всех остальных стран добавляем плюс в начало и все
    formattedInputValue = `+${inputNumbersValue.substring(0, 18)}`;
  }
  return formattedInputValue;
};

export const ROUTE_NAME = {
  executionRequests: 'executionRequests',
  executionRequest: 'executionRequest',
  executionRequestInfo: 'executionRequestInfo',
  executionRequestRuns: 'executionRequestRuns',
  executionRequestDistribution: 'executionRequestDistribution',
};

export const EXECUTION_REQUESTS_VIEW_FILTER_SECTION = 'executionRequestsView';

export enum EResourceType {
  driver = 'driver',
  truck = 'truck',
  donkey = 'donkey',
  mainTrailer = 'mainTrailer',
  secondaryTrailer = 'secondaryTrailer',
}

export enum ECustomerValidationRuleValidator {
  fieldRequired = 'field.required',
}

export enum EFreightExecutionContextError {
  executionRequestHasRuns = 'DomainErrors::FreightExecutionContext::ExecutionRequestHasRuns',
}

export enum EExecutionRequestAgreementStatistic {
  delivered = 'delivered',
  distributed = 'distributed',
  awaitingDistribution = 'awaitingDistribution',
  notTradedOut = 'notTradedOut',
}

export const EXECUTION_REQUEST_PANEL_TAB_RUNS = 'executionRequestPanelTabRuns';

/** Ключ для хранения примененных фильтров в сторе для рейсов групповой заявки */
export const COMBINED_EXECUTION_REQUEST_RUNS_FILTER_SECTION = 'combinedExecutionRequestRuns';

export enum ECombinedExecutionRequestsStatus {
  active = 'active',
  canceledByExecutor = 'canceled_by_executor',
  canceledByCustomer = 'canceled_by_customer',
  completed = 'completed',
}

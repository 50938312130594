import { TSelectOption, TSelectValue } from '@/ui/types';
import { hasProperty } from '@/utils';

export const isSelectedValue = (currentValue: TSelectValue | null, selectedValue: TSelectOption) => {
  if (!currentValue && currentValue !== 0) return false;
  // если текущее значение - объект, то сравниваем текущее значение и значение внутри выпадающего списка
  if (typeof currentValue === 'object' && hasProperty(currentValue, 'value')) {
    return (currentValue as TSelectOption).value === selectedValue.value
      && (currentValue as TSelectOption).label === selectedValue.label;
  }
  // если значение внутри селекта не объект, то сравниваем value или label у выпадающего списка
  return currentValue === selectedValue.value || currentValue === selectedValue.label;
};

<template>
  <section class="welcome-loader">
    <AppLogo class="welcome-loader__icon" />
    <Progress
      class="welcome-loader__progress"
      status="active"
      :strokeColor="{
        '0%': welcomeLoaderProgress.start,
        '100%': welcomeLoaderProgress.end
      }"
      :percent="99.9"
      :showInfo="false"
    />
  </section>
</template>

<script lang="ts">
import { Progress } from 'ant-design-vue';

import useAppTheme from '@/composables/application/useAppTheme';
import AppLogo from '@/components/AppLogo/index.vue';

export default {
  name: 'WelcomeLoader',
  components: {
    Progress,
    AppLogo,
  },
  setup() {
    const { getThemeConfig } = useAppTheme();
    const { welcomeLoaderProgress } = getThemeConfig();

    return { welcomeLoaderProgress };
  },
};
</script>

<style lang="scss" src="./style.scss" />

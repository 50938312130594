import setAppFavicon from '@/utils/setAppFavicon';
import useAppTheme from '@/composables/application/useAppTheme';

const { getThemeConfig } = useAppTheme();

const setDefaultFavicon = () => {
  const icon = getThemeConfig().icons.favicon;
  setAppFavicon(`${import.meta.env.BASE_URL}${icon}`);
};

export default setDefaultFavicon;

import { EPermissionComparisonFunction } from '@/constants';
import store from '@/store';
import checkPermissionsCommon from '@/domains/permissions/checkPermissionsCommon';
import type { TEmployeeRolePermissions, TTenantEmployeeRole } from '@/types';
import { hasProperty } from '@/utils';

export type TRolePermissionItem = TFinalPathsOfObject<TEmployeeRolePermissions>;

export const getPermissionsObject = (userRoles: TTenantEmployeeRole[]): TEmployeeRolePermissions | null => {
  if (!userRoles || !userRoles.length) return null;

  // Результирующий объект с permissions
  const permissionsObject = {};

  // Проходимся по всем ролям сотрудника
  userRoles.forEach((role: TTenantEmployeeRole) => {
    // Проходимся по всем доступным permissions у роли
    Object.keys(role.permissions).forEach((rolePermissionKey: string) => {
      // Если в результирующем объекте еще нет нужной role permission, то берем его из permissions у роли
      if (!hasProperty(permissionsObject, rolePermissionKey)) {
        permissionsObject[rolePermissionKey] = role.permissions[rolePermissionKey];
      } else {
        // Проходимся по permissions у роли
        Object.keys(role.permissions[rolePermissionKey]).forEach((permissionKey: string) => {
          // Если хотя бы у одной из ролей пользователя есть разрешение на действие, то пользователь может совершать это действие
          // Для этого берем разрешение у которого значение true, если таких нет, то сетим false
        // Пример: targetOffers: { browse: false } + targetOffers: { browse: true, manage: true }) => targetOffers: { browse: true, manage: true }
          permissionsObject[rolePermissionKey][permissionKey] = role.permissions[rolePermissionKey][permissionKey]
           || permissionsObject[rolePermissionKey][permissionKey]
           || false;
        });
      }
    });
  });

  return permissionsObject as TEmployeeRolePermissions;
};

const checkRolePermissions = (
  roleItems: TRolePermissionItem[],
  operator: EPermissionComparisonFunction = EPermissionComparisonFunction.AND,
) => {
  const permissionsObject = getPermissionsObject(store.state.tenants.currentTenant?.employee?.roles || []);

  return checkPermissionsCommon<TRolePermissionItem[]>(permissionsObject, roleItems, operator);
};

export default checkRolePermissions;

import { TTenantsState } from './types';

export const initialState = (): TTenantsState => ({
  userTenants: [],
  currentTenant: null,
  currentTenantInfo: null,
  complexityCheck: null,
  isLoading: false,
  lastTenantId: null,
  referenceData: null,
});

<template>
  <Drawer
    :title="tt('tenant.drawer.header')"
    class="add-tenant-drawer"
    visible
    @close="onClose"
  >
    <Form
      ref="formRef"
      :layout="ELayout.vertical"
      :model="modelRef"
      :rules="formRules"
      autocomplete="off"
      @validate="onValidateField"
    >
      <Divider
        class="add-tenant-drawer__main-info-divider"
        orientation="left"
      >
        {{ tt('shared.mainInfoTitle') }}
      </Divider>
      <p class="add-tenant-drawer__subtitle">
        {{ tt('tenant.shared.mainInfoSubtitle') }}
      </p>
      <FormItem
        :label="tt('tenant.shared.form.name')"
        name="name"
      >
        <ContractorsSelect
          placeholder=""
          :items="options"
          :isLoading="isOptionsLoading"
          :value="modelRef.name"
          @select="handleTenantChange"
          @search="handleSearch"
        />
      </FormItem>
      <div class="add-tenant-drawer__row">
        <FormItem
          class="add-tenant-drawer__row-item add-tenant-drawer__row-item_left"
          :label="tt('shared.inn')"
          name="inn"
        >
          <Input
            :value="modelRef.inn"
            isDigital
            :isInvalid="!formValidation['inn']"
            @change="handleInputChange('inn', $event.target.value)"
          />
        </FormItem>
        <FormItem
          class="add-tenant-drawer__row-item"
          :label="tt('shared.kpp')"
          name="kpp"
        >
          <Input
            :value="modelRef.kpp"
            isDigital
            :isInvalid="!formValidation['kpp']"
            @change="handleInputChange('kpp', $event.target.value)"
          />
        </FormItem>
      </div>
      <FormItem
        class="add-tenant-drawer__row-item_left add-tenant-drawer__row-item_half"
        :label="tt('shared.phone')"
      >
        <PhoneInput
          :value="modelRef.phone"
          placeholder=""
          :hasMask="false"
          @input="handleInputChange('phone', $event.target.value)"
        />
      </FormItem>
      <div class="add-tenant-drawer__row">
        <FormItem
          name="legalAddress"
          class="add-tenant-drawer__row-item"
          :label="tt('shared.legalAddress')"
        >
          <Input
            :value="modelRef.legalAddress"
            @change="handleInputChange('legalAddress', $event.target.value)"
          />
        </FormItem>
      </div>
      <FormItem
        class="add-tenant-drawer__item"
        :label="tt('tenant.shared.form.taxType')"
        name="taxType"
      >
        <RadioGroup
          v-model:value="modelRef.taxType"
          :optionType="ERadioButtonOptionType.button"
          :options="taxType"
        />
      </FormItem>
      <Divider orientation="left">
        {{ tt('tenant.shared.bankDetailsTitle') }}
      </Divider>
      <p class="add-tenant-drawer__subtitle">
        {{ tt('tenant.shared.bankDetailsSubtitle') }}
      </p>
      <div class="add-tenant-drawer__row">
        <FormItem
          class="add-tenant-drawer__row-item add-tenant-drawer__row-item_left"
          :label="tt('tenant.shared.form.bankBik')"
          name="bankBik"
        >
          <BankSelector
            :items="bankOptions"
            :isLoading="isOptionsBankLoading"
            :value="modelRef.bankBik"
            dropdownClassName="add-tenant-drawer__bank-dropdown"
            @change="handleBankChange"
            @search="handleSearchBankChange"
          />
        </FormItem>
        <FormItem
          class="add-tenant-drawer__row-item"
          :label="tt('tenant.shared.form.bankCheckingAccount')"
          name="bankCheckingAccount"
        >
          <Input
            :value="modelRef.bankCheckingAccount"
            isDigital
            :isInvalid="!formValidation['bankCheckingAccount']"
            @change="handleInputChange('bankCheckingAccount', $event.target.value)"
          />
        </FormItem>
      </div>
      <BankAccountInfo
        v-if="showBankAccount"
        :bank="bankInfo"
      />
    </Form>
    <template #footer>
      <Button
        htmlType="submit"
        class="add-tenant-drawer__close-btn"
        @click.prevent="onClose"
      >
        {{ tt('shared.action.cancel') }}
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        :disabled="submitDisabled"
        @click.prevent="onSubmit"
      >
        {{ tt('tenant.drawer.register') }}
      </Button>
    </template>
  </Drawer>
</template>

<script lang="ts">
import {
  Button,
  Divider,
  Form,
  FormItem,
} from 'ant-design-vue';
import debounce from 'lodash.debounce';
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRaw,
} from 'vue';

import {
  Input,
  PhoneInput,
  RadioGroup,
} from '@/ui';
import useStore from '@/store/useStore';
import ContractorsSelect from '@/components/ContractorsSelect/index.vue';
import Drawer from '@/components/CustomDrawer/index.vue';
import { useFormHandlers } from '@/composables/useFormHandlers';
import { fetchBankAccountsAutocomplete } from '@/contexts/accountingContext/services';
import { fetchContractorsSuggestions } from '@/contexts/resourcesControlContext/services';
import logger from '@/logger';
import { capitalize } from '@/utils';
import { TContractor, TContractorEditablePayload } from '@/contexts/contractorsContext/domain/types';
import { TNewTenantFormData } from '@/contexts/accountingContext/domain/types';
import tt from '@/i18n/utils/translateText';
import { ELayout } from '@/constants';
import { ECompanyRole } from '@/domains/constants';
import useViewState from '@/composables/useViewState';
import {
  ETenantTaxType,
  EBankModelFields,
} from '@/contexts/accountingContext/domain/constants';
import { ERadioButtonOptionType } from '@/ui/types';
import { TDomainError } from '@/types';
import { TReachedResponse } from '@/api/types';

import BankAccountInfo from '../BankAccountInfo/index.vue';
import BankSelector from '../BankSelector/index.vue';
import { TBankAccount, TBankAccounts } from '../BankSelector/types';

export default defineComponent({
  name: 'AddTenantDrawer',
  components: {
    Divider,
    Drawer,
    Button,
    Form,
    FormItem,
    RadioGroup,
    ContractorsSelect,
    BankSelector,
    BankAccountInfo,
    Input,
    PhoneInput,
  },
  props: {
    formDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'success'],
  setup(props, { emit }) {
    const modelRef = reactive<TNewTenantFormData>({
      name: '',
      inn: null,
      kpp: null,
      taxType: ETenantTaxType.osno,
      bankName: null,
      bankBik: null,
      bankAddress: null,
      bankInn: null,
      bankKpp: null,
      bankCorrespondentAccount: null,
      bankCheckingAccount: null,
      phone: null,
      legalAddress: null,
    });

    const store = useStore();
    const { isLoading, setViewStateAs } = useViewState();

    const sberParams = computed(() => store.state.app.sberParams);

    const isOptionsLoading = ref(false);
    const isOptionsBankLoading = ref(false);
    const options = ref<TContractor[]>([]);
    const bankOptions = ref<TBankAccounts>([]);
    const formRef = ref<typeof Form | null>(null);
    const showBankAccount = ref(false);
    const maxBanksOptionsLength = ref(8);

    const roleTypes = [
      {
        label: tt('tenant.shared.form.customer'),
        value: ECompanyRole.customer,
      },
      {
        label: `${tt('shared.sender')}/${tt('shared.recipient')}`,
        value: ECompanyRole.shipper,
      },
      {
        label: tt('tenant.shared.form.executor'),
        value: ECompanyRole.executor,
      },
    ];

    const rulesRef = {
      name: [{
        required: true,
        message: tt('tenant.shared.form.rule.nameRequired'),
      }],
      taxType: [{
        required: true,
        message: tt('tenant.shared.form.rule.taxTypeRequired'),
      }],
      legalAddress: [{
        required: true,
        message: tt('tenant.shared.form.rule.legalAddressRequired'),
      }],
      bankBik: [{}],
      bankCheckingAccount: [],
      kpp: [],
      inn: [{
        required: true,
        message: tt('shared.rule.innRequired'),
      }],
    };

    const taxType = [
      {
        label: tt('tenant.shared.form.usn'),
        value: ETenantTaxType.usn,
      },
      {
        label: tt('tenant.shared.form.osno'),
        value: ETenantTaxType.osno,
      },
    ];

    const submitDisabled = computed(() => props.formDisabled || isLoading.value);

    const {
      onError,
      onControlChange,
      onValidateField,

      formValidation,
      formRules,
    } = useFormHandlers(formRef, {
      rules: rulesRef,
      formData: modelRef,
    });

    const handleFormSubmit = (payload: TNewTenantFormData) => {
      setViewStateAs.loading();

      store.dispatch('tenants/registerNewTenant', payload)
        .then(() => {
          setViewStateAs.done();
          emit('success');
          store.dispatch('app/setSberParams', null);
        })
        .catch((error: TDomainError) => {
          setViewStateAs.withError();

          if (error?.errors?.bank) {
            const serializedErrors = Object.entries(error?.errors?.bank).reduce(
              (res: Record<string, string>, [key, message]: [string, string]) => {
                res[`bank${capitalize(key)}`] = message;

                return res;
              }, {});

            onError({ errors: serializedErrors });
          }
        });
    };

    const onSubmit = () => {
      if (!formRef.value) return;
      formRef.value.validate()
        .then(() => {
          handleFormSubmit(toRaw(modelRef));
        })
        .catch((err) => {
          onError(err);
          logger.log('error', err);
        });
    };

    const onClose = () => {
      if (sberParams.value && sberParams.value.userData) {
        store.dispatch('app/setSberParams', null);
      }
      emit('close');
    };

    const handleTenantChange = (optionId: string, option: TContractor) => {
      const contractor = options.value.find((item: TContractor) => item?.id === optionId);
      if (contractor) {
        modelRef.name = option.name;
        modelRef.inn = option.inn;
        modelRef.kpp = option.kpp;
        modelRef.phone = option.phone;
        modelRef.legalAddress = option.legalAddress;
      }
    };

    const handleInputChange = (name: TContractorEditablePayload, value: string) => {
      modelRef[name] = value;
      onControlChange({ name });
    };

    const handleSearch = (query: string) => {
      options.value = [];
      if (!query) return;
      isOptionsLoading.value = true;
      modelRef.name = query;
      fetchContractorsSuggestions(query)
        .then((result: TReachedResponse<TContractor[]>) => {
          if (!result.data) return;
          const { data: contragents } = result;
          const serializedContragents = contragents.map((contragent: TContractor) => ({
            ...contragent,
            id: `${contragent.inn}-${contragent.kpp}`,
          }));
          options.value = serializedContragents;
        })
        .catch((err: TDomainError) => {
          logger.log('error', err);
        })
        .finally(() => {
          isOptionsLoading.value = false;
        });
    };

    const handleBankChange = (option: TBankAccount) => {
      modelRef.bankBik = option.bik;
      modelRef.bankName = option.name;
      modelRef.bankAddress = option.address;
      modelRef.bankInn = option.inn;
      modelRef.bankKpp = option.kpp;
      modelRef.bankCorrespondentAccount = option.correspondentAccount;

      showBankAccount.value = true;
    };

    const resetBankModelFields = () => {
      Object.keys(EBankModelFields).forEach((field: string) => {
        modelRef[field] = null;
      });

      showBankAccount.value = false;
    };

    const handleSearchBankChange = (query: string) => {
      onControlChange({ name: 'bankBik' });
      bankOptions.value = [];
      if (!query) {
        // Когда инпут пустой, нужно удалить ранее выбранный банк
        resetBankModelFields();
        return;
      }
      isOptionsBankLoading.value = true;
      fetchBankAccountsAutocomplete(query)
        .then((result: TReachedResponse<TBankAccounts>) => {
          if (result.data.length) {
            const { data: banks } = result;
            const serializedBanks = banks.map((bank: TBankAccount) => ({
              ...bank,
              id: `${bank.bik}-${bank.name}`,
            }));
            bankOptions.value = serializedBanks.slice(0, maxBanksOptionsLength.value);
          }
        })
        .catch((err: TDomainError) => {
          logger.log('error', err);
        })
        .finally(() => {
          isOptionsBankLoading.value = false;
        });
    };

    const bankInfo = computed(() => ({
      bankName: modelRef.bankName,
      bankCorrespondentAccount: modelRef.bankCorrespondentAccount,
      bankAddress: modelRef.bankAddress,
      bankInn: modelRef.bankInn,
      bankKpp: modelRef.bankKpp,
    }));

    const setFormData = () => {
      if (sberParams.value && sberParams.value.userData) {
        modelRef.name = sberParams.value.userData.organization.name;
        modelRef.inn = sberParams.value.userData.organization.inn;
        modelRef.kpp = sberParams.value.userData.organization.kpp;
        modelRef.bankBik = sberParams.value.userData.organization.bic;
        modelRef.phone = sberParams.value.userData.organization.phone || null;
        modelRef.legalAddress = sberParams.value.userData.organization.legalAddress || null;
      }
    };

    const processSberParams = () => {
      setFormData();
    };

    onMounted(() => {
      processSberParams();
    });

    return {
      modelRef,
      formRef,
      formRules,
      bankInfo,
      ELayout,
      ECompanyRole,
      ERadioButtonOptionType,
      options,
      bankOptions,
      isOptionsLoading,
      isOptionsBankLoading,
      roleTypes,
      showBankAccount,
      isLoading,
      submitDisabled,
      formValidation,
      taxType,

      tt,
      onSubmit,
      onClose,
      handleSearch: debounce(handleSearch, 350),
      handleSearchBankChange: debounce(handleSearchBankChange, 350),
      handleTenantChange,
      handleBankChange,
      handleInputChange,
      onValidateField,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

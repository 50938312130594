import {
  computed,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';

import useStore from '@/store/useStore';
import {
  EAppState,
  ETransitionMethod,
  ROUTE_TYPE,
} from '@/constants';
import logger from '@/logger';
import { hasProperty } from '@/utils';
import { EErrorEvents } from '@/api/constants';
import { SET_APP_STATE } from '@/store/modules/app/mutation-types';

// oldState -> newState -> reaction
const STATE_TRANSITIONS = {
  [EAppState.initial]: {},
  [EAppState.checking]: {},
  [EAppState.unauthenticated]: {},
  [EAppState.interactionLocked]: {},
  [EAppState.authenticated]: {},
};

const unregisterAllTransitions = () => {
  STATE_TRANSITIONS[EAppState.initial] = {};
  STATE_TRANSITIONS[EAppState.checking] = {};
  STATE_TRANSITIONS[EAppState.unauthenticated] = {};
  STATE_TRANSITIONS[EAppState.interactionLocked] = {};
  STATE_TRANSITIONS[EAppState.authenticated] = {};
};

/**
  * Сетит функцию, которую необходимо вызвать после перехода между состояниями
*/
const registerTransition = (oldState: EAppState, newState: EAppState, transition: ETransitionMethod) => {
  STATE_TRANSITIONS[oldState][newState] = transition;
};

// регистрируем все возможные переходы между состояниями
registerTransition(EAppState.initial, EAppState.checking, ETransitionMethod.onCheck);
registerTransition(EAppState.checking, EAppState.interactionLocked, ETransitionMethod.onInteractionLock);
registerTransition(EAppState.checking, EAppState.authenticated, ETransitionMethod.onSuccessfulAuthentication);
registerTransition(EAppState.checking, EAppState.unauthenticated, ETransitionMethod.onFailedAuthentication);
registerTransition(EAppState.unauthenticated, EAppState.authenticated, ETransitionMethod.onLogin);
registerTransition(EAppState.unauthenticated, EAppState.interactionLocked, ETransitionMethod.onInteractionLock);
registerTransition(EAppState.authenticated, EAppState.unauthenticated, ETransitionMethod.onLogout);
registerTransition(EAppState.authenticated, EAppState.onMaintenance, ETransitionMethod.onMaintenance);
registerTransition(EAppState.interactionLocked, EAppState.authenticated, ETransitionMethod.onInteractionUnlock);
registerTransition(EAppState.interactionLocked, EAppState.unauthenticated, ETransitionMethod.onLogout);
registerTransition(EAppState.checking, EAppState.onMaintenance, ETransitionMethod.onMaintenance);
registerTransition(EAppState.unauthenticated, EAppState.onMaintenance, ETransitionMethod.onMaintenance);

/**
  * Вызывает функцию подготовки данных перед переходом в состояние "аутентифицирован",
  * например, запрос списка тенантов, данные о пользователе и тд.
*/
const prepareDataBeforeAuthentication = (accessToken: string | null, preparationMethod: () => Promise<unknown>) => {
  if (!accessToken) return Promise.reject();
  return preparationMethod();
};

/**
  * Вызывает коллбек для перехода между состояниями
*/
const onStateTransit = (
  transitionsCallbacks: Record<ETransitionMethod, () => unknown>,
) => (newState: EAppState, oldState: EAppState) => {
  if (STATE_TRANSITIONS[oldState]?.[newState]) {
    const transitionName: ETransitionMethod = STATE_TRANSITIONS[oldState][newState];
    logger.log('[Global App State] Transition name:', transitionName);

    if (hasProperty(transitionsCallbacks, transitionName)) {
      transitionsCallbacks[transitionName]();
    }
  }
};

export const useAppState = (
  dataPreparationCallback: () => Promise<void>,
  transitionCallbacks: Record<ETransitionMethod, () => unknown>,
) => {
  const store = useStore();
  const route = useRoute();

  const transitToAuthenticated = () => store.commit(`app/${SET_APP_STATE}`, EAppState.authenticated);
  const transitToUnauthenticated = () => store.commit(`app/${SET_APP_STATE}`, EAppState.unauthenticated);

  /**
    * Меняет состояние приложения в зависимости от того
    * аутентифицирован ли пользователь в системе
  */
  const performAuthCheck = () => {
    store.commit(`app/${SET_APP_STATE}`, EAppState.checking);
    const { accessToken } = store.state.app;

    prepareDataBeforeAuthentication(accessToken, dataPreparationCallback)
      .then(transitToAuthenticated)
      .catch((err) => {
        if (err?.errorEvent === EErrorEvents.maintenanceMode
          || err?.errorEvent === EErrorEvents.badGatewayError
          || err?.errorEvent === EErrorEvents.intervalServerError
          || err?.errorEvent === EErrorEvents.applicationError
          || err?.errorEvent === EErrorEvents.unexpectedError) return;
        transitToUnauthenticated();
      });
  };

  const isIntermediate = computed((): boolean => store.getters['app/isIntermediate']);
  const currentState = computed((): EAppState => store.state.app.state);

  const shouldRedirectToSignUp = computed(() => {
    const { state } = store.state.app;

    if (state === EAppState.authenticated) {
      return false;
    }

    return !isIntermediate.value && route.meta.type === ROUTE_TYPE.private;
  });

  watch(() => currentState.value, (newValue: EAppState, oldValue: EAppState) => {
    onStateTransit(transitionCallbacks)(newValue, oldValue);
  });

  return {
    isIntermediate,
    shouldRedirectToSignUp,

    performAuthCheck,
  };
};

export const objForTest = {
  STATE_TRANSITIONS,
  registerTransition,
  unregisterAllTransitions,
  prepareDataBeforeAuthentication,
  onStateTransit,
};

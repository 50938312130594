import { v4 as uuidv4 } from 'uuid';

import logger from '@/logger';
import hasProperty from '@/utils/hasProperty';
import isObject from '@/utils/isObject';
import isString from '@/utils/isString';

type TEmitter = string | null;
type TEvent = string | null;
type TObjectEmitPayload = {
  event: string | null,
  emitter: TEmitter,
};
type TObjectSubscribePayload = {
  event: string | null,
  key: TEmitter,
};
type TSubscribePayload = TObjectSubscribePayload | string | null;
type TEmitPayload = TObjectEmitPayload | string | null;
export type TFn = (...args: any[]) => unknown;
type TListener = {
  callback: TFn,
  initialCallback: TFn,
  key: string,
};
type TSubscribe = (event: TSubscribePayload, callback: TFn) => void;
type TUnsubscribe = (event: string, callback: TFn) => void;
type TEmit = (event: TEmitPayload, ...args: any[]) => void;
type TListeners = Record<string, Record<string, TListener> | null>;

const getUniqId = () => `${uuidv4()}`;

const getIsObjectSubscribePayload = (payload: TObjectSubscribePayload | TObjectEmitPayload): payload is TObjectSubscribePayload => hasProperty(payload, 'key');

const getMeta = (key: 'key' | 'emitter') => (payload: TObjectSubscribePayload | TObjectEmitPayload) => {
  let event: TEvent = null;
  let dynamicKey: TEmitter = null;
  if (isString(payload)) {
    event = payload as string;
  } else if (isObject(payload)) {
    event = payload.event;
    if (getIsObjectSubscribePayload(payload)) {
      dynamicKey = payload.key;
    } else {
      dynamicKey = payload.emitter;
    }
  }

  return {
    event,
    [key]: dynamicKey,
  };
};
const getMetaFromSubscription = (payload: TObjectSubscribePayload) => getMeta('key')(payload);
const getMetaFromEmit = (payload: TObjectEmitPayload) => getMeta('emitter')(payload);

/**
 * eventBus реализует обычный pub-sub плюс небольшие доработки поверх:
 * - при подписке первым аргументом можно передать строку с ключом подписчика либо объект
 *   для указания специфической информации (см. тип TSubscribePayload);
 * - все слушатели определенного события заносятся в объект, чтобы было проще ими манипулировать;
 * -
 */
export const createEventBus = ({ debug }: { debug: boolean } = { debug: false }) => {
  const listeners: TListeners = {};

  const subscribe: TSubscribe = (subscriptionPayload, callback) => {
    let subscribedEvent: TEvent = null;
    /**
     * subscriberKey используется только для того, чтобы уметь определять собственные
     * события и не реагировать на них. subscriberKey получает значение только в том случае,
     * если в payload передан объект, и в этом объекте есть ключ
     */
    let subscriberKey: TEmitter = null;

    if (isString(subscriptionPayload)) {
      subscribedEvent = subscriptionPayload as string;
    } else if (isObject(subscriptionPayload)) {
      /** Если при подписке указан объект, в нём указаны event, на который осуществляется подписка
       * и key – ключ подписчика, чтобы в дальнейшем можно было определить собственное событие.
       */
      const { event: eventFromPayload, key: keyFromPayload } = getMetaFromSubscription(subscriptionPayload);
      subscribedEvent = eventFromPayload;
      subscriberKey = keyFromPayload;
    }

    const localCallback = (emitPayload: any, ...args: any[]) => {
      const { emitter } = emitPayload;
      if (!subscriberKey || !emitter || emitter !== subscriberKey) {
        callback(...args);
      }
    };

    if (!subscribedEvent) {
      logger.warn(`Wrong event to subscribe. Event: ${subscribedEvent}`);
      return;
    }

    const newListenerKey = getUniqId();
    if (isObject(listeners[subscribedEvent])) {
      /** Если на данное событие уже есть подписки, и оно зарегистрировано, то просто добавляем новый ключ */
      const currentListeners = listeners[subscribedEvent] as Record<string, TListener>;
      currentListeners[newListenerKey] = {
        callback: localCallback,
        initialCallback: callback,
        key: newListenerKey,
      };
    } else {
      /** Иначе создаём объект с новым подписчиком */
      listeners[subscribedEvent] = {
        [newListenerKey]: {
          callback: localCallback,
          initialCallback: callback,
          key: newListenerKey,
        },
      };
    }
  };

  const unsubscribe: TUnsubscribe = (event, callback) => {
    /**
     * если такое событие было зарегистрировано,
     * то отписываем от него конкретного слушателя по переданному callback
     */
    if (isObject(listeners[event])) {
      const currentListeners = listeners[event] as Record<string, TListener>;
      const listenerKeyToUnsubscribe = Object.values(currentListeners).find((l) => l.initialCallback === callback);
      if (listenerKeyToUnsubscribe) {
        delete currentListeners[listenerKeyToUnsubscribe.key];
      } else {
        logger.warn(`There is no function to unsubscribe from "${event}" event.`);
      }
    } else {
      logger.warn(`Event "${event}" is not registered. Cannot to unsubscribe.`);
    }
  };

  const emit: TEmit = (eventPayload, ...args) => {
    let event: TEvent = null;
    let emitter: TEmitter = null;
    if (isString(eventPayload)) {
      event = eventPayload;
    } else if (isObject(eventPayload)) {
      const { event: eventFromPayload, emitter: emitterFromPayload } = getMetaFromEmit(eventPayload);
      event = eventFromPayload;
      emitter = emitterFromPayload;
    }

    if (!event) {
      logger.warn(`Event has wrong annotation. Event: ${event}. EventPayload: ${JSON.stringify(eventPayload)}.`);
      return;
    }
    if (!listeners[event]) {
      logger.warn(`Event "${event}" is not registered.`);
      return;
    }
    if (Object.keys(listeners[event] as Record<string, TListener>).length === 0) {
      logger.warn(`There is no registered callbacks for event "${event}".`);
      return;
    }

    /**
     * если такое событие было зарегистрировано,
     * то вызываем всех подписчиков поочередно, передавая им аргументы
     */
    if (isObject(listeners[event])) {
      const currentListeners = listeners[event] as Record<string, TListener>;
      Object.values(currentListeners).forEach((listener) => {
        listener.callback({
          event,
          emitter,
        }, ...args);
      });
      if (debug) {
        logger.log(`Event "${event}" called ${Object.values(currentListeners).length} times. Arguments: ${args}`);
      }
    }
  };

  const stopEvent = (event: any) => {
    if (listeners[event]) {
      listeners[event] = null;
    }
  };

  return {
    subscribe,
    unsubscribe,
    stopEvent,
    emit,
  };
};

export const globalEventBus = createEventBus();

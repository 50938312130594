import {
  reactive,
  Ref,
  ref,
  watch,
} from 'vue';

import logger from '@/logger';
import { hasProperty } from '@/utils';
import { TFormHandlerOptions } from '@/types';

/**
 * @description
 * Чтобы очистить валидацию удаляем все custom-rule в массиве с правилами
 */
const filterCustomRule = (rules) => {
  // Если  rules массив, то фильтруем его и возвращаем без custom-rule
  if (Array.isArray(rules)) {
    return rules.filter((rule) => rule?.id !== 'custom-rule');
  }

  const nestedRules = {};

  // Если объект, то проходимся по всем вложенным полям и фильтруем custom-rule
  Object.entries(rules).forEach(([key, value]: [key: string, value: unknown]) => {
    nestedRules[key] = filterCustomRule(value);
  });

  return nestedRules;
};

/**
 * @description
 * Получаем правило, которое будет воспринято как невалидное компонентом Form.
 * Нужно это правило для того, чтобы вручную устанавливать текст ошибки в элементы формы.
 * Этот объект будет среди других правил валидации, которые потом будут переданы в пропс rules.
 * Важно!
 * Для того, чтобы кастомный текст ошибки для конкретного поля работал, необходимо, чтобы в
 * изначальном объекте rules присутствовал ключ нужного поля (достаточно значения null).
 * Например, для поля username: rules = { username: null, password: [{ min: 5 }, { required: true }] };
 */
const getCustomRule = (message) => ({
  id: 'custom-rule',
  message,
  validator: () => Promise.reject(message),
});

const getFormDataDefaultValidation = (formData?: { [key: string]: any }) => {
  if (!formData) {
    return {};
  }
  return Object.keys(formData).reduce((result: { [key: string]: boolean }, key: string) => ({
    ...result,
    [key]: true,
  }), {});
};

/**
 * @description
 * Принудительный вызов метода у формы validateFields, чтобы после добавления custom-rule через getCustomRule
 * форма провалидировала все поля в соответствии с rules и вывела соответствующие ошибки с подсветкой интерфейса.
 */
const validateFieldsForce = (form) => {
  if (typeof form?.validateFields !== 'function') return;
  form.validateFields().catch((error) => {
    logger.warn('[validateFieldsForce] error: ', error);
  });
};

// TODO: затипизировать rules как Ref<Record<string, any>> для отслеживания реактивных изменений
/** @deprecated Вместо него используйте useFormHelpers */
export const useFormHandlers = (form: Ref, options?: TFormHandlerOptions) => {
  if (!form || !options) {
    throw new Error('Form and options arguments are required');
  }

  const { rules, computedRules, formData } = options;
  const commonError = ref(null);
  /**
   * @description
   * Заполняться всеми ключами из полей формы и хранит в себе данные по валидации каждого поля.
   * { [key: string]: true }
   */
  const formValidation = reactive<Record<string, boolean>>(getFormDataDefaultValidation(formData));
  const formRules = ref(rules);

  const removeCustomRuleFromItem = (ruleName: string) => {
    if (!formRules.value[ruleName]) return;
    formRules.value[ruleName] = filterCustomRule(formRules.value[ruleName]);
  };

  const clearAllErrors = () => {
    Object.keys(formRules.value || {}).forEach(removeCustomRuleFromItem);
  };

  /**
   * @description
   * Очищает всю валидацию для полей внутри формы
   */
  const clearValidation = () => {
    commonError.value = null;
    form.value?.resetFields();
    Object.keys(formValidation).forEach((key: string) => onValidateField(key, true));
  };

  /**
   * @description
   * Очищаем ошибку для конкретного поля формы после взаимодействия с ним пользователя.
   */
  const onControlChange = ({ name } = { name: '' }) => {
    commonError.value = null;
    if (!formRules.value[name]) return;

    removeCustomRuleFromItem(name);
    onValidateField(name, true);
    form.value?.clearValidate(name);
  };

  /**
   * @description
   * Разбираем объект с ошибками и прописываем в каждое правило для поля соответствующую ему ошибку.
   * @returns
   * true – в ходе разбора объекта с ошибками хотя бы для одного поля из formRules была установлена кастомная ошибка;
   * false – не установлено ни одной ошибки.
   */
  const setFieldsErrors = (errors) => {
    let withError = false;
    Object.entries(errors).forEach(([key, message]) => {
      if (!message) return;
      if (hasProperty(formRules.value, key)) {
        /**
         * возможно есть другой способ, но я довел до рабочего состояния только этот:
         * если хотим показать кастомную ошибку у поля, то добавляем к его rules
         * новое правило с заведомо невалидным условием валидации Promise.reject(),
         * в message записываем сообщение, которое пришло с бэка
         */
        formRules.value[key].push(getCustomRule(message));
        withError = true;
      }
    });

    return withError;
  };

  const onError = (error) => {
    clearAllErrors();

    let hasAnyErrors = false;
    if (!error) return Promise.resolve(hasAnyErrors);

    const { status, errors } = error;
    const errorsKeys = Object.keys(errors || {});
    const isErrorWithoutField = errorsKeys.length === 0;
    if (isErrorWithoutField && (status === 404 || status === 500 || status === 422)) {
      commonError.value = error;
      onValidateFields(false);
      return Promise.resolve(hasAnyErrors);
    }

    if (!errors) return Promise.resolve(hasAnyErrors);

    hasAnyErrors = setFieldsErrors(errors);

    validateFieldsForce(form?.value);
    return Promise.resolve(hasAnyErrors);
  };

  const setFieldError = (field: string, error: string) => {
    if (!hasProperty(formRules.value, field)) return;

    removeCustomRuleFromItem(field);

    if (!Array.isArray(formRules.value[field])) {
      formRules.value[field] = [getCustomRule(error)];
    } else {
      formRules.value[field].push(getCustomRule(error));
    }
    validateFieldsForce(form?.value);
  };

  /**
   * @description
   * Валидирует поле формы по значению status
   */
  const onValidateField = (field: string | number | string[] | number[], status: boolean) => {
    if (typeof field === 'string') {
      if (hasProperty(formValidation, field)) {
        formValidation[field] = status;
      }
    }
  };

  /**
   * @description
   * Валидирует все поля формы значением status
   */
  const onValidateFields = (status: boolean) => {
    Object.keys(formValidation).forEach((key: string) => onValidateField(key, status));
  };

  const validate = () => {
    validateFieldsForce(form?.value);
  };

  // Позволяет реактивно изменять правила формы
  watch(() => computedRules?.value, (value: Record<string, any>) => {
    formRules.value = value;
  });

  return {
    formValidation,
    onValidateField,
    onControlChange,
    clearValidation,
    onError,
    setFieldError,
    formRules,
    commonError,
    validate,
  };
};

import { COUNTER_WIDTH, TAGS_GAP_SIZE } from '../constants';

export const getResponsiveMaxTagCount = ({ maxAvailableWidth, tagWidths, minTagWidth }: {
  maxAvailableWidth: number | undefined,
  tagWidths: Array<number>,
  minTagWidth: number,
}) => {
  if (!maxAvailableWidth) return 1;

  let currentTotalWidth = 0;
  let availableTagCount = 0;
  const availableWidth = maxAvailableWidth - COUNTER_WIDTH;

  for (let i = 0; i < tagWidths.length; i++) {
    // заполянем доступное место тегами со гэпом
    currentTotalWidth += tagWidths[i] + TAGS_GAP_SIZE;
    // если текущая сумма элементов меньше максимальной ЛИБО если оставшегося места достаточно для вывода ellipsis тега
    if (currentTotalWidth <= availableWidth
        || availableWidth - currentTotalWidth + tagWidths[i] + TAGS_GAP_SIZE > minTagWidth) {
      availableTagCount += 1;
    } else {
      break; // завершаем цикл, когда набрали возможную ширину
    }
  }
  return availableTagCount || 1; // как минимум 1 в любом случае выводим
};

import { computed, ref } from 'vue';

import { EBillingPlanSelectionSteps } from '../domain/constants';

export const useChangePlanSelectionStep = () => {
  const step = ref<EBillingPlanSelectionSteps>(EBillingPlanSelectionSteps.planSelection);

  const changePlanSelectionStep = (newStep: EBillingPlanSelectionSteps) => {
    step.value = newStep;
  };

  const isStepPlanSelection = computed(() => step.value === EBillingPlanSelectionSteps.planSelection);
  const isStepChangeTenantInfo = computed(() => step.value === EBillingPlanSelectionSteps.changeTenantInfo);
  const isStepCompleteConnection = computed(() => step.value === EBillingPlanSelectionSteps.completeConnection);

  return {
    step,
    isStepPlanSelection,
    isStepChangeTenantInfo,
    isStepCompleteConnection,

    changePlanSelectionStep,
  };
};

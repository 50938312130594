import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

export enum EBillingPlanCode {
  shipper = 'tms.plan.shipper',
  carrierBasic = 'tms.plan.carrier.basic',
  carrierExtended = 'tms.plan.carrier.extended',
  carrierStart = 'tms.plan.carrier.start',
  freightForwarderBasic = 'tms.plan.freight_forwarder.basic',
  freightForwarderExtended = 'tms.plan.freight_forwarder.extended',
  cargoOwner = 'tms.plan.cargo_owner',
}

export enum EBillingShortPlanCode {
  basic = 'basic',
  extended = 'extended',
  start = 'start',
}

export enum EBillingPlanGroup {
  shipper = 'shipper',
  carrier = 'carrier',
  freightForwarder = 'freight_forwarder',
  cargoOwner = 'cargo_owner',
}

export enum EBillingPlanCodeStatus {
  active = 'active',
  pending = 'pending',
}

export enum EBillingTransactionCode {
  countOfCompletedExecutionRequests = 'tms.count_of_completed_execution_requests',
  countOfCompletedBookings = 'tms.count_of_completed_bookings',
  countOfCompletedRuns = 'tms.count_of_completed_runs',
}

export enum ETenantSubscriptionsEventTypeOld {
  subscriptionPlanUpdated = 'Events::BillingContext::SubscriptionPlanUpdated',
}

export enum ETenantSubscriptionsEventTypeNew {
  subscriptionPlanUpdated = 'billing_context.subscription_plan_updated',
}

export const ETenantSubscriptionsEventType = checkFeatureIsEnabled(EExperimentalFeatures.eventsMapping)
  ? ETenantSubscriptionsEventTypeNew
  : ETenantSubscriptionsEventTypeOld;

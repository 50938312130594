import { getShortPlan } from '@/contexts/billingContext/domain/getShortPlan';
import tt from '@/i18n/utils/translateText';
import { joinString } from '@/utils';
import { EBillingPlanCode, EBillingPlanGroup } from '@/contexts/billingContext/domain/constants';

// В связи с тем, что у нас в разных состояниях карточки с информацией о тарифе в настройках организации представлены текстовки в разных формах, доработал утилиту, добавив 3 аргумента:
// isNow - если нужно в начале текста слово "Сейчас", а не "Подключен";
// withoutPrefix - если нужно, чтоб текст начинался сразу с тарифа;
// withoutTariff - если нужно, чтоб в тексте не было слова "тарифный".

type TGetTextAboutConnectedPlanOptions = {
  planCode: EBillingPlanCode | null,
  isNow?: boolean,
  withoutPrefix?: boolean,
  withoutTariff?: boolean,
};

export const getTextAboutConnectedPlan = ({
  planCode,
  isNow = false,
  withoutPrefix = false,
  withoutTariff = false,
}: TGetTextAboutConnectedPlanOptions): string => {
  const planCodeText = planCode ? tt(`billing.planCode.${getShortPlan(planCode)}`) : '';

  let planGroupText = '';
  if (!planCode) {
    return tt('shared.no');
  }
  if (planCode.includes(EBillingPlanGroup.carrier)) {
    planGroupText = withoutTariff ? tt('billing.planForCarrier') : `${tt('billing.tariff')} ${tt('billing.planForCarrier')}`;
  } else if (planCode.includes(EBillingPlanGroup.freightForwarder)) {
    planGroupText = withoutTariff ? tt('billing.planForFreightForwarder') : `${tt('billing.tariff')} ${tt('billing.planForFreightForwarder')}`;
  } else if (planCode.includes(EBillingPlanGroup.shipper)) {
    planGroupText = withoutTariff ? tt('billing.planForShipper') : `${tt('billing.tariff')} ${tt('billing.planForShipper')}`;
  } else {
    planGroupText = tt('billing.tariff');
  }
  const getPrefix = () => {
    if (withoutPrefix) {
      return '';
    }
    if (isNow) {
      return tt('shared.now');
    }
    return tt('shared.connected');
  };

  return joinString([
    getPrefix(),
    planCodeText,
    planGroupText,
  ]);
};

import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import {
  TAddress,
  TFileUpload,
  TImportInfoResponse,
  TPaginationForRequest,
  TTableColumns,
} from '@/types';
import { TFilterItemDTO } from '@/components/Filter/types';

import {
  EEntityType,
  TCustomAttributesResponse,
  TReferenceAsSelectOptionsResponse,
  TCustomReferenceRowsRequest,
  TCustomReferenceRowsResponse,
  TCustomReferencesResponse,
  TImportCustomReferenceRowsRequest,
} from '../domain/types';

export const createAddress = (payload: TAddress) => sendApiRequest<TAddress>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/addresses',
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getCustomAttributes = (entityType: EEntityType[]) => sendApiRequest<TCustomAttributesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/custom_attribute_definitions',
  params: { entityType },
});

export const changeOrderCustomAttribute = (orderIid: string | number, customAttributeId: string, payload) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/orders/${orderIid}/custom_attributes/${customAttributeId}`,
  payload: { attributes: payload },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

/** Получение данных о кастомных справочниках и типах их полей */
export const getCustomReferences = () => sendApiRequest<TCustomReferencesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/custom_references',
});

/** Получение настроек колонок для отображения таблицы кастомного справочника */
export const getCustomReferencesColumnsConfig = (customReferenceId: string) => sendApiRequest<TTableColumns>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/custom_references/${customReferenceId}/rows/columns`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

/** Получение значений для строк таблицы кастомного справочника */
export const getCustomReferenceRows = (
  {
    filterId,
    customReferenceId,
    currentPage,
    pageItems,
    sort,
    sortBy,
  } = {
    pageItems: null,
    currentPage: null,
  } as TPaginationForRequest & TCustomReferenceRowsRequest,
) => sendApiRequest<TCustomReferenceRowsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/custom_references/${customReferenceId}/rows`,
  params: {
    items: pageItems,
    page: currentPage,
    filterId,
    sort,
    sortBy,
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

/** Импорт строк кастомного справочника из Excel */
export const importCustomReferenceFromExcel = (
  { file }: TFileUpload,
  { customReferenceId, mode }: TImportCustomReferenceRowsRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/custom_references/${customReferenceId}/imports`,
  payload: {
    file,
    mode,
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getCustomReferenceImportInfo = (
  customReferenceId: string, importId: string) => sendApiRequest<TImportInfoResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/custom_references/${customReferenceId}/imports/${importId}`,
});

/** Экспорт строк кастомного справочника в Excel */
export const exportCustomReferenceToExcel = (customReferenceId: string) => sendApiRequest<Blob>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/custom_references/${customReferenceId}`,
  responseType: 'blob',
  headers: { Accept: 'blob' },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

/** Применить фильтры к строкам кастомного справочника */
export const applyCustomReferenceRowsFilters = (
  customReferenceId: string,
  payload: { filters: TFilterItemDTO[] },
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/custom_references/${customReferenceId}/rows/filters`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getCustomReferenceAsSelectOptions = (
  customReferenceId: string) => sendApiRequest<TReferenceAsSelectOptionsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/custom_references/${customReferenceId}/as_select_options`,
});

export const getSystemReferenceAsSelectOptions = (
  resourceName: string) => sendApiRequest<TReferenceAsSelectOptionsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/${resourceName}/as_select_options`,
});

export enum EPartnershipsInvitationType {
  incoming = 'incoming',
  outgoing = 'outgoing',
}

export enum EPartnershipsListTabsKey {
  partnershipsPartners = 'partnershipsPartners',
  partnershipsInvitations = 'partnershipsInvitations',
  partnershipsContracts = 'partnershipsContracts',
}

export enum EPartnershipsImportStatisticsField {
  stored = 'stored',
  alreadyInvited = 'alreadyInvited',
  alreadyPartner = 'alreadyPartner',
  failed = 'failed',
}

export const INVITATIONS_IMPORT_STATISTICS_FIELDS = Object.values(EPartnershipsImportStatisticsField);
export const INVITATIONS_IMPORT_STATISTICS_PATH_TO_TRANSLATE = 'partnerships.list.import.statistics';

import { watch } from 'vue';

import useStore from '@/store/useStore';
import useWebsocketChannel from '@/composables/useWebsocketChannel';

import useGlobalEventBus from '../useGlobalEventBus';

// TenantImportsChannel - общий канал для импорта excel файлов разных сущностей
const useImportWsUtils = () => {
  const store = useStore();

  const { emit: emitToBus } = useGlobalEventBus();

  const handleNewMessage = (message: any) => {
    if (!message) return;
    emitToBus('getImportsChannelPayload', message.payload);
  };

  const { connect, closeConnection } = useWebsocketChannel({
    channelName: 'TenantImportsChannel',
    onMessage: handleNewMessage,
  });

  const connectToTenantImportsChannel = () => {
    connect();
  };

  const disconnectFromTenantImportsChannel = () => {
    closeConnection();
  };

  const reconnectTenantImportsChannel = () => {
    disconnectFromTenantImportsChannel();
    connectToTenantImportsChannel();
  };

  watch(() => store.state.tenants.currentTenant?.id, () => {
    if (!store.state.tenants.currentTenant?.id) return;
    reconnectTenantImportsChannel();
  });
};

export default useImportWsUtils;

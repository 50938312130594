import { defineStore } from 'pinia';
import { ref } from 'vue';

import { i18n } from '@/i18n';
import { ELocale } from '@/domains/constants';
import { TI18nStore } from '@/stores/i18n/types';

export const useI18nStore = defineStore(
  'TMS_I18N',
  (): TI18nStore => {
    const currentLocale = ref<ELocale | null>(null);

    const setCurrentLocale = (nextLocale: ELocale) => {
      currentLocale.value = nextLocale;
      i18n.global.locale = nextLocale;
      i18n.global.fallbackLocale = currentLocale.value === ELocale.en ? ELocale.ru : ELocale.en;
    };

    return {
      currentLocale,
      setCurrentLocale,
    };
  },
  { persist: true });

import {
  computed,
  ref,
} from 'vue';

import { useFormHelpers } from '@/composables/useFormHelpers';
import tt from '@/i18n/utils/translateText';
import { generatePhoneRule } from '@/domains/generatePhoneRule';
import useStore from '@/store/useStore';
import { DATE_FORMAT, DEFAULT_EMAIL_RULE } from '@/constants';
import { TDomainError } from '@/types';
import { formatDate, getCurrentDayJsDate } from '@/utils/dateUtils';

import { TBillingShipperForm } from '../domain/types';

export const useInteractWithForm = () => {
  const store = useStore();

  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);

  const formData = ref<TBillingShipperForm>({
    subscriptionAt: formatDate(getCurrentDayJsDate(), { outputFormat: DATE_FORMAT.fullDate }),
    organizationPhone: currentTenantInfo.value?.legalEntity?.phone || '',
    publicOfferAccept: false,
    invoicingEmail: currentTenantInfo.value?.legalEntity?.invoicingEmail || '',
  });

  const isEmailConfirmed = ref(false);

  const isSubmitDisabled = computed(() => !formData.value.publicOfferAccept || !isEmailConfirmed.value);

  const rules = computed(() => ({
    subscriptionAt: [{
      required: true,
      message: tt('shared.rule.commonRequired'),
    }],
    organizationPhone: [
      generatePhoneRule(formData.value.organizationPhone, 'blur'),
    ],
    invoicingEmail: [
      {
        ...DEFAULT_EMAIL_RULE,
        trigger: 'blur',
      },
      {
        required: true,
        message: tt('shared.rule.emailRequired'),
        trigger: 'blur',
      }],
  }));

  const handleEmailConfirmed = () => {
    isEmailConfirmed.value = true;
  };

  const {
    validateInfos,

    setResponseErrors: onError,
    onControlChange,
    validate,
  } = useFormHelpers(
    formData.value,
    rules,
    { validateOnRuleChange: false },
  );

  const handleFieldChange = (field: string) => {
    onControlChange({ name: field });
  };

  const handleFieldValidate = (field: string) => {
    validate(field);
  };

  const handleChangeEmail = () => {
    isEmailConfirmed.value = false;
    formData.value.invoicingEmail = '';
  };

  const setResponseErrors = (error: TDomainError) => {
    onError(error);

    validate().catch(() => {});
  };

  const onSubmit = () => validate()
    .then(() => Promise.resolve(formData.value))
    .catch(() => Promise.reject());

  return {
    formData,
    validateInfos,
    isEmailConfirmed,
    isSubmitDisabled,

    handleFieldValidate,
    handleFieldChange,
    handleEmailConfirmed,
    handleChangeEmail,
    onSubmit,
    setResponseErrors,
  };
};

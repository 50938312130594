<template>
  <Layout class="app-layout">
    <div
      v-if="hasPageHeader"
      class="app-layout__page-header"
    >
      <slot name="pageHeader" />
    </div>

    <LayoutContent
      class="app-layout__content"
      :class="{ [className]: className }"
    >
      <slot />
    </LayoutContent>
  </Layout>
</template>

<script lang="ts">
import { Layout, LayoutContent } from 'ant-design-vue';
import {
  computed,
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'AppLayout',
  components: {
    Layout,
    LayoutContent,
  },
  props: {
    className: {
      type: String,
      default: null,
    },
  },
  setup(_, { slots }) {
    const hasPageHeader = computed(() => !!slots.pageHeader);

    return { hasPageHeader };
  },
});
</script>

<style lang="scss" scoped src="./styles.scss" />

<template>
  <div
    class="sider-top-content"
    :class="{ 'sider-top-content_collapsed': isCollapsed }"
  >
    <template v-if="isCollapsed">
      <Button
        class="sider-top-content__uncollapse-button"
        type="link"
        @click="handleCollapse"
      >
        <HamburgerSvg />
      </Button>
    </template>
    <template v-else>
      <div class="sider-top-content__logo-block">
        <Button
          class="sider-top-content__logo"
          type="link"
          aria-label="TMS logo"
          @click="handleLogoClick"
        >
          <AppLogo v-if="!isHiddenLogo" />
        </Button>
        <Button
          class="sider-top-content__collapse-button"
          type="link"
          aria-label="collapse sidebar"
          @click="handleCollapse"
        >
          <CollapseSvg />
        </Button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Button } from 'ant-design-vue';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import CollapseSvg from '@/assets/svg/16x16/collapse.svg';
import AppLogo from '@/components/AppLogo/index.vue';
import useStore from '@/store/useStore';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import HamburgerSvg from '@/assets/svg/16x16/hamburger.svg';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

export default defineComponent({
  name: 'SiderTopContent',
  components: {
    AppLogo,
    HamburgerSvg,
    Button,
    CollapseSvg,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['collapse'],
  setup(_, { emit }) {
    const router = useRouter();
    const store = useStore();

    const isAuthByEntryCode = computed(() => store.getters['app/isAuthByEntryCode']);

    const isHiddenLogo = computed(
      () => checkFeatureIsEnabled(EExperimentalFeatures.hideLogo));

    const handleLogoClick = () => {
      if (isAuthByEntryCode.value) {
        router.push('/contractor_personal_account/runs');
      } else {
        redirectToInitialRoute();
      }
    };

    const handleCollapse = () => {
      emit('collapse');
    };

    return {
      isHiddenLogo,
      handleLogoClick,
      handleCollapse,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

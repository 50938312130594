const IS_PRODUCTION = import.meta.env.PROD;

export type TLogger = {
  log: (...args: any[]) => void,
  warn: (...args: any[]) => void,
  error: (...args: any[]) => void,
  info: (...args: any[]) => void,
};

export const turnOffLogger = (logger: TLogger): TLogger => {
  const copy = { ...logger };
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const method in copy) {
    copy[method] = () => {};
  }
  return copy;
};

const logger: TLogger = {
  log: (...args) => {
    if (IS_PRODUCTION) return;
    // eslint-disable-next-line no-console
    console.log(...args);
  },
  warn: (...args) => {
    if (IS_PRODUCTION) return;
    console.warn(...args);
  },
  error: (...args) => {
    if (IS_PRODUCTION) return;
    console.error(...args);
  },
  info: (...args) => {
    if (IS_PRODUCTION) return;
    console.info(...args);
  },
};

export default logger;

import TruckSvg from '@/assets/svg/16x16/truck.svg';
import ClockSvg from '@/assets/svg/16x16/clock.svg';
import CheckSvg from '@/assets/svg/16x16/check.svg';
import WarningSvg from '@/assets/svg/16x16/warning.svg';

type TStaticSchemaItem = {
  key: string,
  path: string,
  label: 'incidents' | 'current' | 'planned' | 'finished',
  icon: SVGElement,
  sequence: number,
};

export const runsSchema: TStaticSchemaItem[] = [
  {
    key: 'current',
    path: '/contractor_personal_account/runs/current',
    label: 'current',
    icon: TruckSvg,
    sequence: 1,
  },
  {
    key: 'planned',
    path: '/contractor_personal_account/runs/planned',
    label: 'planned',
    icon: ClockSvg,
    sequence: 2,
  },
  {
    key: 'finished',
    path: '/contractor_personal_account/runs/finished',
    label: 'finished',
    icon: CheckSvg,
    sequence: 3,
  },
];

export const warningSchema: TStaticSchemaItem[] = [
  {
    key: 'incidents',
    path: '/contractor_personal_account/incidents',
    label: 'incidents',
    icon: WarningSvg,
    sequence: 1,
  },
];

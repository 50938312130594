import axios, { AxiosResponse, AxiosError } from 'axios';
import qs from 'qs';

import loggerInstance, { turnOffLogger } from '@/logger';
import { camelizeStr } from '@/utils';

import { EErrorEvents, httpStatus } from './constants';
import type {
  TErrorPayload,
  TReachedResponse,
  TInternalRequestConfig,
  TInternalRequestConfigWithSignal,
  TPreparedRequest,
} from './types';
import {
  removeAbortSignal,
  prepareAbortSignal,
} from './utils/requestAbortController';

const logger = import.meta.env.VITE_APP_COMMON_API_CLIENT_LOGGER_ENABLED === 'true'
  ? loggerInstance
  : turnOffLogger(loggerInstance);

const axiosInstance = axios.create({
  timeout: 30000,
  paramsSerializer: (params) => qs.stringify(params, {
    indices: false,
    arrayFormat: 'brackets',
  }),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const prepareRequest = (request: TInternalRequestConfigWithSignal): TPreparedRequest => {
  const {
    baseURL,
    endpoint,
    headers,
    method,
    authTokens,
    contentType,
    responseType,
    payload,
    params,
    signal,
    withCredentials,
    withoutAuth,
  } = request;

  if (!authTokens?.accessToken && !withoutAuth) {
    // если нет accessToken'а и параметр withoutAuth у эндпоинта false || undefined, то кидаем warn. Для запроса не будет установлен accessToken
    console.warn(`[No Access Token] Request data: endpoint ${endpoint}, payload ${payload}, params ${params}`);
  }

  const mergedHeaders = {} as { Authorization: string, 'Content-Type': string };

  if (authTokens?.accessToken) {
    mergedHeaders.Authorization = `Bearer ${authTokens.accessToken}`;
  }

  if (contentType) {
    mergedHeaders['Content-Type'] = contentType;
  }

  if (withCredentials) {
    axiosInstance.defaults.withCredentials = true;
  }

  const preparedRequest: TPreparedRequest = {
    baseURL,
    url: endpoint,
    headers: {
      ...mergedHeaders,
      ...headers,
    },
    params,
    responseType,
    data: payload,
  };

  if (signal) {
    preparedRequest.signal = signal;
  }

  if (method) {
    preparedRequest.method = method;
  }

  return preparedRequest;
};

const paginationHeaders = ['current-page', 'page-items', 'total-pages', 'total-count'];

const handleResponse = <T>(response: AxiosResponse): TReachedResponse<T> => {
  const { headers, data } = response;
  const result = {
    data,
    pagination: {},
    headers,
  };
  Object.keys(headers).forEach((header) => {
    if (paginationHeaders.includes(header)) {
      result.pagination[camelizeStr(header)] = Number(headers[header]);
    }
  });

  logger.log('[Response headers]', headers, result);

  return result as TReachedResponse<T>;
};

const APPLICATION_HTTP_CODES = [
  httpStatus.FORBIDDEN.code,
  httpStatus.NOT_FOUND.code,
  httpStatus.UNPROCESSABLE_ENTITY.code];

const isApplicationLevelError = (status?: number) => !!status && APPLICATION_HTTP_CODES.includes(status);
const isAuthenticationError = (status?: number) => status === httpStatus.UNAUTHORIZED.code;
const isMaintenanceModeActive = (status?: number) => status === httpStatus.SERVICE_UNAVAILABLE.code;
const isBadGatewayError = (status?: number) => status === httpStatus.BAD_GATEWAY_ERROR.code;
const isIntervalServerError = (status?: number) => status === httpStatus.INTERNAL_SERVER_ERROR.code;

const makeAxiosRequest = <T>(request: TInternalRequestConfig): Promise<TReachedResponse<T>> => {
  const preparedAbortSignal = prepareAbortSignal(request);
  const preparedRequest = prepareRequest({
    ...request,
    signal: preparedAbortSignal,
  });

  return new Promise((resolve, reject) => {
    axiosInstance
      .request(preparedRequest)
      .then((response) => {
        removeAbortSignal(request.signalName);
        resolve(handleResponse<T>(response));
      })
      .catch((error: AxiosError) => {
        if (axios.isCancel(error)) return;

        /* eslint-disable prefer-promise-reject-errors */
        const errorPayload: TErrorPayload = {
          error,
          errorEvent: null,
        };
        if (isApplicationLevelError(error?.response?.status)) {
          errorPayload.errorEvent = EErrorEvents.applicationError;
        } else if (isAuthenticationError(error?.response?.status)) {
          errorPayload.errorEvent = EErrorEvents.authenticationFailed;
        } else if (isMaintenanceModeActive(error?.response?.status)) {
          errorPayload.errorEvent = EErrorEvents.maintenanceMode;
        } else if (isBadGatewayError(error?.response?.status)) {
          errorPayload.errorEvent = EErrorEvents.badGatewayError;
        } else if (isIntervalServerError(error?.response?.status)) {
          errorPayload.errorEvent = EErrorEvents.intervalServerError;
        } else {
          errorPayload.errorEvent = EErrorEvents.unexpectedError;
        }

        reject(errorPayload);
        /* eslint-enable prefer-promise-reject-errors */
      });
  });
};

// eslint-disable-next-line unused-imports/no-unused-vars
export const getAxiosInstance = (baseUrlBuilder = (request: any) => '' as string | null) => ({
  sendRequest: <T>(request: any): Promise<TReachedResponse<T>> => new Promise((resolve, reject) => {
    const baseURL = baseUrlBuilder(request);

    if (!baseURL) {
      reject();
      return;
    }

    logger.log('[Internal API]', request);
    makeAxiosRequest<T>({
      baseURL,
      ...request,
    }).then(resolve).catch(reject);
  }),
});

export default makeAxiosRequest;

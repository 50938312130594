<template>
  <div
    v-click-outside="handleOutsideClick"
    class="custom-autocomplete"
  >
    <!-- TODO: заменить Input после добавления "suffix" -->
    <Input
      :placeholder="placeholder"
      :value="valueRef"
      :loading="isLoading"
      :disabled="disabled"
      :title="showTitle ? valueRef : ''"
      :size="size"
      autocomplete="off"
      @update:value="handleValueUpdate"
      @focus="handleFocus"
      @pressEnter="handleEnterPress"
    >
      <template
        v-if="withClearButton"
        #suffix
      >
        <Button
          v-show="!!valueRef"
          shape="circle"
          class="custom-autocomplete__clear-button"
          @click="handleClearClick"
        >
          <CloseSvg class="custom-autocomplete__clear-icon" />
        </Button>
      </template>
    </Input>

    <Dropdown v-if="state.isOptionsVisible">
      <ul
        class="custom-autocomplete__options"
        :class="dropdownClassName"
      >
        <li
          v-if="isLoading"
          class="custom-autocomplete__loader"
        >
          <Spin size="small" />
        </li>
        <li
          v-else-if="isEmptyOptions"
          class="custom-autocomplete__empty-block"
        >
          <span class="custom-autocomplete__empty-data">
            <slot name="empty">
              {{ tt('shared.noData') }}
            </slot>
          </span>
          <slot name="action" />
        </li>
        <template v-else>
          <template v-if="groups">
            <template
              v-for="group in groups"
              :key="group.key"
            >
              <template v-if="handledOptions?.[group.key].length > 0">
                <li class="custom-autocomplete__group-title">
                  {{ group.name }}
                </li>
                <li
                  v-for="option in handledOptions[group.key]"
                  :key="option.key"
                >
                  <Button
                    class="custom-autocomplete__option"
                    :class="{ 'custom-autocomplete__option_selected': selectedKeyRef === option.key }"
                    :disabled="option.disabled"
                    type="default"
                    @click="handleOptionClick(option)"
                  >
                    <slot
                      name="optionTemplate"
                      :option="option"
                    >
                      {{ option.value }}
                    </slot>
                  </Button>
                </li>
              </template>
            </template>
          </template>
          <template v-else>
            <li
              v-for="option in handledOptions"
              :key="option.key"
            >
              <Button
                class="custom-autocomplete__option"
                :class="{ 'custom-autocomplete__option_selected': selectedKeyRef === option.key }"
                :disabled="option.disabled"
                type="default"
                @click="handleOptionClick(option)"
              >
                <slot
                  name="optionTemplate"
                  :option="option"
                >
                  {{ option.value }}
                </slot>
              </Button>
            </li>
          </template>
          <slot name="action" />
        </template>
      </ul>
    </Dropdown>
  </div>
</template>

<script lang="ts">
import {
  Button,
  Input,
  Spin,
  Dropdown,
} from 'ant-design-vue';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  PropType,
  watch,
} from 'vue';

import { isObject } from '@/utils';
import CloseSvg from '@/assets/svg/16x16/close.svg';
import tt from '@/i18n/utils/translateText';

import {
  TPlainOption,
  TPlainOptions,
  TGroupedOptions,
} from './types';

export default defineComponent({
  name: 'CustomAutoComplete',
  components: {
    Input,
    Spin,
    Button,
    CloseSvg,
    Dropdown,
  },
  props: {
    placeholder: {
      type: String,
      default: tt('shared.placeholder.typeForSearch'),
    },
    value: {
      type: [String, Number, null] as PropType<string | number | null>,
      default: null,
    },
    options: {
      type: [Array, Object] as PropType<TPlainOptions | TGroupedOptions>,
      default: () => [],
    },
    trackBy: {
      type: [String, Function],
      default: 'id',
    },
    valueBy: {
      type: [String, Function],
      default: 'value',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    groupsLabels: {
      type: Object,
      default: null,
    },
    withClearButton: {
      type: Boolean,
      default: false,
    },
    dropdownClassName: {
      type: String,
      default: '',
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  emits: ['update:value', 'select', 'search', 'focus', 'blur'],
  setup(props, { emit, expose }) {
    const valueRef = ref(props.value);
    const selectedKeyRef = ref<null | string>(null);

    const state = reactive({ isOptionsVisible: false });

    watch(() => props.value, () => {
      valueRef.value = props.value;
    });

    const getTrackingKeyFromOption = (option: TPlainOption) => {
      if (typeof props.trackBy === 'function') return props.trackBy(option);

      return option[props.trackBy];
    };

    const getValueFromOption = (option: TPlainOption) => {
      if (typeof props.valueBy === 'function') return props.valueBy(option);

      return option[props.valueBy];
    };

    const prepareOptions = (data: TPlainOptions | TGroupedOptions): TPlainOptions | TGroupedOptions => {
      const reachOption = (option: TPlainOption) => ({
        value: getValueFromOption(option),
        key: getTrackingKeyFromOption(option),
        disabled: !!option.disabled,
        data: option,
      });
      const isGroupedOptions = !!props.groupsLabels;
      if (isGroupedOptions && isObject(data)) {
        return Object.entries(data).reduce((result, [key, value]) => {
          // eslint-disable-next-line no-param-reassign
          result[key] = value.map(reachOption);
          return result;
        }, {} as TGroupedOptions);
      }
      return (data as TPlainOptions)?.map(reachOption);
    };

    const handleOptionsGroups = (options: TPlainOptions | TGroupedOptions) => {
      const isOptionsAsObjectWithoutGroupsLabels = !props.groupsLabels && isObject(options);
      if (isOptionsAsObjectWithoutGroupsLabels) {
        return Object.values(options as TGroupedOptions).reduce((result, current) => result.concat(current), []);
      }
      return options;
    };

    const handledOptions = computed(() => {
      if (!props.options) return [] as TPlainOptions;
      return prepareOptions(handleOptionsGroups(props.options));
    });

    const groups = computed(() => {
      if (isObject(props.groupsLabels)) {
        return Object.entries(props.groupsLabels).map(([key, name]) => ({
          key,
          name,
        }));
      }
      return null;
    });

    const isEmptyOptions = computed(() => {
      if (Array.isArray(handledOptions.value)) {
        return props.options?.length === 0;
      }
      if (isObject(handledOptions.value)) {
        return !Object.values(handledOptions.value).some((option) => option?.length > 0);
      }
      return false;
    });

    const updateValue = (newValue: string) => {
      valueRef.value = newValue;

      emit('search', valueRef.value);
      emit('update:value', valueRef.value);
    };

    const handleOptionClick = (option: TPlainOption) => {
      state.isOptionsVisible = false;

      valueRef.value = option.value;
      selectedKeyRef.value = option.key;

      emit('select', option.data);
    };

    const handleEnterPress = () => {
      state.isOptionsVisible = false;
    };

    const handleValueUpdate = (newValue: string) => {
      updateValue(newValue);
    };

    const handleClearClick = () => {
      updateValue('');
      state.isOptionsVisible = false;
    };

    const handleFocus = () => {
      state.isOptionsVisible = true;
      emit('focus');
    };

    const handleOutsideClick = () => {
      state.isOptionsVisible = false;
      emit('blur');
    };

    const onReset = (lastSavedValue: string | number) => {
      valueRef.value = lastSavedValue;
    };

    expose({
      onReset,
      valueRef,
    });

    return {
      state,
      groups,

      valueRef,
      selectedKeyRef,

      isEmptyOptions,

      handleFocus,
      handledOptions,
      handleValueUpdate,
      handleOptionClick,
      handleOutsideClick,
      handleEnterPress,
      handleClearClick,
      onReset,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./style.scss" />

import { TDateAppFormat } from '@/types';
import getCurrentDayJsDate from '@/utils/dateUtils/getters/getCurrentDayJsDate';

const getIsDisabledRangeForDate = (current: TDateAppFormat, range: number) => {
  const today = getCurrentDayJsDate().startOf('day');
  const maxDate = getCurrentDayJsDate().add(range, 'days').startOf('day');
  return current && (current.isBefore(today, 'day') || current.isAfter(maxDate, 'day'));
};

export default getIsDisabledRangeForDate;

<template>
  <component :is="logo" />
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';

import useAppTheme from '@/composables/application/useAppTheme';
import { toPascalCase } from '@/utils';
import TruckerTmsDarkSvg from '@/assets/svg/logo/trucker-tms-dark.svg';
import TruckerTmsLightSvg from '@/assets/svg/logo/trucker-tms-light.svg';
import AplSvg from '@/assets/svg/logo/apl.svg';

import { EAppLogoNames } from './constants';

export default defineComponent({
  name: 'AppLogo',
  components: {
    AplSvg,
    TruckerTmsDarkSvg,
    TruckerTmsLightSvg,
  },
  setup() {
    const { getThemeConfig } = useAppTheme();
    const { icons } = getThemeConfig();

    /** Столкнулись с проблемой, что логотип "моргает" при загрузке приложения(только логотип, с прогрессом - все ок).
     * Выяснилось, что компонент логотипа подгружается не единожды, а примерно 5-6 раз
     * В связи с чем, defineAsyncComponent(который подгружал логотип в зависимости от icons.logo)
     * каждый раз асинхронно переимпортировал компонент из-за чего и случались "моргания" логотипа.
     * Поэтому решение было следующим: импортировать все логотипы, вычислить актуальный компонент с логотипом
     * и отрендерить его в зависимости от конфига приложения(icons.logo устанавливается один раз и зависит
     * от VITE_APP_THEME_CONFIG_NAME переменной)
     */
    const logo = computed(() => {
      /** Название текущего логотипа, который нужно отрендерить */
      const currentAppLogoName = Object.values(EAppLogoNames).find((logoName: string) => logoName === icons.logo) || '';

      if (currentAppLogoName) {
        /** Возвращаем имя компонента в строковом формате, которое должно быть идентично импортированному компоненту из блока components,
        * чтобы <component is="" /> отрендерил нужный. */
        return `${toPascalCase(currentAppLogoName)}Svg`;
      }

      /** В качестве фоллбэка вернем наш стандартный логотип */
      return `${toPascalCase(EAppLogoNames.truckerTmsDark)}Svg`;
    });

    return { logo };
  },
});
</script>

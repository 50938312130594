import { EStatusType } from '@/domains/constants';
import tt from '@/i18n/utils/translateText';

import { EQuotaAccountingType } from '../components/QuotaSharedSettings/domain/constants';

export const ROUTE_NAME = { quotas: 'quotas' };

export enum EQuotaStatus {
  draft = 'draft',
  published = 'published',
  finished = 'finished',
  archived = 'archived',
}

export const QUOTA_STATUS_MODEL = {
  [EQuotaStatus.draft]: {
    text: tt('quota.shared.status.draft'),
    type: EStatusType.default,
  },
  [EQuotaStatus.published]: {
    text: tt('quota.shared.status.published'),
    type: EStatusType.processing,
  },
  [EQuotaStatus.finished]: {
    text: tt('quota.shared.status.finished'),
    type: EStatusType.success,
  },
  [EQuotaStatus.archived]: {
    text: tt('quota.shared.status.archived'),
    type: EStatusType.error,
  },
};

export enum EQuotaType {
  absolute = 'absolute',
  relativeByStats = 'relative_by_stats',
  relativeByEstimatedQuantity = 'relative_by_estimated_quantity',
}

export const QUOTA_TYPE_TRANSLATION = {
  [EQuotaType.absolute]: tt('quota.shared.quotaTypes.absolute'),
  [EQuotaType.relativeByStats]: tt('quota.shared.quotaTypes.relativeByStats'),
  [EQuotaType.relativeByEstimatedQuantity]: tt('quota.shared.quotaTypes.relativeByEstimatedQuantity'),
};

export enum EQuotaPeriodType {
  day = 'day',
  week = 'week',
  month = 'month',
}

export const QUOTA_PERIOD_TYPE_TRANSLATION = {
  [EQuotaPeriodType.day]: tt('quota.shared.quotaPeriodTypes.day'),
  [EQuotaPeriodType.week]: tt('quota.shared.quotaPeriodTypes.week'),
  [EQuotaPeriodType.month]: tt('quota.shared.quotaPeriodTypes.month'),
};

export const QUOTA_PERIOD_TYPE_TRANSLATION_SHORT = {
  [EQuotaPeriodType.day]: tt('quota.shared.quotaPeriodShortTypes.day'),
  [EQuotaPeriodType.week]: tt('quota.shared.quotaPeriodShortTypes.week'),
  [EQuotaPeriodType.month]: tt('quota.shared.quotaPeriodShortTypes.month'),
};

export const QUOTA_ACCOUNTING_TYPE_TRANSLATION = {
  [EQuotaAccountingType.issuingAnOffer]: tt('quota.shared.accountingQuota.issuingAnOffer'),
  [EQuotaAccountingType.acceptingAnOffer]: tt('quota.shared.accountingQuota.acceptingAnOffer'),
};

export enum EQuotaRankingRules {
  byRank = 'by_rank',
  byActualDailyQuota = 'by_actual_daily_quota',
}

export enum EQuotaFilters {
  all = 'all',
  published = 'published',
  finished = 'finished',
  draft = 'draft',
  archived = 'archived',
}

export const QUOTA_FILTERS = [
  {
    key: EQuotaFilters.all,
    label: tt('shared.all'),
  },
  {
    key: EQuotaFilters.published,
    label: tt('quota.shared.filters.published'),
  },
  {
    key: EQuotaFilters.finished,
    label: tt('quota.shared.filters.finished'),
  },
  {
    key: EQuotaFilters.draft,
    label: tt('quota.shared.filters.draft'),
  },
  {
    key: EQuotaFilters.archived,
    label: tt('quota.shared.filters.archived'),
  },
];

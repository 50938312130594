import { defineStore } from 'pinia';
import { ref } from 'vue';

import { TSearchState, TSearchStore } from './types';
import { DEFAULT_SEARCH_STATE, ESearchStateSection } from './constants';

export const useSearchStore = defineStore(
  'search',
  (): TSearchStore => {
    const state = ref<TSearchState>({ ...DEFAULT_SEARCH_STATE });

    const setSearchQueryForSection = (section: ESearchStateSection, query: string) => {
      state.value[section] = query;
    };

    const hasSearchQueries = () => Object.values(state.value).some((query: string | null) => !!query);

    const $reset = () => {
      state.value = { ...DEFAULT_SEARCH_STATE };
    };

    return {
      state,

      setSearchQueryForSection,
      hasSearchQueries,
      $reset,
    };
  },
  { persist: true });

import { EPermissionComparisonFunction } from '@/constants';
import store from '@/store';
import checkPermissionsCommon from '@/domains/permissions/checkPermissionsCommon';
import { TActiveSubscriptionsConditionsFeatures } from '@/types';

export type TSubscriptionPermissionItem = TFinalPathsOfObject<TActiveSubscriptionsConditionsFeatures>;

const checkSubscriptionPermissions = (
  subscriptionItems: TSubscriptionPermissionItem[],
  operator: EPermissionComparisonFunction = EPermissionComparisonFunction.AND,
) => checkPermissionsCommon<TSubscriptionPermissionItem[]>(store.state.tenants.currentTenant?.activeSubscriptionsConditions?.features, subscriptionItems, operator);

export default checkSubscriptionPermissions;

import store from '@/store';

import { EExperimentalFeatures } from './constants';

export const checkFeatureIsEnabled = (featureName: EExperimentalFeatures): boolean => checkGlobalFeatureIsEnabled(featureName) || checkFeatureForTenantIsEnabled(featureName);

const checkGlobalFeatureIsEnabled = (featureName: EExperimentalFeatures): boolean => {
  const globalExperimentalFeatures = store.state.app.appSettings?.experimentalFeatures;
  return !!globalExperimentalFeatures?.[featureName];
};

const checkFeatureForTenantIsEnabled = (featureName: EExperimentalFeatures): boolean => {
  const experimentalFeatures = store.state.tenants.currentTenantInfo?.experimentalFeatures;
  return !!experimentalFeatures?.[featureName];
};

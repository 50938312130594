import { TReachedResponse } from '@/api/types';
import { TSessionWithTokensResponse, TSessionWithTokensSerialized } from '@/contexts/accountingContext/domain/types';

export const convertSessionResponse = (response: TReachedResponse<TSessionWithTokensResponse>): TSessionWithTokensSerialized | null => {
  if (!response) return null;
  return modifySessionFields(response.data || {});
};

export const modifySessionFields = (data: TSessionWithTokensResponse): TSessionWithTokensSerialized => ({
  accessToken: data.access,
  accessTokenExpiresAt: data.accessExpiresAt,
  csrfToken: data.csrf,
  refreshToken: data.refresh,
  refreshTokenExpiresAt: data.refreshExpiresAt,
});

import cloneDeep from 'lodash.clonedeep';

import isObject from '@/utils/isObject';

type TGetObjectWithoutEmptyFields = <T extends Record<string, unknown>>(value: T, options?: { withStructures: boolean }) => Partial<T>;

const getObjectWithoutEmptyFields: TGetObjectWithoutEmptyFields = (
  value,
  { withStructures } = { withStructures: false },
) => {
  if (!isObject(value)) return value;
  const result = cloneDeep(value) as typeof value;

  const deleteEmptyFields = (target: any) => {
    if (!isObject(target)) return;
    /* eslint-disable no-param-reassign */
    Object.entries(target).forEach(([key, value]) => {
      if (value !== 0 && value !== false && !value) {
        delete target[key];
      } else if (isObject(value)) {
        deleteEmptyFields(value);
        if (withStructures && Object.keys(value).length === 0) {
          delete target[key];
        }
      } else if (Array.isArray(value)) {
        if (withStructures && value.length === 0) {
          delete target[key];
        } else {
          value.forEach(deleteEmptyFields);
        }
      }
    });
    /* eslint-enable no-param-reassign */
  };

  deleteEmptyFields(result);
  return result;
};

export default getObjectWithoutEmptyFields;

import { ref, computed } from 'vue';

import { EBillingPlanSelectionSteps } from '@/contexts/billingContext/components/BillingPlanSelectionModal/domain/constants';
import { TChangePlanSelectionStepFunction } from '@/contexts/billingContext/components/BillingPlanSelectionModal/domain/types';
import { TBillingAvailablePlan } from '@/contexts/billingContext/domain/types';

export const useChangePlan = (changePlanSelectionStep: TChangePlanSelectionStepFunction) => {
  const selectedPlanWithPrice = ref<TBillingAvailablePlan | null>(null);

  const planCode = computed(() => selectedPlanWithPrice.value?.planCode || null);

  const handlePlanChange = (selectedPlan: TBillingAvailablePlan) => {
    selectedPlanWithPrice.value = selectedPlan;

    changePlanSelectionStep(EBillingPlanSelectionSteps.changeTenantInfo);
  };

  return {
    planCode,
    selectedPlan: selectedPlanWithPrice,

    handlePlanChange,
  };
};

<template>
  <div class="paywall-message">
    <div class="paywall-message__text">
      <slot name="text" />
    </div>
    <slot name="action" />
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({ name: 'PaywallMessage' });
</script>
<style lang="scss" src="./styles.scss" />

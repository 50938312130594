import {
  ComputedRef,
  reactive,
  ref,
  UnwrapNestedRefs,
  watch,
} from 'vue';
import { Form } from 'ant-design-vue';
import cloneDeep from 'lodash.clonedeep';
import isEmpty from 'lodash.isempty';

import { DebounceSettings, TDomainError } from '@/types';
import { hasProperty, isString } from '@/utils';

const { useForm } = Form;

const defaultOptions = { validateOnRuleChange: true };

export const useFormHelpers = (
  formState: UnwrapNestedRefs<Record<string, any>>, // принимает только reactive()
  rules: ComputedRef<Record<string, any>>, // принимает только computed()
  options: {
    immediate?: boolean,
    deep?: boolean,
    validateOnRuleChange?: boolean, // Должно быть true если есть валидация на бэкенде, валидирует форму при изменении объекта currentRules
    debounce?: DebounceSettings,
    onValidate?: (
      name: string | number | string[] | number[],
      status: boolean,
      errors: string[] | null) => void, // Метод вызывается на каждое изменение поля формы
  } = defaultOptions,
) => {
  if (isEmpty(formState) || !rules.value) {
    throw new Error('FormState and rules arguments are required');
  }

  // Реактивный Ref с объектом правил для полей формы
  const currentRules = ref(rules.value);

  /**
 * Сетит ошибку, пришедшую с бэкенда, в качестве нового rule для поля формы.
 * Добавляет заведомо невалидное правило (через Promise.reject()) с флагом fromResponse (затирая все предыдущие чтобы не выводить несколько ошибок сразу).
 * В качестве ключа используется название поля формы, взятое из payload ошибки
  */
  const setResponseErrors = (error: TDomainError) => {
    if (!error?.errors) return;
    Object.keys(error.errors).forEach((key: string) => {
      const clonedCurrentRules = cloneDeep(currentRules.value);

      clonedCurrentRules[key] = [
        {
          message: error.errors[key][0] || '',
          fromResponse: true,
          validator: () => Promise.reject(error.errors[key][0] || ''),
        }];

      currentRules.value = clonedCurrentRules;
    });
  };

  /**
 * Метод очищает ошибки, пришедшие с бэкенда, при изменении значение поля, заменяя на правила по умолчанию из rules.
 * Если не передан name, то метод проходится по всем полям формы
  */
  const onControlChange = ({ name }: { name?: string }) => {
    const clearResponseErrors = (fieldName: string) => {
      const hasCustomRule = currentRules.value[fieldName]?.some((rule) => rule.fromResponse);

      if (hasCustomRule) {
        const clonedCurrentRules = cloneDeep(currentRules.value);

        clonedCurrentRules[fieldName] = rules.value[fieldName] || []; // возвращаем предыдущие правила если они были до этого
        currentRules.value = clonedCurrentRules;

        clearValidate(fieldName); // отчистка валидации конкретного поля формы
      }
    };

    if (!name) {
      Object.keys(formState).forEach((key: string) => clearResponseErrors(key));
    } else {
      clearResponseErrors(name);
    }
  };

  // TODO: проверить актуальность методов getFormDataDefaultValidation, onValidateField
  const getFormDataDefaultValidation = () => {
    if (!formState) {
      return {};
    }
    return Object.keys(formState).reduce((result: { [key: string]: boolean }, key: string) => ({
      ...result,
      [key]: true,
    }), {});
  };

  const onValidateField = (
    field: string | number | string[] | number[],
    status: boolean,
    _: string[]) => {
    if (isString(field)) {
      if (hasProperty(formValidation, field)) {
        formValidation[field] = status;
      }
    }
  };

  const isFieldInvalid = (fieldName: string = ''): boolean => !!fieldName && validateInfos[fieldName]?.validateStatus === 'error';

  /** Проверка состояния формы на наличие ошибок без вызова валидации формы.
   * Следует передавать validateInfos аргументом, чтобы всегда проверялось состояние той формы, на которой находится пользователь
  */
  const hasErrorFormValidationStatus = (formValidateInformation: typeof validateInfos) => {
    let isValid = true;

    Object.keys(formValidateInformation).forEach((fieldName: string) => {
      if (formValidateInformation[fieldName].required && formValidateInformation[fieldName].validateStatus !== 'success') {
        isValid = false;
      }
    });

    return isValid;
  };

  /**
   * когда useFormHandlers будет получать данные по полям формы, то formValidation будет заполняться всеми
   * ключами из formFields и на выходе получится объект вида:
   * { [key: string]: true },
   * который будет хранить в себе данные по валидации каждого поля в форме
   */
  const formValidation = reactive<Record<string, boolean>>(getFormDataDefaultValidation());

  const {
    validateInfos,
    initialModel,

    resetFields,
    validate,
    mergeValidateInfo,
    clearValidate,
  } = useForm(formState, currentRules, options);

  // Позволяет реактивно изменять правила формы
  watch(() => rules.value, (value: Record<string, any>) => {
    currentRules.value = value;
  });

  return {
    validateInfos,
    initialModel,
    formValidation,

    setResponseErrors,
    onControlChange,
    getFormDataDefaultValidation,
    onValidateField,
    isFieldInvalid,
    hasErrorFormValidationStatus,

    // useForm методы
    resetFields,
    validate,
    mergeValidateInfo,
    clearValidate,
  };
};

import { onMounted, onBeforeUnmount } from 'vue';

import { httpStatus } from '@/api/constants';
import logger from '@/logger';

export const useCaptureGlobalErrors = () => {
  const captureUnhandledRejection = (event: PromiseRejectionEvent) => {
    const time = new Date().toISOString();
    logger.warn(`${time} Unhandled Promise rejection.\n`, event);
    // существуют event-ы, у которых нет .reason, поэтому добавляем проверку на null/undefined
    if (event.reason?.status === httpStatus.FORBIDDEN.code) {
      event.preventDefault();
    }
  };

  onMounted(() => {
    window.addEventListener('unhandledrejection', captureUnhandledRejection);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('unhandledrejection', captureUnhandledRejection);
  });
};

import * as types from './mutation-types';
import { TContractorInfoMutations } from './types';

const mutations: TContractorInfoMutations = {
  [types.SET_CURRENT_RUNS_COUNT](state, { count }) {
    state.currentRunsCount = count;
  },
  [types.SET_PLANNED_RUNS_COUNT](state, { count }) {
    state.plannedRunsCount = count;
  },
  [types.SET_FINISHED_RUNS_COUNT](state, { count }) {
    state.finishedRunsCount = count;
  },
  [types.SET_INCIDENTS_COUNT](state, { count }) {
    state.incidentsCount = count;
  },
  [types.SET_CAN_SEE_RUN_INCIDENTS](state, { canSeeRunIncidents }) {
    state.canSeeRunIncidents = canSeeRunIncidents;
  },
};

export default mutations;

<template>
  <Menu
    class="layout-sider-content__menu"
    theme="light"
    mode="inline"
    :selectedKeys="selectedKeys"
  >
    <MenuItem
      v-for="item in adminSchema"
      :key="item.key"
    >
      <SmartLink
        class="layout-sider-content__menu-link"
        :href="item.path"
      >
        <MenuIconWithLabel
          :icon="item.icon"
          :label="tt(`sider.menu.${item.label}`)"
          :isCollapsed="isCollapsed"
        />
      </SmartLink>
    </MenuItem>
  </Menu>
</template>

<script lang="ts">
import { Menu, MenuItem } from 'ant-design-vue';
import { defineComponent, PropType } from 'vue';

import SmartLink from '@/components/SmartLink/index.vue';
import tt from '@/i18n/utils/translateText';
import MenuIconWithLabel from
  '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/components/MenuIconWithLabel/index.vue';

import { adminSchema } from './constants';

export default defineComponent({
  name: 'AdminContent',
  components: {
    Menu,
    MenuItem,
    SmartLink,
    MenuIconWithLabel,
  },
  props: {
    selectedKeys: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      adminSchema,
      tt,
    };
  },
});
</script>

import { useRoute, useRouter } from 'vue-router';
import { ComputedRef } from 'vue';

import { ETransitionMethod } from '@/constants';
import { showErrorNotification } from '@/utils';

import useWebsocketController from '../useWebsocketController';
import { useAppGeneralNavigation } from './useAppGeneralNavigation';
import { useAppGlobalEntities } from './useAppGlobalEntities';
import useAppHeap from './useAppHeap';
import useTargetTenant from './useTargetTenant';

export const useAppTransitions = (shouldRequestCommonData: ComputedRef<boolean>) => {
  const route = useRoute();
  const router = useRouter();

  const {
    isNavigationAfterSignInInProgress,
    navigateAfterSignIn,
    navigateAfterLogout,
    navigateAfterFailedAuthentication,
    navigateToMaintenance,
  } = useAppGeneralNavigation();

  const { identify } = useAppHeap();

  const { openWsConnectionForCurrentTenant } = useWebsocketController();

  const { fetchGlobalEntities } = useAppGlobalEntities();

  const { processTargetTenant } = useTargetTenant();

  const fetchCommonData = () => {
    isNavigationAfterSignInInProgress.value = true;
    identify();
    openWsConnectionForCurrentTenant();
    return fetchGlobalEntities()
      .then(navigateAfterSignIn)
      .catch(showErrorNotification)
      .finally(async () => {
        /** Роутер не успевает резолвится после получения данных по юзеру и тенанту и до перехода на новый роут,
         * поэтому нужно дождаться готовности будущего роута и только после этого отключить лоадер.
         * Лоадер в свою очередь зависит от переменной isNavigationAfterSignInInProgress
         */
        await router.beforeResolve(() => {
          isNavigationAfterSignInInProgress.value = false;
        });
      });
  };

  const stateTransitions: Record<ETransitionMethod, () => unknown> = {
    [ETransitionMethod.onCheck]: () => {},
    [ETransitionMethod.onFailedAuthentication]: async () => {
      await router.isReady().catch(() => {});
      navigateAfterFailedAuthentication();
    },
    [ETransitionMethod.onLogin]: () => {
    /**
     * onLogin вызывается после успешного входа через форму входа
     */
      if (shouldRequestCommonData.value) {
        fetchCommonData();
      }
      return Promise.resolve();
    },
    [ETransitionMethod.onSuccessfulAuthentication]: async () => {
    /**
     * onSuccessfulAuthentication вызывается после старта приложения после успешной проверки
     * на то, что текущий пользователь авторизован
     */
      if (shouldRequestCommonData.value) {
        identify();
        await router.isReady().catch(() => {});
        /**
       * здесь небольшой костылик с проверкой и вызовом processTargetTenant.
       * Дело в том, что если у роута есть permissions, значит он был создан с помощью утилиты buildProtectedRoute,
       * а эта утилита в себе вызывает в самом начале функцию processTargetTenant.
       * А если у роута не указаны permissions, то мы вручную должны вызвать processTargetTenant для
       * проверки target_tenant в query.
       */
        if (!route.meta.permissions) {
          await processTargetTenant();
        }
        navigateAfterSignIn();
        openWsConnectionForCurrentTenant();
      }
    },
    [ETransitionMethod.onLogout]: () => navigateAfterLogout(),
    [ETransitionMethod.onMaintenance]: () => navigateToMaintenance(),
    [ETransitionMethod.onInteractionLock]: () => {},
    [ETransitionMethod.onInteractionUnlock]: () => {
      /**
       * onInteractionUnlock вызывается после успешной разблокировки системой
       */
      if (shouldRequestCommonData.value) {
        fetchCommonData();
      }
      return Promise.resolve();
    },
  };

  return {
    stateTransitions,
    isNavigationAfterSignInInProgress,
  };
};

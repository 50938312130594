<template>
  <main class="not-found">
    <Result
      title="404"
      :subTitle="subTitle"
      status="404"
    >
      <template #extra>
        <div class="not-found__buttons">
          <Button
            type="primary"
            class="not-found__button not-found__back-button"
            @click="handleHomeClick"
          >
            {{ tt('infrastructure.action.toHomePage') }}
          </Button>

          <Button
            type="primary"
            class="not-found__button not-found__back-button"
            @click="handleBackClick"
          >
            {{ tt('infrastructure.action.back') }}
          </Button>
        </div>
      </template>
    </Result>
  </main>
</template>

<script lang="ts">
import { Button, Result } from 'ant-design-vue';
import { defineComponent } from 'vue';

import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import router from '@/router';
import tt from '@/i18n/utils/translateText';

export default defineComponent({
  name: 'NotFound',
  components: {
    Button,
    Result,
  },
  props: {
    subTitle: {
      type: String,
      required: false,
      default: tt('infrastructure.description.notFound'),
    },
  },
  setup() {
    const handleHomeClick = () => {
      redirectToInitialRoute();
    };

    const handleBackClick = () => {
      router.back();
    };

    return {
      handleHomeClick,
      handleBackClick,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./style.scss" />

import {
  onUnmounted,
  ref,
} from 'vue';

import {
  INTERSECTION_OBSERVER_ROOT_MARGIN,
  INTERSECTION_OBSERVER_THRESHOLD,
  MAX_INTERSECTION_RATIO,
} from '../domain/constants';

type TUseObserveViewportIntersection = {
  handleChangeSelectedToPreviousMonth: () => void,
  handleChangeSelectedToNextMonth: () => void,
};

export const useObserveViewportIntersection = ({
  handleChangeSelectedToPreviousMonth,
  handleChangeSelectedToNextMonth,
}: TUseObserveViewportIntersection) => {
  const observerInstance = ref<IntersectionObserver | null>(null);

  const observerHandler = (entries: IntersectionObserverEntry[]) => {
    const {
      isIntersecting,
      boundingClientRect,
      intersectionRatio,
      rootBounds,
    } = entries[0];

    /**
     * Скроллим вверх
     *
     * Если текущий выбранный месяц полностью находится в области просмотра (с учетом rootMargin)
     * или если верхняя точка наблюдаемого элемента находится ниже верхней точки области просмотра (на случай быстрого скролла)
     */
    if (intersectionRatio === MAX_INTERSECTION_RATIO || (rootBounds && (rootBounds.top - boundingClientRect.top < 0))) {
      handleChangeSelectedToPreviousMonth();

      /**
      * Скроллим вниз
      *
      * Если текущий выбранный/активный месяц вышел за пределы области видимости (сверху)
      */
    } else if (!isIntersecting) {
      handleChangeSelectedToNextMonth();
    }
  };

  const initIntersectionObserver = (viewportRef: HTMLElement) => {
    observerInstance.value = new IntersectionObserver(observerHandler, {
      root: viewportRef,
      /** Смещение области просмотра относительно первоначального положения */
      rootMargin: INTERSECTION_OBSERVER_ROOT_MARGIN,
      /** Порог пересечения, при котором будет срабатывать вызов коллбэка observerHandler */
      threshold: INTERSECTION_OBSERVER_THRESHOLD,
    });
  };

  const observeMonthElement = (monthRef: HTMLElement | null) => {
    if (!monthRef) return;

    // Если были наблюдаемые, не следим за ними
    observerInstance.value?.disconnect();

    observerInstance.value?.observe(monthRef);
  };

  const unobserveMonthElement = (monthRef: HTMLElement | null) => {
    if (!monthRef) return;

    observerInstance.value?.unobserve(monthRef);
  };

  onUnmounted(() => {
    observerInstance.value?.disconnect();
  });

  return {
    initIntersectionObserver,
    observeMonthElement,
    unobserveMonthElement,
  };
};

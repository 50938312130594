export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_TOKENS = 'SET_TOKENS';
export const DELETE_TOKENS = 'DELETE_TOKENS';
export const SET_WAS_AUTH_BY = 'SET_WAS_AUTH_BY';
export const SET_COLLAPSED = 'SET_COLLAPSED';
export const SET_LEGISLATIVE_REMARK_VISIBLE = 'SET_LEGISLATIVE_REMARK_VISIBLE';
export const SET_IS_TAB_ACTIVE = 'SET_IS_TAB_ACTIVE';
export const SET_LAST_PATH_BEFORE_AUTH = 'SET_LAST_PATH_BEFORE_AUTH';
export const SET_LAST_DATA_BEFORE_AUTH = 'SET_LAST_DATA_BEFORE_AUTH';
export const SET_FOLDER_OPEN_KEYS = 'SET_FOLDER_OPEN_KEYS';
export const SET_SBER_PARAMS = 'SET_SBER_PARAMS';
export const SET_APP_SETTINGS = 'SET_APP_SETTINGS';

import {
  Ref,
  computed,
  ref,
  watch,
} from 'vue';

import useViewState from '@/composables/useViewState';
import useTimer from '@/composables/useTimer';
import { REGEX_PATTERNS } from '@/constants';

import { TConfirmCodeFunction, TRequestCodeFunction } from '../domain/types';

type TUseConfirm = {
  email: Ref<string>,

  emit: (event: 'confirmed' | 'change', ...args: unknown[]) => void,
  requestCode: TRequestCodeFunction,
  confirmCode: TConfirmCodeFunction,
};

export const useConfirm = ({
  email,

  emit,
  requestCode,
  confirmCode,
}: TUseConfirm) => {
  const code = ref('');
  const isCodeInputShown = ref(false);

  const { startTimer, secondsRemain } = useTimer();

  const {
    isLoading,
    setViewStateAs,
  } = useViewState();

  const isEmailInvalid = computed(() => !email.value.match(REGEX_PATTERNS.onlyEmail));
  const isConfirmationCodeEmpty = computed(() => !code.value);

  watch(() => email.value, () => {
    isCodeInputShown.value = false;
    code.value = '';
  });

  const getCode = () => {
    setViewStateAs.loading();

    requestCode(email.value)
      .then(() => {
        startTimer();

        isCodeInputShown.value = true;
      })
      .catch(() => {})
      .finally(setViewStateAs.done);
  };

  const handleInputChange = (value: string) => {
    code.value = value;

    emit('change');
  };

  const handleSubmit = () => {
    setViewStateAs.loading();

    confirmCode(email.value, code.value)
      .then(() => {
        isCodeInputShown.value = false;

        emit('confirmed');
      })
      .catch(() => {})
      .finally(setViewStateAs.done);
  };

  return {
    code,
    isCodeInputShown,
    isLoading,
    secondsRemain,
    isEmailInvalid,
    isConfirmationCodeEmpty,

    getCode,
    handleInputChange,
    handleSubmit,
  };
};

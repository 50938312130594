<template>
  <div
    class="ellipsis-label"
    :class="{ 'ellipsis-label_always': alwaysShowTooltip }"
  >
    <Tooltip
      :visible="showTooltip || alwaysShowTooltip"
      :overlayClassName="overlayClassName"
      :title="stringContent"
    >
      <span v-if="alwaysShowTooltip">
        {{ stringContent }}
      </span>
      <TypographyParagraph
        v-else
        :ellipsis="{
          onEllipsis: handleEllipsisStateChange,
          rows: rows
        }"
        :content="stringContent"
      />
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { TypographyParagraph } from 'ant-design-vue';
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from 'vue';

import { Tooltip } from '@/ui';

export default defineComponent({
  name: 'EllipsisLabel',
  components: {
    Tooltip,
    TypographyParagraph,
  },
  props: {
    content: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
    // для кейсов, когда либо всегда нужно отображать тултип,
    // либо когда пересчет критично влияет на производительность
    alwaysShowTooltip: {
      type: Boolean,
      default: false,
    },
    onTopOfEverything: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const showTooltip = ref(props.alwaysShowTooltip);

    const overlayClassName = computed(() => (props.onTopOfEverything ? 'ellipsis-label__tooltip_top' : 'ellipsis-label__tooltip'));
    const stringContent = computed(() => String(props.content));

    const handleEllipsisStateChange = (event: boolean) => {
      showTooltip.value = event;
    };

    return {
      showTooltip,
      overlayClassName,
      stringContent,

      handleEllipsisStateChange,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import CompanySvg from '@/assets/svg/16x16/company.svg';
import SummarySvg from '@/assets/svg/16x16/dashboard.svg';
import UserSvg from '@/assets/svg/16x16/user.svg';

 type TAdminSchemaItem = {
   key: string,
   path: string,
   label: 'home' | 'companies' | 'users',
   icon: SVGElement,
   sequence: number,
 };

export const adminSchema: TAdminSchemaItem[] = [
  {
    key: 'admin',
    path: '/admin',
    label: 'home',
    icon: SummarySvg,
    sequence: 1,
  },
  {
    key: 'admin_tenants',
    path: '/admin/tenants',
    label: 'companies',
    icon: CompanySvg,
    sequence: 2,
  },
  {
    key: 'admin_users',
    path: '/admin/users',
    label: 'users',
    icon: UserSvg,
    sequence: 3,
  },
];

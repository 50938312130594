import { ROUTE_TYPE } from '@/constants';
import { ROUTE_NAME } from '@/contexts/operationalDocumentsContext/domain/constants';
import { hasOperationalDocumentsSubscription } from '@/domains/permissions/subscription';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import isTenantVerified from '@/domains/isTenantVerified';

const routes = [
  buildProtectedRoute({
    path: '/operational_documents',
    name: ROUTE_NAME.operationalDocumentsList,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        hasOperationalDocumentsSubscription,
        isTenantVerified,
      ],
      component: () => import('@/contexts/operationalDocumentsContext/views/OperationalDocumentsListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/operational_documents/constructor/:id',
    name: ROUTE_NAME.operationalDocumentsConstructor,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        hasOperationalDocumentsSubscription,
        isTenantVerified,
      ],
      component: () => import('@/contexts/operationalDocumentsContext/views/OperationalDocumentsConstructorView/index.vue'),
    },
  }),
];

export default routes;

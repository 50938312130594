import { EActiveSubscriptionsConditionsFeature } from '@/domains/constants';
import checkSubscriptionPermissions from '@/domains/permissions/checkSubscriptionPermissions';

const hasResponsibleOnLookupAssignmentRulesSubscription = () => {
  const result = checkSubscriptionPermissions([
    EActiveSubscriptionsConditionsFeature.responsibleOnLookupAssignmentRules,
  ]);
  return result.passed;
};

export default hasResponsibleOnLookupAssignmentRulesSubscription;

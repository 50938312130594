<template>
  <PaywallMessage
    class="billing-debt-paywall-message"
  >
    <template #text>
      <label>
        {{ `${tt('billing.debtPaywallMessage.dearClientYouHaveDebt')}. ` }}
        <span>
          {{ tt('billing.debtPaywallMessage.pleasePayWithinThreeDays') }},
        </span>
        {{ tt('billing.debtPaywallMessage.infoAboutDebtRestrictions', {
          param: {
            phone: support.phone,
            email: support.email
          }
        }
        ) }}
      </label>
    </template>
    <template #action>
      <div
        class="billing-debt-paywall-message__close-icon"
      >
        <CloseSvg
          @click="$emit('close')"
        />
      </div>
    </template>
  </PaywallMessage>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import CloseSvg from '@/assets/svg/16x16/close.svg';
import PaywallMessage from '@/components/PaywallMessage/index.vue';
import useAppTheme from '@/composables/application/useAppTheme';

export default defineComponent({
  name: 'BillingDebtPaywallMessage',
  components: {
    CloseSvg,
    PaywallMessage,
  },
  emits: ['close'],
  setup() {
    const { getThemeConfig } = useAppTheme();

    const { support } = getThemeConfig();
    return {
      support,

      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

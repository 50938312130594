import { TDateAppFormat } from '@/types';
import getDayJsDate from '@/utils/dateUtils/getters/getDayJsDate';
import isDayjsGuard from '@/utils/dateUtils/domains/guards';

import isString from '../../isString';

const getMsFromDate = (date: number | string | TDateAppFormat, timeZone?: string): number => {
  if (isDayjsGuard(date)) {
    return timeZone ? date.tz(timeZone).valueOf() : date.valueOf();
  }

  if (isString(date)) {
    return timeZone ? +getDayJsDate(date, timeZone) : +new Date(date);
  }

  return date;
};

export default getMsFromDate;

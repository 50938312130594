import { ROUTE_TYPE } from '@/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { EApprovalsType } from '@/contexts/approvalsContext/domain/constants';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasCarrierWorkplaceSubscription,
} from '@/domains/permissions/subscription';

const routes = [
  buildProtectedRoute({
    path: '/approvals',
    name: 'approvals',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        () => hasCargoOwnerWorkplaceSubscription() || hasCarrierWorkplaceSubscription(),
      ],
      component: () => import('@/contexts/approvalsContext/views/ApprovalsView/index.vue'),
    },
    redirect: hasCargoOwnerWorkplaceSubscription() ? '/approvals/claims' : '/approvals/surcharges',
    children: [
      {
        path: 'surcharges',
        name: EApprovalsType.surcharges,
        meta: { type: ROUTE_TYPE.private },
        component: () => import('@/contexts/approvalsContext/views/ApprovalsView/components/ApprovalsSurcharges/index.vue'),
      },
      {
        path: 'claims',
        name: EApprovalsType.claims,
        meta: { type: ROUTE_TYPE.private },
        component: () => import('@/contexts/approvalsContext/views/ApprovalsView/components/ApprovalsClaims/index.vue'),
      },
    ],
  }),
];

export default routes;

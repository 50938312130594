import canBrowseOrdersPendingByRole from './canBrowseOrdersPendingByRole';
import canBrowseOrdersOnBiddingByRole from './canBrowseOrdersOnBiddingByRole';
import canBrowseOrdersOnTargetOfferingByRole from './canBrowseOrdersOnTargetOfferingByRole';
import canBrowseOrdersOnExecutionByRole from './canBrowseOrdersOnExecutionByRole';
import canBrowseOrdersFinalizedByRole from './canBrowseOrdersFinalizedByRole';

// Доступ к разделу заказы определяется наличием доступа хотя бы к одной папке
const canBrowseOrdersSectionByRole = () => canBrowseOrdersPendingByRole()
    || canBrowseOrdersOnBiddingByRole()
    || canBrowseOrdersOnTargetOfferingByRole()
    || canBrowseOrdersOnExecutionByRole()
    || canBrowseOrdersFinalizedByRole();

export default canBrowseOrdersSectionByRole;

<template>
  <div
    class="localization-button"
    @click.stop="handleChangeLocale"
  >
    <component
      :is="suggestedIcon"
      class="localization-button__icon"
    />
    <span class="localization-button__label">
      {{ tt(`sider.localization.${suggestedLocale}`) }}
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import FlagRuSvg from '@/assets/svg/others/flag-ru.svg';
import FlagEnSvg from '@/assets/svg/others/flag-en.svg';
import tt from '@/i18n/utils/translateText';
import { ELocale } from '@/domains/constants';
import { useI18nStore } from '@/stores/i18n';

export default defineComponent({
  name: 'LocalizationButton',
  setup() {
    const i18nStore = useI18nStore();

    const currentLocale = computed(() => i18nStore.currentLocale);

    const suggestedLocale = computed(() => (currentLocale.value === ELocale.en ? ELocale.ru : ELocale.en));
    const suggestedIcon = computed(() => (currentLocale.value === ELocale.en ? FlagRuSvg : FlagEnSvg));

    const handleChangeLocale = () => {
      const newLocale = currentLocale.value === ELocale.en ? ELocale.ru : ELocale.en;

      i18nStore.setCurrentLocale(newLocale);
    };

    return {
      suggestedLocale,
      suggestedIcon,

      handleChangeLocale,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { computed, Ref } from 'vue';

import useStore from '@/store/useStore';
import { createUrlParams, generateRandomHash } from '@/utils';

import {
  TSberBusinessRedirectParams,
  TUserData,
  TQueryHashParams,
} from '../domain/types';
import { SBER_BUSINESS_PARAMS } from '../domain/constants';

export const useProcessingSberParams = (path: Ref<string>) => {
  const store = useStore();
  const sberParams = computed(() => store.state.app.sberParams);

  const generateRedirectUrl = (params: TSberBusinessRedirectParams): string => {
    const hashParams = generateHashParams();
    saveHashParams(hashParams);

    const query = createUrlParams({
      state: hashParams.state,
      nonce: hashParams.nonce,
      scope: params.scope,
      client_id: params.clientId,
      response_type: SBER_BUSINESS_PARAMS.responseType,
      redirect_uri: `${import.meta.env.VITE_APP_HOST}${path.value}`,
    });

    return `${params.url}?${query}`;
  };

  const generateHashParams = (): TQueryHashParams => ({
    state: generateRandomHash(SBER_BUSINESS_PARAMS.stateLength),
    nonce: generateRandomHash(SBER_BUSINESS_PARAMS.nonceLength),
  });

  const saveHashParams = (query: TQueryHashParams) => {
    store.dispatch('app/setSberParams', {
      ...sberParams.value,
      query,
    });
  };

  const removeHashParams = () => {
    store.dispatch('app/setSberParams', {
      ...sberParams.value,
      query: null,
    });
  };

  const saveUserDataParams = (userData: TUserData) => {
    store.dispatch('app/setSberParams', {
      ...sberParams.value,
      userData,
    });
  };

  const compareHashParams = (params: TQueryHashParams): boolean => {
    if (sberParams.value && sberParams.value.query) {
      return sberParams.value.query.state === params.state && sberParams.value.query.nonce === params.nonce;
    }
    return false;
  };

  return {
    generateRedirectUrl,
    compareHashParams,
    saveUserDataParams,
    removeHashParams,
  };
};

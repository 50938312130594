<template>
  <div class="checkbox">
    <label class="checkbox__label" :class="className">
      <input
        :id="name"
        type="checkbox"
        class="checkbox__input"
        :checked="checked"
        :name="name"
        :disabled="disabled"
        @change="handleChange($event)"
      />
      <span class="checkbox__inner" :class="classNameIndeterminate" />
      <span v-if="hasSlot" class="checkbox__title">
        <!-- @slot Слот для вывода текстового контента -->
        <slot />
      </span>
    </label>
    <div v-if="hasSlot && hasHint" class="checkbox__hint">
      {{ hint }}
    </div>
  </div>
</template>
<script lang="ts">
import {
  PropType,
  defineComponent,
  computed,
} from 'vue';

import { useHasSlot } from '@/composables/useHasSlot';

export default defineComponent({
  name: 'Checkbox',
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'change',
    /** Поддержка двустороннего связывания (v-model) свойства 'checked' */
    'update:checked',
  ],
  setup(props, { slots, emit }) {
    const hasSlot = useHasSlot(slots, 'default');
    const hasHint = computed(() => !!props.hint);
    const classNameIndeterminate = computed(() => ({ checkbox__inner_indeterminate: props.indeterminate }));
    const className = computed(() => {
      let resultClassName = props.disabled ? 'checkbox__label_disabled' : '';

      if (props.isInvalid) {
        resultClassName += ' checkbox__label_invalid';
      }

      return resultClassName;
    });

    const handleChange = (event: Event) => {
      const targetChecked = (event.target as HTMLInputElement).checked;

      emit('update:checked', targetChecked);
      emit('change', event);
    };

    return {
      hasSlot,
      hasHint,
      className,
      classNameIndeterminate,
      handleChange,
    };
  },
});
</script>
<style lang="scss" src="./styles.scss" />

import { ref } from 'vue';

import { TReachedResponse } from '@/api/types';
import { TBillingAvailablePlansResponse } from '@/contexts/billingContext/domain/types';
import { getAvailablePlans } from '@/contexts/billingContext/services';
import { showErrorNotification } from '@/utils';
import { getGroupedAvailablePlans } from '@/contexts/billingContext/components/BillingPlanSelectionModal/components/BillingPlanSelectionModalContent/components/BillingPlanSelection/domain/getGroupedAvailablePlans';
import useViewState from '@/composables/useViewState';

import { TGroupedAvailablePlans } from '../domain/types';

export const useFetchAvailablePlans = () => {
  const availablePlans = ref<TBillingAvailablePlansResponse>([]);
  const groupedAvailablePlans = ref<TGroupedAvailablePlans | null>(null);
  const { setViewStateAs, isLoading, isDone } = useViewState();

  const fetchAvailablePlans = () => {
    setViewStateAs.loading();
    getAvailablePlans()
      .then((response: TReachedResponse<TBillingAvailablePlansResponse>) => {
        availablePlans.value = response.data;

        groupedAvailablePlans.value = getGroupedAvailablePlans(availablePlans.value);
      })
      .catch(showErrorNotification)
      .finally(setViewStateAs.done);
  };

  return {
    availablePlans,
    groupedAvailablePlans,
    isLoading,
    isDone,

    fetchAvailablePlans,
  };
};

import { actions } from './actions';
import * as getters from './getters';
import mutations from './mutations';
import { initialState } from './state';

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations,
};

import {
  ref,
  onMounted,
  onUnmounted,
} from 'vue';

import logger from '@/logger';
import { ONE_SECOND } from '@/constants';
import { useDateValue } from '@/composables/useDateValue';

const useTimeFromNow = (date: string | null) => {
  const timeFromNow = ref<string | null>(null);

  const timeout = ONE_SECOND;
  const momentWasReceived = useDateValue(ref(date)).inAppFormat.value;

  const isMounted = ref(false);
  const timeFromNowId = ref<number | null>(null);

  const getTimeFromNow = () => {
    if (!momentWasReceived) {
      logger.warn('[useTimerFromNow]: getTimeFromNow - momentWasReceived is empty');
    } else {
      timeFromNow.value = momentWasReceived.fromNow();
    }
  };

  onMounted(() => {
    if (!date) {
      logger.warn('[useTimerFromNow] time is empty');
      return;
    }
    getTimeFromNow();
    isMounted.value = true;
    timeFromNowId.value = window.setInterval(() => {
      if (isMounted.value) {
        getTimeFromNow();
      } else {
        clearInterval(timeFromNowId.value as number);
      }
    }, timeout);
  });

  onUnmounted(() => {
    if (timeFromNowId.value) {
      clearInterval(timeFromNowId.value);
      timeFromNowId.value = null;
    }
    isMounted.value = false;
  });

  return { timeFromNow };
};

export default useTimeFromNow;

import { TParseDateToAppFormat, TDateParseToApp } from '@/utils/dateUtils/domains/types';
// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';
import isDayjsGuard from '@/utils/dateUtils/domains/guards';

const parseDateToAppFormat: TParseDateToAppFormat = (
  date: TDateParseToApp,
  inputFormat?: string,
  timeZone?: string,
) => {
  if (!date) return null;

  if (isDayjsGuard(date)) return timeZone ? date.tz(timeZone) : date as any;

  if (timeZone) {
    return inputFormat ? dayjs.tz(date, inputFormat, timeZone) : dayjs.tz(date, timeZone);
  }

  return inputFormat ? dayjs(date, inputFormat) : dayjs(date);
};

export default parseDateToAppFormat;

import { TCustomStoreInAction } from '@/store/types';

import * as types from './mutation-types';

export enum ETimerSection {
  operationalDocuments = 'operationalDocuments',
}

export type TSetTimerEndPayload = {
  section: ETimerSection,
  endDate: Date | null,
};

export type TEdmTimerState = {
  endDate: Date | null,
};

export type TTimerState = {
  operationalDocuments: TEdmTimerState,
};

export type TTimerMutations<S = TTimerState> = {
  [types.SET_END_DATE]: (state: S, payload: TSetTimerEndPayload) => void,
};

export type TTimerActions<S = TCustomStoreInAction<TTimerState>> = {
  setEndDate(store: S, payload: TSetTimerEndPayload): void,
};

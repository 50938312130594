import {
  computed,
  Ref,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import { useDateValue } from '@/composables/useDateValue';
import { TNotification } from '@/contexts/notificationsContext/domain/types';
import useTimeFromNow from '@/composables/useTimeFromNow';
import { ENotificationStatus } from '@/contexts/notificationsContext/domain/constants';

import getNotificationActions from '../domain/getNotificationActions';
import getNotificationAdditionalInfo from '../domain/getNotificationAdditionalInfo';
import getNotificationMessage from '../domain/getNotificationMessage';

export const useView = (notification: Ref<TNotification>) => {
  const isAdditionalInfoVisible = ref<boolean>(false);
  const isNotificationWasReceivedToday = ref<boolean>(false);

  const { timeFromNow } = useTimeFromNow(notification.value.createdAt);

  const notificationMessage = computed(() => getNotificationMessage(notification.value));
  const notificationActions = computed(() => getNotificationActions(notification.value));
  const notificationAdditionalInfo = computed(() => getNotificationAdditionalInfo(notification.value));

  const indicator = computed(() => (notification.value.isRead ? ENotificationStatus.read : ENotificationStatus.unread));
  const indicatorText = computed(() => (indicator.value === ENotificationStatus.read
    ? tt('notification.action.markAsUnread') : tt('notification.action.markAsRead')));

  const time = computed(() => (isNotificationWasReceivedToday.value
    ? timeFromNow.value
    : useDateValue(ref(notification.value.createdAt), { humanFormat: 'onlyTime' }).inHumanFormat.value));

  const toggleAdditionalInfoDropdown = () => {
    isAdditionalInfoVisible.value = !isAdditionalInfoVisible.value;
  };

  const setIsNotificationWasReceivedToday = (value: boolean) => {
    isNotificationWasReceivedToday.value = value;
  };

  return {
    isAdditionalInfoVisible,
    notificationMessage,
    notificationActions,
    notificationAdditionalInfo,
    indicatorText,
    indicator,
    time,

    toggleAdditionalInfoDropdown,
    setIsNotificationWasReceivedToday,
  };
};

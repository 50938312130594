import { ENotificationType } from '@/contexts/notificationsContext/domain/constants';

import getNotificationScheme from './getNotificationScheme';
import { TNotificationSchemeItem } from './types';

const getNotificationMapping = (
  type: ENotificationType,
  payload: Record<string, string>,
): TNotificationSchemeItem | undefined => getNotificationScheme(payload)[type];

export default getNotificationMapping;

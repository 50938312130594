import router from '@/router';
import { useSiderMenuItems } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/application/useSiderMenuItems';
import { ECommonSchemaItemType } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/domains/constants';
import store from '@/store';
import { EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { useFoldersStore } from '@/stores/folders';

import isTenantVerified from './isTenantVerified';

const redirectToInitialRoute = (route?: string | null) => {
  const foldersStore = useFoldersStore();
  const { siderMenuItems } = useSiderMenuItems(foldersStore);

  if (route) {
    router.push(route);
    return;
  }

  const hasActiveSubscription = store.state.tenants.currentTenantInfo?.subscriptions?.some(
    (subscription) => subscription.status === EBillingPlanCodeStatus.active);

  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;
  const hasLicenseRole = !!store.state.tenants.currentTenantInfo?.licenseRole;

  // редиректим на корневой роут, если:
  // на инстансе есть настройки лицензии, но не засечена роль тенанта
  // или нет настроек лицензии (используется биллинг), но тенант не верифицирован или не имеет активной подписки

  if ((hasInstanceLicense && !hasLicenseRole)
     || (!hasInstanceLicense && (!isTenantVerified() || !hasActiveSubscription))) {
    router.push('/');
    return;
  }

  // фильтруем страницы без типа withDivider, т.к. нам нужны только основные
  const firstPage = siderMenuItems.value
    .filter((item) => item.type !== ECommonSchemaItemType.withDivider)[0]?.path || null;

  if (firstPage) {
    router.push(firstPage);
  }
};

export default redirectToInitialRoute;

export enum EOrderInfoDrawerTab {
  orderInfoDrawerInformation = 'OrderInfoDrawerInformation', // Информация
  orderInfoDrawerLookups = 'OrderInfoDrawerLookups', // Подборы
  orderInfoDrawerExecutionRequests = 'OrderInfoDrawerExecutionRequests', // Заявки
  orderInfoDrawerExecutionChecklist = 'ExecutionChecklist', // Заявки
  orderInfoDrawerExecution = 'OrderInfoDrawerExecution', // Исполнение (Заявки + Рейсы)
}

export enum EOrderType {
  single = 'single', // Заказ типа one-to-one (один рейс)
  multiple = 'multiple', // Заказ типа one-to-many (больше одного рейса)
}

export enum EOrderInfoDrawerTabExecutionTabs {
  executionRequests = 'executionRequests',
  runs = 'runs',
}

const addByIndex = <T extends any[], N>(array: T, index: number, newItem: N): (T[number] | N)[] => {
  const wrongIndex = typeof index !== 'number' || index < 0;
  const correctArray = Array.isArray(array);
  const indexGreaterLength = correctArray && index > array.length;
  if (!correctArray || wrongIndex || indexGreaterLength) return array;

  const itemsBefore = array.slice(0, index);
  const itemsAfter = array.slice(index);

  return [...itemsBefore, newItem, ...itemsAfter];
};

export default addByIndex;

import { defineStore } from 'pinia';
import { ref } from 'vue';

import {
  addByIndex,
  removeByIndex,
  showErrorNotification,
} from '@/utils';
import {
  getTableSettings,
  resetTableSettings,
  saveTableSettings,
  TViewSettingsConfig,
} from '@/services';
import { TReachedResponse } from '@/api/types';
import { TTableConfigLoaded } from '@/components/CustomTableLayout/types';

import { TCombinedOrdersStore } from './types';

export const useCombinedOrdersStore = defineStore(
  'TMS_COMBINED_ORDERS',
  (): TCombinedOrdersStore => {
    const selectedOrdersIids = ref<number[]>([]);
    const tableSettings = ref<TTableConfigLoaded>([]);
    const currentFilterId = ref<string | null>(null);

    const setCurrentFilterId = (id: string | null) => {
      currentFilterId.value = id;
    };

    const setOrdersIids = (iids: number[]) => {
      selectedOrdersIids.value = iids;
    };

    const addOrderIid = (iid: number) => {
      const iidIndex = selectedOrdersIids.value.findIndex((selectedOrderIid: number) => selectedOrderIid === iid);

      if (iidIndex === -1) {
        selectedOrdersIids.value = addByIndex(selectedOrdersIids.value, selectedOrdersIids.value.length, iid);
      }
    };

    const removeOrderIid = (iid: number) => {
      const iidIndex = selectedOrdersIids.value.findIndex((selectedOrderIid: number) => selectedOrderIid === iid);

      if (iidIndex !== -1) {
        selectedOrdersIids.value = removeByIndex(selectedOrdersIids.value, iidIndex);
      }
    };

    const setTableSettings = (settings: TTableConfigLoaded) => {
      tableSettings.value = settings;
    };

    const fetchTableSettings = (config: TViewSettingsConfig) => getTableSettings(config)
      .then(({ data }: TReachedResponse<{ columns: TTableConfigLoaded }>) => setTableSettings(data.columns))
      .catch(() => {});

    const updateTableSettings = (
      payload: TTableConfigLoaded,
      config: TViewSettingsConfig,
    ) => saveTableSettings(payload, config)
      .then(({ data }: TReachedResponse<{ columns: TTableConfigLoaded }>) => setTableSettings(data.columns))
      .catch(showErrorNotification);

    const setDefaultTableSettings = (config: TViewSettingsConfig) => resetTableSettings(config)
      .then(() => fetchTableSettings(config))
      .catch(showErrorNotification);

    const $reset = () => {
      selectedOrdersIids.value = [];
      currentFilterId.value = null;
      tableSettings.value = [];
    };

    return {
      selectedOrdersIids,
      tableSettings,
      currentFilterId,

      $reset,
      setOrdersIids,
      addOrderIid,
      removeOrderIid,
      fetchTableSettings,
      setTableSettings,
      setDefaultTableSettings,
      updateTableSettings,
      setCurrentFilterId,
    };
  },
  { persist: true },
);

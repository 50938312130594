<template>
  <div class="quick-filter">
    <template
      v-for="(filter, index) in filterOptions"
      :key="filter.value"
    >
      <div
        class="quick-filter__item"
        :class="[
          { 'quick-filter__item-checked': filter.checked },
          { 'quick-filter__item-small': size === ESize.small }
        ]"
        @click="handleFilterChange(index)"
      >
        <component
          :is="filter.iconLeft"
          v-if="filter.iconLeft"
          class="quick-filter__icon-left"
          :class="{ 'quick-filter__icon-left-small': size === ESize.small }"
        />
        <span>{{ filter.label }}</span>
        <component
          :is="filter.iconRight"
          v-if="filter.iconRight"
          class="quick-filter__icon-right"
          :class="{ 'quick-filter__icon-right-small': size === ESize.small }"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">

import {
  defineComponent,
  PropType,
  ref,
} from 'vue';

import {
  ESize,
  TQuickFilterItem,
  TQuickFilterSize,
} from '@/ui/types';

export default defineComponent({
  name: 'QuickFilter',
  props: {
    options: {
      type: Object as PropType<TQuickFilterItem[]>,
      required: true,
    },
    size: {
      type: String as PropType<TQuickFilterSize>,
      default: ESize.medium,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const filterOptions = ref<TQuickFilterItem[]>(props.options);

    const handleFilterChange = (index: number) => {
      filterOptions.value[index].checked = !filterOptions.value[index].checked;
      const checkedValue = filterOptions.value.filter((item: TQuickFilterItem) => item.checked);
      emit('select', checkedValue);
    };

    return {
      ESize,
      filterOptions,

      handleFilterChange,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

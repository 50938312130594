import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import { TBillingAvailablePlan, TBillingAvailablePlansResponse } from '@/contexts/billingContext/domain/types';
import { TSubscription } from '@/types';

type TGetPlanPrice = {
  planCode: EBillingPlanCode,
  availablePlans: TBillingAvailablePlansResponse,
  currentActiveSubscription: TSubscription | null,
};

export const getPlanPrice = ({
  planCode,
  availablePlans,
  currentActiveSubscription,
}: TGetPlanPrice): number => {
  const isActivePlan = currentActiveSubscription?.planCode === planCode;

  return availablePlans.find(
    (plan: TBillingAvailablePlan) => plan.planCode === planCode)?.price
  || (isActivePlan
  && currentActiveSubscription?.price)
  || 0;
};

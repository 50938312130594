import setAppFavicon from '@/utils/setAppFavicon';
import useAppTheme from '@/composables/application/useAppTheme';

const { getThemeConfig } = useAppTheme();

const setNotificationFavicon = () => {
  const notificationIcon = getThemeConfig().icons.faviconNotification;
  setAppFavicon(`${import.meta.env.BASE_URL}${notificationIcon}`);
};

export default setNotificationFavicon;

export const SBER_BUSINESS_PARAMS = {
  /** Хеш-параметр для предотвращения CSRF-атак, длина не менее 36 символов */
  stateLength: 36,
  /** Хеш-параметр для защиты от replay-атак, длина не менее 10 символов */
  nonceLength: 10,
  /** Авторизация по СберБизнес ID поддерживает только authorization code flow протокола OAuth 2.0 */
  responseType: 'code',
};

export enum EIntegrationEvent {
  linking = 'linking',
  authorization = 'authorization',
}

export enum ESberButtonSize {
  small = 'small',
  large = 'large',
}

import tt from '@/i18n/utils/translateText';
import { TFormRule } from '@/types';
import { REGEX_PATTERNS } from '@/constants';

export const PHONE_NUMBER_RULE_DEFAULT = {
  pattern: REGEX_PATTERNS.foreignPhoneNumber,
  message: tt('shared.rule.phoneCorrect'),
};
export const PHONE_NUMBER_RULE_RUSSIA = {
  pattern: REGEX_PATTERNS.russianPhoneNumber,
  message: tt('shared.rule.phoneCorrect'),
};

export const generatePhoneRule = (value?: string, trigger?: 'blur' | 'change'): TFormRule => {
  if (!value) return {};

  if (value.startsWith('7') || value.startsWith('+7')) {
    return {
      ...PHONE_NUMBER_RULE_RUSSIA,
      trigger,
    };
  }
  return {
    ...PHONE_NUMBER_RULE_DEFAULT,
    trigger,
  };
};

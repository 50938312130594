export enum ELabelDeclension {
  masculine = 'masculine',
  feminine = 'feminine',
  neuter = 'neuter',
  plural = 'plural',
}

export enum ELabelSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

import { ROUTE_TYPE } from '@/constants';

const routes = [
  {
    path: '/',
    name: 'summaryView',
    meta: { type: ROUTE_TYPE.private },
    component: () => import('@/contexts/summaryContext/views/SummaryView/index.vue'),
  },
];

export default routes;

import { DirectiveBinding } from 'vue';

import { MAX_DISTANCE_TO_END_INFINITY_SCROLL, MIN_DISTANCE_TO_END_INFINITY_SCROLL } from './constants';

export const clickOutside = {
  beforeMount(element: any, binding: any) {
    /* eslint-disable-next-line no-param-reassign */
    element.clickOutsideEvent = (event: any) => {
      if (!(element === event.target || element.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent);
  },
  beforeUnmount(element: any) {
    document.body.removeEventListener('click', element.clickOutsideEvent);
    /* eslint-disable-next-line no-param-reassign */
    element.clickOutsideEvent = null;
  },
};

const infiniteScrollEvent = (event: Event, binding?: DirectiveBinding) => {
  if (!event.target || typeof binding?.value !== 'function') return;
  const { scrollTop, clientHeight, scrollHeight } = event.target as HTMLElement;
  const scrollDistance = scrollTop + clientHeight;
  // До конца скролла осталось от 150px до 100px
  if (scrollDistance >= (scrollHeight - MAX_DISTANCE_TO_END_INFINITY_SCROLL)
    && scrollDistance <= (scrollHeight - MIN_DISTANCE_TO_END_INFINITY_SCROLL)) {
    binding.value(event);
  }
};

export const infiniteScroll = {
  beforeMount(element: HTMLElement, binding: DirectiveBinding) {
    element.addEventListener('scroll', (event: Event) => infiniteScrollEvent(event, binding));
  },
  beforeUnmount(element: HTMLElement) {
    element.removeEventListener('scroll', infiniteScrollEvent);
  },
};

import {
  computed,
  Slots,
  VNode,
  Text,
  Comment,
} from 'vue';

export const useHasSlot = (slots: Readonly<Slots>, slotName: string) => computed(() => {
  if (!slots || typeof slots[slotName] !== 'function') {
    return false;
  }

  const slotContent = slots[slotName]();
  return Array.isArray(slotContent)
    && slotContent.length > 0
    && slotContent.some((vnode: VNode) => {
      if (vnode.type === Comment) {
        return false;
      }
      if (vnode.type === Text) {
        return typeof vnode.children === 'string' && vnode.children?.trim().length > 0;
      }
      return true;
    });
});

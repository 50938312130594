<template>
  <div
    v-for="card in infoForFreightForwarderPlanCards"
    :key="card.planCode"
  >
    <BillingPlanSelectionCard
      :title="card.title"
      :subtitle="card.subtitle"
      :price="card.price"
      :planCode="card.planCode"
      :forExtended="card.isExpanding"
      :isActivePlan="card.isActivePlan"
      :hasInfoAboutTrialPeriod="card.hasInfoAboutTrialPeriod"
      @change="$emit('change', $event)"
    >
      <template #description>
        <li v-for="(description, index) in card.description" :key="index">
          {{ description }}
        </li>
      </template>
    </BillingPlanSelectionCard>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import {
  TBillingAvailablePlansResponse,
  TInfoForPlanCards,
} from '@/contexts/billingContext/domain/types';

import { useView } from '../../application/useView';
import BillingPlanSelectionCard from '../BillingPlanSelectionCard/index.vue';
import { getPlanPrice } from '../../domain/getPlanPrice';
import { getDescriptionByPlanCode } from '../../domain/getDescriptionByPlanCode';

export default defineComponent({
  name: 'BillingCardsForFreightForwarder',
  components: { BillingPlanSelectionCard },
  props: {
    availablePlans: {
      type: Array as PropType<TBillingAvailablePlansResponse>,
      default: () => [],
    },
  },
  emits: ['change'],
  setup(props) {
    const {
      hasInfoAboutTrialPeriod,
      currentActiveSubscription,
    } = useView();

    const infoForFreightForwarderPlanCards = ref<TInfoForPlanCards[]>([
      {
        title: tt('billing.planCode.basic'),
        subtitle: tt('billing.modal.forSmallAndMediumOrganizations'),
        price: getPlanPrice({
          planCode: EBillingPlanCode.freightForwarderBasic,
          availablePlans: props.availablePlans,
          currentActiveSubscription: currentActiveSubscription.value,
        }),
        planCode: EBillingPlanCode.freightForwarderBasic,
        isExpanding: false,
        hasInfoAboutTrialPeriod: hasInfoAboutTrialPeriod.value,
        isActivePlan: currentActiveSubscription.value?.planCode === EBillingPlanCode.freightForwarderBasic,
        description: getDescriptionByPlanCode(EBillingPlanCode.freightForwarderBasic),
      },
      {
        title: tt('billing.planCode.extended'),
        subtitle: tt('billing.modal.forLargeCarriers'),
        price: getPlanPrice({
          planCode: EBillingPlanCode.freightForwarderExtended,
          availablePlans: props.availablePlans,
          currentActiveSubscription: currentActiveSubscription.value,
        }),
        planCode: EBillingPlanCode.freightForwarderExtended,
        isExpanding: true,
        hasInfoAboutTrialPeriod: hasInfoAboutTrialPeriod.value,
        isActivePlan: currentActiveSubscription.value?.planCode === EBillingPlanCode.freightForwarderExtended,
        description: getDescriptionByPlanCode(EBillingPlanCode.freightForwarderExtended),
      },
    ].filter((card: TInfoForPlanCards) => props.availablePlans?.some((plan) => plan.planCode === card.planCode)
    || card.isActivePlan));

    return {
      EBillingPlanCode,
      infoForFreightForwarderPlanCards,
      currentActiveSubscription,

      tt,
    };
  },
});
</script>

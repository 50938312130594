import * as types from './mutation-types';
import { TFilterActions } from './types';

const actions: TFilterActions = {
  resetState: ({ commit }) => {
    commit(types.RESET_STATE);
  },
  resetMainFilterCurrentAppliedSet: ({ commit }, payload) => {
    commit(types.RESET_MAIN_FILTER_CURRENT_APPLIED_SET, payload);
  },
  resetMainFilterCurrentAppliedPreset: ({ commit }, payload) => {
    commit(types.RESET_MAIN_FILTER_CURRENT_APPLIED_PRESET, payload);
  },
  resetMainFilterCurrentEditablePreset: ({ commit }, payload) => {
    commit(types.RESET_MAIN_FILTER_CURRENT_EDITABLE_PRESET, payload);
  },
  setMainFilterCurrentAppliedSet: ({ commit }, payload) => {
    commit(types.SET_MAIN_FILTER_CURRENT_APPLIED_SET, payload);
  },
  setMainFilterCurrentAppliedPreset: ({ commit }, payload) => {
    commit(types.SET_MAIN_FILTER_CURRENT_APPLIED_PRESET, payload);
  },
  setMainFilterCurrentEditablePreset: ({ commit }, payload) => {
    commit(types.SET_MAIN_FILTER_CURRENT_EDITABLE_PRESET, payload);
  },
  resetFilterSelectCurrent: ({ commit }, payload) => {
    commit(types.RESET_FILTER_SELECT_CURRENT, payload);
  },
  setFilterSelectCurrent: ({ commit }, payload) => {
    commit(types.SET_FILTER_SELECT_CURRENT, payload);
  },
  setSubsections: ({ commit }, payload) => {
    commit(types.SET_SUBSECTIONS, payload);
  },
  resetFilterSelectWithIdCurrent: ({ commit }, payload) => {
    commit(types.RESET_FILTER_SELECT_WITH_ID_CURRENT, payload);
  },
  setFilterSelectWithIdCurrent: ({ commit }, payload) => {
    commit(types.SET_FILTER_SELECT_WITH_ID_CURRENT, payload);
  },
};

export default actions;

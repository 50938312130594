<template>
  <section class="auth-layout">
    <slot />
  </section>
</template>

<script lang="ts">

export default { name: 'AuthLayout' };
</script>

<style lang="scss" src="./styles.scss" />

export enum EExecutorsLookupsFeedViewTabs {
  new = 'new',
  bidding = 'bidding',
  finished = 'finished',
}

export const EXECUTORS_LOOKUPS_FEED_VIEW_FILTER_SECTION = 'executorsLookupsFeedView';
export const EXECUTORS_LOOKUPS_FEED_VIEW_FILTER_SELECT_NAME = 'lookupMethodType';

export enum EExecutorsLookupsFeedViewFilterSubSection {
  new = 'new',
  bidding = 'bidding',
  finished = 'finished',
}

export const CONNECTION_INSTRUCTION_LINK = 'https://cat-humerus-e91.notion.site/6c0c2a36a26a477eaf4787f0d44a846d';

<template>
  <label class="radio-button" :class="backgroundClassName">
    <span class="radio-button__innerButton" :class="className">
      <span class="radio-button__wrapper">
        <input
          :id="name"
          type="radio"
          class="radio-button__input"
          :value="value"
          :name="name"
          :checked="isChecked"
          :disabled="isDisabled"
          @change="onChange"
        />
        <span class="radio-button__inner" />
      </span>
      <span v-if="hasSlot">
        <!-- Слот для вывода текста -->
        <slot />
      </span>
      <span
        v-if="hasIconSlot"
        class="radio-button__icon"
      >
        <!-- Слот для прокидывания иконок -->
        <slot name="icon" />
      </span>
    </span>
  </label>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  PropType,
} from 'vue';

import { useHasSlot } from '@/composables/useHasSlot';
import {
  ESize,
  TRadioGroupContext,
  RadioGroupContextKey,
} from '@/ui/types';

export default defineComponent({
  name: 'RadioButton',
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
  },
  emits: [
    'change',
    /** Поддержка двустороннего связывания (v-model) свойства 'checked' */
    'update:checked',
  ],
  setup(props, { emit, slots }) {
    /** Контекст radioGroupContext предоставляется в компоненте RadioGroup.
     * Внутри provide передается объект вида
     *    {
     *       onRadioChange,
     *       stateValue,
     *       props,
     *    };
     * Нам необходимо использовать этот контекст до тех пор пока не появится собственный компонент RadioGroup для того,
     * чтобы RadioGroup продолжала ловить onChange из Radio.
     */
    const radioGroupContext = inject<TRadioGroupContext>(RadioGroupContextKey);

    const hasSlot = useHasSlot(slots, 'default');
    const hasIconSlot = useHasSlot(slots, 'icon');
    const isDisabled = computed(() => props.disabled || radioGroupContext?.props.disabled);
    const isChecked = computed(() => props.checked || props.value === radioGroupContext?.stateValue.value);
    const isSmallSize = computed(() => props.size === ESize.small || radioGroupContext?.props.size === ESize.small);
    const className = computed(() => {
      let resultClassName = isDisabled.value ? 'radio-button__innerButton_disabled' : '';

      if (isChecked.value) {
        resultClassName += ' radio-button__innerButton_checked';
      }

      if (isSmallSize.value) {
        resultClassName += ' radio-button__innerButton-small';
      }

      return resultClassName;
    });

    const backgroundClassName = computed(() => {
      let resultClassName = '';

      if (isSmallSize.value) {
        resultClassName += ' radio-button-small';
      }

      return resultClassName;
    });

    const name = computed(() => radioGroupContext?.props.name);

    const onChange = (event: Event) => {
      const targetChecked = (event.target as HTMLInputElement).checked;

      emit('update:checked', targetChecked);
      emit('change', event);
      if (radioGroupContext && radioGroupContext.onRadioChange) {
        radioGroupContext.onRadioChange(event);
      }
    };

    return {
      hasSlot,
      isChecked,
      isDisabled,
      name,
      className,
      hasIconSlot,
      backgroundClassName,

      onChange,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { ROUTE_TYPE } from '@/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { ROUTE_NAME } from '@/contexts/tendersContext/domain/constants';
import { canBrowseTendersByRole } from '@/domains/permissions/role/tenders';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasCarrierStarterWorkplaceSubscription,
  hasCarrierWorkplaceSubscription,
} from '@/domains/permissions/subscription';

export default [
  buildProtectedRoute({
    path: '/tenders',
    name: ROUTE_NAME.tenders,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseTendersByRole,
        () => hasCargoOwnerWorkplaceSubscription()
          || hasCarrierWorkplaceSubscription()
          || hasCarrierStarterWorkplaceSubscription(),
      ],
      component: () => import('@/contexts/tendersContext/views/TendersListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/tenders/:id',
    name: ROUTE_NAME.tender,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseTendersByRole,
        () => hasCargoOwnerWorkplaceSubscription()
          || hasCarrierWorkplaceSubscription()
          || hasCarrierStarterWorkplaceSubscription(),
      ],
      component: () => import('@/contexts/tendersContext/views/TenderView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/tenders/:tenderId/survey/:stageId',
    name: ROUTE_NAME.surveyStage,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseTendersByRole,
        () => hasCargoOwnerWorkplaceSubscription()
         || hasCarrierWorkplaceSubscription()
         || hasCarrierStarterWorkplaceSubscription(),
      ],
      component: () => import('@/contexts/tendersContext/views/SurveyStageView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/tenders/:tenderId/submission/:stageId',
    name: ROUTE_NAME.submissionStage,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseTendersByRole,
        () => hasCargoOwnerWorkplaceSubscription()
          || hasCarrierWorkplaceSubscription()
          || hasCarrierStarterWorkplaceSubscription(),
      ],
      component: () => import('@/contexts/tendersContext/views/SubmissionStageView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/tenders_mailings',
    name: ROUTE_NAME.tendersMailings,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseTendersByRole,
        hasCargoOwnerWorkplaceSubscription,
      ],
      component: () => import('@/contexts/tendersContext/views/TendersMailingsListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/tenders_mailings/mailing',
    name: ROUTE_NAME.mailingCreate,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseTendersByRole,
        hasCargoOwnerWorkplaceSubscription,
      ],
      component: () => import('@/contexts/tendersContext/views/TendersMailingCreate/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/tenders_mailings/mailing/:id',
    name: ROUTE_NAME.mailing,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseTendersByRole,
        hasCargoOwnerWorkplaceSubscription,
      ],
      component: () => import('@/contexts/tendersContext/views/TendersMailingView/index.vue'),
    },
  }),
];

import { EBillingPlanCode, EBillingShortPlanCode } from '@/contexts/billingContext/domain/constants';

export const getShortPlan = (planCode: EBillingPlanCode): EBillingShortPlanCode => {
  if (planCode.includes(EBillingPlanCode.carrierStart)) {
    return EBillingShortPlanCode.start;
  }
  if (planCode.includes(EBillingPlanCode.carrierExtended)
    || planCode.includes(EBillingPlanCode.freightForwarderExtended)) {
    return EBillingShortPlanCode.extended;
  }
  return EBillingShortPlanCode.basic;
};

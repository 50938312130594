import { decodeJWT } from '@/utils';

import {
  SET_APP_STATE,
  SET_TOKENS,
  SET_LAST_DATA_BEFORE_AUTH,
  DELETE_TOKENS,
  SET_LAST_PATH_BEFORE_AUTH,
  SET_WAS_AUTH_BY,
  SET_COLLAPSED,
  SET_LEGISLATIVE_REMARK_VISIBLE,
  SET_IS_TAB_ACTIVE,
  SET_FOLDER_OPEN_KEYS,
  SET_SBER_PARAMS,
  SET_APP_SETTINGS,
} from './mutation-types';
import { TAppMutations } from './types';

const mutations: TAppMutations = {
  [SET_APP_STATE](state, value) {
    state.state = value;
  },
  [SET_COLLAPSED](state, collapsed) {
    state.isSiderCollapsed = collapsed;
  },
  [SET_LEGISLATIVE_REMARK_VISIBLE](state, isVisible) {
    state.isLegislativeRemarkVisible = isVisible;
  },
  [SET_TOKENS](state, tokens) {
    state.accessToken = tokens.accessToken;
    state.accessTokenExpiresAt = tokens.accessTokenExpiresAt;
    state.csrfToken = tokens.csrfToken;

    if (tokens?.refreshToken) {
      state.refreshToken = tokens.refreshToken;
      state.refreshTokenExpiresAt = tokens.refreshTokenExpiresAt;
    }
  },
  [DELETE_TOKENS](state) {
    state.accessToken = null;
    state.accessTokenExpiresAt = null;
    state.refreshToken = null;
    state.refreshTokenExpiresAt = null;
    state.csrfToken = null;
  },
  [SET_WAS_AUTH_BY](state, wasAuthBy) {
    state.wasAuthBy = wasAuthBy;
  },
  [SET_IS_TAB_ACTIVE](state, isTabActive) {
    state.isTabActive = isTabActive;
  },
  [SET_LAST_PATH_BEFORE_AUTH](state, path) {
    state.pathBeforeAuth = path;
  },
  [SET_LAST_DATA_BEFORE_AUTH](state, { key, value }) {
    if (key === 'entryUid') {
      state.dataBeforeAuth[key] = state.accessToken ? (decodeJWT(state.accessToken) as any)?.entry_uid : null;
    } else if (key === 'utmSources' && value) {
      state.dataBeforeAuth[key].push(value);
    }
  },
  [SET_FOLDER_OPEN_KEYS](state, folderOpenKeys) {
    state.folderOpenKeys = folderOpenKeys;
  },
  [SET_SBER_PARAMS](state, value) {
    state.sberParams = value || null;
  },
  [SET_APP_SETTINGS](state, value) {
    state.appSettings = value;
  },
};

export default mutations;

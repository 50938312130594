export const RESET_STATE = 'RESET_STATE';
export const SET_MAIN_FILTER_CURRENT_APPLIED_SET = 'SET_MAIN_FILTER_CURRENT_APPLIED_SET';
export const SET_MAIN_FILTER_CURRENT_APPLIED_PRESET = 'SET_MAIN_FILTER_CURRENT_APPLIED_PRESET';
export const SET_MAIN_FILTER_CURRENT_EDITABLE_PRESET = 'SET_MAIN_FILTER_CURRENT_EDITABLE_PRESET';
export const RESET_MAIN_FILTER_CURRENT_APPLIED_SET = 'RESET_MAIN_FILTER_CURRENT_APPLIED_SET';
export const RESET_MAIN_FILTER_CURRENT_APPLIED_PRESET = 'RESET_MAIN_FILTER_CURRENT_APPLIED_PRESET';
export const RESET_MAIN_FILTER_CURRENT_EDITABLE_PRESET = 'RESET_MAIN_FILTER_CURRENT_EDITABLE_PRESET';
export const RESET_FILTER_SELECT_CURRENT = 'RESET_FILTER_SELECT_CURRENT';
export const SET_FILTER_SELECT_CURRENT = 'SET_FILTER_SELECT_CURRENT';
export const SET_SUBSECTIONS = 'SET_SUBSECTIONS';
export const RESET_FILTER_SELECT_WITH_ID_CURRENT = 'RESET_FILTER_SELECT_WITH_ID_CURRENT';
export const SET_FILTER_SELECT_WITH_ID_CURRENT = 'SET_FILTER_SELECT_WITH_ID_CURRENT';

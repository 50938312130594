import { ROUTE_TYPE } from '@/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import isCurrentUserAdmin from '@/contexts/accountingContext/domain/isCurrentUserAdmin';
import isCurrentUserSupport from '@/contexts/accountingContext/domain/isCurrentUserSupport';

const routes = [
  buildProtectedRoute({
    path: '/admin',
    name: 'adminView',
    meta: {
      type: ROUTE_TYPE.admin,
      permissions: [() => isCurrentUserAdmin() || isCurrentUserSupport()],
      component: () => import('@/contexts/adminContext/views/AdminView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/admin/tenants',
    name: 'adminTenants',
    meta: {
      type: ROUTE_TYPE.admin,
      permissions: [() => isCurrentUserAdmin() || isCurrentUserSupport()],
      component: () => import('@/contexts/adminContext/views/TenantsListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/admin/tenants/:id',
    name: 'adminTenantInfo',
    meta: {
      type: ROUTE_TYPE.admin,
      permissions: [() => isCurrentUserAdmin() || isCurrentUserSupport()],
      component: () => import('@/contexts/adminContext/views/TenantInfoView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/admin/users',
    name: 'adminUsers',
    meta: {
      type: ROUTE_TYPE.admin,
      permissions: [() => isCurrentUserAdmin() || isCurrentUserSupport()],
      component: () => import('@/contexts/adminContext/views/UsersListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/admin/users/:id',
    name: 'adminUsersInfo',
    meta: {
      type: ROUTE_TYPE.admin,
      permissions: [() => isCurrentUserAdmin() || isCurrentUserSupport()],
      component: () => import('@/contexts/adminContext/views/UsersInfoView/index.vue'),
    },
  }),
];

export default routes;

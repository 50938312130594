export enum EViewSettingsSections {
  contractorPersonalAccountCurrentRunsTable = 'contractorPersonalAccountCurrentRunsTable',
  contractorPersonalAccountFinishedRunsTable = 'contractorPersonalAccountFinishedRunsTable',
  contractorPersonalAccountPlannedRunsTable = 'contractorPersonalAccountPlannedRunsTable',
  executionRequestsTable = 'executionRequestsTable',
  executorsLookupsFeed = 'executorsLookupsFeed',
  ordersFinalized = 'ordersFinalized',
  ordersOnBidding = 'ordersOnBidding',
  ordersOnExecution = 'ordersOnExecution',
  ordersOnTargetOffering = 'ordersOnTargetOffering',
  ordersPending = 'ordersPending',
  combinedOrdersTable = 'combinedOrdersTable',
}

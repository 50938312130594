import {
  onMounted,
  ref,
  watch,
} from 'vue';
import {
  useRoute,
  useRouter,
  LocationQueryRaw,
  LocationQuery,
} from 'vue-router';
import cloneDeep from 'lodash.clonedeep';

import {
  isObject,
  concatenateArrays,
  getArrayWithUniqueValues,
} from '@/utils';

const useRouteState = () => {
  const route = useRoute();
  const router = useRouter();

  const routeState = ref<LocationQuery>({});

  const setRouteState = (newState: LocationQueryRaw) => {
    router.replace({ query: newState });
  };

  const addToRouteState = (payload: Record<string, unknown>) => {
    const newState = cloneDeep(route.query);

    Object.entries(payload).forEach(([key, value]) => {
      const stateByKey = newState[key];
      const isStateByKeyArray = Array.isArray(stateByKey);
      const isValueArray = Array.isArray(value);

      const stringifiedValue = isValueArray
        ? value.map((item) => `${item}`)
        : `${value}`;

      if (isStateByKeyArray && isValueArray) {
        // @ts-ignore
        newState[key] = concatenateArrays(stateByKey, stringifiedValue as string[]);
      } else if (isStateByKeyArray) {
        newState[key] = getArrayWithUniqueValues(stateByKey.concat(stringifiedValue));
      } else if (newState[key]) {
        newState[key] = getArrayWithUniqueValues([stateByKey].concat(stringifiedValue));
      } else if (Array.isArray(stringifiedValue)) {
        newState[key] = getArrayWithUniqueValues(stringifiedValue);
      }
    });

    setRouteState(newState);
  };

  const removeFromRouteState = (payload) => {
    const initialState = route.query;
    if (isObject(payload)) {
      const newState = cloneDeep(initialState);
      Object.entries(payload).forEach(([key, value]) => {
        const stateByKey = newState[key];
        if (Array.isArray(stateByKey)) {
          newState[key] = stateByKey.filter((v) => v !== `${value}`);
        } else {
          delete newState[key];
        }
      });
      setRouteState(newState);
    } else if (typeof payload === 'string') {
      const { [payload]: removableKey, ...restNewState } = initialState;
      setRouteState(restNewState);
    }
  };

  onMounted(() => {
    routeState.value = route.query;
  });

  watch(() => route.query, (newQuery) => {
    routeState.value = newQuery;
  });

  return {
    setRouteState,
    routeState,
    addToRouteState,
    removeFromRouteState,
  };
};

export default useRouteState;

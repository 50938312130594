<template>
  <LoaderSvgMediumSize class="action-progress-spinner" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import LoaderSvgMediumSize from '@/assets/svg/16x16/loader.svg';

export default defineComponent({
  name: 'ActionProgressSpinner',
  components: { LoaderSvgMediumSize },
});
</script>

<style lang="scss" src="./styles.scss" />

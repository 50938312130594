import { ROUTE_TYPE } from '@/constants';
import { hasCargoOwnerWorkplaceSubscription } from '@/domains/permissions/subscription';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';

import { ROUTE_NAME } from '../domains/constants';

const routes = [
  buildProtectedRoute({
    path: '/extra_services',
    name: ROUTE_NAME.extraServices,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [hasCargoOwnerWorkplaceSubscription],
      component: () => import('@/contexts/extraServicesContext/views/ExtraServicesView/index.vue'),
    },
  }),
];

export default routes;

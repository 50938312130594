type TUrlParams = {
  [name: string]: string,
};

export const createUrlParams = (data: TUrlParams): string => {
  const result: string[] = [];

  Object.keys(data).forEach((item: string) => {
    if (data[item]) {
      result.push(`${encodeURIComponent(item)}=${encodeURIComponent(data[item])}`);
    }
  });

  return result.join('&');
};

<template>
  <div class="email-confirmation">
    <div class="email-confirmation__row">
      <slot />

      <Button
        v-if="!isCodeInputShown && isConfirmationRequired"
        class="email-confirmation__button-link"
        type="link"
        :disabled="isLoading || isEmailInvalid"
        @click="getCode"
      >
        {{ tt('emailConfirmation.action.confirmEmail') }}
      </Button>

      <div v-else-if="isConfirmationRequired">
        <Input
          class="email-confirmation__code-input"
          :value="code"
          autocomplete="off"
          @input="handleInputChange($event.target.value)"
        />

        <Button
          class="email-confirmation__button-primary"
          type="primary"
          :disabled="isLoading || isConfirmationCodeEmpty"
          @click="handleSubmit"
        >
          {{ tt('shared.action.confirm') }}
        </Button>
      </div>
    </div>

    <p v-if="isCodeInputShown" class="email-confirmation__hint">
      {{ `${tt('emailConfirmation.emailSent')} ${tt('emailConfirmation.checkSpam')}` }}

      <span v-if="secondsRemain">
        {{ tt('emailConfirmation.resendAvailableFrom', { param: { seconds: secondsRemain } }) }}
      </span>

      <Button
        v-else
        class="email-confirmation__button-link"
        type="link"
        :disabled="isLoading"
        @click="getCode"
      >
        {{ tt('emailConfirmation.action.sendCodeAgain') }}
      </Button>
    </p>
  </div>
</template>

<script lang="ts">

import {
  PropType,
  defineComponent,
  toRef,
} from 'vue';
import { Button } from 'ant-design-vue';

import { Input } from '@/ui';
import tt from '@/i18n/utils/translateText';

import { useConfirm } from './application/useConfirm';
import { TRequestCodeFunction, TConfirmCodeFunction } from './domain/types';

export default defineComponent({
  name: 'EmailConfirmation',
  components: {
    Input,
    Button,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    isConfirmationRequired: {
      type: Boolean,
      default: true,
    },
    // функция для запрос кода для верификации e-mail
    requestCodeFunction: {
      type: Function as PropType<TRequestCodeFunction>,
      required: true,
    },
    // функция для подтверждения кода верификации e-mail
    confirmCodeFunction: {
      type: Function as PropType<TConfirmCodeFunction>,
      required: true,
    },
  },
  emits: ['confirmed', 'change'],
  setup(props, { emit }) {
    const email = toRef(props, 'email');

    const {
      code,
      isCodeInputShown,
      isLoading,
      secondsRemain,
      isEmailInvalid,
      isConfirmationCodeEmpty,

      getCode,
      handleInputChange,
      handleSubmit,
    } = useConfirm({
      email,

      emit,
      requestCode: props.requestCodeFunction,
      confirmCode: props.confirmCodeFunction,
    });

    return {
      code,
      isCodeInputShown,
      isLoading,
      isEmailInvalid,
      isConfirmationCodeEmpty,
      secondsRemain,

      getCode,
      handleInputChange,
      handleSubmit,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import tt from '@/i18n/utils/translateText';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

export const ROUTE_NAME = {
  contractors: 'contractors',
  contractorPersonalAccountSignIn: 'contractorPersonalAccountSignIn',
  contractorPersonalAccountUnsubscribe: 'contractorPersonalAccountUnsubscribe',
  contractorPersonalAccountRuns: 'contractorPersonalAccountRuns',
  contractorPersonalAccountRun: 'contractorPersonalAccountRun',
  contractorPersonalAccountIncidents: 'contractorPersonalAccountIncidents',
  digitalQueue: 'digitalQueue',
  digitalQueueBooking: 'digitalQueueBooking',
  digitalQueueSchedule: 'digitalQueueSchedule',
};

export enum EActionType {
  loading = 'loading',
  unloading = 'unloading',
  loadingAndUnloading = 'loadingAndUnloading',
}

export const ACTION_TYPE_TRANSLATION = {
  [EActionType.loading]: tt('shared.loading'),
  [EActionType.unloading]: tt('shared.unloading'),
  [EActionType.loadingAndUnloading]: tt('shared.loadingAndUnloading'),
};

export enum EBookingTarget {
  run = 'run',
  selfDelivery = 'self_delivery',
  manual = 'manual',
}

export enum EBookingState {
  detached = 'detached',
  inProgress = 'in_progress',
  done = 'done',
  lateArrival = 'late_arrival',
  lateDeparture = 'late_departure',
  reserved = 'reserved',
}

export enum EBookingStatusState {
  done = 'done', // Работа завершена
  inProgress = 'in_progress', // В процессе
  booked = 'booked', // Запланировано
  registrationIsNotCompleted = 'registration_is_not_completed', // Ожидает внесения данных (Резервирование)
  annulled = 'annulled', // Аннулировано
  registered = 'registered', // Зарегистрировано(бронь ГВ за ГП в ЦО)
  locked = 'locked', // Заблокировано
  reserved = 'reserved', // Зарезервировано
}

export const BOOKING_STATE_LATE_STATUS_TRANSLATION = {
  [EBookingState.lateArrival]: tt('digitalQueues.shared.lateArrival'),
  [EBookingState.lateDeparture]: tt('digitalQueues.shared.lateDeparture'),
};

export enum EBookingOwnership {
  own = 'own',
  spouses = 'spouses',
  rent = 'rent',
  leasing = 'leasing',
  freeUsage = 'free_usage',
}

export const BOOKING_OWNERSHIP_TRANSLATION = {
  [EBookingOwnership.own]: tt('shared.own'),
  [EBookingOwnership.spouses]: tt('shared.spouses'),
  [EBookingOwnership.rent]: tt('shared.rent'),
  [EBookingOwnership.leasing]: tt('shared.leasing'),
  [EBookingOwnership.freeUsage]: tt('shared.freeUsage'),
};

export enum ERunsTableStatus {
  onExecution = 'on_execution',
  completed = 'completed',
  planned = 'planned',
}

export enum ERunsTableTypes {
  contractorPersonalAccountCurrentRunsTable = 'contractorPersonalAccountCurrentRunsTable',
  contractorPersonalAccountFinishedRunsTable = 'contractorPersonalAccountFinishedRunsTable',
  contractorPersonalAccountPlannedRunsTable = 'contractorPersonalAccountPlannedRunsTable',
}

export enum EContractorCategory {
  customer = 'customer',
  consigneeOrConsignor = 'consigneeOrConsignor',
  payer = 'payer',
}

export const CONTRACTOR_CATEGORY_TRANSLATIONS = {
  [EContractorCategory.customer]: 'customer',
  [EContractorCategory.consigneeOrConsignor]: 'consignee_or_consignor',
  [EContractorCategory.payer]: 'payer',
};

export const CONTRACTOR_CATEGORIES = [{
  name: tt('contractors.shared.customer'),
  id: EContractorCategory.customer,
}, {
  name: tt('contractors.shared.consigneeOrConsignor'),
  id: CONTRACTOR_CATEGORY_TRANSLATIONS[EContractorCategory.consigneeOrConsignor],
}, {
  name: tt('contractors.shared.payer'),
  id: EContractorCategory.payer,
}];

export enum EDigitalQueueTargetType {
  run = 'run', // рейс
  shipment = 'shipment', // самовывоз
  manual = 'manual', // создан вручную ГВ за ГП
  executionRequest = 'execution_request', // резервирования
}

export enum EContractorContextDomainErrorCode {
  contractorAlreadyUsesDigitalQueueOnAddress = 'DomainErrors::ContractorsContext::ContractorAlreadyUsesDigitalQueueOnAddress',
}

export enum EBookingAuditEventOld {
  /** Информация о бронировании отредактирована */
  bookingDriverInfoChanged = 'Events::ContractorsContext::BookingDriverInfoChanged',
  /** Отредактирован комментарий к бронированию */
  bookingCommentChanged = 'Events::ContractorsContext::BookingCommentChanged',
  /** Бронирование создано */
  timeSlotBooked = 'Events::ContractorsContext::TimeSlotBooked',
  /** Резервирование создано */
  timeSlotReserved = 'Events::ContractorsContext::TimeSlotReserved',
  /** Бронирование перебронировано */
  bookingTimeSlotChanged = 'Events::ContractorsContext::BookingTimeSlotChanged',
  /** Резервирование перебронировано */
  reservationTimeSlotChanged = 'Events::ContractorsContext::ReservationTimeSlotChanged',
  /** Изменен статус бронирования */
  bookingWorkflowStateChanged = 'Events::ContractorsContext::BookingWorkflowStateChanged',
  /** Изменен статус бронирования */
  bookingWorkflowStateRolledBack = 'Events::ContractorsContext::BookingWorkflowStateRolledBack',
  /** Бронирование аннулировано грузовладельцем */
  bookingAnnulled = 'Events::ContractorsContext::BookingAnnulled',
  /** Резервирование аннулировано грузовладельцем */
  reservationAnnulled = 'Events::ContractorsContext::ReservationAnnulled',
  /** Бронирование аннулировано(отозвано) грузоперевозчиком */
  bookingRevoked = 'Events::ContractorsContext::BookingRevoked',
  /** Резервирование аннулировано(отозвано) грузоперевозчиком */
  reservationRevoked = 'Events::ContractorsContext::ReservationRevoked',
  /** Резервирование отредактировано */
  reservationReassigned = 'Events::ContractorsContext::ReservationReassigned',
  /** Бронирование отредактировано */
  bookingReassigned = 'Events::ContractorsContext::BookingReassigned',
  /** Бронирование под самовывоз отредактировано */
  bookingReassignedForShipment = 'Events::ContractorsContext::BookingReassignedForShipment',
  /** Кастомный атрибут изменен */
  customAttributeChanged = 'Events::ReferencesContext::CustomAttributeChanged',
}

export enum EBookingAuditEventNew {
  /** Информация о бронировании отредактирована */
  bookingDriverInfoChanged = 'contractors_context.booking_driver_info_changed',
  /** Отредактирован комментарий к бронированию */
  bookingCommentChanged = 'contractors_context.booking_comment_changed',
  /** Бронирование создано */
  timeSlotBooked = 'contractors_context.time_slot_booked',
  /** Резервирование создано */
  timeSlotReserved = 'contractors_context.time_slot_reserved',
  /** Бронирование перебронировано */
  bookingTimeSlotChanged = 'contractors_context.booking_time_slot_changed',
  /** Резервирование перебронировано */
  reservationTimeSlotChanged = 'contractors_context.reservation_time_slot_changed',
  /** Изменен статус бронирования */
  bookingWorkflowStateChanged = 'contractors_context.booking_workflow_state_changed',
  /** Изменен статус бронирования */
  bookingWorkflowStateRolledBack = 'contractors_context.booking_workflow_state_rolled_back',
  /** Бронирование аннулировано грузовладельцем */
  bookingAnnulled = 'contractors_context.booking_annulled',
  /** Резервирование аннулировано грузовладельцем */
  reservationAnnulled = 'contractors_context.reservation_annulled',
  /** Бронирование аннулировано(отозвано) грузоперевозчиком */
  bookingRevoked = 'contractors_context.booking_revoked',
  /** Резервирование аннулировано(отозвано) грузоперевозчиком */
  reservationRevoked = 'contractors_context.reservation_revoked',
  /** Резервирование отредактировано */
  reservationReassigned = 'contractors_context.reservation_reassigned',
  /** Бронирование отредактировано */
  bookingReassigned = 'contractors_context.booking_reassigned',
  /** Бронирование под самовывоз отредактировано */
  bookingReassignedForShipment = 'contractors_context.booking_reassigned_for_shipment',
  /** Кастомный атрибут изменен */
  customAttributeChanged = 'references_context.custom_attribute_changed',
}

export const EBookingAuditEvent = checkFeatureIsEnabled(EExperimentalFeatures.eventsMapping)
  ? EBookingAuditEventNew
  : EBookingAuditEventOld;

export const BOOKING_AUDIT_EVENT_TRANSLATIONS = {
  [EBookingAuditEvent.timeSlotReserved]: tt('bookingAuditEvent.timeSlotReserved'),
  [EBookingAuditEvent.reservationReassigned]: tt('bookingAuditEvent.reservationReassigned'),
  [EBookingAuditEvent.bookingReassigned]: tt('bookingAuditEvent.bookingReassigned'),
  [EBookingAuditEvent.bookingReassignedForShipment]: tt('bookingAuditEvent.bookingReassigned'),
  [EBookingAuditEvent.reservationTimeSlotChanged]: tt('bookingAuditEvent.reservationTimeSlotChanged'),
  [EBookingAuditEvent.timeSlotBooked]: tt('bookingAuditEvent.timeSlotBooked'),
  [EBookingAuditEvent.bookingAnnulled]: tt('bookingAuditEvent.bookingAnnulled'),
  [EBookingAuditEvent.reservationAnnulled]: tt('bookingAuditEvent.reservationAnnulled'),
  [EBookingAuditEvent.bookingDriverInfoChanged]: tt('bookingAuditEvent.bookingDriverInfoChanged'),
  [EBookingAuditEvent.bookingTimeSlotChanged]: tt('bookingAuditEvent.bookingTimeSlotChanged'),
  [EBookingAuditEvent.bookingWorkflowStateChanged]: tt('bookingAuditEvent.bookingWorkflowStateChanged'),
  [EBookingAuditEvent.bookingWorkflowStateRolledBack]: tt('bookingAuditEvent.bookingWorkflowStateRolledBack'),
  [EBookingAuditEvent.customAttributeChanged]: tt('bookingAuditEvent.customAttributeChanged'),
};

export enum EBookingAuditLogKey {
  referenceNumber = 'referenceNumber',
  transitPointAction = 'transitPointAction',
  address = 'address',
  workflowState = 'workflowState',
  happenedAt = 'happenedAt',
  comment = 'comment',
  powerOfAttorneyNumber = 'powerOfAttorneyNumber',
  powerOfAttorneyIssuedOn = 'powerOfAttorneyIssuedOn',
  powerOfAttorneyInfo = 'powerOfAttorneyInfo',
  gateName = 'gateName',
  fullName = 'fullName',
  birthday = 'birthday',
  birthplace = 'birthplace',
  passportNumber = 'passportNumber',
  passportIssuedOn = 'passportIssuedOn',
  passportIssuedBy = 'passportIssuedBy',
  passportDetails = 'passportDetails',
  registrationAddress = 'registrationAddress',
  phone = 'phone',
  inn = 'inn',
  truckLicensePlate = 'truckLicensePlate',
  truckBrand = 'truckBrand',
  truckOwnership = 'truckOwnership',
  truckForwarderExecutor = 'truckForwarderExecutor',
  truckForwarderExecutorInn = 'truckForwarderExecutorInn',
  truckForwarderContract = 'truckForwarderContract',
  truckForwarderContractIssuedOn = 'truckForwarderContractIssuedOn',
  truckOwnershipDocument = 'truckOwnershipDocument',
  trailerLicensePlate = 'trailerLicensePlate',
  trailerBrand = 'trailerBrand',
  trailerOwnership = 'trailerOwnership',
  trailerOwnershipDocument = 'trailerOwnershipDocument',
  trailerForwarderExecutor = 'trailerForwarderExecutor',
  trailerForwarderExecutorInn = 'trailerForwarderExecutorInn',
  trailerForwarderContract = 'trailerForwarderContract',
  trailerForwarderContractIssuedOn = 'trailerForwarderContractIssuedOn',
}

export const BOOKING_AUDIT_LOG_KEY_TRANSLATIONS = {
  [EBookingAuditLogKey.referenceNumber]: tt('shared.referenceNumber'),
  [EBookingAuditLogKey.transitPointAction]: tt('digitalQueues.showBookingDrawer.auditEvents.transitPointAction'),
  [EBookingAuditLogKey.address]: tt('approvals.shared.routeAddress'),
  [EBookingAuditLogKey.gateName]: tt('digitalQueues.addGatesDrawer.form.gateName'),
  [EBookingAuditLogKey.workflowState]: tt('digitalQueues.showBookingDrawer.auditEvents.workflowState'),
  [EBookingAuditLogKey.happenedAt]: tt('digitalQueues.showBookingDrawer.auditEvents.happenedAt'),
  [EBookingAuditLogKey.comment]: tt('shared.comment'),
  [EBookingAuditLogKey.powerOfAttorneyNumber]: tt('shared.powerOfAttorneyNumber'),
  [EBookingAuditLogKey.powerOfAttorneyIssuedOn]: tt('shared.powerOfAttorneyDate'),
  [EBookingAuditLogKey.powerOfAttorneyInfo]: tt('shared.powerOfAttorneyDetails'),
  [EBookingAuditLogKey.fullName]: tt('digitalQueues.driverForm.label.fullName'),
  [EBookingAuditLogKey.birthday]: tt('digitalQueues.driverForm.label.birthday'),
  [EBookingAuditLogKey.birthplace]: tt('digitalQueues.driverForm.label.birthplace'),
  [EBookingAuditLogKey.passportNumber]: tt('shared.passport'),
  [EBookingAuditLogKey.passportIssuedOn]: tt('digitalQueues.driverForm.label.passportIssuedOn'),
  [EBookingAuditLogKey.passportIssuedBy]: tt('driverInfoDropdown.panel.passportIssuedBy'),
  [EBookingAuditLogKey.registrationAddress]: tt('digitalQueues.driverForm.label.registrationAddress'),
  [EBookingAuditLogKey.phone]: tt('shared.phone'),
  [EBookingAuditLogKey.inn]: tt('shared.inn'),
  [EBookingAuditLogKey.truckLicensePlate]: tt('freightExecution.executionRequests.runInfo.truck.truckLicensePlate'),
  [EBookingAuditLogKey.truckBrand]: tt('freightExecution.executionRequests.runInfo.truck.truckBrand'),
  [EBookingAuditLogKey.truckOwnership]: tt('freightExecution.executionRequests.runInfo.truck.truckOwnership'),
  [EBookingAuditLogKey.truckOwnershipDocument]: tt('freightExecution.executionRequests.runInfo.truck.truckOwnershipDocument'),
  [EBookingAuditLogKey.truckForwarderExecutor]: tt('shared.executorName'),
  [EBookingAuditLogKey.truckForwarderExecutorInn]: tt('shared.executorInn'),
  [EBookingAuditLogKey.truckForwarderContract]: tt('freightExecution.executionRequests.runInfo.truck.contractNumber'),
  [EBookingAuditLogKey.truckForwarderContractIssuedOn]: tt('freightExecution.executionRequests.runInfo.truck.contractSignedOn'),
  [EBookingAuditLogKey.trailerLicensePlate]: tt('freightExecution.executionRequests.runInfo.truck.trailerLicensePlate'),
  [EBookingAuditLogKey.trailerBrand]: tt('freightExecution.executionRequests.runInfo.truck.trailerBrand'),
  [EBookingAuditLogKey.trailerOwnership]: tt('freightExecution.executionRequests.runInfo.truck.trailerBrand'),
  [EBookingAuditLogKey.trailerOwnershipDocument]: tt('freightExecution.executionRequests.runInfo.truck.trailerOwnershipDocument'),
  [EBookingAuditLogKey.trailerForwarderExecutor]: tt('shared.executorName'),
  [EBookingAuditLogKey.trailerForwarderExecutorInn]: tt('shared.executorInn'),
  [EBookingAuditLogKey.trailerForwarderContract]: tt('freightExecution.executionRequests.runInfo.trailer.contractNumber'),
  [EBookingAuditLogKey.trailerForwarderContractIssuedOn]: tt('freightExecution.executionRequests.runInfo.trailer.contractSignedOn'),
};

export enum ERunTypes {
  current = 'current',
  planned = 'planned',
  finished = 'finished',
}

// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';

/**
 * Возвращает Dayjs объект, из которого можно получить количество месяцев,
 * дней, часов и т.д. из какого-то промежутка времени, чтобы, например,
 * из duration: 154800sec получить "1д 19ч"
 *
 * getDuration(100).milliseconds() => 100
 */
const getDuration = (value: number) => dayjs.duration(value);

export default getDuration;

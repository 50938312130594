import { defineStore } from 'pinia';
import { ref } from 'vue';

import { TMatricesResponse, TMatrix } from '@/contexts/rateManagementContext/domain/types';
import { TCustomReference, TCustomReferencesResponse } from '@/contexts/referencesContext/domain/types';
import { getMatrices } from '@/contexts/rateManagementContext/services';
import { getCustomReferences } from '@/contexts/referencesContext/services';
import { TDomainError } from '@/types';
import { getContractCustomAttributesSchema, getContractCustomColumns } from '@/contexts/partnershipsContext/services';
import { TReachedResponse } from '@/api/types';
import {
  TContractCustomAttributesSchemaResponse,
  TContractCustomColumnsResponse,
} from '@/contexts/partnershipsContext/domain/types';

import { TCustomizationStore, TContractState } from './types';

export const useCustomizationStore = defineStore(
  'customization',
  (): TCustomizationStore => {
    const matrices = ref<TMatrix[]>([]);
    const customReferences = ref<TCustomReference[]>([]);
    const contract = ref<TContractState | null>(null);

    const loadMatrices = () => getMatrices()
      .then((response: TReachedResponse<TMatricesResponse>) => {
        if (!response.data) return;
        setMatrices(response.data);
      })
      .catch((error: TDomainError) => Promise.reject(error));

    const loadCustomReferences = () => getCustomReferences()
      .then((response: TReachedResponse<TCustomReferencesResponse>) => {
        if (!response.data) return;
        setCustomReferences(response.data);
      })
      .catch((error: TDomainError) => Promise.reject(error));

    const loadContract = () => Promise.all([
      getContractCustomAttributesSchema(),
      getContractCustomColumns(),
    ])
      .then((response: [
        TReachedResponse<TContractCustomAttributesSchemaResponse>,
        TReachedResponse<TContractCustomColumnsResponse>,
      ]) => {
        setContract({
          contractAttributesSchema: response[0].data?.contractAttributesSchema,
          columns: response[1].data,
        });
      })
      .catch((error: TDomainError) => Promise.reject(error));

    const setMatrices = (payload: TMatrix[]) => {
      matrices.value = payload.length ? payload : [];
    };

    const setCustomReferences = (payload: TCustomReference[]) => {
      customReferences.value = payload.length ? payload : [];
    };

    const setContract = (payload: TContractState | null) => {
      contract.value = payload || null;
    };

    const $reset = () => {
      matrices.value = [];
      customReferences.value = [];
      contract.value = null;
    };

    return {
      matrices,
      customReferences,
      contract,

      loadMatrices,
      loadCustomReferences,
      loadContract,

      setMatrices,
      setCustomReferences,
      setContract,
      $reset,
    };
  },
  { persist: true });

import { EAppState } from '@/constants';

import { TAppState } from './types';

export const initialState = (): TAppState => ({
  state: EAppState.initial,
  accessToken: null,
  accessTokenExpiresAt: null,
  refreshToken: null,
  refreshTokenExpiresAt: null,
  csrfToken: null,
  wasAuthBy: null,
  isSiderCollapsed: false,
  isLegislativeRemarkVisible: true,
  isTabActive: false,
  pathBeforeAuth: null,
  dataBeforeAuth: {
    entryUid: null,
    utmSources: [],
  },
  folderOpenKeys: [],
  sberParams: null,
  appSettings: null,
});

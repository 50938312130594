<template>
  <Modal
    class="license-role-selection-modal"
    visible
    :width="400"
    destroyOnClose
  >
    <template #title>
      <p class="license-role-selection-modal__title">
        {{ tt('tenant.licenseRoleSelectionModal.title') }}
      </p>
    </template>

    <template #closeIcon>
      <CloseSvg @click="$emit('close')" />
    </template>

    <Select
      v-model:value="selectedLicenseRole"
      :options="availableLicenseRoles"
      :placeholder="tt('shared.placeholder.selectFrom')"
      showArrow
    />

    <template #footer>
      <Button
        :type="EButtonType.primary"
        :title="tt('shared.action.choose')"
        :disabled="!selectedLicenseRole"
        :loading="isLoading"
        @click="onSubmit"
      />
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from 'ant-design-vue';

import tt from '@/i18n/utils/translateText';
import CloseSvg from '@/assets/svg/16x16/close.svg';
import {
  Button,
  Select,
} from '@/ui';
import {
  EButtonType,
  ESize,
} from '@/ui/types';
import { ELicenseRole } from '@/contexts/accountingContext/domain/constants';

import { useSelectLicenseRole } from './application/useSelectLicenseRole';

export default defineComponent({
  name: 'LicenseRoleSelectionModal',
  components: {
    Modal,
    Select,
    Button,
    CloseSvg,
  },
  emits: ['close'],
  setup(_, { emit }) {
    const {
      selectedLicenseRole,
      availableLicenseRoles,
      isLoading,

      onSubmit,
    } = useSelectLicenseRole(emit);

    return {
      EButtonType,
      ESize,
      ELicenseRole,
      availableLicenseRoles,
      selectedLicenseRole,
      isLoading,

      tt,
      onSubmit,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { onMounted } from 'vue';

import store from '@/store';

const handleTabFocus = () => {
  store.dispatch('app/setIsTabActive', true);
};

const handleTabBlur = () => {
  store.dispatch('app/setIsTabActive', false);
};

const useAppTabState = () => {
  onMounted(() => {
    window.addEventListener('focus', handleTabFocus);
    window.addEventListener('blur', handleTabBlur);
  });
};

export default useAppTabState;

import useWebsocketChannel from '@/composables/useWebsocketChannel';
import { TWsChannelSubscribers } from '@/websocket/types';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

export enum EViewSettingsWSEventsOld {
  viewSettingMaxColumnsUpdated = 'Events::PlatformContext::ViewSettingMaxColumnsUpdated',
}

export enum EViewSettingsWSEventsNew {
  viewSettingMaxColumnsUpdated = 'platform_context.view_setting_max_columns_updated',
}

export const EViewSettingsWSEvents = checkFeatureIsEnabled(EExperimentalFeatures.eventsMapping)
  ? EViewSettingsWSEventsNew
  : EViewSettingsWSEventsOld;

export const useTenantSettingsWsChannel = (subscribers: TWsChannelSubscribers) => {
  const channelName = 'TenantSettingsChannel';

  useWebsocketChannel({
    channelName,
    subscribers,
  });
};

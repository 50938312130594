import { joinString } from '@/utils';

import * as types from './mutation-types';
import { TStrategiesMutations } from './types';

const mutations: TStrategiesMutations = {
  [types.SET_CHAIN_ID](state, { strategyId, automationId, chainId }) {
    const keyFromParams = joinString([strategyId, automationId], '_');
    const isKeyFromParamsContainedInObject = Object.keys(state.persistedStrategies).includes(keyFromParams);
    if (isKeyFromParamsContainedInObject) {
      if (chainId) {
        state.persistedStrategies[keyFromParams] = chainId;
      } else {
        delete state.persistedStrategies[keyFromParams];
      }
    } else {
      state.persistedStrategies[keyFromParams] = chainId;
    }
  },
};

export default mutations;

import { computed } from 'vue';

import { canCreateSubscriptionsByRole } from '@/domains/permissions/role/subscriptions';
import useStore from '@/store/useStore';
import { getShortPlan } from '@/contexts/billingContext/domain/getShortPlan';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { formatDate } from '@/utils/dateUtils';
import { DATE_FORMAT } from '@/constants';
import { TSubscription } from '@/types';

export const useView = () => {
  const store = useStore();

  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);
  const subscriptionRequest = computed(() => currentTenantInfo.value?.subscriptionRequest);

  const subscriptions = computed(() => currentTenantInfo.value?.subscriptions || []);

  const subscriptionWithPendingStatus = computed(
    () => subscriptions.value.find(
      (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.pending,
    ),
  );

  const shortPlan = computed(() => {
    if (subscriptionWithPendingStatus.value?.planCode) {
      return getShortPlan(subscriptionWithPendingStatus.value.planCode);
    }

    if (subscriptionRequest.value?.planCode) {
      return getShortPlan(subscriptionRequest.value.planCode);
    }

    return '';
  },
  );

  const hasCarrierStartSubscription = computed(
    () => subscriptions.value.find(
      (subscription: TSubscription) => subscription.planCode === EBillingPlanCode.carrierStart,
    ),
  );

  const сanCreateSubscriptions = computed(() => canCreateSubscriptionsByRole());

  const isCurrentTenantWithoutSubscription = computed(() => !subscriptions.value.length && !subscriptionRequest.value);

  const showPlanSelectionButton = computed(
    () => сanCreateSubscriptions.value
    && (isCurrentTenantWithoutSubscription.value || hasCarrierStartSubscription.value),
  );

  const hasCargoOwnerSubscription = computed(
    () => !!(subscriptionWithPendingStatus.value?.planCode?.startsWith(EBillingPlanCode.cargoOwner)
    || subscriptionRequest.value?.planCode?.startsWith(EBillingPlanCode.cargoOwner)),
  );

  const expectedSubscriptionDate = computed(() => {
    if (subscriptionWithPendingStatus.value) {
      return formatDate(subscriptionWithPendingStatus.value.subscriptionAt || '', { outputFormat: DATE_FORMAT.dayMonthYear });
    }
    return formatDate(subscriptionRequest.value?.subscriptionAt || '', { outputFormat: DATE_FORMAT.dayMonthYear });
  });

  return {
    showPlanSelectionButton,
    сanCreateSubscriptions,
    subscriptionRequest,
    shortPlan,
    hasCargoOwnerSubscription,
    expectedSubscriptionDate,
    subscriptionWithPendingStatus,
    hasCarrierStartSubscription,
  };
};

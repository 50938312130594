// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';

const SUNDAY = 7;

/** Возвращает день недели (от 1 до 7) для указанной даты. Если переданное значение не дата, возвращается null */
const getDayOfCurrentWeek = (value: string | number | Date): number | null => {
  const date = dayjs(value);

  if (!date.isValid()) return null;

  // доп условие, так как воскресенье имеет порядковый номер 0
  return date.day() || SUNDAY;
};

export default getDayOfCurrentWeek;

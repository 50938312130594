import {
  ComputedRef,
  computed,
} from 'vue';

import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';

export const useView = (planCode: ComputedRef<EBillingPlanCode | null>) => {
  const isShipperFormShown = computed(
    () => planCode.value === EBillingPlanCode.shipper || planCode.value === EBillingPlanCode.cargoOwner,
  );
  return { isShipperFormShown };
};

import * as types from './mutation-types';
import { TUserMutations } from './types';

const mutations: TUserMutations = {
  [types.SET_USER](state, user) {
    state.data = user;
  },
  [types.RESET_STATE](state) {
    state.isLoading = false;
    state.data = null;
  },
  [types.IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
};

export default mutations;

import { TBillingAvailablePlan, TBillingAvailablePlansResponse } from '@/contexts/billingContext/domain/types';
import { EBillingPlanGroup } from '@/contexts/billingContext/domain/constants';

import { TGroupedAvailablePlans } from './types';

export const getGroupedAvailablePlans = (
  availablePlans: TBillingAvailablePlansResponse,
): TGroupedAvailablePlans => {
  const groupedAvailablePlans: TGroupedAvailablePlans = {
    [EBillingPlanGroup.carrier]: [],
    [EBillingPlanGroup.freightForwarder]: [],
    [EBillingPlanGroup.shipper]: [],
    [EBillingPlanGroup.cargoOwner]: [],
  };

  availablePlans.forEach((availablePlan: TBillingAvailablePlan) => {
    if (availablePlan.planCode.includes(EBillingPlanGroup.carrier)) {
      groupedAvailablePlans[EBillingPlanGroup.carrier].push(availablePlan);
    } else if (availablePlan.planCode.includes(EBillingPlanGroup.shipper)) {
      groupedAvailablePlans[EBillingPlanGroup.shipper].push(availablePlan);
    } else if (availablePlan.planCode.includes(EBillingPlanGroup.freightForwarder)) {
      groupedAvailablePlans[EBillingPlanGroup.freightForwarder].push(availablePlan);
    } else if (availablePlan.planCode.includes(EBillingPlanGroup.cargoOwner)) {
      groupedAvailablePlans[EBillingPlanGroup.cargoOwner].push(availablePlan);
    }
  });

  return groupedAvailablePlans;
};

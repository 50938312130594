import { ROUTE_TYPE } from '@/constants';
import { canBrowseDirectionsByRole } from '@/domains/permissions/role/directions';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { getIsTenantWithCustomization } from '@/domains/getIsTenantWithCustomization';
import { hasCargoOwnerWorkplaceSubscription } from '@/domains/permissions/subscription';

import { ROUTE_NAME } from '../domain/constants';

export default [
  buildProtectedRoute({
    path: '/directions',
    name: ROUTE_NAME.directions,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        () => !getIsTenantWithCustomization(),
        hasCargoOwnerWorkplaceSubscription,
        canBrowseDirectionsByRole,
      ],
      component: () => import('@/contexts/directionsContext/views/DirectionsListView/index.vue'),
    },
  }),
];

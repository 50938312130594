import { TMethodByPart } from '@/utils/dateUtils/domains/types';

// Используется для форматирования даты в ISO когда необходимо использовать кастомную timezone
// Проблема в том, что dayjs, при использовании toISOString(), приводит дату к локальному времени, вместо этого нужно использовать format()
export const CUSTOM_ISO_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSS[Z]';

export const METHOD_BY_PART: TMethodByPart = {
  second: {
    appFormat: 'second',
    jsDate: 'getSeconds',
  },
  minute: {
    appFormat: 'minute',
    jsDate: 'getMinutes',
  },
  hour: {
    appFormat: 'hour',
    jsDate: 'getHours',
  },
  day: {
    appFormat: 'date',
    jsDate: 'getDate',
  },
  month: {
    appFormat: 'month',
    jsDate: 'getMonth',
  },
  year: {
    appFormat: 'year',
    jsDate: 'getFullYear',
  },
};

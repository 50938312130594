<template>
  <Drawer
    class="custom-drawer"
    :class="{
      'custom-drawer_flat': flat,
      'custom-drawer_with-footer': hasFooterSlot,
    }"
    :width="width || ''"
    :closable="false"
    :visible="visible"
  >
    <div v-if="tabs.length > 0" class="custom-drawer__tabs-container">
      <Tabs
        :activeKey="activeTab"
        type="card"
        tabPosition="left"
        size="small"
        :tabBarGutter="0"
        @change="$emit('changeTab', $event)"
      >
        <TabPane v-for="tab in tabs" :key="tab.key">
          <template #tab>
            <component :is="tab.icon" />
          </template>
        </TabPane>
      </Tabs>
    </div>

    <div class="custom-drawer__main">
      <div class="custom-drawer__header">
        <div class="custom-drawer__title">
          <slot name="title">
            {{ title }}
          </slot>
        </div>
        <slot name="icon">
          <button class="ant-drawer-close" @click="$emit('close')">
            <CloseSvg />
          </button>
        </slot>
      </div>
      <div class="custom-drawer__content">
        <slot />
      </div>
    </div>

    <footer
      v-if="hasFooterSlot"
      class="custom-drawer__footer"
      :class="{ 'custom-drawer__footer': footerClass }"
    >
      <slot name="footer" />
    </footer>
  </Drawer>
</template>

<script lang="ts">
import {
  Drawer,
  Tabs,
  TabPane,
} from 'ant-design-vue';
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import CloseSvg from '@/assets/svg/16x16/close.svg';
import InfoSvg from '@/assets/svg/16x16/info.svg';
import TenderSvg from '@/assets/svg/16x16/tender.svg';
import CommitmentSvg from '@/assets/svg/16x16/commitment.svg';

type TDrawerTab = {
  key: string,
  icon: string,
};

export default defineComponent({
  name: 'CustomDrawer',
  components: {
    Drawer,
    Tabs,
    TabPane,
    CloseSvg,
    InfoSvg,
    TenderSvg,
    CommitmentSvg,
  },
  props: {
    footerClass: {
      type: String,
      default: null,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    activeTab: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array as PropType<TDrawerTab[]>,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'changeTab'],
  setup(_, { slots }) {
    const hasFooterSlot = computed(() => !!slots.footer);

    return { hasFooterSlot };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { ROUTE_TYPE } from '@/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { ROUTE_NAME } from '@/contexts/selfBillingContext/domain/constants';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasCarrierWorkplaceSubscription,
} from '@/domains/permissions/subscription';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

const isSelfBillingRegistriesAvailable = () => checkFeatureIsEnabled(EExperimentalFeatures.selfBillingRegistries);

export default [
  buildProtectedRoute({
    path: '/registries',
    name: ROUTE_NAME.selfBilling,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        () => isSelfBillingRegistriesAvailable()
          && (hasCargoOwnerWorkplaceSubscription() || hasCarrierWorkplaceSubscription()),
      ],
      component: () => import('@/contexts/selfBillingContext/views/SelfBillingListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/registries/:id',
    name: ROUTE_NAME.selfBillingRegistry,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        () => isSelfBillingRegistriesAvailable()
          && (hasCargoOwnerWorkplaceSubscription() || hasCarrierWorkplaceSubscription()),
      ],
      component: () => import('@/contexts/selfBillingContext/views/SelfBillingView/index.vue'),
    },
  }),
];

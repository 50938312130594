import cloneDeep from 'lodash.clonedeep';

import store from '@/store';

const yellowStyle = 'background: gold; color: black; font-weight: bold;';

export const logCurrentTenant = () => {
  console.groupCollapsed('%c Current tenant ', yellowStyle); // eslint-disable-line no-console
  console.dir(cloneDeep(store.state.tenants.currentTenant)); // eslint-disable-line no-console
  console.groupEnd(); // eslint-disable-line no-console
};

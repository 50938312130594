import store from '@/store';

export default function useAppHeap() {
  const identify = () => {
    if (window.heap) {
      const user = store.state?.user?.data?.email || store.state?.user?.data?.id;
      if (user) {
        window.heap.identify(user);
      }
    }
  };

  return { identify };
}

<template>
  <teleport to="body">
    <div
      v-if="visible"
      ref="dropdown"
      v-click-outside="handleClickOutside"
      class="dropdown-list"
      :class="className"
      aria-labelledby="dropdownList"
      tabindex="0"
      :style="inlineStyle"
      @keydown="$emit('keydown', $event)"
      @focusout="handleBlur"
      @mousedown.stop
    >
      <div
        v-if="withSearch"
        class="dropdown-list__header"
      >
        <Input
          ref="searchInputRef"
          :value="searchValue"
          :placeholder="tt('shared.search')"
          @input="$emit('search', $event.target.value)"
          @keydown.stop
          @blur="handleBlur"
        />
      </div>
      <div
        v-if="withoutOptions"
        class="dropdown-list__content"
      >
        <slot />
      </div>
      <slot v-else-if="isNotFoundVisible" name="notFoundContent">
        <div class="dropdown-list__empty">
          <EmptySvg viewBox="0 0 210 150" />
          <p class="dropdown-list__empty-text">
            {{ notFoundContent }}
          </p>
        </div>
      </slot>
      <ul
        v-else
        v-infinite-scroll="handleUpdateList"
        class="dropdown-list__items-list"
        role="listbox"
      >
        <slot />
      </ul>
      <div
        v-if="isFooterVisible"
        class="dropdown-list__footer"
      >
        <div class="dropdown-list__footer-control_left">
          <slot name="footerControlLeft" />
        </div>
        <div class="dropdown-list__footer-control_right">
          <slot name="footerControlRight" />
        </div>
      </div>
    </div>
  </teleport>
</template>
<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import EmptySvg from '@/assets/svg/others/empty.svg';

import Input from '../Input/index.vue';
import { getInlineStyle } from './utils/getInlineStyle';

export default defineComponent({
  name: 'DropdownList',
  components: {
    Input,
    EmptySvg,
  },
  props: {
    optionsLength: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    withoutOptions: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    targetElement: {
      type: [HTMLElement, null] as PropType<HTMLElement | null>,
      default: null,
    },
    className: {
      type: [String, Array] as PropType<string | string[]>,
      default: '',
    },
    dropdownMatchSelectWidth: {
      type: [Number, Boolean] as PropType<number | boolean>,
      default: true,
    },
    notFoundContent: {
      type: String,
      default: '',
    },
    /** зафиксировать DropdownList у левого края Select'а */
    isFixedOnBottomLeft: {
      type: Boolean,
      default: false,
    },
    /** зафиксировать DropdownList сбоку от Control'a */
    isFixedOnTopRight: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
    withAutoClosing: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['keydown', 'blur', 'search', 'updateList', 'update:visible', 'visibleChange'],
  setup(props, { slots, expose, emit }) {
    const targetElementClientRect = ref<DOMRect | null>(null);
    const targetElementSubMenu = ref<DOMRect | null>(null);
    const dropdownRef = ref<HTMLDivElement>();
    const searchInputRef = ref<typeof Input | null>(null);

    const isFooterVisible = computed(() => slots.footerControlLeft || slots.footerControlRight);

    const setTargetElementClientRect = () => {
      if (props.targetElement) {
        targetElementClientRect.value = props.targetElement.getBoundingClientRect();
      }
    };

    const handleTargetElementClientRectChange = () => {
      if (!props.visible) return;
      setTargetElementClientRect();
    };

    const inlineStyle = computed(() => getInlineStyle({
      targetElementClientRect,
      targetElementSubMenu,
      optionsLength: props.optionsLength,
      dropdownMatchSelectWidth: props.dropdownMatchSelectWidth,
      isFixedOnBottomLeft: props.isFixedOnBottomLeft,
      isFixedOnTopRight: props.isFixedOnTopRight,
    }));

    const isNotFoundVisible = computed(() => !props.optionsLength);

    const handleBlur = (event: FocusEvent) => {
      // Здесь используем именно setTimeout, для того чтобы подвинуть обработчик в конец стека (дождаться всплытия
      // event до панели, если событие было произведено инпутом)
      setTimeout(() => {
        if (!dropdownRef.value?.contains(document.activeElement)) {
          emit('blur', event);
        }
      }, 0);
    };

    const focus = (options: FocusOptions) => dropdownRef.value?.focus(options);

    const focusSearch = () => {
      searchInputRef.value?.focus();
    };

    // Данная обертка нужна, чтобы в директиве v-infinite-scroll в binding.value попала ф-ция которую можно вызвать
    const handleUpdateList = () => {
      emit('updateList');
    };

    const handleClickOutside = (event: PointerEvent) => {
      if (!props.withAutoClosing) return;

      if (!(event.target instanceof Element)) return;
      const isEventTargetInsideDropdown = dropdownRef.value?.contains(event.target);
      const isActiveElementTargetElement = props.targetElement && event.target === props.targetElement;
      const isTargetElementContainEventTarget = props.targetElement?.contains(event.target);
      if (isEventTargetInsideDropdown || isActiveElementTargetElement || isTargetElementContainEventTarget) return;
      emit('update:visible', false);
      /*
        Эмит нужен для удобства: чтобы в коде, использующем компонент, не приходилось делать watch.
        Эмита visibleChange со значением true у нас нет, т.к. DropdownList сам себя открыть не может
      */
      emit('visibleChange', false);
    };

    expose({
      dropdown: dropdownRef,
      focus,
      focusSearch,
    });

    watch(dropdownRef, () => {
      if (props.isFixedOnTopRight && dropdownRef.value) {
        targetElementSubMenu.value = dropdownRef.value.getBoundingClientRect();
      }
    });

    watch(() => props.visible, handleTargetElementClientRectChange);

    onMounted(() => window.addEventListener('resize', handleTargetElementClientRectChange));

    onUnmounted(() => window.removeEventListener('resize', handleTargetElementClientRectChange));

    return {
      dropdown: dropdownRef,
      inlineStyle,
      isFooterVisible,
      isNotFoundVisible,
      searchInputRef,

      tt,
      handleBlur,
      handleUpdateList,
      focus,
      handleClickOutside,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

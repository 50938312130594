<template>
  <RestrictionLayout :description="tt('tenant.licensePaywallMessage.text')" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import RestrictionLayout from '@/components/RestrictionLayout/index.vue';

export default defineComponent({
  name: 'LicenseRestrictionLayout',
  components: { RestrictionLayout },
  setup() {
    return { tt };
  },
});
</script>

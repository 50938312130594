<template>
  <AuthLayout>
    <AppTitle :title="tt('infrastructure.title.restore')" />
    <InformationPanel v-if="isInformationPanelEnabled" />
    <main class="unlock-view">
      <div class="unlock-view__card">
        <div v-if="!isInformationPanelEnabled" class="unlock-view__logo">
          <AppLogo />
        </div>
        <div class="unlock-view__title">
          {{ tt('infrastructure.title.restore') }}
        </div>
        <Form
          ref="formRef"
          :rules="formRules"
          :model="formData"
          :layout="ELayout.vertical"
          autocomplete="off"
          @validate="onValidateField"
        >
          <div class="unlock-view__view__description-container">
            <p class="unlock-view__description">
              {{ tt("infrastructure.description.accountTemporarilyBlocked") }}
            </p>

            <p class="unlock-view__description">
              {{ tt("infrastructure.description.unlockCodeSent") }}
            </p>
          </div>

          <FormItem
            name="code"
            :label="tt('shared.codeFromTheEmail')"
          >
            <Input
              :value="formData.code"
              :size="ESize.large"
              :isInvalid="!formValidation['code']"
              @input="handleInputChange($event.target.value)"
            />
          </FormItem>

          <Button
            class="unlock-view__submit-button"
            :htmlType="EHtmlType.submit"
            :loading="isLoading"
            :type="EButtonType.primary"
            :size="ESize.large"
            :title="tt('infrastructure.action.signIn')"
            @click="handleSubmit"
          />
        </Form>
        <div class="unlock-view__link">
          <SmartLink
            href="/sign_in"
            @click="handleLogoutClick"
          >
            {{ tt("infrastructure.action.backToSignIn") }}
          </SmartLink>
        </div>
      </div>
    </main>
  </AuthLayout>
</template>

<script lang="ts">
import {
  Form,
  FormItem,
} from 'ant-design-vue';
import {
  defineComponent,
  reactive,
  ref,
} from 'vue';

import { Button, Input } from '@/ui';
import {
  EButtonType,
  ESize,
  EHtmlType,
} from '@/ui/types';
import useViewState from '@/composables/useViewState';
import { useFormHandlers } from '@/composables/useFormHandlers';
import AuthLayout from '@/components/layouts/AuthLayout/index.vue';
import tt from '@/i18n/utils/translateText';
import useStore from '@/store/useStore';
import { unlockAccount } from '@/contexts/accountingContext/services';
import SmartLink from '@/components/SmartLink/index.vue';
import { ELayout } from '@/constants';
import InformationPanel from '@/components/InformationPanel/index.vue';
import AppLogo from '@/components/AppLogo/index.vue';
import useAppTheme from '@/composables/application/useAppTheme';

export default defineComponent({
  name: 'UnlockView',
  components: {
    AppLogo,
    Button,
    SmartLink,
    AuthLayout,
    Form,
    FormItem,
    Input,
    InformationPanel,
  },
  setup() {
    const { getThemeConfig } = useAppTheme();
    const { isInformationPanelEnabled } = getThemeConfig();
    const store = useStore();

    const formRef = ref<typeof Form | null>(null);

    const formData = reactive({ code: '' });

    const rules = {
      code: [{
        required: true,
        message: tt('shared.rule.codeCorrect'),
      }],
    };

    const {
      formRules,
      formValidation,
      onControlChange,
      onValidateField,
      onError,
    } = useFormHandlers(formRef, {
      rules,
      formData,
    });

    const { isLoading, setViewStateAs } = useViewState();

    const handleLogoutClick = () => {
      store.dispatch('app/logout');
    };

    const handleInputChange = (code: string) => {
      formData.code = code;
      onControlChange({ name: 'code' });
    };

    const handleSubmit = () => {
      if (!formRef.value) return;

      formRef.value?.validate()
        .then(() => {
          setViewStateAs.loading();
          unlockAccount({ code: formData.code })
            .then(() => {
              setViewStateAs.done();
              store.dispatch('user/fetchUser')
                .then(() => store.dispatch('app/proceedAuthentication', store.getters['user/currentUser']));
            })
            .catch((error) => {
              setViewStateAs.withError();
              onError(error);
            });
        })
        .catch(() => {});
    };

    return {
      formData,
      formRef,
      formRules,
      formValidation,
      isLoading,
      isInformationPanelEnabled,
      EButtonType,
      ESize,
      EHtmlType,
      ELayout,

      tt,
      onValidateField,
      handleSubmit,
      handleInputChange,
      handleLogoutClick,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { logPermissions } from '@/domains/logPermissions';
import { logCurrentTenant } from '@/domains/logCurrentTenant';

const usePermissionLogger = () => {
  const userPermissions = { show: logPermissions };
  const currentTenant = { show: logCurrentTenant };

  // eslint-disable-next-line no-underscore-dangle
  window._logger = {
    userPermissions,
    currentTenant,
  };
};

export default usePermissionLogger;

import mutations from './mutations';
import { initialState } from './state';
import actions from './actions';

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
};

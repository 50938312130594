import {
  EPopoverPlacement,
  TPlacementData,
  TPopoverData,
} from '@/ui/types';

export const getPopoverPlacementData = (
  {
    targetElementClientRect,
    popoverClientRect,
    placement,
    align,
  }: TPopoverData,
): TPlacementData => {
  if (!targetElementClientRect.value || !popoverClientRect.value) {
    return {
      style: '',
      popoverPlacement: placement,
      withScroll: false,
    };
  }

  const MAX_TOOLTIP_HEIGHT = 500;

  let popoverPlacement = placement;

  // Параметры элемента на котором будет отображаться popover
  const targetTop = targetElementClientRect.value.top;
  const targetBottom = targetElementClientRect.value.bottom;
  const targetLeft = targetElementClientRect.value.left;
  const targetRight = targetElementClientRect.value.right;
  const targetWidth = targetElementClientRect.value.width;
  const targetHeight = targetElementClientRect.value.height;

  // Параметры  popover`а
  const popoverHeight = popoverClientRect.value.height < MAX_TOOLTIP_HEIGHT
    ? popoverClientRect.value.height : MAX_TOOLTIP_HEIGHT;
  const popoverWidth = popoverClientRect.value.width;

  let top = 0;
  let left = 0;

  // Заходит ли popover снизу за экран
  const canShowPopoverFitOnTheBottom = document.documentElement.clientHeight - targetBottom < popoverHeight;
  // Заходит ли popover сверху за экран
  const canShowPopoverFitOnTheTop = targetTop < popoverHeight;
  // Заходит ли popover слева за экран
  const canShowPopoverFitOnTheLeft = targetLeft < popoverWidth;
  // Заходит ли popover справа за экран
  const canShowPopoverFitOnTheRight = document.documentElement.clientWidth - targetRight < popoverWidth;

  // Если тултип находится по центру - то он может выглядывать с 2 сторон (слева и справа), во всех остальных случаях только с 1
  let sidesCount = 1;
  if (placement === EPopoverPlacement.top || placement === EPopoverPlacement.bottom) {
    sidesCount = 2;
  }

  // Заходит ли popover слева за экран если он расположен по центру (top / bottom)
  const isPopoverGoesBehindTheScreenFromLeft = targetLeft - ((popoverWidth - targetWidth) / sidesCount) < 0;
  // Заходит ли popover справа за экран если он расположен по центру (top / bottom)
  const isPopoverGoesBehindTheScreenFromRight = targetRight + ((popoverWidth - targetWidth) / sidesCount)
    > document.documentElement.clientWidth;

  const offsetLeft = align?.offset?.[0] || 0;
  const offsetTop = align?.offset?.[1] || 0;

  const setPopoverToTop = () => {
    top = targetTop - popoverHeight + offsetTop;
    left = targetLeft + (targetWidth / 2) - (popoverWidth / 2) + offsetLeft;
    popoverPlacement = EPopoverPlacement.top;
  };

  const setPopoverToTopLeft = () => {
    top = targetTop - popoverHeight + offsetTop;
    left = targetLeft + offsetLeft;
    popoverPlacement = EPopoverPlacement.topLeft;
  };

  const setPopoverToTopRight = () => {
    top = targetTop - popoverHeight;
    left = targetRight - popoverWidth + offsetLeft;
    popoverPlacement = EPopoverPlacement.topRight;
  };

  const setPopoverToLeft = () => {
    // Соотносим середину элемента с серединой popover`а
    top = targetTop + (targetHeight / 2) - (popoverHeight / 2);
    left = targetLeft - popoverWidth;
    popoverPlacement = EPopoverPlacement.left;
  };

  const setPopoverToLeftTop = () => {
    left = targetLeft - popoverWidth;
    top = targetTop;
    popoverPlacement = EPopoverPlacement.leftTop;
  };

  const setPopoverToLeftBottom = () => {
    left = targetLeft - popoverWidth;
    top = targetBottom - popoverHeight;
    popoverPlacement = EPopoverPlacement.leftBottom;
  };

  const setPopoverToRight = () => {
    left = targetRight;
    // Соотносим середину элемента с серединой popover`а
    top = targetTop + (targetHeight / 2) - (popoverHeight / 2);
    popoverPlacement = EPopoverPlacement.right;
  };

  const setPopoverToRightTop = () => {
    left = targetRight;
    top = targetTop;
    popoverPlacement = EPopoverPlacement.rightTop;
  };

  const setPopoverToRightBottom = () => {
    left = targetRight;
    top = targetBottom - popoverHeight;
    popoverPlacement = EPopoverPlacement.rightBottom;
  };

  const setPopoverToBottom = () => {
    top = targetBottom + offsetTop;
    // Соотносим середину элемента с серединой popover`а
    left = targetLeft + (targetWidth / 2) - (popoverWidth / 2) + offsetLeft;
    popoverPlacement = EPopoverPlacement.bottom;
  };

  const setPopoverToBottomLeft = () => {
    top = targetBottom + offsetTop;
    left = targetLeft + offsetLeft;
    popoverPlacement = EPopoverPlacement.bottomLeft;
  };

  const setPopoverToBottomRight = () => {
    top = targetBottom + offsetTop;
    left = targetRight - popoverWidth + offsetLeft;
    popoverPlacement = EPopoverPlacement.bottomRight;
  };

  const checkTopAndBottomPosition = () => {
    if (canShowPopoverFitOnTheTop) {
      setPopoverToBottom();
    } if (canShowPopoverFitOnTheBottom) {
      setPopoverToTop();
    }
  };

  const checkCenterPositionSides = () => {
    if (isPopoverGoesBehindTheScreenFromLeft) {
      setPopoverToRight();
    }
    if (isPopoverGoesBehindTheScreenFromRight) {
      setPopoverToLeft();
    }
  };

  const checkTopPosition = () => {
    if (canShowPopoverFitOnTheTop) {
      setPopoverToBottom();
    }
    checkCenterPositionSides();
  };

  const checkBottomPosition = () => {
    if (canShowPopoverFitOnTheBottom) {
      setPopoverToTop();
    }
    checkCenterPositionSides();
  };

  // Если popover выходит за пределы экрана - проставляем ему обратный placement
  switch (placement) {
    case EPopoverPlacement.top:
      setPopoverToTop();
      checkTopPosition();
      break;
    case EPopoverPlacement.topLeft:
      setPopoverToTopLeft();
      if (canShowPopoverFitOnTheTop) {
        setPopoverToBottomLeft();
      }
      checkCenterPositionSides();
      break;
    case EPopoverPlacement.topRight:
      setPopoverToTopRight();
      if (canShowPopoverFitOnTheTop) {
        setPopoverToBottomRight();
      }
      checkCenterPositionSides();
      break;
    case EPopoverPlacement.left:
      setPopoverToLeft();
      if (canShowPopoverFitOnTheLeft) {
        setPopoverToRight();
      }
      checkTopAndBottomPosition();
      break;
    case EPopoverPlacement.leftTop:
      setPopoverToLeftTop();
      if (canShowPopoverFitOnTheLeft) {
        setPopoverToRightTop();
      }
      checkTopAndBottomPosition();
      break;
    case EPopoverPlacement.leftBottom:
      setPopoverToLeftBottom();
      if (canShowPopoverFitOnTheLeft) {
        setPopoverToRightBottom();
      }
      checkTopAndBottomPosition();
      break;
    case EPopoverPlacement.right:
      setPopoverToRight();
      if (canShowPopoverFitOnTheRight) {
        setPopoverToLeft();
      }
      checkTopAndBottomPosition();
      break;
    case EPopoverPlacement.rightTop:
      setPopoverToRightTop();
      if (canShowPopoverFitOnTheRight) {
        setPopoverToLeftTop();
      }
      checkTopAndBottomPosition();
      break;
    case EPopoverPlacement.rightBottom:
      setPopoverToRightBottom();
      if (canShowPopoverFitOnTheRight) {
        setPopoverToLeftBottom();
      }
      checkTopAndBottomPosition();
      break;
    case EPopoverPlacement.bottom:
      setPopoverToBottom();
      checkBottomPosition();
      break;
    case EPopoverPlacement.bottomLeft:
      setPopoverToBottomLeft();
      if (canShowPopoverFitOnTheTop) {
        setPopoverToTopLeft();
      }
      checkCenterPositionSides();
      break;
    case EPopoverPlacement.bottomRight:
      setPopoverToBottomRight();
      if (canShowPopoverFitOnTheTop) {
        setPopoverToTopRight();
      }
      checkCenterPositionSides();
      break;
    default:
      return {
        style: '',
        popoverPlacement: placement,
        withScroll: false,
      };
  }

  return {
    style: `top: ${top}px; left: ${left}px;`,
    popoverPlacement,
    withScroll: popoverHeight >= MAX_TOOLTIP_HEIGHT,
  };
};

import { apiClient } from '@/api';
import { refreshAccessToken } from '@/contexts/accountingContext/services';
import loggerInstance, { turnOffLogger } from '@/logger';
import store from '@/store';
import { showErrorNotification } from '@/utils';
import { getNowMs, getMsFromDate } from '@/utils/dateUtils';
import { SET_TOKENS } from '@/store/modules/app/mutation-types';
import { RESET_AUTH_STATE } from '@/store/modules/app/action-types';
import { TDomainError } from '@/types';
import { TSessionWithTokensSerialized } from '@/contexts/accountingContext/domain/types';
import { ONE_SECOND, SECONDS_IN_MINUTE } from '@/constants';
import tt from '@/i18n/utils/translateText';

import { EErrorEvents } from './constants';
import {
  TReachedResponse,
  TOriginalRequestOptions,
} from './types';

const logger = import.meta.env.VITE_APP_SEND_API_REQUEST_LOGGER_ENABLED === 'true'
  ? loggerInstance
  : turnOffLogger(loggerInstance);

const handleResponseError = (error: any) => {
  if (!error?.errorEvent) throw error;

  switch (error.errorEvent) {
    case EErrorEvents.authenticationFailed: {
      store.dispatch(`app/${RESET_AUTH_STATE}`);
      throw error;
    }
    case EErrorEvents.maintenanceMode:
      store.dispatch('app/setMaintenanceMode');
      throw error;
    case EErrorEvents.unexpectedError:
      logger.warn('[sendApiRequest] Unexpected error');
      throw error;
    case EErrorEvents.applicationError:
      throw error.error;
    default:
      throw error;
  }
};

let isAccessTokenRefreshing = false; // Флаг для отслеживания обновления токена
let subscribers: Array<(token: TSessionWithTokensSerialized) => void> = []; // Очередь ожидающих запросов

// Функция для подписки на обновление токена
const subscribeTokenRefresh = (callback: (token: TSessionWithTokensSerialized) => void) => {
  subscribers.push(callback);
};

// Функция для выполнения всех ожидающих запросов с новым access-токеном
const applyPendedSubscribers = (token: TSessionWithTokensSerialized) => {
  subscribers.forEach((callback: (token: TSessionWithTokensSerialized) => void) => callback(token));
  subscribers = [];
};

export const getActualAuthToken = async (withoutTokens: boolean = false) => {
  if (withoutTokens) {
    return Promise.resolve({});
  }

  const authTokens = store.state.app;
  const {
    accessTokenExpiresAt,
    refreshTokenExpiresAt,
    refreshToken,
  } = authTokens;

  const accessTokenExpiresAtMs = accessTokenExpiresAt ? getMsFromDate(accessTokenExpiresAt) : 0;
  const refreshTokenExpiresAtMs = refreshTokenExpiresAt ? getMsFromDate(refreshTokenExpiresAt) : 0;

  /** Добавляем 1 минуту (60000 мс), чтобы заранее обновлять токены в случае, когда очередь запросов забивается
   и бек не успевает обработать вовремя смену токена */
  const now = getNowMs() + ONE_SECOND * SECONDS_IN_MINUTE;

  // Протух refresh-токен или его нет в сторе
  if (now > refreshTokenExpiresAtMs || !refreshToken) {
    logger.warn('[sendApiRequest] Refresh token is expired or does not exist, set application state to unauthenticated...');
    store.dispatch(`app/${RESET_AUTH_STATE}`);
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      internal: true,
      reason: !refreshToken ? tt('shared.api.error.noRefreshToken') : tt('shared.api.error.tokensExpired'),
    });
  }

  // Протух access-токен -> идем получать новый
  if (now > accessTokenExpiresAtMs) {
    logger.warn('[sendApiRequest] Access token is expired, let\'s exchange token to new one...');

    // Если токен уже обновляется, добавляем запрос в очередь
    if (isAccessTokenRefreshing) {
      return new Promise((resolve) => {
        subscribeTokenRefresh((newToken: TSessionWithTokensSerialized) => {
          resolve(newToken); // Продолжаем запрос с новым токеном
        });
      });
    }

    // Если токен не обновляется, начинаем обновление
    isAccessTokenRefreshing = true;

    try {
      const newAuthTokens = await refreshAccessToken({ refreshToken });
      if (newAuthTokens) {
        store.commit(`app/${SET_TOKENS}`, newAuthTokens);
        // Выполняем все запросы, ожидавшие обновления
        applyPendedSubscribers(newAuthTokens);
      }
    } catch (error: any) {
      logger.warn(`[sendApiRequest] Error while trying to refresh token with errorEvent = ${error?.errorEvent}.`);
      subscribers = [];
      return Promise.reject(error);
    } finally {
      isAccessTokenRefreshing = false;
    }
  }

  return Promise.resolve(authTokens);
};

const mixinCurrentTenant = (request: any) => {
  if (!request.endpoint.includes(':tenant_id')) return request;

  const isAuthByEntryCode = store.getters['app/isAuthByEntryCode'];
  const tenantId = isAuthByEntryCode ? store.getters['app/decodedJWT']?.tenant_id : store.getters['tenants/currentTenantId'];

  if (!tenantId) {
    throw new Error('No tenantId found');
  }

  return {
    ...request,
    endpoint: request.endpoint.replace(':tenant_id', tenantId),
  };
};

export const sendUsingInstance = (
  apiInstance: typeof apiClient.internal | typeof apiClient.admin,
) => <T = any>(originalRequest: TOriginalRequestOptions): Promise<TReachedResponse<T>> => {
  const sendRequest = (request: TOriginalRequestOptions) => apiInstance
    .sendRequest<T>(request)
    .catch(handleResponseError);

  const handleCommonError = (catchError: any) => {
    const {
      message = '',
      errors = '',
      code = '',
      meta = null,
    } = catchError?.response?.data || {};
    const { status = null } = catchError?.response || {};
    const newError: TDomainError = {
      status,
      message,
      errors,
      code,
      meta,
    };
    // для обратной совместимости со старыми requestOptions
    if (originalRequest.requestOptions?.showError) {
      showErrorNotification({
        options: originalRequest.requestOptions,
        ...newError,
      });
    }
    if (catchError?.internal) throw new Error(catchError.reason);
    throw catchError?.response ? newError : catchError;
  };

  return getActualAuthToken(originalRequest.withoutAuth)
    .then((authTokens) => ({
      ...originalRequest,
      authTokens,
    }))
    .then(mixinCurrentTenant)
    .then(sendRequest)
    .catch(handleCommonError);
};

export const sendApiRequest = sendUsingInstance(apiClient.internal);
export const sendAdminApiRequest = sendUsingInstance(apiClient.admin);

import useStore from '@/store/useStore';
import { ONE_SECOND } from '@/constants';
import { setAppTitle } from '@/utils';
import tt from '@/i18n/utils/translateText';

const toggleAppTitleWithNotifications = () => {
  const store = useStore();
  let intervalId: number | null = null;
  let isDefaultTitleVisible = true;
  let defaultTitle: string | null = null;

  const getTitleWithNotifications = () => {
    if (!store.state.notifications.unreadCount) return tt('notification.new');
    return tt('notification.amountOfNew', { param: { amount: store.state.notifications.unreadCount } });
  };

  const stop = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    if (defaultTitle) {
      setAppTitle(defaultTitle);
    }
  };

  const toggleTitle = () => {
    const titleWithNotifications = getTitleWithNotifications();
    const title = isDefaultTitleVisible
      ? defaultTitle
      : titleWithNotifications;

    if (title) {
      setAppTitle(title);
    }
    isDefaultTitleVisible = !isDefaultTitleVisible;
  };

  const start = () => {
    defaultTitle = document.title;

    if (intervalId) {
      stop();
    }

    // @ts-ignore
    intervalId = setInterval(toggleTitle, ONE_SECOND);
    toggleTitle();
  };

  return {
    start,
    stop,
  };
};

export default toggleAppTitleWithNotifications;

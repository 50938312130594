export enum EErrorEvents {
  applicationError = 'applicationError',
  authenticationFailed = 'authenticationFailed',
  maintenanceMode = 'maintenanceMode',
  unexpectedError = 'unexpectedError',
  badGatewayError = 'badGatewayError',
  intervalServerError = 'intervalServerError',
}

export const httpStatus = {
  /** Запрос успешно выполнен */
  OK: {
    id: 'OK',
    message: 'Ok',
    details: 'The request has succeeded',
    code: 200,
  },
  /** Запрос успешно выполнен, создан новый ресурс, тело ответа содержит представление. Использовать для POST-запросов */
  CREATED: {
    id: 'CREATED',
    message: 'Created',
    details: 'The request has been fulfilled and has resulted in one or more new resources being created',
    code: 201,
  },
  /** Запрос успешно выполнен, но тела ответа нет */
  NO_CONTENT: {
    id: 'NO_CONTENT',
    message: 'No content',
    details: 'The request has been fulfilled but the response body has no additional content',
    code: 204,
  },
  /** Запрошенный ресурс был окончательно перенесен на URI, указанный в заголовке Location */
  MOVED_PERMANENTLY: {
    id: 'MOVED_PERMANENTLY',
    message: 'Moved permanently',
    details: 'The requested resource has been changed permanently. The new URL is given in the response',
    code: 301,
  },
  /** Запрошенный ресурс временно перенесен на URI, указанный в заголовке Location */
  FOUND: {
    id: 'FOUND',
    message: 'Found',
    details: 'The requested resource has been changed temporarily. The new URL is given in the response',
    code: 302,
  },
  /** Кэшированная версия ресурса */
  NOT_MODIFIED: {
    id: 'NOT_MODIFIED',
    message: 'Not Modified',
    details: 'The response has not been modified, so continue using the cached version of resource',
    code: 304,
  },
  /** Запрос не может быть обработан, указаны некорректные параметры */
  BAD_REQUEST: {
    id: 'BAD_REQUEST',
    message: 'Bad request',
    details: 'The request specifies an invalid parameter',
    code: 400,
  },

  INVALID_LOGIN_ERROR: {
    status: 400,
    code: 'accountInvalidData',
    message: 'Invalid login data',
    errors: { login: ['Invalid login data'] },
    meta: {
      field: 'login',
      meta: null,
      errorPath: null,
      fullMessages: false,
    },
  },
  /** Для доступа к ресурсу недостаточно учетных данных, требуется аутентификация */
  UNAUTHORIZED: {
    id: 'UNAUTHORIZED',
    message: 'Unauthenticated',
    details: 'The operation requires authentication',
    code: 401,
  },
  /** Для доступа к ресурсу недостаточно полномочий, требуется авторизация. Для скрытых ресурсов лучше отдавать 404 */
  FORBIDDEN: {
    id: 'FORBIDDEN',
    message: 'Permission denied',
    details: 'The request does not have sufficient permission',
    code: 403,
  },
  /** Запрашиваемый ресурс не найден */
  NOT_FOUND: {
    id: 'NOT_FOUND',
    message: 'Not found',
    details: 'The requested resource not found',
    code: 404,
  },
  /** Запрос не может быть выполнен из-за конфликтного обращения к ресурсу, например, параллельного чтения и записи */
  CONFLICT: {
    id: 'CONFLICT',
    message: 'Aborted',
    details: 'Concurrency conflict, such as read-modify-write conflict',
    code: 409,
  },
  /** Запрошенный ресурс был удален и больше недоступен */
  GONE: {
    id: 'GONE',
    message: 'No longer available',
    details: 'The requested resource was deleted and is no longer available',
    code: 410,
  },
  /** Не поддерживаемый контент в теле запроса */
  UNPROCESSABLE_ENTITY: {
    id: 'UNPROCESSABLE_ENTITY',
    message: 'Unprocessable Entity',
    details: 'Unable to process the request because it contains invalid data',
    code: 422,
  },
  /** Превышен лимит запросов. Ответ может содержать заголовок Retry-After, указывающий, сколько ждать перед новым запросом */
  TOO_MANY_REQUESTS: {
    id: 'TOO_MANY_REQUESTS',
    message: 'Resource exhausted',
    details: 'The request limit exceeded',
    code: 429,
  },
  /** Клиент закрыл соединение */
  CLIENT_CLOSED_REQUEST: {
    id: 'CLIENT_CLOSED_REQUEST',
    message: 'Cancelled',
    details: 'The request cancelled by the client',
    code: 499,
  },
  /** Внутрення ошибка сервера, которая помешала выполнить запрос */
  INTERNAL_SERVER_ERROR: {
    id: 'INTERNAL_SERVER_ERROR',
    message: 'Internal server error',
    details: 'The server encountered an unexpected condition that prevented it from fulfilling the request',
    code: 500,
  },
  /** Ошибка сервера, которая указывает, что сервер, действуя как шлюз или прокси, получил неверный ответ от восходящего сервера. */
  BAD_GATEWAY_ERROR: {
    id: 'BAD_GATEWAY_SERVER_ERROR',
    message: 'Bad Gateway',
    details: 'The server error response code indicates that the server, while acting as a gateway or proxy, received an invalid response from the upstream server.',
    code: 502,
  },
  /** Cервис временно недоступен. Пожалуйста, подождите немного и повторить запрос */
  SERVICE_UNAVAILABLE: {
    id: 'SERVICE_UNAVAILABLE',
    message: 'Service unavailable',
    details: 'The service is currently unavailable. Try again in a few minutes',
    code: 503,
  },
};

<template>
  <div
    ref="targetElement"
    class="tooltip"
    @mouseover="handleTargetElementMouseover"
    @mouseleave="handleTargetElementMouseleave"
  >
    <span v-if="showSpanWrapper">
      <slot />
    </span>
    <slot v-else />
    <teleport to="body">
      <div
        v-if="isTooltipVisible"
        ref="tooltip"
        class="tooltip__block"
        :class="`tooltip__block_${tooltipPlacement}`"
        :style="inlineStyle"
        @mouseover="handleTooltipMouseover"
        @mouseleave="handleTooltipMouseleave"
      >
        <div
          class="tooltip__content"
          :class="contentClasses"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  ref,
  watch,
} from 'vue';

import { useHasSlot } from '@/composables/useHasSlot';

import { TTooltipAlign, EPopoverPlacement } from '../types';
import { getPopoverPlacementData } from '../utils/getPopoverPlacementData';

export default defineComponent({
  name: 'Tooltip',
  props: {
    title: {
      type: String,
      default: '',
    },
    placement: {
      type: String as PropType<EPopoverPlacement>,
      default: EPopoverPlacement.top,
    },
    overlayClassName: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: true,
    },
    align: {
      type: Object as PropType<TTooltipAlign>,
      default: () => {},
    },
  },
  emits: ['visibleChange'],
  setup(props, { emit, slots }) {
    const isTooltipHover = ref<boolean>(false);
    const isTextHover = ref<boolean>(false);
    const targetElement = ref<HTMLDivElement | null>(null);
    const tooltip = ref<HTMLDivElement | null>(null);
    const popoverClientRect = ref<DOMRect | null>(null);
    const targetElementClientRect = ref<DOMRect | null>(null);
    const tooltipPlacement = ref<EPopoverPlacement>(props.placement);
    const contentWithScroll = ref<boolean>(false);
    const inlineStyle = ref<string>('');
    const showSpanWrapper = ref<boolean>(false);

    const hasTitleContent = useHasSlot(slots, 'title');

    const isTooltipVisible = computed(() => (isTooltipHover.value || isTextHover.value)
      && props.visible && (!!props.title || hasTitleContent.value));

    const contentClasses = computed(() => [props.overlayClassName, contentWithScroll.value ? 'tooltip__content_with-scroll' : '']);

    const handleTargetElementMouseover = () => {
      isTextHover.value = true;
    };

    const handleTargetElementMouseleave = () => {
      // setTimeout нужен для того, чтобы handleTooltipMouseover успел отработать
      // кейс когда пользователь переводит мышку с элемента на tooltip
      setTimeout(() => {
        isTextHover.value = false;
        // Убираем span обертку т.к. контент внутри слота может поменяться к следующему отображению тултипа
        showSpanWrapper.value = false;
        if (!isTooltipHover.value) {
          inlineStyle.value = '';
          contentWithScroll.value = false;
        }
      }, 0);
    };

    const handleTooltipMouseover = () => {
      isTooltipHover.value = true;
    };

    const handleTooltipMouseleave = () => {
      // setTimeout нужен для того, чтобы handleTargetElementMouseover успел отработать
      // кейс когда пользователь переводит мышку с tooltip`а на элемент
      setTimeout(() => {
        isTooltipHover.value = false;
        if (!isTextHover.value) {
          inlineStyle.value = '';
          contentWithScroll.value = false;
        }
      }, 0);
    };

    const setTargetElementClientRect = () => {
      if (!targetElement.value) return;
      // Если в слот передан только 1 дочерний html элемент - то берем его rect
      // Это нужно для того, чтобы правильно отобразить тултип если у дочернего элемента есть свои отсупы
      targetElementClientRect.value = targetElement.value?.children.length === 1
        ? targetElement.value.children[0].getBoundingClientRect()
        : targetElement.value.getBoundingClientRect();
      // Дожидаемся когда tooltip появится в DOM-дереве
      nextTick().then(() => {
        if (!tooltip.value) return;
        popoverClientRect.value = tooltip.value.getBoundingClientRect();
        const {
          style,
          popoverPlacement,
          withScroll,
        } = getPopoverPlacementData({
          targetElementClientRect,
          popoverClientRect,
          placement: props.placement,
          align: props.align,
        });
        inlineStyle.value = style;
        tooltipPlacement.value = popoverPlacement;
        contentWithScroll.value = withScroll;
      });
    };

    watch(() => isTooltipVisible.value, (visible: boolean) => {
      emit('visibleChange', visible);
      if (visible) {
        // Если в слот передан текст - отображем span обертку для него
        if (targetElement.value?.children.length === 0) {
          showSpanWrapper.value = true;
          // Дожидаемся пока span обертка рендерится
          nextTick(setTargetElementClientRect);
        } else {
          setTargetElementClientRect();
        }
      }
    });

    return {
      targetElement,
      tooltip,
      inlineStyle,
      tooltipPlacement,
      isTooltipVisible,
      contentClasses,
      showSpanWrapper,

      handleTargetElementMouseover,
      handleTargetElementMouseleave,
      handleTooltipMouseover,
      handleTooltipMouseleave,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

// Назначение API-клиента – правильная упаковка вызовов под конкретную версию внутреннего API.
import logger from '@/logger';

import { getAxiosInstance } from './commonApiClient';

const baseApiHost = import.meta.env.VITE_APP_INTERNAL_API_HOST || null;
const baseApiVersion = import.meta.env.VITE_APP_INTERNAL_API_VERSION || null;

const buildBaseURL = (host: string, version: string) => {
  if (!host) {
    logger.error('[Admin API] Please specify admin API host using VITE_APP_INTERNAL_API_HOST env variable');
    return null;
  }

  if (!version) {
    logger.error('[Admin API] Please specify admin API version using VITE_APP_INTERNAL_API_VERSION env variable');
    return null;
  }

  // В будущем способ построения ссылки будет зависеть от версии API
  return [host, 'admin', version].filter((item) => !!item).join('/');
};

const result = getAxiosInstance(() => buildBaseURL(baseApiHost, baseApiVersion));
export default result;

// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';
import parseDateToAppFormat from '@/utils/dateUtils/parsing/parseDateToAppFormat';

const isToday = (date: string, timeZone?: string) => {
  const dateInAppFormat = parseDateToAppFormat(date, '', timeZone);
  const now = parseDateToAppFormat(timeZone ? dayjs() : new Date(), '', timeZone);
  return timeZone ? dayjs.tz(dateInAppFormat, timeZone).isSame(now, 'day') : dayjs(dateInAppFormat).isSame(now, 'day');
};

export default isToday;

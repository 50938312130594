<template>
  <div class="notification">
    <Tooltip>
      <template #title>
        {{ indicatorText }}
      </template>
      <Button
        class="notification__indicator"
        type="link"
        :disabled="isIndicatorDisabled"
        @click="handleIndicatorClick"
      >
        <div
          class="notification__indicator-dot"
          :class="`notification__indicator-dot_${indicator}`"
        />
      </Button>
    </Tooltip>
    <div class="notification__content">
      <p class="notification__time">
        {{ time }}
      </p>
      <span>{{ notificationMessage }}</span>

      <template v-if="notificationAdditionalInfo?.title && notificationAdditionalInfo?.description">
        <div
          class="notification__dropdown"
          @click="toggleAdditionalInfoDropdown"
        >
          <span>{{ notificationAdditionalInfo.title }}</span>
          <ChevronDownSvg :class="{ 'notification__dropdown_open': isAdditionalInfoVisible }" />
        </div>
        <span v-if="isAdditionalInfoVisible" class="notification__dropdown-content">
          {{ notificationAdditionalInfo.description }}
        </span>
      </template>

      <div class="notification__row">
        <template
          v-for="(action, index) in notificationActions"
          :key="index"
        >
          <Button
            type="link"
            class="notification__action"
            @click="handleNotificationActionClick(action)"
          >
            {{ action.text }}
          </Button>
          <div
            v-if="index < notificationActions?.length - 1"
            class="notification__action-dot"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Button } from 'ant-design-vue';
import {
  defineComponent,
  onMounted,
  PropType,
  toRef,
} from 'vue';

import { Tooltip } from '@/ui';
import { TNotification } from '@/contexts/notificationsContext/domain/types';
import { isToday } from '@/utils/dateUtils';
import tt from '@/i18n/utils/translateText';
import ChevronDownSvg from '@/assets/svg/16x16/chevron-down.svg';

import getActionPath from './domain/getActionPath';
import { useView } from './application/useView';
import { useInteractWithNotification } from './application/useInteractWithNotification';

export default defineComponent({
  name: 'Notification',
  components: {
    Button,
    Tooltip,
    ChevronDownSvg,
  },
  props: {
    notification: {
      type: Object as PropType<TNotification>,
      required: true,
    },
  },
  setup(props) {
    const {
      isAdditionalInfoVisible,
      notificationMessage,
      notificationActions,
      notificationAdditionalInfo,
      indicator,
      indicatorText,
      time,

      toggleAdditionalInfoDropdown,
      setIsNotificationWasReceivedToday,
    } = useView(toRef(props, 'notification'));

    const {
      isIndicatorDisabled,

      handleIndicatorClick,
      handleNotificationActionClick,
    } = useInteractWithNotification(toRef(props, 'notification'));

    onMounted(() => {
      setIsNotificationWasReceivedToday(isToday(props.notification.createdAt));
    });

    return {
      indicator,
      indicatorText,
      time,
      isIndicatorDisabled,
      notificationMessage,
      notificationActions,
      isAdditionalInfoVisible,
      notificationAdditionalInfo,

      getActionPath,
      handleNotificationActionClick,
      handleIndicatorClick,
      toggleAdditionalInfoDropdown,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

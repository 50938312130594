<template>
  <Layout class="app-table-layout">
    <div class="app-table-layout__container">
      <div
        v-if="hasPageHeader"
        class="app-table-layout__page-header"
      >
        <slot name="pageHeader" />
      </div>

      <div class="app-table-layout__content-wrapper">
        <LayoutContent
          class="app-table-layout__content"
          :class="{ [className]: className }"
        >
          <slot />
        </LayoutContent>
        <div
          v-if="hasRightPanel"
          class="app-table-layout__right-panel"
        >
          <slot name="rightPanel" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script lang="ts">
import { Layout, LayoutContent } from 'ant-design-vue';
import {
  computed,
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'AppTableLayout',
  components: {
    Layout,
    LayoutContent,
  },
  props: {
    className: {
      type: String,
      default: null,
    },
  },
  setup(_, { slots }) {
    const hasPageHeader = computed(() => !!slots.pageHeader);
    const hasRightPanel = computed(() => !!slots.rightPanel);

    return {
      hasPageHeader,
      hasRightPanel,
    };
  },
});
</script>

<style lang="scss" scoped src="./styles.scss" />

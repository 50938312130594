import type { TInternalRequestConfig } from '@/api/types';

export const abortSignalCache: Record<string, AbortController> = {};

export const clearAbortSignalCache = () => {
  Object.keys(abortSignalCache).forEach((key) => {
    delete abortSignalCache[key];
  });
};

export const removeAbortSignal = (signalName?: string) => {
  if (signalName) {
    delete abortSignalCache[signalName];
  }
};

const abortSignalByName = (signalName?: string) => {
  if (signalName && abortSignalCache[signalName]) {
    abortSignalCache[signalName].abort();
  }
};

const addNewAbortSignal = (signalName: string): AbortController => {
  const newAbortController = new AbortController();
  abortSignalCache[signalName] = newAbortController;
  return newAbortController;
};

export const prepareAbortSignal = (request: TInternalRequestConfig): AbortSignal | null => {
  abortSignalByName(request.signalName);
  return request.signalName ? addNewAbortSignal(request.signalName).signal : null;
};

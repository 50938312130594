import isObject from '@/utils/isObject';

const parseJson = (json: any) => {
  if (!json) return {};
  if (isObject(json)) return json;

  try {
    const result = JSON.parse(json);
    return result;
  } catch {
    return {};
  }
};

export default parseJson;

import tt from '@/i18n/utils/translateText';
import { EStatusType } from '@/domains/constants';

export const ROUTE_NAME = {
  operationalDocumentsList: 'operationalDocumentsList',
  operationalDocumentsConstructor: 'operationalDocumentsConstructor',
};

export enum EDocumentsStatus { // Енам статусов документов
  draft = 'DRAFT', // Черновик
  awaitingConfirmation = 'AWAITING_CONFIRMATION', // Ожидает подтверждения
  awaitingForSign = 'AWAITING_FOR_SIGN', // Ожидается подпись
  sent = 'SENT', // Отправлен
  yourSignRequired = 'YOUR_SIGN_REQUIRED', // Требуется Ваша подпись
  accepted = 'ACCEPTED', // Принят
  signedByAll = 'SIGNED_BY_ALL', // Подписан всеми сторонами
  youProposedCancellation = 'YOU_PROPOSED_CANCELLATION', // Вы предложили аннулирование
  contractorProposedCancellation = 'CONTRACTOR_PROPOSED_CANCELLATION', // Вам предложили аннулирование
  cancelled = 'CANCELLED', // Аннулирован
  youRejectedDocument = 'YOU_REJECTED_DOCUMENT', // Вы отклонили документ
  rejected = 'REJECTED', // Отклонен
}

export enum EDocumentStepTitle {
  contract = 'CONTRACT',
  other = 'OTHER',
  cargoTransfer = 'CARGO_TRANSFER',
  cargoAcceptance = 'CARGO_ACCEPTANCE',
  cargoReception = 'CARGO_RECEPTION',
  cargoDelivery = 'CARGO_DELIVERY',
  cargoForwarding = 'CARGO_FORWARDING',
  cargoRelay = 'CARGO_RELAY',
  resign = 'RESIGN',
}

export enum EDocumentStepType {
  error = 'ERROR',
  title = 'TITLE',
  postConfirmation = 'POST_CONFIRMATION',
  sendConfirmation = 'SEND_CONFIRMATION',
  receiveNotice = 'RECEIVE_NOTICE',
  titleReject = 'TITLE_REJECT',
  cancellationOffer = 'CANCELLATION_OFFER',
  cancellationRejection = 'CANCELLATION_REJECTION',
  cancellationAcception = 'CANCELLATION_ACCEPTION',
  correctionNotice = 'CORRECTION_NOTICE',
}

export enum EDocumentStepState {
  prepared = 'PREPARED',
  processing = 'PROCESSING',
  finished = 'FINISHED',
  error = 'ERROR',
  draft = 'DRAFT',
}

export enum EDocumentsCustomStatus {
  signedByShipper = 'SIGNED_BY_SHIPPER',
  signedByCarrierOnLoading = 'SIGNED_BY_CARRIER_ON_LOADING',
  signedByConsignee = 'SIGNED_BY_CONSIGNEE',
  signedByCarrierOnUnloading = 'SIGNED_BY_CARRIER_ON_UNLOADING',
  signedCargoForwarding = 'SIGNED_CARGO_FORWARDING',
  signedCargoRelay = 'SIGNED_CARGO_RELAY',
}

export enum EGetEdmAttachedFileRole {
  main = 'MAIN',
  additional = 'ADDITIONAL',
  printable = 'PRINTABLE',
  full = 'FULL',
  qr = 'QRCODE',
}

export enum EDocumentParticipantRole {
  shipper = 'SHIPPER',
  carrier = 'CARRIER',
  consignee = 'CONSIGNEE',
  sender = 'SENDER',
  receiver = 'RECEIVER',
}

export enum EEdmNonformalizedDocumentType {
  carriageContract = 'CARRIAGE_CONTRACT', // Договор перевозки
  freightExpeditionContract = 'FREIGHT_EXPEDITION_CONTRACT', // Договор транспортной экспедиции
  additionalAgreement = 'ADDITIONAL_AGREEMENT', // Доп. соглашение (к договору)
  orderOneTimeContract = 'ORDER_ONE_TIME_CONTRACT', // Заказ (разовая договор-заявка)
  carriageApplication = 'CARRIAGE_APPLICATION', // Заявка на перевозку
  driverPowerAttorney = 'DRIVER_POWER_ATTORNEY', // Доверенность на водителя
  forwarderInstruction = 'FORWARDER_INSTRUCTION', // Поручение экспедитору
  forwardingReceipt = 'FORWARDING_RECEIPT', // Экспедиторская расписка
  agencyAgreement = 'AGENCY_AGREEMENT', // Агентский договор
  driverEmploymentContract = 'DRIVER_EMPLOYMENT_CONTRACT', // Трудовой договор с водителем
  gphContract = 'GPH_CONTRACT', // Договор ГПХ
  leasingCompanyAgreement = 'LEASING_COMPANY_AGREEMENT', // Договор с лизинговой компанией
  gratuitousUseContract = 'GRATUITOUS_USE_CONTRACT', // Договор безвозмездного использования
  leaseWithoutCrew = 'LEASE_WITHOUT_CREW', // Договор аренды без экипажа
  leaseWithCrew = 'LEASE_WITH_CREW', // Договор аренды с экипажем
  forwarderReport = 'FORWARDER_REPORT', // Отчет экспедитора
  transportationRegister = 'TRANSPORTATION_REGISTER', // Реестр перевозок
  paymentInvoice = 'PAYMENT_INVOICE', // Счет на оплату
  multiStatements = 'MULTI_STATEMENTS', // Акт о приемке вып. работ (на несколько перевозок)
  act = 'ACT', // Акт (коммерческий)
  torg12 = 'TORG12', // Товарная накладная (ТОРГ 12)
  other = 'OTHER', // Другое
}

export enum EEdmNonformalizedDocumentСustomer {
  carriageContract = 'CARRIAGE_CONTRACT', // Договор перевозки
  agencyAgreement = 'AGENCY_AGREEMENT', // Агентский договор
  freightExpeditionContract = 'FREIGHT_EXPEDITION_CONTRACT', // Договор транспортной экспедиции
  additionalAgreement = 'ADDITIONAL_AGREEMENT', // Доп. соглашение (к договору)
  orderOneTimeContract = 'ORDER_ONE_TIME_CONTRACT', // Заказ (разовая договор-заявка)
  carriageApplication = 'CARRIAGE_APPLICATION', // Заявка на перевозку
  forwarderInstruction = 'FORWARDER_INSTRUCTION', // Поручение экспедитору
  act = 'ACT', // Акт (коммерческий)
  torg12 = 'TORG12', // Товарная накладная (ТОРГ 12)
  other = 'OTHER', // Другое
}

export enum EEdmNonformalizedDocumentExecutor {
  agencyAgreement = 'AGENCY_AGREEMENT', // Агентский договор
  carriageContract = 'CARRIAGE_CONTRACT', // Договор перевозки
  freightExpeditionContract = 'FREIGHT_EXPEDITION_CONTRACT', // Договор транспортной экспедиции
  additionalAgreement = 'ADDITIONAL_AGREEMENT', // Доп. соглашение (к договору)
  driverEmploymentContract = 'DRIVER_EMPLOYMENT_CONTRACT', // Трудовой договор с водителем
  gphContract = 'GPH_CONTRACT', // Договор ГПХ
  leasingCompanyAgreement = 'LEASING_COMPANY_AGREEMENT', // Договор с лизинговой компанией
  gratuitousUseContract = 'GRATUITOUS_USE_CONTRACT', // Договор безвозмездного использования
  leaseWithoutCrew = 'LEASE_WITHOUT_CREW', // Договор аренды без экипажа
  leaseWithCrew = 'LEASE_WITH_CREW', // Договор аренды с экипажем
  orderOneTimeContract = 'ORDER_ONE_TIME_CONTRACT', // Заказ (разовая договор-заявка)
  carriageApplication = 'CARRIAGE_APPLICATION', // Заявка на перевозку
  forwardingReceipt = 'FORWARDING_RECEIPT', // Экспедиторская расписка
  forwarderReport = 'FORWARDER_REPORT', // Отчет экспедитора
  driverPowerAttorney = 'DRIVER_POWER_ATTORNEY', // Доверенность на водителя
  act = 'ACT', // Акт (коммерческий)
  other = 'OTHER', // Другое
  paymentInvoice = 'PAYMENT_INVOICE', // Счет на оплату
  transportationRegister = 'TRANSPORTATION_REGISTER', // Реестр перевозок
}

export enum EEdmFormalizedDocumentType {
  epd = 'EPD', // ЭТрН
  invoice = 'INVOICE', // Счет-фактура
  universalInvoice = 'UNIVERSAL_INVOICE', // УПД
  universalStatement = 'UNIVERSAL_STATEMENT', // Акт об отгрузке товаров
  statement = 'STATEMENT', // Акт выполненных работ
}

export const EDM_ADDITIONAL_FILE_WEIGHT_IN_MB = 35;

export enum EEdmDocumentStatusModifier {
  error = 'ERROR',
  empty = 'EMPTY',
  cargoPassedToCarrier = 'CARGO_PASSED_TO_CARRIER',
  cargoAcceptedByCarrier = 'CARGO_ACCEPTED_BY_CARRIER',
  cargoAcceptedByConsignee = 'CARGO_ACCEPTED_BY_CONSIGNEE',
  cargoDeliveredByCarrier = 'CARGO_DELIVERED_BY_CARRIER',
  costDeclaration = 'COST_DECLARATION',
  costConfirmation = 'COST_CONFIRMATION',
  rerouting = 'REROUTING',
  driverChange = 'DRIVER_CHANGE',
  cargoPassedToCarrierGen = 'CARGO_PASSED_TO_CARRIER_GEN',
  cargoAcceptedByCarrierGen = 'CARGO_ACCEPTED_BY_CARRIER_GEN',
  cargoAcceptedByReceiverGen = 'CARGO_ACCEPTED_BY_RECEIVER_GEN',
  cargoDeliveredByCarrierGen = 'CARGO_DELIVERED_BY_CARRIER_GEN',
  partnerGen = 'PARTNER_GEN',
  partnerIns = 'PARTNER_INS',
  consigneeIns = 'CONSIGNEE_INS',
  carrierIns = 'CARRIER_INS',
  shipperIns = 'SHIPPER_INS',
  carrierOnLoadGen = 'CARRIER_ON_LOAD_GEN',
  carrierOnLoadIns = 'CARRIER_ON_LOAD_INS',
  carrierOnUnloadGen = 'CARRIER_ON_UNLOAD_GEN',
  consigneeGen = 'CONSIGNEE_GEN',
  errorDuringSending = 'ERROR_DURING_SENDING',
  carrierOnUnloadIns = 'CARRIER_ON_UNLOAD_INS',
  driverConfirmed = 'DRIVER_CONFIRMED',
}

export const OPERATIONAL_DOCUMENTS_STATUS_MODEL = {
  [EDocumentsStatus.draft]: {
    text: tt('operationalDocuments.documentStatuses.draft'),
    type: EStatusType.default,
  },
  [EDocumentsStatus.awaitingConfirmation]: {
    text: tt('operationalDocuments.documentStatuses.awaitingConfirmation'),
    type: EStatusType.warning,
  },
  [EDocumentsStatus.awaitingForSign]: {
    text: tt('operationalDocuments.documentStatuses.awaitingForSign'),
    type: EStatusType.processing,
  },
  [EDocumentsStatus.sent]: {
    text: tt('operationalDocuments.documentStatuses.sent'),
    type: EStatusType.processing,
  },
  [EDocumentsStatus.yourSignRequired]: {
    text: tt('operationalDocuments.documentStatuses.yourSignRequired'),
    type: EStatusType.warning,
  },
  [EDocumentsStatus.accepted]: {
    text: tt('operationalDocuments.documentStatuses.accepted'),
    type: EStatusType.success,
  },
  [EDocumentsStatus.signedByAll]: {
    text: tt('operationalDocuments.documentStatuses.signedByAll'),
    type: EStatusType.success,
  },
  [EDocumentsStatus.youRejectedDocument]: {
    text: tt('operationalDocuments.documentStatuses.youRejectedDocument'),
    type: EStatusType.error,
  },
  [EDocumentsStatus.rejected]: {
    text: tt('operationalDocuments.documentStatuses.rejected'),
    type: EStatusType.error,
  },
};

export enum EOperationalDocumentsResponseError {
  operationalDocumentsIntegrationError = 'DomainErrors::OperationalDocumentsContext::IntegrationError',
}

import {
  computed,
  ref,
  onMounted,
  onUnmounted,
} from 'vue';
import debounce from 'lodash.debounce';

import { BREAKPOINTS } from '@/constants';

const useBreakpoints = () => {
  const windowWidth = ref(window.innerWidth);

  const resizeObserver = new ResizeObserver(debounce((entries) => {
    if (!Array.isArray(entries) || !entries.length) {
      return;
    }
    setTimeout(() => {
      if (entries[0].contentBoxSize) {
        windowWidth.value = entries[0].contentBoxSize[0]?.inlineSize;
      } else {
        windowWidth.value = entries[0].contentRect.width;
      }
    }, 0);
  }, 100));

  const observedElem = window.document.body;

  const isMobile = computed(() => windowWidth.value < BREAKPOINTS.widemobile);
  const isTablet = computed(() => windowWidth.value >= BREAKPOINTS.widemobile
    && windowWidth.value < BREAKPOINTS.widelaptop);
  const isDesktop = computed(() => windowWidth.value >= BREAKPOINTS.widelaptop);

  onMounted(() => resizeObserver.observe(observedElem));
  onUnmounted(() => resizeObserver.disconnect());

  return {
    width: windowWidth,
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useBreakpoints;

import { ROUTE_TYPE } from '@/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasCarrierStarterWorkplaceSubscription,
  hasCarrierWorkplaceSubscription,
  hasForwarderFeaturesSubscription,
} from '@/domains/permissions/subscription';
import { canBrowsePartnershipsByRole } from '@/domains/permissions/role/partnerships';
import isTenantVerified from '@/domains/isTenantVerified';
import { canBrowsePartnershipResourcesByRole } from '@/domains/permissions/role/partnershipResources';

import {
  EPartnershipRouteName,
  EPartnershipsRouteName,
} from '../domain/constants';
import isPartnershipResourcesVerificationEnabled from '../domain/isPartnershipResourcesVerificationEnabled';

const routes = [
  buildProtectedRoute({
    path: '/partnerships',
    name: EPartnershipsRouteName.partnerships,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowsePartnershipsByRole,
        isTenantVerified,
        () => hasCargoOwnerWorkplaceSubscription()
          || hasCarrierWorkplaceSubscription()
          || hasCarrierStarterWorkplaceSubscription(),
      ],
      component: () => import('@/contexts/partnershipsContext/views/PartnershipsListView/index.vue'),
    },
    redirect: '/partnerships/partners',
    children: [
      {
        path: 'partners',
        name: EPartnershipsRouteName.partnershipsPartners,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipsListView/components/PartnershipsPartners/index.vue'),
      },
      {
        path: 'invitations',
        name: EPartnershipsRouteName.partnershipsInvitations,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipsListView/components/PartnershipsInvitations/index.vue'),
      },
      {
        path: 'contracts',
        name: EPartnershipsRouteName.partnershipsContracts,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipsListView/components/PartnershipsContracts/index.vue'),
      },
    ],
  }),
  buildProtectedRoute({
    path: '/partnerships/partners/:id',
    name: EPartnershipRouteName.partnershipInfo,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [canBrowsePartnershipsByRole],
      component: () => import('@/contexts/partnershipsContext/views/PartnershipInfoView/index.vue'),
    },
    redirect: (to) => ({
      name: EPartnershipRouteName.partnershipsPartnerResources,
      params: { id: to.params.id },
    }),
    children: [
      {
        path: 'resources',
        name: EPartnershipRouteName.partnershipsPartnerResources,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipInfoView/components/PartnershipsPartnerResources/index.vue'),
      },
      {
        path: 'contracts',
        name: EPartnershipRouteName.partnershipsPartnerContracts,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipInfoView/components/PartnershipsPartnerContracts/index.vue'),
      },
      {
        path: 'contacts',
        name: EPartnershipRouteName.partnershipsPartnerContacts,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipInfoView/components/PartnershipsPartnerContacts/index.vue'),
      },
    ],
  }),
  buildProtectedRoute({
    path: '/partnerships/partners/:id/vehicles/:vehicleId',
    name: 'partnershipVehicle',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [canBrowsePartnershipsByRole],
      component: () => import('@/contexts/partnershipsContext/views/PartnershipVehicle/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/partnerships/partners/:id/drivers/:driverId',
    name: 'partnershipDriver',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [canBrowsePartnershipsByRole],
      component: () => import('@/contexts/partnershipsContext/views/PartnershipDriver/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/partnership_resources',
    name: EPartnershipsRouteName.partnershipResources,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        isPartnershipResourcesVerificationEnabled,
        canBrowsePartnershipResourcesByRole,
        () => hasCargoOwnerWorkplaceSubscription() || hasForwarderFeaturesSubscription(),
      ],
      component: () => import('@/contexts/partnershipsContext/views/PartnershipResourcesView/index.vue'),
    },
    redirect: '/partnership_resources/vehicles',
    children: [
      {
        path: 'vehicles',
        name: EPartnershipsRouteName.partnershipResourcesVehicles,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipResourcesView/components/PartnershipResourcesVehicles/index.vue'),
      },
      {
        path: 'drivers',
        name: EPartnershipsRouteName.partnershipResourcesDrivers,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipResourcesView/components/PartnershipResourcesDrivers/index.vue'),
      },
    ],
  }),
];

export default routes;

import * as getters from './getters';
import { initialState } from './state';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};

<template>
  <RestrictionLayout
    :title="title"
    :description="description"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import RestrictionLayout from '@/components/RestrictionLayout/index.vue';

import { useView } from './application/useView';
import { getDescriptionForRestrictionLayout } from './domain/getDescriptionForRestrictionLayout';

export default defineComponent({
  name: 'BillingRestrictionLayout',
  components: { RestrictionLayout },
  setup() {
    const { title } = useView();

    return {
      title,
      description: getDescriptionForRestrictionLayout(),

      tt,
    };
  },
});
</script>

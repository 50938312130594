import { computed, watch } from 'vue';

import store from '@/store';
import {
  disconnectConsumer,
  openWsConnection,
  reconnectWithNewUrl,
} from '@/websocket/controller';

const useWebsocketController = () => {
  const currentTenant = computed(() => store.state.tenants.currentTenant);

  watch(() => currentTenant.value?.id, (newValue?: string, oldValue?: string) => {
    if (newValue && (newValue !== oldValue)) {
      reconnectWithNewUrl();
    }
  });

  return {
    disconnectConsumer,
    openWsConnectionForCurrentTenant: openWsConnection,
  };
};

export default useWebsocketController;

import {
  TTimerMutations,
  TSetTimerEndPayload,
  TTimerState,
} from './types';
import * as types from './mutation-types';

const mutations: TTimerMutations = {
  [types.SET_END_DATE](state: TTimerState, { section, endDate }: TSetTimerEndPayload) {
    state[section] = { endDate };
  },
};

export default mutations;

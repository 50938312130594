import { joinString } from '@/utils';
import type { TEntityWithFirstSecondLastName } from '@/types';

type TConcatFullnameEmployee = Partial<Omit<TEntityWithFirstSecondLastName, 'id'>> & Record<string, any>;
type TConcatFullname = (employee: TConcatFullnameEmployee | null | undefined) => string;

export const concatFullname: TConcatFullname = (employee = null) => {
  if (!employee) return '';
  const { firstName, lastName, secondName } = employee;
  return joinString([lastName, firstName, secondName]);
};

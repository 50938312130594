<template>
  <span
    ref="tagRef"
    class="tag"
    :class="[
      `tag_${size}`,
      { 'tag_disabled': disabled },
    ]"
  >
    <span>
      {{ text }}
    </span>
    <component
      :is="closeSvgComponent"
      v-if="!disabled"
      class="tag__icon"
      @click="$emit('delete')"
    />
  </span>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from 'vue';

import {
  ESize,
  TRawSelectValue,
  TTagSize,
} from '@/ui/types';
import SmallCloseSvg from '@/assets/svg/14x14/close.svg';
import MediumCloseSvg from '@/assets/svg/16x16/close.svg';

export default defineComponent({
  name: 'Tag',
  components: {
    SmallCloseSvg,
    MediumCloseSvg,
  },
  props: {
    text: {
      type: [String, Number] as PropType<TRawSelectValue>,
      default: '',
    },
    size: {
      type: String as PropType<TTagSize>,
      default: ESize.medium,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number] as PropType<TRawSelectValue>,
      default: '',
    },
  },
  emits: ['delete'],
  setup(props) {
    const tagRef = ref<HTMLElement | null>(null);

    const closeSvgComponent = computed(() => {
      if (props.size === ESize.medium) {
        return 'MediumCloseSvg';
      }
      if (props.size === ESize.small) {
        return 'SmallCloseSvg';
      }
      return '';
    });

    return {
      ESize,
      tagRef,
      closeSvgComponent,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

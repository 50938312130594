import { useRoute } from 'vue-router';
import { computed } from 'vue';

import useStore from '@/store/useStore';
import { EExternalAccountProviders } from '@/domains/constants';
import { TExternalAccount } from '@/contexts/accountingContext/domain/types';

import { EIntegrationEvent } from '../domain/constants';

export const useSberButtonVisible = (integrationEvent: EIntegrationEvent) => {
  const route = useRoute();
  const store = useStore();

  const isSberBusinessEnabled = !!store?.state.app.appSettings?.settings?.sberBusinessEnabled || false;

  const currentUser = computed(() => store.state.user.data);
  const hasSberAccount = computed(() => currentUser.value && currentUser.value.externalAccounts
    .findIndex((item: TExternalAccount) => item.provider === EExternalAccountProviders.sber_business) !== -1);

  const isSberButtonVisible = computed(() => {
    if (integrationEvent !== EIntegrationEvent.linking) {
      const isSocialAuth = route.query.socialAuth === 'sber';
      return isSberBusinessEnabled && !isSocialAuth;
    }
    return isSberBusinessEnabled && !hasSberAccount.value;
  });

  return { isSberButtonVisible };
};

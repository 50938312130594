<template>
  <div class="custom-form-item-help">
    <div
      v-for="(rule, index) in filteredRules"
      :key="index"
      class="custom-form-item-help__message"
      :class="getClass(value, rule.pattern)"
    >
      <CheckSvg />
      {{ rule.message }}
    </div>
  </div>
</template>

<script lang="ts">

import {
  defineComponent,
  PropType,
  computed,
} from 'vue';

import CheckSvg from '@/assets/svg/16x16/check.svg';
import { TFormRule } from '@/types';

export default defineComponent({
  name: 'CustomFormItemHelp',
  components: { CheckSvg },
  props: {
    rules: {
      type: Array as PropType<TFormRule[]>,
      default: () => [],
    },
    value: {
      type: [String, null] as PropType<string | null>,
      required: true,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  emits: [],
  setup(props) {
    const filteredRules = computed(() => props.rules.filter((rule) => !!rule?.pattern));

    const validateValue = (value: string, pattern: RegExp | undefined): boolean => !!(pattern && value.match(pattern));

    const getClass = (value: string | null, pattern: RegExp | undefined): string => {
      if (!value) {
        if (props.isInvalid) {
          return 'custom-form-item-help__message_negative';
        }
        return 'custom-form-item-help__message_neutral';
      }

      if (validateValue(value, pattern)) {
        return 'custom-form-item-help__message_positive';
      }

      return 'custom-form-item-help__message_negative';
    };

    return {
      filteredRules,
      validateValue,
      getClass,
    };
  },

});
</script>

<style lang="scss" src="./styles.scss" />

import logger from '@/logger';
import { i18n } from '@/i18n';

import localeTextTranslationsRu from './localeTextTranslationsRu';

type TLocaleOptions = {
  param?: Record<string, unknown> | null,
};

/**
 * @param text
 * @param options
 * @description функция-обертка над функцией t из пакета i18n,
 * проверяющая наличие перевода для строки text в объекте с переводами;
 * параметр options - объект, состоящий из param - объект для задания динамических переводов
 */

const translateText = (
  text: TPathsOfObject<typeof localeTextTranslationsRu>,
  options: TLocaleOptions = { param: null },
): string => {
  const { t, te } = i18n.global;
  if (!te(text)) {
    logger.warn('translation not found:', text);
  }
  if (!t(text)) {
    return 'no translated';
  }
  return options.param ? t(text, options.param) : t(text);
};

export default translateText;

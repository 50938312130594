import {
  Ref,
  computed,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import {
  getMonthsFromDate,
  getNowISO,
  getYearsFromDate,
} from '@/utils/dateUtils';
import { EMonth } from '@/ui/types/constants';

import DatePickerMonthFeed from '../components/DatePickerMonthFeed/index.vue';

type TUseInteractWithDatePicker = {
  minDate: Ref<string>,
  maxDate: Ref<string>,
  datePickerMonthFeedRef: Ref<InstanceType<typeof DatePickerMonthFeed> | null>,
  setDropdownFocus: () => void,
};

export const useInteractWithDatePicker = (
  {
    minDate,
    maxDate,
    datePickerMonthFeedRef,
    setDropdownFocus,
  }: TUseInteractWithDatePicker,
) => {
  const currentDate = getNowISO();

  const monthSelectValue = ref(getMonthsFromDate(currentDate) + 1);
  const yearSelectValue = ref(getYearsFromDate(currentDate));

  const yearsOptions = computed(() => {
    const maxYear = getYearsFromDate(maxDate.value);
    const minYear = getYearsFromDate(minDate.value);

    return Array.from({ length: maxYear - minYear + 1 }).map((_, index: number) => ({
      value: minYear + index,
      label: minYear + index,
    }));
  });

  const monthsOptions = computed(
    () => Object.keys(EMonth).filter((value: string) => Number.isNaN(Number(value))).map(
      (month: string, index: number) => ({
        value: index + 1,
        label: tt(`shared.months.${month as keyof typeof EMonth}`),
      })),
  );

  const handleCurrentDateSet = () => {
    const currentDate = getNowISO();

    // Увеличиваем на 1, так как функция возвращает месяц в диапазоне от 0 до 11
    const month = getMonthsFromDate(currentDate) + 1;
    const year = getYearsFromDate(currentDate);

    handleSelectedMonthChange(month, year);

    datePickerMonthFeedRef.value?.changeMonthFeedState(month, year);
  };

  const handleSelectedMonthChange = (month: EMonth, year: number) => {
    monthSelectValue.value = month;
    yearSelectValue.value = year;
  };

  const handleMonthSelect = (month: EMonth) => {
    setDropdownFocus();

    monthSelectValue.value = month;

    datePickerMonthFeedRef.value?.changeMonthFeedState(month, yearSelectValue.value);
  };

  const handleYearSelect = (year: number) => {
    setDropdownFocus();

    yearSelectValue.value = year;

    datePickerMonthFeedRef.value?.changeMonthFeedState(monthSelectValue.value, year);
  };

  return {
    yearsOptions,
    monthsOptions,
    monthSelectValue,
    yearSelectValue,

    handleCurrentDateSet,
    handleSelectedMonthChange,
    handleMonthSelect,
    handleYearSelect,
  };
};

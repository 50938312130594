type TValue = (string | undefined | number | null)[];

const defaultSeparator = ' ';

const softFilterFunction = (value) => {
  if (value === 0) return true;
  return !!value;
};

const joinString = (value: TValue = [], separator = defaultSeparator) => {
  if (!Array.isArray(value)) return '';
  let tempSeparator = separator;
  if (typeof separator !== 'string') {
    tempSeparator = defaultSeparator;
  }
  return value.filter(softFilterFunction).join(tempSeparator);
};

export default joinString;

<template>
  <Form
    class="billing-shipper-form"
    :model="formData"
    :layout="ELayout.vertical"
    autocomplete="off"
  >
    <div class="billing-shipper-form__subscription-date-block">
      <p class="billing-shipper-form__subscription-date-block-title">
        {{ getTextAboutConnectedPlan({
          planCode: selectedPlan?.planCode || null,
          withoutTariff: true,
          withoutPrefix: true,
        }) }}
      </p>

      <FormItem
        name="subscriptionAt"
        v-bind="validateInfos.subscriptionAt"
        :label="tt('shared.activationDate')"
      >
        <div class="billing-shipper-form__about-subscription-block">
          <DatePicker
            v-model:value="formData.subscriptionAt"
            class="billing-shipper-form__data-picker"
            :format="DATE_FORMAT.fullDate"
            :valueFormat="DATE_FORMAT.fullDate"
            :disabledDate="(date) => getIsDisabledRangeForDate(date, 60)"
            @change="handleFieldChange('subscriptionAt')"
          />
          <div class="billing-shipper-form__about-subscription-block-info">
            <p>
              <span>
                {{ formatNumberAsCurrency(selectedPlan?.price, 0, {
                  separator: ' ',
                  symbol: tt('shared.currency.symbol.rub'),
                  pattern: '# !'
                }) }}
              </span>
              /{{ tt('shared.shortMonth') }}
            </p>
            <SmartLink :href="MORE_INFO_ABOUT_PLANS_URL" alwaysOpenNewTab>
              {{ tt('billing.aboutTerms') }}
            </SmartLink>
          </div>
        </div>
      </FormItem>
    </div>

    <div class="billing-shipper-form__row">
      <FormItem
        name="organizationPhone"
        v-bind="validateInfos.organizationPhone"
        :label="tt('shared.tenantPhone')"
      >
        <PhoneInput
          v-model:value="formData.organizationPhone"
          hasMask
          @input="handleFieldChange('organizationPhone')"
          @blur="handleFieldValidate('organizationPhone')"
        />
      </FormItem>
    </div>

    <FormItem
      name="invoicingEmail"
      v-bind="validateInfos.invoicingEmail"
      class="billing-shipper-form__form-item"
      :label="tt('shared.invoicingEmail')"
    >
      <EmailConfirmation
        :email="formData.invoicingEmail || ''"
        :isConfirmationRequired="!isEmailConfirmed"
        :requestCodeFunction="requestConfirmationCode"
        :confirmCodeFunction="confirmCodeForEmail"
        @confirmed="handleEmailConfirmed"
      >
        <div class="billing-shipper-form__email-confirmation-wrapper">
          <Input
            v-model:value="formData.invoicingEmail"
            :disabled="isEmailConfirmed"
            @input="handleFieldChange('invoicingEmail')"
            @blur="handleFieldValidate('invoicingEmail')"
          />
          <DeleteSvg
            v-if="isEmailConfirmed"
            class="billing-shipper-form__delete-icon"
            @click="handleChangeEmail"
          />
        </div>

        <CheckColoredSvg v-if="isEmailConfirmed" />
      </EmailConfirmation>
    </FormItem>

    <FormItem
      name="publicOfferAccept"
      class="billing-shipper-form__form-item"
    >
      <Checkbox
        v-model:checked="formData.publicOfferAccept"
      >
        {{ `${tt('shared.readAndAccept')} ${ tt('shared.licensed')}` }}

        <SmartLink :href="publicOfferLink" alwaysOpenNewTab>
          {{ tt('shared.offerContract') }}
        </SmartLink>
      </Checkbox>
    </FormItem>
  </Form>
</template>

<script lang="ts">

import {
  PropType,
  defineComponent,
  toRefs,
  computed,
} from 'vue';
import {
  Form,
  FormItem,
  DatePicker,
} from 'ant-design-vue';

import CheckColoredSvg from '@/assets/svg/16x16/check-colored.svg';
import DeleteSvg from '@/assets/svg/16x16/delete.svg';
import tt from '@/i18n/utils/translateText';
import {
  Checkbox,
  Input,
  PhoneInput,
} from '@/ui';
import { ESize } from '@/ui/types';
import {
  ELayout,
  DATE_FORMAT,

} from '@/constants';
import { MORE_INFO_ABOUT_PLANS_URL } from '@/domains/constants';
import SmartLink from '@/components/SmartLink/index.vue';
import EmailConfirmation from '@/components/EmailConfirmation/index.vue';
import { confirmCodeForEmail, requestConfirmationCode } from '@/contexts/billingContext/services';
import { getIsDisabledRangeForDate } from '@/utils/dateUtils';
import { TBillingAvailablePlan } from '@/contexts/billingContext/domain/types';
import { getTextAboutConnectedPlan } from '@/contexts/billingContext/domain/getTextAboutConnectedPlan';
import { formatNumberAsCurrency } from '@/utils';

import { useView } from './application/useView';
import { useInteractWithForm } from './application/useInteractWithForm';

export default defineComponent({
  name: 'BillingShipperForm',
  components: {
    SmartLink,
    Form,
    FormItem,
    Input,
    PhoneInput,
    DatePicker,
    Checkbox,
    EmailConfirmation,
    CheckColoredSvg,
    DeleteSvg,
  },
  props: {
    selectedPlan: {
      type: [Object, null] as PropType<TBillingAvailablePlan | null>,
      default: null,
    },
  },
  setup(props, { expose }) {
    const { selectedPlan } = toRefs(props);

    const planCode = computed(() => selectedPlan.value?.planCode || null);

    const { publicOfferLink } = useView(planCode);

    const {
      formData,
      validateInfos,
      isSubmitDisabled,
      isEmailConfirmed,

      handleFieldValidate,
      handleFieldChange,
      handleEmailConfirmed,
      handleChangeEmail,
      onSubmit,
      setResponseErrors,
    } = useInteractWithForm();

    expose({
      isSubmitDisabled,

      onSubmit,
      setResponseErrors,
    });

    return {
      formData,
      validateInfos,
      isSubmitDisabled,
      isEmailConfirmed,
      ELayout,
      DATE_FORMAT,
      ESize,
      publicOfferLink,
      MORE_INFO_ABOUT_PLANS_URL,

      tt,
      handleFieldValidate,
      handleFieldChange,
      handleEmailConfirmed,
      handleChangeEmail,
      requestConfirmationCode,
      confirmCodeForEmail,
      onSubmit,
      setResponseErrors,
      getIsDisabledRangeForDate,
      formatNumberAsCurrency,
      getTextAboutConnectedPlan,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

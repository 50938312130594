<template>
  <div class="notifications-list">
    <div class="notifications-list__header">
      <h1 class="notifications-list__header-title">
        {{ tt('shared.notifications') }}
      </h1>
      <Button
        class="notifications-list__icon-wrapper"
        type="link"
        @click="handleSettingsClick"
      >
        <SettingsSvg class="notifications-list__header-icon" />
      </Button>
    </div>

    <div
      v-if="isShowExtraButtons"
      class="notifications-list__extra-buttons"
    >
      <div class="notifications-list__switch-row">
        <Toggle
          v-model:checked="onlyUnread"
          class="notifications-list__switch"
          @change="refetchNotificationsList"
        />
        <span class="notifications-list__switch-label">
          {{ tt('notification.onlyUnread') }}
        </span>
      </div>
      <Button
        v-if="!isAllNotificationsRead"
        type="link"
        class="notifications-list__link"
        :disabled="isReadAllButtonDisabled"
        @click="handleReadAll"
      >
        {{ tt('notification.action.readAll') }}
      </Button>
    </div>

    <CustomEmpty
      v-if="isNotificationsEmpty"
      class="notifications-list__custom-empty"
    >
      <template #description>
        <template v-if="isAllNotificationsRead && onlyUnread">
          {{ tt('notification.allRead') }}
        </template>
        <template v-else>
          {{ tt('notification.noNotifications') }}
        </template>
      </template>
    </CustomEmpty>
    <template v-else>
      <div class="notifications-list__groups-wrapper">
        <Loadable
          class="notifications-list__loadable"
          :isLoading="isListLoading"
          :isDone="isListLoadingDone"
        >
          <div
            v-for="(notification, index) in serializedNotifications"
            :key="index"
            class="notifications-list__group"
          >
            <p class="notifications-list__day">
              {{ getDayText(notification.day) }}
            </p>
            <Notification
              v-for="item in notification.data"
              :key="item.id"
              :notification="item"
            />
          </div>
        </Loadable>
        <Button
          v-if="isMoreItemsAvailable"
          class="notifications-list__load-more"
          :disabled="isMoreItemsLoading"
          @click="handleLoadMore"
        >
          {{ tt('shared.action.showMore') }}
        </Button>
        <p
          v-else-if="isListLoadingDone"
          class="notifications-list__no-more-notifications"
        >
          {{ tt('notification.itIsAllNotifications') }}
        </p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {
  Button,
  notification,
} from 'ant-design-vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';

import useStore from '@/store/useStore';
import CustomEmpty from '@/components/CustomEmpty/index.vue';
import Loadable from '@/components/Loadable/index.vue';
import { capitalize } from '@/utils';
import { isToday } from '@/utils/dateUtils';
import { useDateValue } from '@/composables/useDateValue';
import { usePaginationList } from '@/composables/usePaginationList';
import useViewState from '@/composables/useViewState';
import Notification from '@/contexts/notificationsContext/components/Notification/index.vue';
import { readAllNotifications } from '@/contexts/notificationsContext/services';
import tt from '@/i18n/utils/translateText';
import { Toggle } from '@/ui';
import SettingsSvg from '@/assets/svg/16x16/settings.svg';

export default defineComponent({
  name: 'NotificationsList',
  components: {
    SettingsSvg,
    CustomEmpty,
    Toggle,
    Button,
    Notification,
    Loadable,
  },
  setup() {
    const onlyUnread = ref(false);
    const isShowExtraButtons = ref(false);
    const store = useStore();
    const router = useRouter();

    const {
      pagination,
      resetPagination,
      setFetchFunction,
      loadNextPage,
      isPaginationInitialized,
      isLastPage,
      fetchDataWithPagination,
    } = usePaginationList({
      initialPagination: {
        pageItems: 20,
        currentPage: 1,
      },
      fetchOnMount: false,
    });

    const {
      isLoading: isReadAllButtonDisabled,
      setViewStateAs: setReadAllButtonDisabled,
    } = useViewState();

    const {
      isLoading: isMoreItemsLoading,
      setViewStateAs: setIsMoreItemsLoadingAs,
    } = useViewState();

    const {
      isLoading: isListLoading,
      isDone: isListLoadingDone,
      setViewStateAs: setIsListLoading,
    } = useViewState();

    const isNotificationsEmpty = computed(() => store.state.notifications.notifications.ids?.length === 0);
    const isAllNotificationsEmpty = computed(() => isNotificationsEmpty.value && !onlyUnread.value);
    const isAllNotificationsRead = computed(() => store.getters['notifications/unreadCount'] === 0);
    const currentTenant = computed(() => store.getters['tenants/currentTenant']);
    const serializedNotifications = computed(() => store.getters['notifications/groupedNotificationsByDate']);
    const fetchNotificationsWithoutLoader = () => store.dispatch('notifications/fetchNotifications', {
      onlyUnread: onlyUnread.value,
      pagination,
    });

    setFetchFunction(fetchNotificationsWithoutLoader);

    const fetchNotificationsWithLoader = () => {
      setIsListLoading.loading();
      fetchDataWithPagination()
        .finally(() => {
          if (!isNotificationsEmpty.value) {
            isShowExtraButtons.value = true;
          }
          setIsListLoading.done();
        });
    };

    const clearNotificationsList = () => {
      store.commit('notifications/CLEAR_NOTIFICATIONS');
    };

    const refetchNotificationsList = () => {
      clearNotificationsList();
      resetPagination();
      fetchNotificationsWithLoader();
    };

    const handleLoadMore = () => {
      setIsMoreItemsLoadingAs.loading();
      loadNextPage().finally(setIsMoreItemsLoadingAs.done);
    };

    const handleReadAll = () => {
      setReadAllButtonDisabled.loading();
      readAllNotifications()
        .then(() => {
          notification.success({ message: tt('notification.notification.success.allRead') });
          refetchNotificationsList();
        })
        .catch(() => {
          notification.error({ message: tt('notification.notification.error.allRead') });
        })
        .finally(() => {
          setReadAllButtonDisabled.done();
        });
    };

    const closeNotificationsList = () => {
      store.dispatch('notifications/toggleNotificationsListVisible', false);
    };

    const handleSettingsClick = () => {
      if (!currentTenant.value?.employee?.id) return;
      closeNotificationsList();
      router.push(`/employees/${currentTenant.value?.employee?.id}/notifications`);
    };

    const getDayText = (date: any) => (isToday(date) ? capitalize(tt('shared.today')) : useDateValue(ref(date), { humanFormat: 'dayWithMonth' }).inHumanFormat.value);

    const isMoreItemsAvailable = computed(() => isPaginationInitialized.value && !isLastPage.value);

    onMounted(() => {
      clearNotificationsList();
      fetchNotificationsWithLoader();
    });

    return {
      onlyUnread,
      isNotificationsEmpty,
      serializedNotifications,
      isReadAllButtonDisabled,
      isListLoading,
      isListLoadingDone,
      isMoreItemsLoading,
      isMoreItemsAvailable,
      isAllNotificationsRead,
      isAllNotificationsEmpty,
      isShowExtraButtons,

      handleLoadMore,
      handleSettingsClick,
      getDayText,
      handleReadAll,
      refetchNotificationsList,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { TGetIsDateBetweenOrSame } from '@/utils/dateUtils/domains/types';
// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';
import { TDateAppFormat } from '@/types';

const getIsDateBetweenOrSame: TGetIsDateBetweenOrSame = (
  date: number | string | TDateAppFormat | null,
  dateFrom: number | string | TDateAppFormat,
  dateTo: number | string | TDateAppFormat,
) => !!date && (dayjs(date).isAfter(dayjs(dateFrom)) || dayjs(date).isSame(dayjs(dateFrom)))
  && (dayjs(date).isBefore(dayjs(dateTo)) || dayjs(date).isSame(dayjs(dateTo)));

export default getIsDateBetweenOrSame;

<template>
  <div
    ref="datePickerInputRef"
    :class="[
      'date-picker-input',
      `date-picker-input_${size}`,
    ]"
    :tabindex="disabled ? undefined : 0"
    @focus="handleInputFocus"
    @blur="$emit('blur')"
    @mousedown="handleInputClick"
  >
    <div class="date-picker-input__sections">
      <span
        class="date-picker-input__section"
        :class="{ 'date-picker-input__section_active': selectedSection === EDatePickerInputSection.day }"
        :data-section="EDatePickerInputSection.day"
      >__</span>

      <span>.</span>

      <span
        class="date-picker-input__section"
        :class="{ 'date-picker-input__section_active': selectedSection === EDatePickerInputSection.month }"
        :data-section="EDatePickerInputSection.month"
      >__</span>

      <span>.</span>

      <span
        class="date-picker-input__section"
        :class="{ 'date-picker-input__section_active': selectedSection === EDatePickerInputSection.year }"
        :data-section="EDatePickerInputSection.year"
      >____</span>
    </div>

    <CalendarSvg class="date-picker__icon" />
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  ref,
} from 'vue';

import CalendarSvg from '@/assets/svg/16x16/calendar.svg';
import { EDatePickerInputSection, ESize } from '@/ui/types/constants';

export default defineComponent({
  name: 'DatePickerInput',
  components: { CalendarSvg },
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
  },
  emits: ['focus', 'blur'],
  setup(props, { expose, emit }) {
    const datePickerInputRef = ref<HTMLDivElement | null>(null);

    const selectedSection = ref<EDatePickerInputSection | null>(null);

    const handleSelectSection = (section: EDatePickerInputSection) => {
      selectedSection.value = section;
    };

    const resetSelectedSection = () => {
      selectedSection.value = null;
    };

    const handleInputFocus = () => {
      if (props.disabled) return;

      if (!selectedSection.value) {
        selectedSection.value = EDatePickerInputSection.day;
      }

      emit('focus');
    };

    const handleInputClick = (event: MouseEvent) => {
      if (props.disabled) return;

      const { dataset } = event.target as HTMLElement;

      selectedSection.value = (dataset.section || EDatePickerInputSection.day) as EDatePickerInputSection;
    };

    const blur = () => datePickerInputRef.value?.blur();

    expose({
      blur,
      resetSelectedSection,
    });

    return {
      datePickerInputRef,
      selectedSection,
      EDatePickerInputSection,

      handleInputClick,
      handleInputFocus,
      handleSelectSection,
      resetSelectedSection,
      blur,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

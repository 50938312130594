<template>
  <Empty
    class="custom-empty"
    :image="simpleImage"
  >
    <template
      v-if="hasDescription"
      #description
    >
      <slot name="description" />
    </template>
    <div
      v-if="hasActions"
      class="custom-empty__actions"
    >
      <slot name="actions" />
    </div>
  </Empty>
</template>

<script lang="ts">
import { Empty } from 'ant-design-vue';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'CustomEmpty',
  components: { Empty },
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  setup(_, { slots }) {
    const hasDescription = computed(() => !!slots.description);
    const hasActions = computed(() => !!slots.actions);

    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      hasDescription,
      hasActions,
    };
  },

});
</script>

<style lang="scss" src="./styles.scss" />

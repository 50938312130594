import { Ref, computed } from 'vue';

import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import {
  CARGO_OWNER_PUBLIC_OFFER_LINK,
  CARRIER_PUBLIC_OFFER_LINK,
} from '@/domains/constants';

export const useView = (planCode: Ref<EBillingPlanCode | null>) => {
  const isCargoOwnerPlan = computed(() => planCode.value === EBillingPlanCode.cargoOwner);

  const publicOfferLink = computed(() => (
    isCargoOwnerPlan.value ? CARGO_OWNER_PUBLIC_OFFER_LINK : CARRIER_PUBLIC_OFFER_LINK),
  );

  return { publicOfferLink };
};

<template>
  <div>
    <BillingPlanSelection
      v-if="currentStep === EBillingPlanSelectionSteps.planSelection"
      @change="handlePlanChange"
    />

    <template v-if="planCode">
      <template v-if="currentStep === EBillingPlanSelectionSteps.changeTenantInfo">
        <BillingShipperForm
          v-if="isShipperFormShown"
          ref="shipperFormRef"
          :planCode="planCode"
          :selectedPlan="selectedPlan"
        />

        <BillingCarrierForm
          v-else
          ref="carrierFormRef"
          :planCode="planCode"
          :selectedPlan="selectedPlan"
          @goPreviousStep="changePlanSelectionStep(EBillingPlanSelectionSteps.planSelection)"
          @close="$emit('close')"
        />
      </template>

      <BillingSuccessfulPlanConnectionCard
        v-if="currentStep === EBillingPlanSelectionSteps.completeConnection"
        :planCode="planCode"
        :isContinueButtonLoading="isContinueButtonLoading"
        @close="$emit('close')"
      />
    </template>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  ref,
} from 'vue';

import { useChangePlan } from './application/useChangePlan';
import BillingShipperForm from './components/BillingShipperForm/index.vue';
import BillingCarrierForm from './components/BillingCarrierForm/index.vue';
import BillingPlanSelection from './components/BillingPlanSelection/index.vue';
import BillingSuccessfulPlanConnectionCard from './components/BillingSuccessfulPlanConnectionCard/index.vue';
import { EBillingPlanSelectionSteps } from '../../domain/constants';
import { TChangePlanSelectionStepFunction } from '../../domain/types';
import { useView } from './application/useView';
import { useConnectPlan } from './application/useConnectPlan';

export default defineComponent({
  name: 'BillingPlanSelectionModalContent',
  components: {
    BillingPlanSelection,
    BillingShipperForm,
    BillingCarrierForm,
    BillingSuccessfulPlanConnectionCard,
  },
  props: {
    currentStep: {
      type: String as PropType<EBillingPlanSelectionSteps>,
      required: true,
    },
    changePlanSelectionStep: {
      type: Function as PropType<TChangePlanSelectionStepFunction>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit, expose }) {
    const { changePlanSelectionStep } = props;

    const shipperFormRef = ref<InstanceType<typeof BillingShipperForm> | null>(null);
    const carrierFormRef = ref<InstanceType<typeof BillingCarrierForm> | null>(null);

    const {
      planCode,
      selectedPlan,

      handlePlanChange,
    } = useChangePlan(changePlanSelectionStep);

    const { isShipperFormShown } = useView(planCode);

    const {
      isLoading,
      isSubmitDisabled,
      isContinueButtonLoading,

      onSubmit,
    } = useConnectPlan({
      shipperFormRef,
      carrierFormRef,
      planCode,
      isShipperFormShown,

      emit,
      changePlanSelectionStep,
    });

    expose({
      planCode,
      isSubmitDisabled,
      isLoading,

      onSubmit,
    });

    return {
      planCode,
      EBillingPlanSelectionSteps,
      isShipperFormShown,
      isSubmitDisabled,
      shipperFormRef,
      carrierFormRef,
      selectedPlan,
      isLoading,
      isContinueButtonLoading,

      handlePlanChange,
      onSubmit,
    };
  },
});
</script>

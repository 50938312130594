<template>
  <div class="menu-item">
    <div class="menu-item__label-container">
      <template v-if="icon">
        <component
          :is="icon"
          class="menu-item__icon"
        />
      </template>

      <!-- :style="{ opacity: +!isCollapsed }" скрывает текст лейбла когда Sider свернут -->
      <span class="menu-item__label">
        {{ label }}
      </span>
    </div>

    <div class="menu-item__meta">
      <span
        v-if="count !== null"
        class="menu-item__counter"
        :class="[{ 'menu-item__counter_disabled': disabled }, `menu-item__counter_${counterType}`]"
      >
        {{ count }}
      </span>
      <span
        v-if="withArrow"
        class="menu-item__arrow"
      >
        <ChevronRightSvg />
      </span>
    </div>
  </div>
</template>

<script  lang="ts">
import { defineComponent, PropType } from 'vue';

import ChevronRightSvg from '@/assets/svg/16x16/chevron-right.svg';
import { ECounterType } from '@/components/layouts/domain/constants';

export default defineComponent({
  name: 'MenuIconWithLabel',
  components: { ChevronRightSvg },
  props: {
    icon: {
      type: Object as PropType<SVGElement> || null,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    count: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    withArrow: {
      type: Boolean,
      default: false,
    },
    counterType: {
      type: String as PropType<ECounterType>,
      default: ECounterType.default,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { computed } from 'vue';

import { EStatusType } from '@/domains/constants';
import tt from '@/i18n/utils/translateText';

export const ROUTE_NAME = {
  priceLists: 'priceLists',
  priceList: 'priceList',
};

export enum EPriceListState {
  archived = 'archived',
  draft = 'draft',
  active = 'active',
  planned = 'planned',
}

export const PRICE_LIST_STATUS_MODEL = computed(() => ({
  [EPriceListState.active]: {
    text: tt('pricing.shared.priceListStatuses.active'),
    type: EStatusType.success,
  },
  [EPriceListState.planned]: {
    text: tt('pricing.shared.priceListStatuses.planned'),
    type: EStatusType.processing,
  },
  [EPriceListState.draft]: {
    text: tt('shared.draft'),
    type: EStatusType.default,
  },
  [EPriceListState.archived]: {
    text: tt('pricing.shared.priceListStatuses.archived'),
    type: EStatusType.error,
  },
}));

export enum EPriceListColumnSettingKind {
  default = 'default',
  factor = 'factor',
  extraService = 'extra_service',
}

export enum EPriceListDefaultAttributeId {
  direction = 'direction',
  price = 'price',
  vatRate = 'vatRate',
  target = 'target',
  currency = 'currency',
}

export enum EPriceListImportErrorDataKey {
  period = 'period',
  name = 'name',
  dateFrom = 'date_from',
  dateTo = 'date_to',
}

export const PRICE_LIST_IMPORT_ERROR_DATA_KEY_TRANSLATE = {
  [EPriceListImportErrorDataKey.period]: tt('pricing.shared.priceListImportErrorDataKeys.period'),
  [EPriceListImportErrorDataKey.name]: tt('pricing.shared.priceListImportErrorDataKeys.name'),
  [EPriceListImportErrorDataKey.dateFrom]: tt('pricing.shared.priceListImportErrorDataKeys.dateFrom'),
  [EPriceListImportErrorDataKey.dateTo]: tt('pricing.shared.priceListImportErrorDataKeys.dateTo'),
};

export enum EPriceListPublicationCustomResponseCode {
  invalidRows = 'DomainErrors::PricingContext::CannotPublishInvalidPriceList',
}

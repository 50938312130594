import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import { TSessionWithTokensResponse, TSignUpRequest } from '@/contexts/accountingContext/domain/types';
import { convertSessionResponse } from '@/domains/convertSessionResponse';

import {
  TSberBusinessPayloadParams,
  TSberBusinessRedirectParams,
  TSberBusinessResponse,
} from '../domain/types';

export const getRedirectParamsToSberBusiness = () => sendApiRequest<TSberBusinessRedirectParams>({
  method: httpMethods.GET,
  endpoint: '/user/oauth/params/sber_business',
  withoutAuth: true,
});

export const authorizeSberBusinessAccount = (payload: TSberBusinessPayloadParams) => sendApiRequest<TSberBusinessResponse>({
  method: httpMethods.POST,
  endpoint: '/user/oauth/authorization/sber_business',
  payload,
  withoutAuth: true,
  withCredentials: true,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const linkingSberBusinessAccount = (payload: TSberBusinessPayloadParams) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/user/oauth/linking/sber_business',
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const registrationSberBusinessAccount = (payload: TSignUpRequest) => sendApiRequest<TSessionWithTokensResponse>({
  method: httpMethods.POST,
  endpoint: '/user/oauth/registration/sber_business',
  payload,
  withoutAuth: true,
  withCredentials: true,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
}).then(convertSessionResponse);

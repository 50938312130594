<script lang="tsx">
import { defineComponent, VNode } from 'vue';

import { toLowercase } from '@/utils';

const getStringBeforeMark = (source, indexOfMark) => source.slice(0, indexOfMark);
const getStringForMark = (source, indexOfMark, markLength) => source.slice(indexOfMark, indexOfMark + markLength);
const createMarkedNode = (mark) => <span class="marked-value__mark">{mark}</span>;

export default defineComponent({
  name: 'MarkedValue',
  props: {
    value: {
      type: String,
      required: true,
    },
    mark: {
      type: String,
      required: true,
    },
  },
  render() {
    if (!this.value || !this.mark) return this.value;

    const getNodesWithMarks = (inputString, mark) => {
      let internalString = inputString;
      let indexOfMark = toLowercase(inputString).indexOf(toLowercase(mark));
      const markLength = mark.length;
      const children = [] as VNode[];

      while (indexOfMark !== -1) {
        const stringBefore = getStringBeforeMark(internalString, indexOfMark);
        if (stringBefore) {
          children.push(stringBefore);
        }

        const stringForMark = getStringForMark(internalString, indexOfMark, markLength);
        if (stringForMark) {
          const markedNode = createMarkedNode(stringForMark);
          children.push(markedNode);
        }

        internalString = internalString.slice(indexOfMark + markLength);
        indexOfMark = toLowercase(internalString).indexOf(toLowercase(mark));
      }

      if (internalString) {
        children.push(internalString);
      }

      return children;
    };

    const nodes = getNodesWithMarks(this.value, this.mark);

    return (
      <div class="marked-value">
        {nodes}
      </div>
    );
  },
});
</script>

<style lang="scss" src="./styles.scss" />

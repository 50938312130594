import { MAX_VISIBLE_DROPDOWN_ITEM, MIN_ITEM_HEIGHT } from '@/ui/types/constants';

export const getDropdownListHeight = (optionsLength: number | null) => {
  if (optionsLength) {
    if (optionsLength > MAX_VISIBLE_DROPDOWN_ITEM) {
      return MAX_VISIBLE_DROPDOWN_ITEM * MIN_ITEM_HEIGHT;
    }
    return optionsLength * MIN_ITEM_HEIGHT;
  }
  return MIN_ITEM_HEIGHT;
};

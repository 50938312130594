export const MESSAGE_TYPE = {
  disconnect: 'disconnect',
  welcome: 'welcome',
  ping: 'ping',
  /**
   * Если подписка невозможна, например,
   * пользователю нельзя подписаться на указанный аукцион
   */
  rejectSubscription: 'reject_subscription',
  confirmSubscription: 'confirm_subscription',
};

export const MESSAGE_REASON = { unauthorized: 'unauthorized' };

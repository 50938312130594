// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';
import { TDateAppFormat } from '@/types';
import getMsFromDate from '@/utils/dateUtils/getters/getMsFromDate';
import isDayjsGuard from '@/utils/dateUtils/domains/guards';

import isNumber from '../../isNumber';
import isString from '../../isString';

/**
 * https://github.com/microsoft/TypeScript/issues/24929#issuecomment-397071586
 * Довольно старая проблема при выводе типа возвращаемого значения с помощью conditional types.
 * Решается через тайпкастинг к any. Ещё один из вариантов решения – перегрузка функций.
 */
const addToDate = (part: dayjs.ManipulateType) => <T extends TDateAppFormat | string | number>(
  date: T,
  value: number = 0,
  timeZone?: string): T extends TDateAppFormat ? TDateAppFormat : number => {
  if (isDayjsGuard(date)) {
    return (timeZone ? date.tz(timeZone) : date).clone().add(value, part) as any;
  }

  const handleNumber = (numberDate: number) => {
    const minutesInMs = dayjs.duration(value, part).asMilliseconds() as number;
    return numberDate + minutesInMs;
  };

  if (isNumber(date)) {
    return handleNumber(date) as any;
  }

  if (isString(date)) {
    const numberDate = getMsFromDate(date, timeZone);
    return handleNumber(numberDate) as any;
  }

  return timeZone ? dayjs.tz(date, timeZone) : date as any;
};

export default addToDate;

import { formatDate } from '@/utils/dateUtils';
import { DATE_FORMAT } from '@/constants';
import { TNotification } from '@/contexts/notificationsContext/domain/types';

import { TNotificationsState } from './types';

export const unreadCount = (state: TNotificationsState) => state.unreadCount;
export const isNotificationsListVisible = (state: TNotificationsState) => state.isNotificationsListVisible;
export const groupedNotificationsByDate = (state: TNotificationsState) => {
  if (!state.notifications.ids) return [];
  const notificationsByDates = state.notifications.ids.reduce((dates, notificationId) => {
    const notification = state.notifications.data?.[notificationId];
    if (!notification) return dates;

    const notificationDate = formatDate(notification.createdAt, { outputFormat: DATE_FORMAT.fullDateWithShortYears });
    if (!Array.isArray(dates[notificationDate])) {
      dates[notificationDate] = [notification];
    } else {
      dates[notificationDate].push(notification);
    }
    return dates;
  }, {} as Record<string, TNotification[]>);

  const result = Object.entries(notificationsByDates).map((notificationsGroup) => ({
    day: notificationsGroup[1][0].createdAt, // берем createdAt первого уведомления, т.к. они отсортированы по дням и тут неважно время
    data: notificationsGroup[1],
  }));

  return result;
};

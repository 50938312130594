import { hasProperty } from '@/utils';

import * as types from './mutation-types';
import { initialState } from './state';
import {
  TFilterMutations,
  TFilterSelectState,
  TFilterSelectWithIdState,
  TFilterState,
  TMainFilterState,
} from './types';

const isValidFilterPointer = (
  state: TFilterState,
  section: string,
  subSection: string,
  name?: string,
) => {
  if (!hasProperty(state, section)) {
    console.warn(`There is no filter section with "${section}" name.`);
    return false;
  }

  if (!hasProperty(state[section], subSection)) {
    console.warn(`There is no filter sub-section with "${subSection}" name in "${section}" section.`);
    return false;
  }

  if (name && !hasProperty(state[section][subSection], name)) {
    console.warn(`There is no filter with name "${name}" in "${section}/${subSection}".`);
    return false;
  }

  return true;
};

const createDefaultCommonFilterState = () => ({
  currentAppliedSet: null,
  currentAppliedPreset: null,
  currentEditablePreset: null,
});

const mutations: TFilterMutations = {
  [types.RESET_STATE](state) {
    const initialStateObject = initialState();

    Object.keys(state).forEach((section: string) => {
      state[section] = { ...initialStateObject[section] };
    });
  },

  [types.RESET_MAIN_FILTER_CURRENT_APPLIED_SET](state, {
    section,
    subSection,
  }) {
    if (!isValidFilterPointer(
      state,
      section,
      subSection,
      'filter',
    )) return;

    (state[section][subSection].filter as TMainFilterState).currentAppliedSet = null;
  },

  [types.RESET_MAIN_FILTER_CURRENT_APPLIED_PRESET](state, {
    section,
    subSection,
  }) {
    if (!isValidFilterPointer(
      state,
      section,
      subSection,
      'filter',
    )) return;

    (state[section][subSection].filter as TMainFilterState).currentAppliedPreset = null;
  },

  [types.RESET_MAIN_FILTER_CURRENT_EDITABLE_PRESET](state, {
    section,
    subSection,
  }) {
    if (!isValidFilterPointer(
      state,
      section,
      subSection,
      'filter',
    )) return;

    (state[section][subSection].filter as TMainFilterState).currentEditablePreset = null;
  },

  [types.SET_MAIN_FILTER_CURRENT_APPLIED_SET](state, {
    section,
    subSection,
    preset,
  }) {
    if (!isValidFilterPointer(
      state,
      section,
      subSection,
      'filter',
    )) return;

    (state[section][subSection].filter as TMainFilterState).currentAppliedSet = preset;
  },

  [types.SET_MAIN_FILTER_CURRENT_APPLIED_PRESET](state, {
    section,
    subSection,
    preset,
  }) {
    if (!isValidFilterPointer(
      state,
      section,
      subSection,
      'filter',
    )) return;

    (state[section][subSection].filter as TMainFilterState).currentAppliedPreset = preset;
  },

  [types.SET_MAIN_FILTER_CURRENT_EDITABLE_PRESET](state, {
    section,
    subSection,
    preset,
  }) {
    if (!isValidFilterPointer(
      state,
      section,
      subSection,
      'filter',
    )) return;

    (state[section][subSection].filter as TMainFilterState).currentEditablePreset = preset;
  },

  [types.RESET_FILTER_SELECT_CURRENT](state, {
    section,
    subSection,
    name,
  }) {
    if (!isValidFilterPointer(
      state,
      section,
      subSection,
      name,
    )) return;

    const storeObject = state[section][subSection][name] as TFilterSelectState;
    storeObject.current = null;
  },

  [types.SET_FILTER_SELECT_CURRENT](state, {
    section,
    subSection,
    name,
    current,
  }) {
    if (!isValidFilterPointer(
      state,
      section,
      subSection,
      name,
    )) return;

    const storeObject = state[section][subSection][name] as TFilterSelectState;
    storeObject.current = current;
  },

  // Для динамического заполнения подразделов
  [types.SET_SUBSECTIONS](state, {
    section,
    subSections,
  }) {
    if (!section) return;
    subSections.forEach((subSection) => {
      if (!state[section][subSection]?.filter) {
        state[section][subSection] = { filter: createDefaultCommonFilterState() };
      }
    });
  },

  [types.RESET_FILTER_SELECT_WITH_ID_CURRENT](state, {
    section,
    subSection,
    name,
  }) {
    if (!isValidFilterPointer(
      state,
      section,
      subSection,
      name,
    )) return;

    const storeObject = state[section][subSection][name] as TFilterSelectWithIdState;
    storeObject.current = null;
    storeObject.id = null;
  },

  [types.SET_FILTER_SELECT_WITH_ID_CURRENT](state, {
    section,
    subSection,
    name,
    current,
    id,
  }) {
    if (!isValidFilterPointer(
      state,
      section,
      subSection,
      name,
    )) return;

    const storeObject = state[section][subSection][name] as TFilterSelectWithIdState;
    storeObject.current = current;
    storeObject.id = id;
  },
};

export default mutations;

import isObject from '@/utils/isObject';

const getByPath = <T extends Record<string, unknown>>(source: T, path: T extends Record<string, unknown>
  ? TPathsOfObject<T> : never) => {
  if (!isObject(source)) return null;
  if (!path) return source;

  const stringified = `${path}`;
  const splitted = stringified.split('.');
  if (splitted?.length < 1) return source;
  return splitted.reduce<Record<string, unknown> | unknown>((result, key) => {
    if (isObject(result) || Array.isArray(result)) return result[key];

    return result;
  }, source);
};

export default getByPath;

import { ref } from 'vue';

import { TReachedResponse } from '@/api/types';
import { TBankAccount, TBankAccounts } from '@/contexts/accountingContext/components/BankSelector/types';
import { fetchBankAccountsAutocomplete } from '@/contexts/accountingContext/services';
import { TSelectOption } from '@/ui/types';

export const useSearchBankIdentificationCodes = (
  handleFieldChange: (field: string) => void,
  setValue: (field: string, value: string | number | boolean) => void,
) => {
  const bankIdentificationCodes = ref<TSelectOption[]>([]);

  const handleSearch = (query: string) => {
    handleFieldChange('bankIdentificationCode');

    bankIdentificationCodes.value = [];

    if (!query) {
      setValue('bankIdentificationCode', '');

      return;
    }

    fetchBankAccountsAutocomplete(query)
      .then((result: TReachedResponse<TBankAccounts>) => {
        if (result.data.length) {
          bankIdentificationCodes.value = result.data.map((bank: TBankAccount) => ({
            value: bank.bik,
            label: bank.bik,
            subTitle: bank.name,
          }));
        }
      })
      .catch(() => {});
  };

  return {
    bankIdentificationCodes,

    handleSearch,
  };
};

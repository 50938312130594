<script lang="tsx">
import { defineComponent } from 'vue';
import { Button } from 'ant-design-vue';

import { notification } from '@/utils';
import store from '@/store';
import tt from '@/i18n/utils/translateText';

export default defineComponent({
  name: 'ToastNotification',
  components: { Button },
  props: {
    message: {
      type: String,
      default: '',
    },
    notificationId: {
      type: String,
      required: true,
    },
    toastKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return { isLoading: false };
  },
  methods: {
    handleClick() {
      this.$data.isLoading = true;
      store.dispatch('notifications/readNotificationById', this.$props.notificationId)
        .then(() => {
          notification.close(this.$props.toastKey);
          store.commit('notifications/TOGGLE_NOTIFICATION_LIST_VISIBLE', true);
        })
        .finally(() => {
          this.$data.isLoading = false;
        });
    },
  },
  render() {
    return (
      <div class="toast-notification">
        <div>
          {this.message}
        </div>
        <Button
          class="toast-notification__button"
          type="link"
          loading={this.isLoading}
          onClick={this.handleClick}
        >
          { tt('notification.action.showAll') }
        </Button>
      </div>
    );
  },
});
</script>

<style lang="scss" src="./styles.scss" />

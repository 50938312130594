import {
  computed,
  onUnmounted,
  ref,
} from 'vue';

import { ONE_SECOND } from '@/constants';
import { getNowMs } from '@/utils/dateUtils';

const useTimer = (timeout = ONE_SECOND * 30) => {
  const timerId = ref<null | number>(null);
  const timerStartedAt = ref(0);
  const msAfterStart = ref(0);

  const secondsRemain = computed(() => {
    if (!timerId.value) return 0;
    return Math.floor((timeout - msAfterStart.value) / 1000);
  });

  const recalcMsAfterStart = () => {
    msAfterStart.value = getNowMs() - timerStartedAt.value;
  };

  const stopInterval = () => {
    if (timerId.value) {
      clearInterval(timerId.value);
      timerId.value = null;
    }
    msAfterStart.value = 0;
    timerStartedAt.value = 0;
  };

  const startTimer = () => {
    timerStartedAt.value = getNowMs();

    timerId.value = window.setInterval(() => {
      recalcMsAfterStart();
      if (secondsRemain.value <= 0) {
        stopInterval();
      }
    }, ONE_SECOND);
  };

  onUnmounted(stopInterval);

  return {
    startTimer,
    stopTimer: stopInterval,
    secondsRemain,
  };
};

export default useTimer;

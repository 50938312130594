import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import {
  TApplicationSettings,
  TRefreshContractsRequest,
} from '@/types';
import {
  TFilterPresetDTO,
  TSaveFilterPresetParams,
  TUpdateFilterPresetParams,
} from '@/components/Filter/types';
import {
  TTableConfig,
  TTableConfigLoaded,
} from '@/components/CustomTableLayout/types';
import { joinString } from '@/utils';
import { TContractPartialInfo } from '@/contexts/partnershipsContext/domain/types';
import { TActionProgressResponse } from '@/components/ActionProgress/domains/types';

export type TViewSettingsConfig = {
  viewName: string,
  viewSubName?: string,
};

export const getUploadingUrl = ({ filename, type }: { filename: string, type: string }) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/imports/presign',
  params: {
    filename,
    type,
  },
});

/* Фильтры */

/** Получение списка сохраненных фильтров. */
export const getFilterPresets = ({ section }: { section: string }) => sendApiRequest<TFilterPresetDTO[]>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/filters/${section}`,
});

/** Сохранение фильтра */
export const saveFilterPreset = (
  {
    filterSet,
    operator,
    section,
    title,
    notificationAttributes,
  }: TSaveFilterPresetParams,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/filters/${section}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
  payload: {
    filterSet,
    operator,
    section,
    title,
    notificationAttributes,
  },
});

/** Обновление сохраненного фильтра. */
export const updateFilterPreset = (
  {
    preset,
    section,
  }: TUpdateFilterPresetParams,
) => {
  const {
    id,
    filterSet,
    notificationAttributes,
    title,
    operator,
  } = preset;

  return sendApiRequest<{ id: string }>({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/filters/${section}/${id}`,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
    payload: {
      filterSet,
      notificationAttributes,
      operator,
      title,
    },
  });
};

/** Удаление сохраненного фильтра. */
export const deleteFilterPreset = (
  {
    section,
    id,
  }: {
    section: string,
    id: string,
  },
) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/filters/${section}/${id}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getApplicationSettings = () => sendApiRequest<TApplicationSettings>({
  method: httpMethods.GET,
  endpoint: '/application_settings',
  withoutAuth: true,
});

/** Получить настройки отображения таблицы */
export const getTableSettings = (config: TViewSettingsConfig) => {
  const endpointData = joinString([config.viewName, config.viewSubName], '/');
  return sendApiRequest<{ columns: TTableConfigLoaded }>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/view_settings/${endpointData}`,
  });
};

/** Создать/обновить настройки отображения таблицы */
export const saveTableSettings = (
  payload: TTableConfigLoaded,
  config: TViewSettingsConfig,
) => {
  const endpointData = joinString([config.viewName, config.viewSubName], '/');
  return sendApiRequest<{ columns: TTableConfig }>({
    method: httpMethods.PUT,
    endpoint: `/:tenant_id/view_settings/${endpointData}`,
    payload: { columns: payload },
  });
};

/** Сбросить настройки отображения таблицы */
export const resetTableSettings = (config: TViewSettingsConfig) => {
  const endpointData = joinString([config.viewName, config.viewSubName], '/');
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/view_settings/${endpointData}`,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
};

export const getContracts = (
  agreementId: string,
  executionRequestId: string,
) => sendApiRequest<TContractPartialInfo[]>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/execution_requests/${executionRequestId}/agreements/${agreementId}/contracts`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const updateContracts = ({
  executionRequestId,
  agreementId,
  partnershipContractId,
}: TRefreshContractsRequest) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/execution_requests/${executionRequestId}/agreements/${agreementId}`,
  payload: { partnershipContractId },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

// eslint-disable-next-line unused-imports/no-unused-vars
export const reconnectToEDM = () => new Promise((_, reject) => setTimeout(() => {
  const err = {
    code: 'FORBIDDEN',
    message: 'asdas message',
    errors: {
      field1: ['Error 1', 'Error 2'],
      field2: ['Error 3'],
    },
    meta: {
      field: 'exampleField',
      meta: null,
      errorPath: '/path/to/error',
      fullMessages: true,
    },
  };

  reject(err);
}, 750));

export const getActionProgress = ({ id }: { id: string }) => sendApiRequest<TActionProgressResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/action_progresses/${id}`,
});

import useViewState from '@/composables/useViewState';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import useStore from '@/store/useStore';

export const useContinue = (emit: (event: 'close', ...args: unknown[]) => void) => {
  const store = useStore();

  const { isLoading, setViewStateAs } = useViewState();

  const onContinue = () => {
    setViewStateAs.loading();

    Promise.all([
      store.dispatch('tenants/fetchCurrentTenantInfo'),
      store.dispatch('tenants/fetchTenants'),
    ])
      .then(() => {
        emit('close');
        redirectToInitialRoute();
      })
      .catch(() => {})
      .finally(setViewStateAs.done);
  };

  return {
    isLoading,

    onContinue,
  };
};

import store from '@/store';
import { TSubscription } from '@/types';
import tt from '@/i18n/utils/translateText';
import isTenantVerified from '@/domains/isTenantVerified';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { getShortPlan } from '@/contexts/billingContext/domain/getShortPlan';
import { formatDate } from '@/utils/dateUtils';
import { DATE_FORMAT } from '@/constants';

export const getDescriptionForRestrictionLayout = (): string => {
  const { currentTenantInfo } = store.state.tenants;

  const subscriptionsRequest = currentTenantInfo?.subscriptionRequest || null;
  const subscriptions = currentTenantInfo?.subscriptions || [];

  const hasActiveSubscription = subscriptions.some(
    (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active);

  const subscriptionWithPendingStatus = subscriptions.find(
    (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.pending);

  // для НЕверифицированного тенанта
  if (!isTenantVerified()) {
    // есть подписка или запрос на подписку
    if (subscriptions.length > 0 || subscriptionsRequest) {
      return tt('billing.restrictionLayout.verificationDescription');
    }
    return tt('billing.restrictionLayout.connectPlanDescription');
  }

  // для верифицированного тенанта
  // есть подписка в статусе active
  if (hasActiveSubscription) return '';

  // есть подписка в статусе pending
  if (subscriptionWithPendingStatus) {
    const { subscriptionAt } = subscriptionWithPendingStatus;
    const { planCode } = subscriptionWithPendingStatus;
    /* здесь проверяем через startsWith, потому что при создании подписки для ГВ
      менеджером tms через интерфейс Лаго planCode может быть кастомным,
      но начинаться будет с EBillingPlanCode.cargoOwner, например, tms.plan.cargo_owner.sibur.
      В этом случае сравнение делаем через startsWith
    */
    if (planCode?.startsWith(EBillingPlanCode.cargoOwner)) {
      return tt('billing.paywallMessage.yourPlanForCargoOwnerWillStartFrom', { param: { date: formatDate(subscriptionAt || '', { outputFormat: DATE_FORMAT.dayMonthYear }) } });
    }
    const planName = planCode && tt(`billing.planCode.${getShortPlan(planCode)}`);

    return tt('billing.restrictionLayout.currentPlanStartedAt', {
      param: {
        name: planName,
        date: formatDate(subscriptionAt || '', { outputFormat: DATE_FORMAT.dayMonthYear }),
      },
    });
  }

  // есть запрос на подписку
  if (subscriptionsRequest) {
    const { subscriptionAt } = subscriptionsRequest;
    const { planCode } = subscriptionsRequest;

    // запрос на подписку можно создать только при выборе тарифа через модальное окно в интерфейсе tms. В этом случае planCode тарифа ЛК ГВ будет равен EBillingPlanCode.cargoOwner.
    if (planCode === EBillingPlanCode.cargoOwner) {
      return tt('billing.paywallMessage.connectionRequestHasBeenSuccessfullyCompleted');
    }
    const planName = planCode && tt(`billing.planCode.${getShortPlan(planCode)}`);

    return tt('billing.restrictionLayout.currentPlanStartedAt', {
      param: {
        name: planName,
        date: formatDate(subscriptionAt || '', { outputFormat: DATE_FORMAT.dayMonthYear }),
      },
    });
  }

  return tt('billing.restrictionLayout.connectPlanDescription');
};

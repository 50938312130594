import * as types from './mutation-types';
import { TPaginationActions, TPaginationResetPayload } from './types';

const actions: TPaginationActions = {
  resetPagination: ({ commit }, payload: TPaginationResetPayload) => {
    commit(types.RESET_PAGINATION, payload);
  },
};

export default actions;

import currency from 'currency.js';

export const toCurrency = (
  number: number | null | undefined,
  currencyPrecision = 2,
  {
    separator = '.',
    decimal = ',',
    symbol = '₽',
    precision = currencyPrecision,
    pattern = '#!',
    negativePattern = '-#!',
  } = {},
) => {
  if (!number && number !== 0) return '';

  return currency(+number, {
    separator,
    decimal,
    symbol,
    precision,
    pattern,
    negativePattern,
  }).format();
};

import StarterKit from '@tiptap/starter-kit';
import { useEditor } from '@tiptap/vue-3';
import {
  computed,
  Ref,
  watch,
} from 'vue';
import Placeholder from '@tiptap/extension-placeholder';

import { ControlWrapper } from '@/ui';
import { EEditorMark, EEditorNode } from '@/ui/types/constants';

type TUseInteractWithEditor = {
  controlWrapperRef: Ref<InstanceType<typeof ControlWrapper> | null>,
  value: Ref<string | null>,
  placeholder: Ref<string>,
  isReadonly: Ref<boolean>,
  isDisabled: Ref<boolean>,
  emit: (event: 'blur' | 'focus' | 'update:value' | 'change' | 'input', ...args: unknown[]) => void,
};

export const useInteractWithEditor = ({
  controlWrapperRef,
  value,
  placeholder,
  isReadonly,
  isDisabled,

  emit,
}: TUseInteractWithEditor) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({ placeholder: placeholder.value }),
    ],
    content: value.value ?? '',
    editable: !isReadonly.value && !isDisabled.value,
    parseOptions: { preserveWhitespace: 'full' },
    onUpdate: () => {
      // При проверке на пустое поле, для получения текущего значения используем getText, а не getHTML. getHTML вернет <p></p>
      if (!editor.value?.getText()) {
        emit('update:value', '');
      } else {
        emit('update:value', editor.value?.getHTML() || '');
      }

      emit('input', editor.value?.getText() || '');
    },
    onFocus: () => {
      controlWrapperRef.value?.focus();

      emit('focus');
    },
    onBlur: () => {
      // Исключаем лишние события blur, когда фокус стоял на поле ввода, а после было нажатие на кнопку в тулбаре
      setTimeout(() => {
        if (!editor.value?.isFocused) {
          controlWrapperRef.value?.blur();

          emit('blur');
          emit('change', editor.value?.getHTML() || '');
        }
      });
    },
  });

  watch(value, (newValue: string | null) => {
    if (newValue !== editor.value?.getHTML()) {
      editor.value?.commands.setContent(newValue ?? '', false);
    }
  });

  watch([isDisabled, isReadonly], () => {
    editor.value?.setOptions({ editable: !isDisabled.value && !isReadonly.value });
  });

  const handleBoldMarkButtonClick = () => {
    editor.value?.chain().focus().toggleBold().run();
  };

  const handleItalicMarkButtonClick = () => {
    editor.value?.chain().focus().toggleItalic().run();
  };

  const handleStrikeMarkButtonClick = () => {
    editor.value?.chain().focus().toggleStrike().run();
  };

  const handleBulletListButtonClick = () => {
    editor.value?.chain().focus().toggleBulletList().run();
  };

  const handleOrderedListButtonClick = () => {
    editor.value?.chain().focus().toggleOrderedList().run();
  };

  const focusEditor = () => {
    editor.value?.commands.focus();
  };

  const isBoldMarkActive = computed(() => editor.value?.isActive(EEditorMark.bold));
  const isItalicMarkActive = computed(() => editor.value?.isActive(EEditorMark.italic));
  const isStrikeMarkActive = computed(() => editor.value?.isActive(EEditorMark.strike));
  const isBulletListActive = computed(() => editor.value?.isActive(EEditorNode.bulletList));
  const isOrderedListActive = computed(() => editor.value?.isActive(EEditorNode.orderedList));

  return {
    editor,
    isBoldMarkActive,
    isItalicMarkActive,
    isStrikeMarkActive,
    isBulletListActive,
    isOrderedListActive,

    handleBoldMarkButtonClick,
    handleItalicMarkButtonClick,
    handleStrikeMarkButtonClick,
    handleBulletListButtonClick,
    handleOrderedListButtonClick,
    focusEditor,
  };
};

import { useRoute } from 'vue-router';

import store from '@/store';
import { ROUTE_TYPE } from '@/constants';

export const getIsLicensePaywallMessageShown = (): boolean => {
  const route = useRoute();
  const isPrivateRoute = route?.meta?.type === ROUTE_TYPE.private;

  const hasTenants = store.state.tenants.userTenants?.length > 0;
  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;
  const hasLicenseRole = !!store.state.tenants.currentTenantInfo?.licenseRole;

  return hasTenants
  && isPrivateRoute
  && hasInstanceLicense
  && !hasLicenseRole;
};

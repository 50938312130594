import { defineStore } from 'pinia';
import { ref } from 'vue';

import { TResourcesControlStore } from '@/stores/resourcesControl/types';

export const useResourcesControlStore = defineStore(
  'TMS_RESOURCES_CONTROL',
  (): TResourcesControlStore => {
    const isEmployeesAutoAssignmentBannerVisible = ref<boolean>(true);

    const setIsEmployeesAutoAssignmentBannerVisible = (newValue: boolean) => {
      isEmployeesAutoAssignmentBannerVisible.value = newValue;
    };

    const $reset = () => {
      isEmployeesAutoAssignmentBannerVisible.value = true;
    };

    return {
      isEmployeesAutoAssignmentBannerVisible,

      setIsEmployeesAutoAssignmentBannerVisible,
      $reset,
    };
  },
  { persist: true });

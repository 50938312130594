import { TComplexityCheck } from '@/contexts/accountingContext/domain/types';
import { addByIndex } from '@/utils';
import tt from '@/i18n/utils/translateText';
import { TFormRule } from '@/types';
import { REGEX_PATTERNS } from '@/constants';

// Правила для пароля по умолчанию
export const DEFAULT_PASSWORD_RULES = {
  containsLowercaseAndUppercaseLetters: {
    pattern: REGEX_PATTERNS.containsLowercaseAndUppercaseLetters,
    message: tt('shared.rule.containsLowercaseAndUppercaseLetters'),
    trigger: 'blur',
  },
  containsAtLeastOneDigit: {
    pattern: REGEX_PATTERNS.containsAtLeastOneDigit,
    message: tt('shared.rule.containsDigit'),
    trigger: 'blur',
  },
};

// Настройки пароля тенанта по умолчанию
export const DEFAULT_PASSWORD_COMPLEXITY_SETTINGS = {
  minLength: 10,
  containsLowercaseAndUppercaseLetters: true,
  containsAtLeastOneDigit: true,
};

// Метод возвращает правило с динамической минимальной длиной пароля
const getDefaultMinLengthRule = (count: number): TFormRule => (
  {
    pattern: new RegExp(`^.{${count},}$`),
    message: tt(
      'shared.rule.containsAtLeastCharacters',
      { param: { count } },
    ),
    trigger: 'blur',
  }
);

export const generatePasswordRules = (
  passwordComplexitySettings: TComplexityCheck = DEFAULT_PASSWORD_COMPLEXITY_SETTINGS,
): TFormRule[] => {
  let passwordSettings: TFormRule[] = [];

  // Добавляем правила в массив в зависимости от настроек тенанта
  Object.keys(passwordComplexitySettings).forEach((passwordComplexitySetting: string) => {
    if (passwordComplexitySettings[passwordComplexitySetting]) {
      if (passwordComplexitySetting === 'minLength') {
        passwordSettings = addByIndex(
          passwordSettings,
          0,
          getDefaultMinLengthRule(passwordComplexitySettings[passwordComplexitySetting]));
      } else if (DEFAULT_PASSWORD_RULES[passwordComplexitySetting]) {
        passwordSettings = addByIndex(
          passwordSettings,
          passwordSettings.length,
          DEFAULT_PASSWORD_RULES[passwordComplexitySetting],
        );
      }
    }
  });

  return passwordSettings;
};

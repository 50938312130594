import {
  onMounted,
  onBeforeUnmount,
  computed,
} from 'vue';

import useStore from '@/store/useStore';
import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { TMS_STORAGE_KEY } from '@/store';

/** Композибл useSyncTokens необходим для корректной синхронизации токенов между вкладками одного браузера.
 * Автоматически vuex/pinia не синхронизируют сторы между активными и неактивными вкладками одного и того же домена,
 * что приводит к ситуации, когда токен в одной вкладке обновился, а вторая вкладка этого не узнала и тоже обновила сама себе токен.
 * Такое поведение приводит к разлогинам пользователей, потому что к запросам цепляется удаленный токен из базы, а не свежий из соседней вкладки.
 *
 * Для решения этой проблемы необходимо отслеживать событие 'storage', которое срабатывает только в неактивных вкладках, когда в активной что-то поменялось в сторе.
 */
export const useSyncTokens = () => {
  const isSyncTokenBetweenBrowserTabsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.syncTokenBetweenBrowserTabs),
  );

  const store = useStore();

  const syncTokensFromLsToStore = (event: StorageEvent) => {
    if (isSyncTokenBetweenBrowserTabsAvailable.value) {
      const { key, newValue, oldValue } = event;

      if (key === TMS_STORAGE_KEY && newValue && oldValue) {
        const newAppState = JSON.parse(newValue).app;
        const oldAppState = JSON.parse(oldValue).app;
        if (newAppState && oldAppState && newAppState.accessToken !== oldAppState.accessToken) {
          store.dispatch('app/setAuthTokens', newAppState);
        }
      }
    }
  };

  onMounted(() => {
    window.addEventListener('storage', syncTokensFromLsToStore);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('storage', syncTokensFromLsToStore);
  });
};

export const getNumberWithLimitedDecimalPart = (input: HTMLInputElement, digitsAfterComma: number) => {
  if (!input.value) {
    return '';
  }

  const normalizedValue = input.value.replace(',', '.');
  const parts = normalizedValue.split('.');

  // если пользователь попытается написать число без целой части, то обнуляем значение в input
  if (parts[0] === '') {
    return '';
  }

  if (parts.length > 1 && parts[1].length > digitsAfterComma) {
    parts[1] = parts[1].slice(0, digitsAfterComma);
    return parts.join('.');
  }

  return normalizedValue;
};

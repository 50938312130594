// Назначение API-клиента – правильная упаковка вызовов под конкретную версию внутреннего API.
import logger from '@/logger';

import { getAxiosInstance } from './commonApiClient';

const baseApiHost = import.meta.env.VITE_APP_INTERNAL_API_HOST || null;
const baseApiVersion = import.meta.env.VITE_APP_INTERNAL_API_VERSION || null;

const buildBaseURL = (host: string, version: string, tenantId: string) => {
  if (!host) {
    logger.error('[Internal API] Please specify internal API host using VITE_APP_INTERNAL_API_HOST env variable');
    return null;
  }

  if (!version) {
    logger.error('[Internal API] Please specify internal API version using VITE_APP_INTERNAL_API_VERSION env variable');
    return null;
  }

  // В будущем способ построения ссылки будет зависеть от версии API
  return [host, 'web', version, tenantId].filter((item) => !!item).join('/');
};

const result = getAxiosInstance((request) => buildBaseURL(baseApiHost, baseApiVersion, request.tenantId));

export default result;

<template>
  <Skeleton
    v-if="isLoading"
    :class="[withBackground ? 'loadable__skeleton' : '', className]"
    active
    :paragraph="paragraph"
    :title="title"
  />

  <NotFound
    v-else-if="isNotFound"
    :subTitle="notFoundTitle"
  />

  <CustomEmpty
    v-else-if="isEmpty"
    :class="className"
  >
    <template #description>
      <slot name="emptyContent">
        {{ emptyText }}
      </slot>
    </template>
    <template #actions>
      <slot name="actions" />
    </template>
  </CustomEmpty>

  <slot v-else-if="isDone || isIdle" />

  <CustomEmpty
    v-else-if="withError"
    :class="className"
  >
    <template #description>
      <slot name="errorContent">
        {{ errorText }}
      </slot>
    </template>
  </CustomEmpty>

  <slot name="spin" />
</template>

<script lang="ts">
import { SkeletonProps, Skeleton } from 'ant-design-vue';
import { defineComponent, PropType } from 'vue';

import CustomEmpty from '@/components/CustomEmpty/index.vue';
import NotFound from '@/components/NotFound/index.vue';
import tt from '@/i18n/utils/translateText';

export default defineComponent({
  name: 'Loadable',
  components: {
    CustomEmpty,
    Skeleton,
    NotFound,
  },
  props: {
    errorText: {
      type: String,
      default: tt('shared.error.gettingDataError'),
    },
    emptyText: {
      type: String,
      default: tt('shared.emptyList'),
    },
    class: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isDone: {
      type: Boolean,
      required: true,
    },
    isIdle: {
      type: Boolean,
      required: false,
    },
    withError: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    isNotFound: {
      type: Boolean,
      default: false,
    },
    notFoundTitle: {
      type: String,
      default: '',
    },
    paragraph: {
      type: [Boolean, Object] as PropType<boolean | Partial<SkeletonProps['paragraph']>>,
      default: true,
    },
    title: {
      type: [Boolean, Object],
      default: true,
    },
    withBackground: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    return {
      className: props.class,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { EmployeeViewSettingsField } from '@/domains/constants';
import { TReferenceDataCountry } from '@/types';

import { TTenantsState } from './types';

export const availableUserTenants = (state: TTenantsState) => state.userTenants;

export const currentTenant = (state: TTenantsState) => state.currentTenant;

export const currentTenantId = (state: TTenantsState) => state.currentTenant?.id;

export const currentTenantInfo = (state: TTenantsState) => state.currentTenantInfo;

export const tenantLoading = (state: TTenantsState) => state.isLoading;

export const complexityCheck = (state: TTenantsState) => state.currentTenantInfo?.settings?.passwordComplexitySettings?.complexityCheck;
export const countriesWithoutRu = (state: TTenantsState) => {
  const { ids, data } = state.referenceData?.countries || {
    ids: [],
    data: {},
  };
  return ids?.reduce((result, current: string) => {
    if (current !== 'RU') {
      result.push(data[current]);
    }
    return result;
  }, [] as TReferenceDataCountry[]);
};

export const viewSettingsTableColumns = (state: TTenantsState) => (
  { entity }: { entity: EmployeeViewSettingsField },
) => state.currentTenant?.employee?.viewSettings[entity];

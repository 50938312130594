import {
  hasProperty,
  isNumber,
  isString,
} from '@/utils';
import {
  TSelectOption,
  TSelectOptions,
  TSelectOptionsGroup,
  TSelectValue,
} from '@/ui/types';

export const isSelectOptionsGroupsGuard = (options: TSelectOptions):
  options is TSelectOptionsGroup[] => !!options[0] && hasProperty(options[0], 'options');

export const isSelectOptionGuard = (
  value: TSelectValue,
): value is TSelectOption => {
  let result;
  if (Array.isArray(value)) {
    result = false;
  } else if (value) {
    result = !isString(value) && !isNumber(value) && value && hasProperty(value, 'value') && hasProperty(value, 'label');
  }
  return result;
};

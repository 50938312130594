import { ROUTE_TYPE } from '@/constants';
import { ROUTE_NAME } from '@/contexts/freightExecutionContext/domain/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { hasCarrierWorkplaceSubscription } from '@/domains/permissions/subscription';
import { canBrowseExecutionRequestsByRole } from '@/domains/permissions/role/executionRequests';

const routes = [
  buildProtectedRoute({
    path: '/execution_requests',
    name: ROUTE_NAME.executionRequests,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseExecutionRequestsByRole,
        hasCarrierWorkplaceSubscription,
      ],
      component: () => import('@/contexts/freightExecutionContext/views/ExecutionRequestsListView/index.vue'),
    },
  }),
];

export default routes;

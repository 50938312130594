import * as types from './mutation-types';
import { TRightPanelMutations } from './types';

const mutations: TRightPanelMutations = {
  [types.SET_IS_OPEN_ROUTES](state, { sectionName, isOpenRoutes }) {
    state[sectionName].isOpenRoutes = isOpenRoutes;
  },
  [types.SET_IS_OPEN_RIGHT_PANEL](state, { sectionName, isOpenRightPanel }) {
    state[sectionName].isOpenRightPanel = isOpenRightPanel;
  },
  [types.SET_RIGHT_PANEL_WIDTH](state, { sectionName, rightPanelWidth }) {
    state[sectionName].rightPanelWidth = rightPanelWidth;
  },
};

export default mutations;

<template>
  <div
    ref="tagGroupRef"
    class="tag-group"
  >
    <Tag
      v-for="option in optionsToDisplay"
      ref="tagRefs"
      :key="option.value"
      :text="option.label"
      :value="option.value"
      :size="size"
      :disabled="disabled || option.disabled"
      @delete="handleTagDelete(option)"
    />
    <Tooltip v-if="showInvisibleOptionsCounter">
      <div
        class="tag-group__counter"
        :class="`tag-group__counter_${size}`"
      >
        {{ invisibleOptionsCountText }}
      </div>
      <template #title>
        <p
          v-for="option in hiddenOptions"
          :key="option.value"
          class="tag-group__tooltip-text"
        >
          {{ option.label }}
        </p>
      </template>
    </Tooltip>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  watch,
} from 'vue';

import {
  TTagGroupOption,
  ESize,
  TTagSize,
} from '@/ui/types';

import Tag from '../Tag/index.vue';
import Tooltip from '../Tooltip/index.vue';
import { getResponsiveMaxTagCount } from './utils/getResponsiveMaxTagCount';
import { MIN_TAG_WIDTH } from './constants';

export default defineComponent({
  name: 'TagGroup',
  components: {
    Tag,
    Tooltip,
  },
  props: {
    value: {
      type: Array as PropType<TTagGroupOption[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<TTagSize>,
      default: ESize.medium,
    },
    maxTagCount: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    /** авторасчет для responsive контейнера, игнорирует maxTagCount, если true (!использовать с оглядкой на производительность) */
    isResponsiveTagCount: {
      type: Boolean,
      default: false,
    },
    minTagWidth: {
      type: Number,
      default: MIN_TAG_WIDTH,
    },
  },
  emits: [
    'change',
    'update:value',
    'delete',
  ],
  setup(props, { emit }) {
    const tagGroupRef = ref<HTMLDivElement | null>(null);
    const tagRefs = ref<typeof Tag[]>([]);
    const availableMaxTagCount = ref<number | null>(null);

    const invisibleOptionsQuantity = computed(() => {
      if (!availableMaxTagCount.value || availableMaxTagCount.value < 0) return null;
      const quantity = props.value.length - availableMaxTagCount.value;
      return quantity > 0 ? quantity : null;
    });

    const invisibleOptionsCountText = computed(() => (invisibleOptionsQuantity.value ? `+${invisibleOptionsQuantity.value}` : ''));

    const showInvisibleOptionsCounter = computed(() => !!invisibleOptionsQuantity.value);

    const optionsToDisplay = computed(() => {
      if (!availableMaxTagCount.value) return props.value;
      return props.value.slice(0, availableMaxTagCount.value);
    });

    const hiddenOptions = computed(() => {
      if (!showInvisibleOptionsCounter.value || !availableMaxTagCount.value) return [];
      return props.value.slice(availableMaxTagCount.value);
    });

    const handleTagDelete = (deleteOption: TTagGroupOption) => {
      const updatedValue = props.value.filter((item: TTagGroupOption) => item.value !== deleteOption.value);
      emit('change', updatedValue);
      emit('update:value', updatedValue);
      emit('delete', deleteOption);
    };

    const getTagRefsOrderedWidths = () => {
      // Тк refs не гарантирует порядок (а в данном случае он его ломает), восстанавливаем его и собираем ширины в массив
      const valueKeys = props.value.map((item: TTagGroupOption) => item.value);
      const sortedRefs = tagRefs.value.sort((a, b) => valueKeys.indexOf(a.value) - valueKeys.indexOf(b.value));
      return sortedRefs.length ? sortedRefs.map((ref: typeof Tag) => ref.tagRef.scrollWidth) : [];
    };

    const setAvailableMaxTagCount = () => {
      if (props.isResponsiveTagCount) {
        availableMaxTagCount.value = getResponsiveMaxTagCount({
          maxAvailableWidth: tagGroupRef.value?.offsetWidth,
          tagWidths: getTagRefsOrderedWidths(),
          minTagWidth: props.minTagWidth,
        });
      } else {
        availableMaxTagCount.value = props.maxTagCount;
      }
    };

    onMounted(setAvailableMaxTagCount);

    watch(() => props.value, () => {
      // сначала сбрасываем, чтобы все теги вмонтировались, и получить их ширины
      availableMaxTagCount.value = null;
      // ждем пока вмонтируется, затем считаем и применяем ограничение
      nextTick().then(setAvailableMaxTagCount);
    });

    return {
      tagGroupRef,
      tagRefs,
      showInvisibleOptionsCounter,
      invisibleOptionsCountText,
      optionsToDisplay,
      hiddenOptions,

      handleTagDelete,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

import { EPermissionComparisonFunction } from '@/constants';
import { getByPath, isObject } from '@/utils';
import { TRolePermissionItem } from '@/domains/permissions/checkRolePermissions';
import { TSettingsItem } from '@/domains/permissions/checkGlobalSettings';
import { TSubscriptionPermissionItem } from '@/domains/permissions/checkSubscriptionPermissions';
import type {
  TEmployeeRolePermissions,
  TTenantFeatures,
  TActiveSubscriptionsConditionsFeatures,
} from '@/types';

type TCurrentPermissions = TEmployeeRolePermissions
| TTenantFeatures
| TActiveSubscriptionsConditionsFeatures;
type TRequiredPermissions = TRolePermissionItem[] | TSettingsItem[] | TSubscriptionPermissionItem[];
type TCheckResult<T extends TRequiredPermissions> = {
  passed: boolean,
  items: Record<T[number], boolean> | null,
};
type TCheckPermissionsCommon = <T extends TRequiredPermissions>(
  currentPermissions: TCurrentPermissions | undefined | null,
  requiredPermissions: TRequiredPermissions | undefined | null,
  operator: EPermissionComparisonFunction,
) => TCheckResult<T>;

const checkPermissionsCommon: TCheckPermissionsCommon = (
  currentPermissions, requiredPermissions,
  operator = EPermissionComparisonFunction.AND,
) => {
  /**
   * если не передан массив с требуемыми доступами, то допускаем пользователя до интерфейса
   */
  if (!requiredPermissions || requiredPermissions.length === 0) {
    return {
      passed: true,
      items: null,
    };
  }

  /**
   * если нет требуемых доступов в локальном хранилище, то не допускаем пользователя
   */
  if (!isObject(currentPermissions) || Object.keys(currentPermissions).length === 0) {
    return {
      passed: false,
      items: null,
    };
  }

  const result = {
    passed: operator === EPermissionComparisonFunction.AND,
    items: {} as ReturnType<typeof checkPermissionsCommon>['items'],
  };
  requiredPermissions.forEach((subscriptionKey: TRequiredPermissions[number]) => {
    const hasCurrentPermission = getByPath(currentPermissions, subscriptionKey);
    result.items![subscriptionKey] = !!hasCurrentPermission;

    if (operator === EPermissionComparisonFunction.AND) {
      if (!hasCurrentPermission) {
        result.passed = false;
      }
    } else if (hasCurrentPermission) {
      result.passed = true;
    }
  });

  return result;
};

export default checkPermissionsCommon;

import { DATE_FORMAT } from '@/constants';

export const ROUTE_NAME = { matrices: 'matrices' };

export enum EMatrixAction {
  info = 'info',
  add = 'add',
  edit = 'edit',
  delete = 'delete',
}

export enum EReportMatrixPickerPeriod {
  date = 'date',
  week = 'week',
  month = 'month',
}

export const REPORT_MATRIX_DATE_FORMAT = {
  [EReportMatrixPickerPeriod.date]: DATE_FORMAT.fullDate,
  [EReportMatrixPickerPeriod.week]: DATE_FORMAT.weekYear,
  [EReportMatrixPickerPeriod.month]: DATE_FORMAT.monthYear,
};

export const REPORT_MATRIX_PERIOD_TYPE = {
  [EReportMatrixPickerPeriod.date]: 'day',
  [EReportMatrixPickerPeriod.week]: 'week',
  [EReportMatrixPickerPeriod.month]: 'month',
};

export enum EMatrixOrderType {
  general = 'general',
  container = 'container',
  any = 'any',
}

<template>
  <div class="info-panel">
    <div class="info-panel__logo">
      <TruckerLogoSvg />
    </div>
    <div class="info-panel__about">
      <div class="info-panel__about-info">
        <span class="info-panel__about-info-header">
          {{ tt('infrastructure.description.aboutCompanyTitle') }}
        </span>
        <span class="info-panel__about-info-description">
          {{ tt('infrastructure.description.aboutCompanyDescription') }}
        </span>
      </div>
      <SupportBlock />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import TruckerLogoSvg from '@/assets/svg/logo/trucker-tms-light.svg';

import SupportBlock from 'src/components/InformationPanel/components/SupportBlock/index.vue';

export default defineComponent({
  name: 'InformationPanel',
  components: {
    SupportBlock,
    TruckerLogoSvg,
  },
  setup() {
    return { tt };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

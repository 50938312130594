import { computed, Ref } from 'vue';

import { TDateAppFormat } from '@/types';
import { capitalize } from '@/utils';
import { DATE_FORMAT } from '@/constants';
import {
  parseDateToAppFormat,
  formatDate,
} from '@/utils/dateUtils';

/*
  Используйте этот composable для работы с датами в Vue-компонентах в целях возможного абстрагирования
  от уровня библиотеки. В будущем есть надежда избавиться от moment.js и дожить до Temporal, и поэтому
  хочется, чтобы работа напрямую с этой библиотекой была сосредоточена в минимуме мест.
*/
const toAppFormat = (v: TDateAppFormat | string | null | undefined) => parseDateToAppFormat(v);
const toISOFormat = (v: TDateAppFormat | null) => {
  const appFormat = toAppFormat(v);
  if (!appFormat) return null;
  return appFormat.toISOString();
};

const HUMAN_FORMAT_CONFIGS = {
  short: (v) => v.format(`${DATE_FORMAT.fullDate}, ${DATE_FORMAT.hoursMinutes}`), // -> 13.08.2021, 15:19
  full: (v) => v.calendar(), // -> сегодня в 16:45
  standart: (v) => v.format(DATE_FORMAT.fullDate), // -> 13.08.2021
  onlyTime: (v) => v.format(DATE_FORMAT.hoursMinutes), // -> 16:37
  dayWithMonth: (v) => v.format(DATE_FORMAT.dayWithMonth), // -> 18 февраля
  onlyTimeWithSeconds: (v) => v.format(DATE_FORMAT.hoursMinutesSeconds), // -> 16:37:49
  dateWithTime: (v) => formatDate(v, { outputFormat: DATE_FORMAT.fullDateWithHoursMinutes }), // -> 13.08.2021, 15:19
};
const uncapitalizeValue = (value: any) => value.charAt(0).toLowerCase() + value.slice(1);

type TConfig = {
  humanFormat?: keyof typeof HUMAN_FORMAT_CONFIGS,
  capitalized?: boolean,
};

export const useDateValue = (valueRef: Ref<string | null | undefined | TDateAppFormat>, config?: TConfig) => {
  const inAppFormat = computed(() => toAppFormat(valueRef?.value));
  const inISOFormat = computed(() => toISOFormat(inAppFormat.value));

  const inHumanFormat = computed(() => {
    const format: TConfig['humanFormat'] = config?.humanFormat && HUMAN_FORMAT_CONFIGS[config.humanFormat]
      ? config.humanFormat
      : 'full';
    const needCapitalize = !!config?.capitalized;
    const caseTransform = (v) => (needCapitalize ? capitalize(v) : uncapitalizeValue(v));

    if (!inAppFormat?.value || (typeof inAppFormat.value?.isValid === 'function' && !inAppFormat.value.isValid())) return '-';
    return caseTransform(HUMAN_FORMAT_CONFIGS[format](inAppFormat.value));
  });

  return {
    rawValue: valueRef,
    inAppFormat,
    inHumanFormat,
    inISOFormat,
  };
};

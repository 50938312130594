<script lang="ts">
import { defineComponent, watch } from 'vue';

export default defineComponent({
  name: 'AppTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const buildTitle = (title: string) => (title ? `${props.title} – Trucker TMS` : 'Trucker TMS');
    const setTitle = (title: string) => {
      document.title = buildTitle(title);
    };

    setTitle(props.title);
    watch(() => props.title, setTitle);
  },
  render: () => null,
});
</script>

import { ref } from 'vue';

import useWebsocketChannel from '@/composables/useWebsocketChannel';
import useStore from '@/store/useStore';
import { TWsChannelSubscribers } from '@/websocket/types';

export const useTenantSubscriptionsWsChannel = (subscribers: TWsChannelSubscribers) => {
  const channelName = 'TenantSubscriptionsChannel';
  const store = useStore();

  /** Активное подключение к каналу */
  const hasActiveConnection = ref(false);

  const { closeConnection, connect } = useWebsocketChannel({
    channelName,
    subscribers,
    options: { onMountInitialization: false },
  });

  const connectToTenantSubscriptionsChannel = () => {
    if (hasActiveConnection.value) return;

    hasActiveConnection.value = true;

    connect({ employeeId: store.getters['tenants/currentTenant']?.employee?.id || null });
  };

  const closeTenantSubscriptionsChannelConnection = () => {
    if (!hasActiveConnection.value) return;

    hasActiveConnection.value = false;

    closeConnection();
  };

  return {
    connectToTenantSubscriptionsChannel,
    closeTenantSubscriptionsChannelConnection,
  };
};

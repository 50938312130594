import { computed } from 'vue';

import tt from '@/i18n/utils/translateText';
import useStore from '@/store/useStore';
import { generatePasswordRules } from '@/domains/generatePasswordRules';

const requiredRule = {
  required: true,
  message: tt('shared.rule.passwordRequired'),
  trigger: 'blur',
};

export const useInfrastructureConstants = () => {
  const store = useStore();

  const complexityCheck = computed(() => store.getters['tenants/complexityCheck'] || store.getters['user/complexityCheck']);

  const updatePasswordRules = computed(() => ({
    oldPassword: [
      requiredRule,
    ],
    newPassword: [
      requiredRule,
      ...generatePasswordRules(complexityCheck.value),
    ],
  }));

  const resetPasswordRules = computed(() => ({
    password: [
      requiredRule,
      ...generatePasswordRules(complexityCheck.value),
    ],
  }));

  return {
    updatePasswordRules,
    resetPasswordRules,
  };
};

import { createRouter, createWebHistory } from 'vue-router';

import selfBillingRoutes from '@/contexts/selfBillingContext/routes';

import accountingRoutes from '../contexts/accountingContext/routes';
import adminRoutes from '../contexts/adminContext/routes';
import contractorsRoutes from '../contexts/contractorsContext/routes';
import executorsLookupRoutes from '../contexts/executorsLookupContext/routes';
import freightExecutionRoutes from '../contexts/freightExecutionContext/routes';
import orderingRoutes from '../contexts/orderingContext/routes';
import partnershipsRoutes from '../contexts/partnershipsContext/routes';
import pricingRoutes from '../contexts/pricingContext/routes';
import resourcesControlRoutes from '../contexts/resourcesControlContext/routes';
import summaryRoutes from '../contexts/summaryContext/routes';
import commonRoutes from './routes/common';
import tendersRoutes from '../contexts/tendersContext/routes';
import directionsRoutes from '../contexts/directionsContext/routes';
import rateManagementRoutes from '../contexts/rateManagementContext/routes';
import quotasRoutes from '../contexts/quotaContext/routes';
import reportRoutes from '../contexts/reportsContext/routes';
import extraServicesRoutes from '../contexts/extraServicesContext/routes';
import operationalDocumentsRoutes from '../contexts/operationalDocumentsContext/routes';
import approvalsRoutes from '../contexts/approvalsContext/routes';
import referencesRoutes from '../contexts/referencesContext/routes';

/**
 * в проекте используются три типа роутов (ROUTE_TYPE):
 * - private – роут, на который может зайти только авторизованный юзер
 * - public – роут, который доступен всем юзерам
 * - unauthenticated – сюда может зайти только неавторизованный пользователь (например, signUp)
 */

const routes = [
  ...commonRoutes,
  ...accountingRoutes,
  ...orderingRoutes,
  ...resourcesControlRoutes,
  ...contractorsRoutes,
  ...partnershipsRoutes,
  ...pricingRoutes,
  ...freightExecutionRoutes,
  ...executorsLookupRoutes,
  ...adminRoutes,
  ...summaryRoutes,
  ...selfBillingRoutes,
  ...tendersRoutes,
  ...directionsRoutes,
  ...rateManagementRoutes,
  ...quotasRoutes,
  ...reportRoutes,
  ...extraServicesRoutes,
  ...operationalDocumentsRoutes,
  ...approvalsRoutes,
  ...referencesRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.onError((error) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    console.warn('A new version released. Need to reload the page to apply changes.');
    window.location.reload();
  }
});

export default router;

import { TPaginationResetPayload } from '@/store/modules/pagination/types';
import store from '@/store';
import { useSearchStore } from '@/stores/search';

import { DATA_FOR_RESET_PAGINATION } from './constants';
import { TDataForResetPagination } from './types';

/**
 * Сбрасывает текущее значение страницы пагинатора для таблиц в сторе.
 *
 * Условия для сброса текущей страницы пагинатора у таблиц, имеющих поле поиска, следующие:
 * 1. Был переход между разделами. К примеру, из "Направлений" в "Гарантии";
 * 2. Поле поиска таблицы было не пустое.
 *
 * Пагинацию не сбрасываем, если было обновление страницы или если при переходе между разделами, поле поиска пустое для соответствующей таблицы.
 */
export const checkSearchStateAndResetPagination = () => {
  const searchStore = useSearchStore();

  DATA_FOR_RESET_PAGINATION.forEach(
    ({ searchSection, paginationResetPayloads }: TDataForResetPagination) => {
      if (searchStore.state[searchSection]) {
        paginationResetPayloads.forEach((paginationResetPayload: TPaginationResetPayload) => {
          store.dispatch('pagination/resetPagination', paginationResetPayload);
        });
      }
    });
};

import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import tt from '@/i18n/utils/translateText';

export const getDescriptionByPlanCode = (planCode: EBillingPlanCode): string[] => {
  const isUpdateBillingPlansAvailable = checkFeatureIsEnabled(EExperimentalFeatures.updateBillingPlans);

  switch (planCode) {
    case EBillingPlanCode.cargoOwner:
      return [
        `${tt('billing.modal.creatingTenders')};`,
        `${tt('billing.modal.distributionOrdersByExecutors')};`,
        `${tt('billing.modal.controlOfExecutionAndDelivery')};`,
        tt('billing.modal.organizationOfTheEnterpriseCourtyardManagementProcess'),
      ];
    case EBillingPlanCode.shipper:
      return [
        `${tt('billing.modal.trackingAndControlOfVehicleArrivalAtLoadingAndUnloadingPoints')};`,
        `${tt('billing.modal.recordingTheDateAndTimeOfArrivalAndDepartureOfTheVehicle')};`,
        tt('billing.modal.creationAndSigningOfEtRN'),
      ];
    case EBillingPlanCode.carrierStart:
      return [
        `${tt('billing.modal.twoManagersForOrganization')};`,
        tt('billing.modal.accessToTenders'),
      ];
    case EBillingPlanCode.carrierBasic:
      return isUpdateBillingPlansAvailable
        ? [
          `${tt('billing.modal.twoManagersForOrganization')};`,
          `${tt('billing.modal.accessToOrdersTendersElectronicDocumentFlowAndTenTools')};`,
          `${tt('billing.modal.automaticFillingOfDriverAndCarDataUsingATemplate')};`,
          tt('billing.modal.accessToSelfBilling'),
        ]
        : [
          `${tt('billing.modal.twoManagersForOrganization')};`,
          tt('billing.modal.accessToOrdersTendersElectronicDocumentFlowAndTenTools'),
        ];
    case EBillingPlanCode.carrierExtended:
      return isUpdateBillingPlansAvailable
        ? [
          `${tt('billing.modal.unlimitedNumberOfManagersInTheOrganization')};`,
          `${tt('billing.modal.accessToResponsibleOnLookupAssignmentRules')};`,
          tt('billing.modal.transportationPriceCalculator'),
        ]
        : [
          `${tt('billing.modal.unlimitedNumberOfManagersInTheOrganization')};`,
          `${tt('billing.modal.automaticFillingOfDriverAndCarDataUsingATemplate')};`,
          tt('billing.modal.transportationPriceCalculator'),
        ];
    case EBillingPlanCode.freightForwarderBasic:
      return isUpdateBillingPlansAvailable
        ? [
          `${tt('billing.modal.twoManagersForOrganization')};`,
          `${tt('billing.modal.accessToOrdersTendersElectronicDocumentFlowAndFifteenTools')};`,
          `${tt('billing.modal.republishingOrdersOnATI')};`,
          `${tt('billing.modal.subcontracting')};`,
          tt('billing.modal.accessToSelfBilling'),
        ]
        : [
          `${tt('billing.modal.twoManagersForOrganization')};`,
          `${tt('billing.modal.accessToOrdersTendersElectronicDocumentFlowAndFifteenTools')};`,
          `${tt('billing.modal.republishingOrdersOnATI')};`,
          tt('billing.modal.subcontracting'),
        ];
    case EBillingPlanCode.freightForwarderExtended:
      return isUpdateBillingPlansAvailable
        ? [
          `${tt('billing.modal.unlimitedNumberOfManagersInTheOrganization')};`,
          `${tt('billing.modal.accessToResponsibleOnLookupAssignmentRules')}`,
          tt('billing.modal.transportationPriceCalculator'),
        ]
        : [
          `${tt('billing.modal.unlimitedNumberOfManagersInTheOrganization')};`,
          `${tt('billing.modal.automaticFillingOfDriverAndCarDataUsingATemplate')}`,
          tt('billing.modal.transportationPriceCalculator'),
        ];
    default:
      return [];
  }
};

import { normalizePayloadToDataIds } from '@/utils';

import * as types from './mutation-types';
import { TTenantsMutations } from './types';

const mutations: TTenantsMutations = {
  [types.SET_TENANTS](state, tenants) {
    state.userTenants = tenants;
  },
  [types.IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [types.SET_CURRENT_TENANT](state, selectedTenant) {
    state.currentTenant = selectedTenant;
  },
  [types.SET_TENANT_INFO](state, currentTenantInfo) {
    state.currentTenantInfo = currentTenantInfo;
  },
  [types.SET_LAST_TENANT_ID](state, tenantId) {
    state.lastTenantId = tenantId;
  },
  [types.ADD_TENANT_TO_LIST](state, payload) {
    state.userTenants = [...state.userTenants, payload];
  },
  [types.REMOVE_TENANT_FROM_LIST](state, payload) {
    state.userTenants = state.userTenants.filter((tenant) => tenant.id !== payload.id);
  },
  [types.SELECT_TENANT](state, payload) {
    if (state.userTenants.find((tenant) => tenant.id === payload.id)) {
      state.currentTenant = payload;
    } else {
      state.currentTenant = null;
    }
  },
  [types.RESET_STATE](state) {
    state.userTenants = [];
    state.currentTenant = null;
    state.currentTenantInfo = null;
    state.complexityCheck = null;
    state.isLoading = false;
    state.referenceData = null;
  },
  [types.SET_REFERENCE_DATA](state, referenceData) {
    const countries = normalizePayloadToDataIds(referenceData.countries, 'isoCode');
    const federalDistricts = normalizePayloadToDataIds(referenceData.federalDistricts, 'code');
    const regions = normalizePayloadToDataIds(referenceData.regions, 'isoCode');

    state.referenceData = {
      countries,
      federalDistricts,
      regions,
    };
  },
  [types.UPDATE_EMPLOYEE_VIEW_SETTINGS_COLUMNS](state, { tableName, columns }) {
    if (!state.currentTenant) return;
    state.currentTenant.employee.viewSettings[tableName] = columns;
  },
};

export default mutations;

export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT';
export const INCREMENT_UNREAD_COUNT = 'INCREMENT_UNREAD_COUNT';
export const DECREMENT_UNREAD_COUNT = 'DECREMENT_UNREAD_COUNT';
export const TOGGLE_NOTIFICATION_LIST_VISIBLE = 'TOGGLE_NOTIFICATION_LIST_VISIBLE';
export const SET_APP_STATE_WITH_UNREAD_NOTIFICATIONS = 'SET_APP_STATE_WITH_UNREAD_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const APPEND_NOTIFICATIONS = 'APPEND_NOTIFICATIONS';
export const SET_IS_NOTIFICATIONS_LOADING = 'SET_IS_NOTIFICATIONS_LOADING';
export const CHANGE_NOTIFICATION_READ_STATE = 'CHANGE_NOTIFICATION_READ_STATE';
export const RESET_STATE = 'RESET_STATE';
export const SET_IS_PERSONALIZED_NOTIFICATIONS_INFO_VISIBLE = 'SET_IS_PERSONALIZED_NOTIFICATIONS_INFO_VISIBLE';

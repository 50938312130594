import { RouteRecordRaw } from 'vue-router';

import logger from '@/logger';
import router from '@/router';

/**
 * Поскольку к некоторым роутам доступ осуществляется по заранее определенным правилам (например, роль пользователя),
 * поэтому сами компоненты роутов подгружаются только после успешной проверки.
 * Если доступа к роуту нет, приложение редиректит пользователя на доступный роут.
 * При смене тенантов нужно перестраивать роуты, к которым не удалось пользователю получить доступ, чтобы они правильно резолвились.
 */

type TProtectedRoutesStorage = Record<string, RouteRecordRaw>;

const protectedRoutesStorage: TProtectedRoutesStorage = {} as TProtectedRoutesStorage;
let rejectedProtectedRoutesNames: string[] = [];

export const addRejectedRouteName = (routeName: string) => {
  rejectedProtectedRoutesNames.push(routeName);
};

export const rebuildRejectedProtectedRoutes = () => {
  rejectedProtectedRoutesNames.forEach((routeName) => {
    const rejectedRoute = protectedRoutesStorage[routeName];
    router.removeRoute(routeName);
    router.addRoute(rejectedRoute);
  });

  rejectedProtectedRoutesNames = [];
};

export const addProtectedRouteToStorage = (protectedRoute: RouteRecordRaw, routeName: string) => {
  if (protectedRoutesStorage[routeName]) {
    logger.warn(`[buildProtectedRoute] Route name should be unique. Route name "${routeName}" is not unique!`);
  }
  protectedRoutesStorage[routeName] = protectedRoute;
};

import { computed } from 'vue';

import tt from '@/i18n/utils/translateText';
import isTenantVerified from '@/domains/isTenantVerified';

export const useView = () => {
  const title = computed(() => (!isTenantVerified()
    ? tt('billing.restrictionLayout.verificationTitle')
    : null));

  return { title };
};

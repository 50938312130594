import { onMounted } from 'vue';

import { i18n } from '@/i18n';
import { useI18nStore } from '@/stores/i18n';
import { ELocale } from '@/domains/constants';

const useI18n = () => {
  const i18nStore = useI18nStore();

  onMounted(() => {
    i18n.global.locale = i18nStore.currentLocale as ELocale;
  });
};

export default useI18n;

import { TPaginationState } from './types';

export const initialState = (): TPaginationState => ({
  // только для грузовладельца
  orderListOnlyOrdersTable: null,
  executorsLookupTable: null,
  priceListTable: null,
  directionsTable: null,
  quotasTable: null,
  employeesTable: null,
  partnershipsTable: null,
  directionOrdersTable: null,
  matricesTable: null,
  shipmentsListTable: null,
  customReferencesTable: null,
  ordersFolders: {
    on_execution: null,
    finalized: null,
    pending: null,
    on_bidding: null,
    on_target_offering: null,
  },
  // только для перевозчика
  vehiclesTable: null,
  runsTable: null,
  executorsLookupFeedTable: null,
  responsibleEmployeesTable: null,
  // общие
  executionRequestsTable: null,
  contractorPersonalAccountIncidentsTable: null,
  partnershipsPartnerContactsTable: null,
  selfBillingView: null,
  selfBillingListView: null,
  // лк контрагента, таблица рейсов
  contractorPersonalAccountPlannedRunsTable: null,
  contractorPersonalAccountCurrentRunsTable: null,
  contractorPersonalAccountFinishedRunsTable: null,
  // электронный документооборот
  operationalDocumentsListTable: null,
  // участники подбора
  executorsLookupParticipantsBidsTable: null,
  executorsLookupParticipantsVisitsTable: null,
  executorsLookupParticipantsUnvisitedTable: null,
  // согласования
  approvalsClaimsTable: null,
  approvalsSurchargesTable: null,
  // админка: входящие файлы
  incomingPayloadsTable: null,
  partnershipsPartnerContracts: null,
  // админка: список компаний
  tenantsAdminTable: null,
  partnershipContractsTable: null,
  partnershipInvitationsTable: null,
  partnershipResourcesVehicles: null,
  partnershipResourcesDrivers: null,
  tendersTable: null,
});

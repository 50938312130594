import { ROUTE_TYPE } from '@/constants';
import {
  hasCarrierWorkplaceSubscription,
  hasCargoOwnerWorkplaceSubscription,
  hasForwarderFeaturesSubscription,
} from '@/domains/permissions/subscription';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { canBrowseLookupStrategiesByRole } from '@/domains/permissions/role/lookupStrategies';

const routes = [
  buildProtectedRoute({
    path: '/executors_lookups/feed',
    name: 'ExecutorsLookupsFeed',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [hasCarrierWorkplaceSubscription, hasCarrierWorkplaceSubscription],
      component: () => import('@/contexts/executorsLookupContext/views/ExecutorsLookupsFeedView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/lookup_strategies',
    name: 'lookupStrategiesList',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseLookupStrategiesByRole,
        () => hasCargoOwnerWorkplaceSubscription() || hasForwarderFeaturesSubscription(),
      ],
      component: () => import('@/contexts/executorsLookupContext/views/LookupStrategiesListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/lookup_strategies/:id',
    name: 'LookupStrategyShowView',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseLookupStrategiesByRole,
        () => hasCargoOwnerWorkplaceSubscription() || hasForwarderFeaturesSubscription(),
      ],
      component: () => import('@/contexts/executorsLookupContext/views/LookupStrategyShowView/index.vue'),
    },
  }),
];

export default routes;

<template>
  <LayoutSider
    class="app-layout-sider"
    theme="light"
    collapsedWidth="64"
    breakpoint="md"
    width="260"
    collapsible
    :trigger="null"
    :collapsed="isCollapsed"
    @breakpoint="setGlobalBreakpoint"
  >
    <ContractorSiderContent
      v-if="isContractorLayout"
      :isCollapsed="isCollapsed"
      @collapse="handleCollapse"
    />
    <TmsSiderContent
      v-else
      :isCollapsed="isCollapsed"
      @collapse="handleCollapse"
    />
  </LayoutSider>
</template>

<script lang="ts">
import { LayoutSider } from 'ant-design-vue';
import {
  computed,
  defineComponent,
} from 'vue';

import useStore from '@/store/useStore';
import logger from '@/logger';

import TmsSiderContent from './components/TmsSiderContent/index.vue';
import ContractorSiderContent from './components/ContractorSiderContent/index.vue';

export default defineComponent({
  name: 'AppLayoutSider',
  components: {
    ContractorSiderContent,
    TmsSiderContent,
    LayoutSider,
  },
  setup() {
    const store = useStore();
    const isCollapsed = computed(() => store.state.app.isSiderCollapsed);
    const isContractorLayout = store.getters['app/isAuthByEntryCode'];

    const setGlobalBreakpoint = (event: any) => {
      logger.log('[Layout Breakpoint]', event);
    };

    const handleCollapse = () => {
      store.dispatch('app/setCollapsed', !isCollapsed.value);
    };

    return {
      isContractorLayout,
      setGlobalBreakpoint,
      isCollapsed,
      handleCollapse,
    };
  },
});
</script>

<style lang="scss" scoped src="./styles.scss" />

import adminApiClient from '@/api/adminApiClient';
import internalApiClient from '@/api/internalApiClient';

export const apiClient = {
  internal: internalApiClient,
  admin: adminApiClient,
};

export const httpMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

import getNotificationMapping from '@/contexts/notificationsContext/domain/getNotificationMapping';
import { TNotification } from '@/contexts/notificationsContext/domain/types';

const getNotificationActions = (notification: TNotification) => {
  const notificationMapping = getNotificationMapping(notification.type, notification.payload);
  if (!notificationMapping) return [];
  return notificationMapping.actions;
};

export default getNotificationActions;

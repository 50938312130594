import { defineStore } from 'pinia';
import { ref } from 'vue';

import { TReachedResponse } from '@/api/types';
import { getExternalDigitalQueuesManifests } from '@/contexts/contractorsContext/services';
import { showErrorNotification } from '@/utils';
import { TExternalDigitalQueueManifestResponse } from '@/contexts/contractorsContext/domain/types';

import { TExternalDigitalQueuesStore, TExternalDigitalQueuesStoreState } from './types';
import { DEFAULT_EXTERNAL_DIGITAL_QUEUES_STORE_STATE } from './constants';

export const useExternalDigitalQueuesStore = defineStore('externalDigitalQueues', (): TExternalDigitalQueuesStore => {
  const state = ref<TExternalDigitalQueuesStoreState>({ ...DEFAULT_EXTERNAL_DIGITAL_QUEUES_STORE_STATE });

  const loadExternalDigitalQueuesManifests = () => {
    getExternalDigitalQueuesManifests()
      .then((response: TReachedResponse<TExternalDigitalQueueManifestResponse>) => {
        state.value.manifests = response.data;
      })
      .catch(showErrorNotification);
  };

  const $reset = () => {
    state.value = { ...DEFAULT_EXTERNAL_DIGITAL_QUEUES_STORE_STATE };
  };

  return {
    state,

    loadExternalDigitalQueuesManifests,
    $reset,
  };
});

import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import {
  TTenantsResponse,
  TSessionWithTokensResponse,
  TCurrentTenantInfoResponse,
  TUserResponse,
  TSignInRequest,
  TSignUpRequest,
  TResponsibleEmployeesResponse,
  TSignInContractorRequest,
  TIntegrationsSettings,
  TNewTenantFormData,
  TUpdatePartnershipResourcesFeaturesPayload,
  TUpdateAdditionalSettingsPayload,
  TGetAdditionalSettingsResponse,
} from '@/contexts/accountingContext/domain/types';
import { TReferenceDataResponse, TTenantFeatures } from '@/types';
import { convertSessionResponse } from '@/domains/convertSessionResponse';
import { registrationSberBusinessAccount } from '@/components/SberBusinessIntegrationButton/services';
import { TForgotPasswordPayload } from '@/views/Infrastructure/ForgotPassword/types';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';

import { ABORT_SIGNAL_NAME } from './constants';
import { ELicenseRole } from '../domain/constants';

export function fetchOrganizationInfoByName({ name }: {
  name: string,
}) {
  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: '/lists/organizations',
    payload: { name },
  });
}

export function fetchTenantsForCurrentUser() {
  return sendApiRequest<TTenantsResponse>({
    method: httpMethods.GET,
    endpoint: '/user/tenants',
  });
}

export function fetchTenantBankAccount(tenantId: string) {
  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: `/${tenantId}/bank_account`,
  });
}

export function fetchTenantInfo(tenantId: string) {
  return sendApiRequest<TCurrentTenantInfoResponse>({
    method: httpMethods.GET,
    endpoint: `/${tenantId}/info`,
  });
}

export function editTenantBankAccount(payload: any, tenantId: string) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `/${tenantId}/bank_account`,
    payload,
  });
}

export function editTenantPublicOfferAccept(payload: {
  planCode: EBillingPlanCode,
  publicOfferAccept: boolean,
}, tenantId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/${tenantId}/public_offers/accept`,
    payload,
  });
}

export function editTenantLegalEntity(payload: any, tenantId: string) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `/${tenantId}/legal_entity`,
    payload,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function registerNewTenant({ tenantAttributes }: {
  tenantAttributes: TNewTenantFormData,
}) {
  const {
    name,
    inn,
    kpp,
    taxType,
    phone,
    legalAddress,
  } = tenantAttributes;

  const payload = {
    name,
    inn,
    kpp,
    taxType,
    phone,
    legalAddress,
    bank: {
      address: tenantAttributes.bankAddress,
      inn: tenantAttributes.bankInn,
      kpp: tenantAttributes.bankKpp,
      name: tenantAttributes.bankName,
      bik: tenantAttributes.bankBik,
      checkingAccount: tenantAttributes.bankCheckingAccount,
      correspondentAccount: tenantAttributes.bankCorrespondentAccount,
    },
  };

  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/user/tenants',
    payload,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function fetchUser() {
  return sendApiRequest<TUserResponse>({
    method: httpMethods.GET,
    endpoint: '/user',
  });
}

export function fetchResponsibleEmployees() {
  return sendApiRequest<TResponsibleEmployeesResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/responsible_employees',
  });
}

export function fetchExecutorEmployees() {
  return sendApiRequest<TResponsibleEmployeesResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/executor_employees',
  });
}

export function signUp({
  email,
  password,
  phone,
  firstName,
  lastName,
  utmSources,
}: TSignUpRequest) {
  return sendApiRequest<TSessionWithTokensResponse>({
    method: httpMethods.POST,
    endpoint: '/user',
    payload: {
      firstName,
      lastName,
      phone,
      email,
      password,
      utmSources,
    },
    withoutAuth: true,
  })
    .then(convertSessionResponse);
}

export function socialSignUp(payload: TSignUpRequest) {
  return registrationSberBusinessAccount(payload);
}

export function signIn(payload: TSignInRequest | TSignInContractorRequest) {
  return sendApiRequest<TSessionWithTokensResponse>({
    method: httpMethods.POST,
    endpoint: '/session',
    payload,
    withoutAuth: true,
  })
    .then(convertSessionResponse);
}

export function unlockAccount(payload: {
  code: string,
}) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: '/user/lock',
    payload,
  });
}

export function refreshAccessToken({ refreshToken }: {
  refreshToken: string,
}) {
  return sendApiRequest<TSessionWithTokensResponse>({
    method: httpMethods.PATCH,
    endpoint: '/session',
    headers: { 'X-Refresh-Token': `Bearer ${refreshToken}` },
    withoutAuth: true,
    signalName: ABORT_SIGNAL_NAME.accountingContextRefreshAccessToken,
  })
    .then(convertSessionResponse);
}

export function signOut({ refreshToken }: {
  refreshToken: string,
}) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: '/session',
    headers: { 'X-Refresh-Token': `Bearer ${refreshToken}` },
  });
}

export function forgotPassword(payload: TForgotPasswordPayload) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/reset_passwords',
    payload,
    withoutAuth: true,
  });
}

export function resetPassword({ password, resetPasswordToken }: {
  password: string,
  resetPasswordToken: string,
}) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: '/reset_passwords',
    payload: {
      password,
      resetPasswordToken,
    },
    withoutAuth: true,
  });
}

export const fetchBankAccountsAutocomplete = (query: string) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/autocomplete/bank_accounts',
  params: { query },
});

export function editUserPersonalData({
  firstName,
  lastName,
  secondName,
}: {
  firstName: string,
  lastName: string,
  secondName: string,
}) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: '/user/personal_data',
    payload: {
      firstName,
      lastName,
      secondName,
    },
  });
}

export function editUserPassword({ oldPassword, newPassword }: {
  oldPassword: string,
  newPassword: string,
}) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: '/user/password',
    payload: {
      oldPassword,
      newPassword,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function editUserOTP({ newPassword }: {
  newPassword: string,
}) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: '/user/password',
    payload: { newPassword },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function editUserPhone({ phone }: {
  phone: string,
}) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: '/user/phone',
    payload: { phone },
  });
}

export function editTenantFeatures(payload: TTenantFeatures) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: '/:tenant_id/features',
    payload,
  });
}

export function editDataByField(field: string, payload: any) {
  switch (field) {
    case 'phone':
      return editUserPhone(payload);
    case 'fullName':
      return editUserPersonalData(payload);
    case 'password':
      return editUserPassword(payload);
    default:
      return Promise.reject();
  }
}

export function makeRequestToEmailChange(newEmail: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/user/change_email_request',
    payload: { newEmail },
  });
}

export function confirmEmailChange(code: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/user/change_email_request/confirmation',
    payload: { code },
  });
}

export function acceptInviteToCompany(invitationId: string, code: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/user/invitations/${invitationId}/acceptance`,
    payload: { code },
  });
}

// TODO: в каком контексте описывать этот сервис, который относится к общим данным системы ?
export function getReferenceData() {
  return sendApiRequest<TReferenceDataResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/reference_data',
  });
}

export const getIntegrationsSettings = () => sendApiRequest<TIntegrationsSettings>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/integrations',
});

export const updateIntegrationsSettings = (payload: TIntegrationsSettings) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: '/:tenant_id/integrations',
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export function setLicenseRole(licenseRole: ELicenseRole) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/license_roles',
    payload: { licenseRole },
  });
}

export const updatePartnershipResourcesFeatures = (
  payload: TUpdatePartnershipResourcesFeaturesPayload,
) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: '/:tenant_id/partnership_resources_features',
  payload,
});

export const getAdditionalSettings = (
  payload: TUpdateAdditionalSettingsPayload,
) => sendApiRequest<TGetAdditionalSettingsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/additional_settings',
  payload,
});

export const updateAdditionalSettings = (
  payload: TUpdateAdditionalSettingsPayload,
) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: '/:tenant_id/additional_settings',
  payload,
});

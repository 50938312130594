import { ROUTE_TYPE } from '@/constants';
import { ROUTE_NAME } from '@/contexts/pricingContext/domain/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { hasCargoOwnerWorkplaceSubscription } from '@/domains/permissions/subscription';
import { canBrowsePriceListsByRole } from '@/domains/permissions/role/priceLists';
import { getIsTenantWithCustomization } from '@/domains/getIsTenantWithCustomization';

const routes = [
  buildProtectedRoute({
    path: '/price_lists',
    name: ROUTE_NAME.priceLists,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        () => !getIsTenantWithCustomization(),
        hasCargoOwnerWorkplaceSubscription,
        canBrowsePriceListsByRole,
      ],
      component: () => import('@/contexts/pricingContext/views/PriceListsListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/price_lists/:id',
    name: ROUTE_NAME.priceList,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        () => !getIsTenantWithCustomization(),
        hasCargoOwnerWorkplaceSubscription,
        canBrowsePriceListsByRole,
      ],
      component: () => import('@/contexts/pricingContext/views/PriceListInfoView/index.vue'),
    },
  }),
];

export default routes;

import {
  computed,
  onMounted,
  onUnmounted,
  Ref,
  ref,
} from 'vue';

import { ONE_SECOND } from '@/constants';
import { joinString } from '@/utils';
import tt from '@/i18n/utils/translateText';
import { getNowMs, getDatesInterval } from '@/utils/dateUtils';

type TExpirationTimerOptions = {
  expirationDateRef: Ref<string | number | null | undefined>,
  suffix?: string | null,
  labelOnFinish?: string | null,
};

export type TExpirationTimerFormat = 'letter' | 'digital' | 'digitalWithDays';

// TODO: возможно здесь нужно обновлять чаще, чем раз в секунду, чтобы показывались максимально актуальные секунды
const useExpirationTimer = (
  { expirationDateRef, labelOnFinish = tt('shared.tradesExpired'), suffix = null } = {} as TExpirationTimerOptions,
  timeout = ONE_SECOND, format: TExpirationTimerFormat = 'letter',
) => {
  const expiration = ref('');
  const computeExpiration = () => {
    const datesInterval = expirationDateRef.value && getDatesInterval(expirationDateRef.value, getNowMs(), format);
    expiration.value = datesInterval
      ? joinString([datesInterval, suffix], ' ')
      : '';
  };
  const getCorrectExpiration = (expiration) => (expiration.includes('-') ? labelOnFinish : expiration);
  const expirationValue = computed<string>(() => getCorrectExpiration(expiration.value));

  const isMounted = ref(false);
  const expirationTimerId = ref(null as null | number);

  onMounted(() => {
    if (!expirationDateRef?.value && expirationDateRef?.value !== 0) {
      return;
    }
    computeExpiration();
    isMounted.value = true;
    /**
     * TODO: запускать один глобальный setInterval и подписывать на него нужные обработчики
     * docs/adr/0008-global-intervals.md
     */
    expirationTimerId.value = window.setInterval(() => {
      if (isMounted.value) {
        computeExpiration();
      } else {
        clearInterval(expirationTimerId.value as number);
      }
    }, timeout);
  });

  onUnmounted(() => {
    clearInterval(expirationTimerId.value as number);
    expirationTimerId.value = null;
    isMounted.value = false;
  });

  return { expirationValue };
};

export default useExpirationTimer;

import { EBillingPlanGroup } from '@/contexts/billingContext/domain/constants';
import { TSubscription } from '@/types';

import { TGroupedAvailablePlans } from './types';

type TGetIsDisabledTab = {
  planCodeGroup: EBillingPlanGroup,
  groupedAvailablePlans: TGroupedAvailablePlans | null | undefined,
  currentActiveSubscription: TSubscription | null,
};

export const getIsDisabledTab = ({
  planCodeGroup,
  groupedAvailablePlans,
  currentActiveSubscription,
}: TGetIsDisabledTab): boolean => groupedAvailablePlans?.[planCodeGroup].length === 0
  && !currentActiveSubscription?.planCode?.includes(planCodeGroup);

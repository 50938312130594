export enum EContractsTableColumnKey {
  iid = 'iid',
  id = 'id',
  name = 'name',
  direction = 'direction',
  partnerType = 'partnerType',
  payer = 'payer',
  servicer = 'servicer',
  servicerCompany = 'servicerCompany',
  status = 'status',
  period = 'period',
}

export enum EContractsTableFilter {
  all = 'all',
  active = 'active',
  inactive = 'inactive',
  archived = 'archived',
}

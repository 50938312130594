<template>
  <div class="bank-account-info">
    <div class="bank-account-info__item">
      <p class="bank-account-info__title">
        {{ tt('tenant.settings.bankName') }}
      </p>
      <p class="bank-account-info__text">
        {{ bank.bankName }}
      </p>
    </div>
    <div class="bank-account-info__item">
      <p class="bank-account-info__title">
        {{ tt('tenant.settings.bankCorrespondentAccount') }}
      </p>
      <p class="bank-account-info__text">
        {{ bank.bankCorrespondentAccount }}
      </p>
    </div>
    <div class="bank-account-info__item">
      <p class="bank-account-info__title">
        {{ tt('tenant.settings.bankAddress') }}
      </p>
      <p class="bank-account-info__text">
        {{ bank.bankAddress }}
      </p>
    </div>
    <div class="bank-account-info__item">
      <p class="bank-account-info__title">
        {{ tt('shared.inn') }}
      </p>
      <p class="bank-account-info__text">
        {{ bank.bankInn }}
      </p>
    </div>
    <div class="bank-account-info__item">
      <p class="bank-account-info__title">
        {{ tt('shared.kpp') }}
      </p>
      <p class="bank-account-info__text">
        {{ bank.bankKpp }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';

export default defineComponent({
  name: 'BankAccountInfo',
  props: {
    bank: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return { tt };
  },
});
</script>

<style lang="scss" src="./styles.scss" />

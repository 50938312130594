import { hasProperty } from '@/utils';
import { TPagination } from '@/types';

import * as types from './mutation-types';
import {
  TPaginationMutations,
  TPaginationPayload,
  TPaginationState,
  TPaginationResetPayload,
} from './types';
import { initialState } from './state';

const getPreparedPagination = (pagination: TPagination, resetOnlyCurrentPage: boolean): TPagination | null => (
  resetOnlyCurrentPage ? {
    ...pagination,
    currentPage: 1,
  } : null
);

const mutations: TPaginationMutations = {
  [types.SET_TABLE_PAGINATION](state: TPaginationState, {
    folderName,
    tableName,
    pagination,
  }: TPaginationPayload) {
    if (folderName) {
      if (!hasProperty(state, folderName)) {
        console.warn(`There is no folder with "${folderName}" name.`);
      } else {
        state[folderName][tableName] = pagination;
      }
    } else if (!hasProperty(state, tableName)) {
      console.warn(`There is no table with "${tableName}" name.`);
    } else {
      state[tableName] = pagination;
    }
  },
  [types.RESET_ALL_PAGINATION](state: TPaginationState) {
    const defaultState = initialState();
    Object.keys(defaultState).forEach((key: string) => {
      state[key] = structuredClone(defaultState[key]);
    });
  },
  [types.RESET_PAGINATION](state: TPaginationState, {
    tableName,
    folderName,
    resetAllTablesInFolder,
    resetOnlyCurrentPage = true,
  }: TPaginationResetPayload) {
    if (folderName && !hasProperty(state, folderName)) {
      console.warn(`There is no folder with "${folderName}" name.`);

      return;
    }

    if (!folderName && tableName && !hasProperty(state, tableName)) {
      console.warn(`There is no table with "${tableName}" name.`);

      return;
    }

    if (resetAllTablesInFolder && folderName) {
      Object.keys(state[folderName]).forEach((name: string) => {
        const currentPagination = state[folderName][name];

        if (currentPagination) {
          state[folderName][name] = getPreparedPagination(currentPagination, resetOnlyCurrentPage);
        }
      });
    } else if (folderName && tableName) {
      const currentPagination = state[folderName][tableName];

      if (currentPagination) {
        state[folderName][tableName] = getPreparedPagination(currentPagination, resetOnlyCurrentPage);
      }
    } else if (tableName) {
      const currentPagination = state[tableName];

      if (currentPagination) {
        state[tableName] = getPreparedPagination(currentPagination, resetOnlyCurrentPage);
      }
    }
  },
};

export default mutations;

/* eslint-disable no-restricted-imports */
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';

/**
 * relativeTime для использования dayjs.fromNow();
 */
dayjs.extend(relativeTime);
/**
 * duration для использования dayjs.duration();
 */
dayjs.extend(duration);
/**
 * customParseFormat нужен чтобы dayjs мог парсить специфичные форматы, вроде того, когда мы передаём только время
 * dayjs('13:27')
 */
dayjs.extend(customParseFormat);

dayjs.extend(utc);

// Плагин позволяет парсить дату в необходимой таймзоне (dayjs().tz(timezone))
dayjs.extend(timezone);

dayjs.extend(calendar);

// Для получения номера недели и получения дат из номера недели в году
dayjs.extend(isoWeek);

dayjs.locale('ru');

export default dayjs;

import { isOptionsGroupsGuard } from '@/ui/utils/getOptionsGroupsGuard';
import {
  TGroupedOptionsGroup,
  TGroupedOption,
  TGroupedOptions,
} from '@/ui/types';

// Унифицируем, сводя все к виду сгруппированных опций (в случае обычного списка опций - группа одна)
// а также индексируем все опции, чтобы суметь в последствии управлять их фокусом
export const getGroupedOptions = (options: TGroupedOptions) => (
  isOptionsGroupsGuard(options)
    ? options.reduce((
      result: TGroupedOptionsGroup[],
      group: TGroupedOptionsGroup,
    ) => {
      // получаем уже накопленную длину всех опций
      const resultOptionsLength = result.map((
        option: TGroupedOptionsGroup,
      ) => option.options).flat().length;

      // индексируем каждую опцию с поправкой на общую длину массива всех опций вне зависимости от группы
      const groupOptions = group.options.map((option: TGroupedOption, i: number) => ({
        ...option,
        key: String(resultOptionsLength + i),
      }));
      return result.concat({
        ...group,
        options: groupOptions,
      });
    }, [])
    : [{
      options: options.map((option: TGroupedOption, i: number) => ({
        ...option,
        key: String(i),
      })),
    }]
) as TGroupedOptionsGroup[];

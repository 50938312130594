import { useRoute } from 'vue-router';

import store from '@/store';
import { ROUTE_TYPE } from '@/constants';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { TSubscription } from '@/types';

export const getIsBillingPaywallMessageShown = (): boolean => {
  const route = useRoute();
  const isPrivateRoute = route?.meta?.type === ROUTE_TYPE.private;

  const hasTenants = store.state.tenants.userTenants?.length > 0;

  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;

  const currentTenantSubscriptions = store.state.tenants.currentTenantInfo?.subscriptions || [];

  const hasSubscriptionWithActiveStatus = !!currentTenantSubscriptions
    .find((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active);

  const hasCarrierStartSubscription = !!currentTenantSubscriptions.find(
    (subscription: TSubscription) => subscription.planCode === EBillingPlanCode.carrierStart,
  );

  // инстанс может работать либо по лицензии, либо с биллингом
  return !hasInstanceLicense
    && hasTenants
    && isPrivateRoute
    && (!hasSubscriptionWithActiveStatus || hasCarrierStartSubscription);
};
